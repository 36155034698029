import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
    Badge,
    Button,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    NavItem,
    Row,
} from "reactstrap";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import { firestore } from '../../../../helpers/Firebase';
import { accessTypes, accessTypesObject, checkPhoneNumberVisibility, createNotification, getPaymentMethod, validateNumber } from "../../../../helpers/Utils";
import Logs from "./Logs";
import ManageOrder from './ManageOrder';
import DetailsTable from "./receipt";
import { NavLink } from 'react-router-dom';
import { setUserFromRequests } from '../../../../redux/chat/actions';
import WhatsappIcon from "../../../../assets/css/img/whatsapp.png";

const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly />
);
const Order = ({
    activeServices,
    toggleModal,
    dismiss,
    selectedBooking,
    update,
    fromHistory }) => {
    const intervalRef = useRef(null);

    const { user } = useSelector(state => state.authUser)
    const [manageOrder, setManageOrder] = useState(false)
    const [waitingTime, setWaitingTime] = useState(5)
    const [chatExists, setChatExists] = useState(false)
    const [showNumber, setShowNumber] = useState(false)
    console.log('chatExists', chatExists, showNumber);

    const { provider, washers, bookings } = useSelector(state => state.providers)
    const [state, setState] = useState(selectedBooking || {})
    const [cancelAppointment, setCancelAppointment] = useState(false)

    const [modifiers, setModifiers] = useState(false)
    const [logs, setLogs] = useState(false)
    const [logsOpen, setLogsOpen] = useState(false)

    const [addonsOptimized, setAddonsOptimized] = useState(false)
    const [superAdminComment, setSuperAdminComment] = useState('')
    const dispatch = useDispatch()

    const startCheckingVisibility = async () => {
        const result = await checkPhoneNumberVisibility({ request: state, waitingTime: waitingTime });
        setShowNumber(result.showNumber);
        setChatExists(result.chatExists);
    };

    useEffect(() => {
        startCheckingVisibility()
        if (!state?.customer_number) return;
        // Start checking visibility every 30 seconds
        intervalRef.current = setInterval(startCheckingVisibility, 30 * 1000);
        // Clean up the interval when the component is unmounted or dependencies change
        return () => clearInterval(intervalRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, waitingTime]);

    useEffect(() => {
        firestore().collection('logs').doc(state.key).onSnapshot(function (logsSnapshot) {
            if (logsSnapshot.exists) {
                setLogs(logsSnapshot.data());
            }
        });
        firestore().collection('settings').doc('messageWaitTime').onSnapshot((snap) => {
            if (snap.exists) {
                setWaitingTime(snap.data()?.amount || 5);
            } else {
                setWaitingTime(5);
            }
        })
        firestore().collection('modifiers').get().then(function (modifiersSnapshot) {
            let allData = {}

            modifiersSnapshot.forEach(rec => {
                let data = rec.data()

                if (!data.deActivated) {
                    allData[rec.id] = { ...data, key: rec.id, value: data.name, label: data.name }
                    return false
                }
            })
            setModifiers(allData)
        });
        if (state.addons) {
            let addonsOptimized = state?.addons?.reduce((cars, currentAddon) => {
                if (currentAddon.cars) {
                    Object.keys(currentAddon.cars).map(carKey => {
                        let car = currentAddon.cars[carKey]
                        if (cars[car.car_model]) {
                            cars[car.car_model][currentAddon.id] = { name: currentAddon.name, type: currentAddon.type, price: car.price, category: car.category, count: car.count }
                            return cars
                        } else {
                            cars[car.car_model] = { [currentAddon.id]: { name: currentAddon.name, type: currentAddon.type, price: car.price, category: car.category, count: car.count } }
                            return cars
                        }
                    })
                }
                return cars
            }, {})
            setAddonsOptimized(addonsOptimized)
        }
    }, [state])
    const submit = () => {

        const newItem = {
            updated: Date.now(),
            status: (state.status === 'pending' || state.status === 'new' ? 'accepted' : state.status === 'accepted' ? 'active' : state.status === 'active' ? 'completed' : state.status === 'completed' || state.status === 'rated' ? 'completed' : ''),
        };
        firestore().collection('bookings').doc(state.key).update(newItem).then(function () {
            firestore().collection('logs').doc(state.key).set({
                data: firestore.FieldValue.arrayUnion({
                    title: 'statusChanged',
                    created: Date.now(),
                    oldsStatus: selectedBooking.status,
                    status: state.status,
                    createdBy: accessTypesObject?.[user?.accessType].name,
                    createdByName: user?.displayName
                }),
                updated: Date.now()
            }, { merge: true }).catch(error => error)


        }).catch(function (error) {
            console.error("Error writing document: ", error);
        });


        if (fromHistory) {
            update();
        } else {
            toggleModal();
        }

        dismiss();
    };
    const handleDateChange = (washer_id, dateSelected) => {
        let washer = Object.values(washers).find(item => item.key === washer_id)
        handleWasherChange(washer, dateSelected)
    }
    const handleWasherChange = (val, dateSelected) => {
        const { bookingDate, bookingTime } = state;
        const washerBookings = Object.values(bookings).filter(item => item.washer_id === val.key);

        const today = moment(dateSelected || bookingDate).format('dddd');
        const isOpenToday = val.active && today !== 'Invalid date' && val.timings[today]?.isOpen;
        const workerTimings = val?.timings?.[today]?.timings; // Assuming worker's timings for the selected day is an array

        if (isOpenToday) {
            const selectedTime = moment(dateSelected);

            // Check if the selected time falls within any of the worker's available time ranges
            const isWithinTimings = workerTimings.some(timeRange => {
                const startTime = moment(timeRange.startTime).set({
                    year: moment(selectedTime).year(),
                    month: moment(selectedTime).month(),
                    date: moment(selectedTime).date(),
                });
                const endTime = moment(timeRange.endTime).set({
                    year: moment(selectedTime).year(),
                    month: moment(selectedTime).month(),
                    date: moment(selectedTime).date(),
                });

                return selectedTime.isBetween(startTime, endTime);
            });
            if (isWithinTimings) {
                if (washerBookings.length) {
                    const overlap = washerBookings.some(item => {
                        if (
                            item.bookingTime &&
                            (['new', 'pending', 'accepted', 'active'].includes(item.status))
                        ) {
                            const startTime = moment(item.bookingTime.startTime).set({
                                year: moment(item.bookingDate).year(),
                                month: moment(item.bookingDate).month(),
                                date: moment(item.bookingDate).date(),
                            });
                            const endTime = moment(item.bookingTime.endTime).set({
                                year: moment(item.bookingDate).year(),
                                month: moment(item.bookingDate).month(),
                                date: moment(item.bookingDate).date(),
                            });

                            const currentBookingEndTime = moment(bookingTime?.endTime).set({
                                year: moment(dateSelected).year(),
                                month: moment(dateSelected).month(),
                                date: moment(dateSelected).date(),
                            });

                            return (
                                (moment(dateSelected).isSame(startTime) || moment(dateSelected).isBetween(startTime, endTime)) ||
                                currentBookingEndTime.isBetween(startTime, endTime)
                            );
                        }
                        return false;
                    });

                    if (overlap) {
                        const newStartTime = moment(`1955-01-05T${moment(dateSelected).format('HH:mm')}`).toDate().getTime();
                        const newEndTime = moment(newStartTime).add(moment.duration(bookingTime?.endTime - bookingTime?.startTime).asMinutes(), 'minutes').toDate().getTime();

                        if (dateSelected) {
                            setState({
                                ...state,
                                reAssignDate: true,
                                bookingDate: dateSelected,
                                bookingTime: { startTime: newStartTime, endTime: newEndTime },
                                overlap: true
                            });
                        } else {
                            setState({
                                ...state,
                                reAssignWasher: true, washer_name: val.label, washer_id: val.key, overlap: true
                            });
                        }
                    } else {
                        if (dateSelected) {
                            const newStartTime = moment(`1955-01-05T${moment(dateSelected).format('HH:mm')}`).toDate().getTime();
                            const newEndTime = moment(newStartTime).add(moment.duration(bookingTime?.endTime - bookingTime?.startTime).asMinutes(), 'minutes').toDate().getTime();

                            setState({ ...state, reAssignDate: true, bookingDate: dateSelected, bookingTime: { startTime: newStartTime, endTime: newEndTime } });
                        } else {
                            setState({
                                ...state,
                                reAssignWasher: true, washer_name: val.label, washer_id: val.key
                            });
                        }
                    }
                } else {
                    if (val.timings[today]?.isOpen) {
                        if (dateSelected) {
                            setState({ ...state, reAssignDate: true, bookingDate: dateSelected });
                        } else {
                            setState({ ...state, reAssignWasher: true, washer_name: val.label, washer_id: val.key });
                        }
                    } else {
                        createNotification("error", "This worker is not available today", 'Error');
                        if (dateSelected) {
                            setState({ ...state, bookingDate: selectedBooking.bookingDate, bookingTime: selectedBooking.bookingTime });
                        }
                    }
                }
            } else {
                createNotification("error", "Selected time is outside worker's availability", 'Error');
                if (dateSelected) {
                    setState({ ...state, bookingDate: selectedBooking.bookingDate, bookingTime: selectedBooking.bookingTime });
                }
            }
        } else {
            createNotification("error", "This worker is not available today", 'Error');
            if (dateSelected) {
                setState({ ...state, bookingDate: selectedBooking.bookingDate, bookingTime: selectedBooking.bookingTime });
            }
        }
    }

    return (

        <Modal
            isOpen={selectedBooking}
            toggle={dismiss}
            // wrapClassName="modal-right"
            backdrop="static"
            size="lg">
            <ModalHeader toggle={dismiss}>
                <div style={{ fontWeight: '600' }} className="mb-1">
                    {state && ((moment(state.created).to(moment()).replace('in ', '')) + " Ago")}
                </div>
                <div className="font-weight-bold m-0 mb-2">Order ID: {state?.orderNumber}</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Badge
                        color={state.type === 'offlineBooking' ? `danger` : 'success'}
                        pill>
                        {state?.subscription ? 'Subscription' : (state?.type === 'offlineBooking' ? `Offline Appointment` : 'Online Appointment')}
                    </Badge>

                </div>
                <Button style={{ position: 'absolute', right: '27px', top: '80px' }} color="primary" size="sm" outline onClick={() => {
                    setLogsOpen(true)
                }}>
                    Show Logs
                </Button>
            </ModalHeader>
            <ModalBody>
                <Row>
                    {/* {chatExists && (!accessTypes.includes(user?.accessType) ? ['new', 'accepted', 'active'].includes(state?.status) : true) ?
                        <Colxx xxs="6" md="6">
                            <Label className="mb-0  text-semi-muted">Customer Number:</Label>
                            <NavLink
                                to={'/app/chat'}
                                onClick={e => dispatch(setUserFromRequests(`971${(state?.customer_number).replace(/\D/g, '')}`))}>
                                <Button color="success" className='d-flex align-items-center' size='xs' >
                                    Chat With Customer <img style={{ width: '15px', marginLeft: '8px' }} alt="" src={WhatsappIcon} />
                                </Button>
                            </NavLink>
                        </Colxx>
                        :
                        <Colxx xxs="6" md="6">
                            <Label className="mb-0  text-semi-muted">Customer Number:</Label>
                            <p className="font-weight-bold">{accessTypes.includes(user?.accessType) ? state?.customer_number : '---'}</p>
                        </Colxx>} */}
                    <Colxx xxs="6" md="6">
                        <Label className="mb-0  text-semi-muted">Customer Number:</Label>
                        <NavLink
                            to={!chatExists ? '#' : '/app/chat'}
                            className='d-flex align-items-center'
                            onClick={e => {
                                if (!chatExists) {
                                    createNotification('error', 'Customer is not available on WhatsApp', 'Error');
                                    return;
                                }
                                dispatch(setUserFromRequests(`${Number(validateNumber((state.country_code || '971'), state.customer_number))}`))
                            }}>
                            {chatExists && !showNumber ? <Button color="success" className='d-flex align-items-center' size='xs' >
                                Chat With Customer <img style={{ width: '15px', marginLeft: '4px' }} alt="" src={WhatsappIcon} />
                            </Button> :
                                <>
                                    {chatExists && <img style={{ width: '30px', marginRight: '4px' }} alt="" src={WhatsappIcon} />}
                                    <p className="font-weight-bold m-0 p-0">{accessTypes.includes(user?.accessType) || showNumber ? Number(validateNumber((state.country_code || '971'), state.customer_number)) : '---'}</p>
                                </>
                            }
                        </NavLink>
                    </Colxx>
                    <Colxx xxs="6" md="6">
                        <div className="mb-0  text-semi-muted">Payment Method:</div>
                        <p className="font-weight-bold">{getPaymentMethod({ paymentType: state?.paymentType, price: state?.price })}</p>
                    </Colxx>
                    <Colxx xxs="6" md="6">
                        <Label className="mb-0  text-semi-muted">Customer Name:</Label>
                        <p className="font-weight-bold">{state?.customer_name}</p>
                    </Colxx>
                    {['card', 'applepay', 'wallet', 'wallet / online']?.includes(getPaymentMethod({ paymentType: state?.paymentType, price: state?.price })) && state?.paymentMethod && state?.paymentMethod.authorize_id ?
                        <Colxx xxs="6" md="6">
                            <div className="mb-0  text-semi-muted">Transaction ID:</div>
                            <p className="font-weight-bold">{state?.paymentMethod?.authorize_id}</p>
                        </Colxx>
                        : ''}
                    <Colxx xxs="6" md="6">
                        <Label className="mb-0  text-semi-muted">Customer Address:</Label>
                        <p className="font-weight-bold">{state?.place?.address}</p>
                    </Colxx>
                    {state?.tip_washer?.authorize_id ?
                        <Colxx xxs="6" md="6">
                            <div className="mb-0  text-semi-muted">Worker Tip Transaction ID:</div>
                            <p className="font-weight-bold">{state?.tip_washer?.authorize_id}</p>
                        </Colxx>
                        : ''}
                    {state?.place?.additionalInstruction && <Colxx xxs="12" md="12">
                        <Label className="mb-0  text-semi-muted">Additional Instructions:</Label>
                        <p className="font-weight-bold">{state?.place.additionalInstruction}</p>
                    </Colxx>
                    }
                    {state?.notes && <Colxx xxs="12" md="12">
                        <Label className="mb-0  text-semi-muted">Notes:</Label>
                        <p className="font-weight-bold">{state?.notes}</p>
                    </Colxx>
                    }

                </Row>
                <div className="border-bottom p-3 mb-5" />

                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <h5 >Vehicles Details</h5>{state?.carDetail?.length ? <h6 style={{ fontWeight: '700', padding: '0 8px' }}>{state.carDetail.length + " vehicle(s)"} </h6> : ''}
                </div>
                <Row>
                    <Colxx xxs="12" md="12">
                        {
                            state?.carDetail?.length ?
                                state?.carDetail.map((car, index) => {
                                    return (
                                        <Colxx xxs="12" md="6" key={index} style={{ padding: '8px', border: '1px solid #cacaca', borderRadius: '4px', margin: '4px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{car?.car_model}{" "}{car.modelNumber?.model}{" "}{car.modelNumber?.category}</div>
                                            <div>{car.plateNumber}</div>
                                        </Colxx>
                                    )

                                })
                                : state?.carDetail?.modelNumber?.category ?
                                    <Colxx xxs="12" md="4">
                                        <div style={{ padding: '8px', border: '1px solid #cacaca', borderRadius: '4px', margin: '4px 0' }}>
                                            <div>{state?.carDetail?.car_model || ''}{" "}{state?.carDetail.modelNumber?.model || ''}{" "}{state?.carDetail.modelNumber?.category || ''}</div>
                                            <div>{state?.carDetail?.plateNumber || ''}</div>
                                        </div>
                                    </Colxx>
                                    : ''
                        }
                    </Colxx>
                </Row>
                {accessTypes.includes(user?.accessType) ?
                    <>
                        <div className="border-bottom p-3 mb-5" />

                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <h5>Service Provider Details</h5>
                        </div>
                        <Row>
                            <Colxx xxs="4" md="4">
                                <Label className="mb-0  text-semi-muted">Provider Name:</Label>
                                <p className="font-weight-bold">{state?.sp_name}</p>
                            </Colxx>
                            <Colxx xxs="4" md="4">
                                <Label className="mb-0  text-semi-muted">Washer Name:</Label>
                                <p className="font-weight-bold">{state?.washer_name}</p>
                            </Colxx>
                            <Colxx xxs="4" md="4">
                                <Label className="mb-0  text-semi-muted">Washer Number:</Label>
                                <p className="font-weight-bold">{Object.values(washers).find((record) => record?.key === state?.washer_id)?.phoneNumber || '--'}</p>
                            </Colxx>
                        </Row>
                    </> : ''
                }

                < div className="border-bottom p-3 mb-5" />
                <h5 className="mt-4">Appointment Details</h5>
                <Row>
                    <Colxx xxs="8" md="4">
                        <Label className="mt-2">Booking Date *</Label>
                        <DatePicker
                            popperClassName="redatepicker"
                            customInput={<DatepickerInput />}
                            selected={moment(state.bookingDate || '').toDate()}
                            onBlur={() => {
                                if (state?.bookingDate !== selectedBooking?.bookingDate) {
                                    handleDateChange(state?.washer_id, state?.bookingDate)
                                }
                            }}
                            onCalendarClose={() => {
                                if (state?.bookingDate !== selectedBooking?.bookingDate) {
                                    handleDateChange(state?.washer_id, state?.bookingDate)
                                }
                            }}
                            onChange={date => {
                                let duration = moment.duration(moment(state?.bookingTime.endTime).diff(moment(state?.bookingTime.startTime)));

                                setState({
                                    ...state,
                                    bookingDate: moment(moment(date).format('lll')).toDate().getTime(),
                                    bookingTime: {
                                        startTime: moment("1955-01-05T" + moment(date).format('HH:mm')).toDate().getTime(),
                                        endTime: moment("1955-01-05T" + moment(date).format('HH:mm')).add(duration.asMinutes(), 'minutes').toDate().getTime()

                                    }
                                })

                            }}
                            showTimeSelect
                            disabled={!accessTypes.includes(user?.accessType) && (['completed', 'rated', 'rejected', 'canceled'].includes(state?.status) || fromHistory)}

                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="dd/MM/yyyy hh:mm a"
                        />
                    </Colxx>
                    <Colxx xxs="8" md="4">
                        <Label className="mt-2">Washer *</Label>
                        <Select
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="form-field-name"
                            options={Object.values(washers).filter(w => w.sp_id === state?.sp_id)}
                            isDisabled={!accessTypes.includes(user?.accessType) && (['completed', 'rated', 'rejected', 'canceled'].includes(state?.status) || fromHistory)}
                            value={washers?.[state?.washer_id]}
                            onChange={val => {
                                if (state.washer_id !== val.key) {
                                    handleWasherChange(val)
                                }
                            }}
                        />
                    </Colxx>
                    <Colxx xxs="8" md="4">
                        <Label className="mt-2">Service *</Label>
                        <Select
                            isDisabled
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="form-field-name"
                            options={Object.values(activeServices)}
                            value={Object.values(activeServices)?.find(s => s.key === state?.service_id)}

                        />
                    </Colxx>
                </Row>
                { !state?.subscription ? <div className="border-bottom p-3 mb-5" /> : ''}
                { !state?.subscription ?
                    <div>
                        <h5 className="mt-4">Details</h5>
                        <DetailsTable modifiers={modifiers} carDetail={state?.carDetail} selectedBooking={state} washer={Object.values(washers).find((record) => record?.key === state?.washer_id)} service={state.service_id} price={state?.price} addonsOptimized={addonsOptimized} />
                    </div>
                    : ''
                }
                {accessTypes.includes(user?.accessType) && <>
                    <div className="border-bottom p-3 mb-5" />
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <h6 >Super Admin Comment</h6>
                    </div>
                    <Row>
                        <Colxx xxs="6" md="6">
                            <Input
                                type="textarea"
                                placeholder="Add your comments here"
                                value={superAdminComment}
                                onChange={event => {
                                    setSuperAdminComment(event.target.value)
                                }}
                            />
                            <Button disabled={!superAdminComment && !superAdminComment?.length} className="mt-2 top-right top-right-button" size="sm" color="primary" outline onClick={() => {
                                firestore().collection('bookings').doc(state?.key).update({ superAdminComment: superAdminComment }).then(function () {
                                    firestore().collection('logs').doc(state?.key).set({
                                        data: firestore.FieldValue.arrayUnion({
                                            title: 'superAdminComment',
                                            created: Date.now(),
                                            createdBy: accessTypesObject?.[user?.accessType].name,
                                            createdByName: user?.displayName,
                                            comment: superAdminComment
                                        }),
                                        updated: Date.now()
                                    }, { merge: true }).then(function () {
                                        createNotification('success', "Your comment is added successfuly", 'Success');

                                    }).catch(error => error)

                                }).catch(function (error) {
                                    console.error("Error writing document: ", error);
                                });
                            }}>
                                Save Comment
                            </Button>
                        </Colxx>
                    </Row>
                </>

                }
            </ModalBody>

            {accessTypes.includes(user?.accessType) && !state?.subscription ? <ModalFooter style={{ justifyContent: 'flex-end', color: '#dc3545 !important' }}>
                <Button color="primary"
                    className="top-right-button"
                    onClick={() => setManageOrder(true)}>Manage Order
                </Button>{" "}
            </ModalFooter> : ((['accepted', 'pending', 'new', 'active'].includes(state?.status)) ? <ModalFooter style={{ justifyContent: "space-between", color: '#dc3545 !important' }}>
                <Button color="danger" outline onClick={() => {
                    setCancelAppointment(true)
                }}>
                    {state?.status === 'new' || state?.status === 'pending' ? 'Reject Appointment' : 'Cancel Appointment'}
                </Button>
                <Button color="primary"
                    className="top-right-button"
                    onClick={() => submit()}
                >
                    {(state?.status === 'pending' || state?.status === 'new' ?
                        "Accept"
                        : state?.status === 'accepted' ?
                            "Start Service"
                            : state?.status === 'active' ?
                                "Complete"
                                : '')
                    }
                </Button>{" "}
            </ModalFooter> : ''
            )
            }
            {manageOrder && <ManageOrder manageOrder={manageOrder}
                selectedBooking={state}
                authUser={user}
                dismiss={() => setManageOrder(false)}
                washers={washers}
                providers={provider}
                modifiers={modifiers}
                bookings={bookings}
                services={activeServices}
                updateAppointment={() => {

                    if (fromHistory) {
                        update();
                    } else {
                        toggleModal();
                        dismiss();
                    }
                    setManageOrder(false)
                }
                }
            />
            }
            <Modal
                isOpen={state?.reAssignWasher}
                backdrop="static">
                <ModalHeader >{state?.overlap ? <div><span style={{
                    fontWeight: '700',
                    color: '#dc3545'
                }}>Overlap</span> </div> : "Reassign Worker"}</ModalHeader>
                <ModalBody>{state?.overlap ?
                    "By doing this you will Overlap with worker appointments, are you sure you want to continue?"
                    : "Are you sure you want to Reassign worker for the appointment?"}</ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={() => setState({
                        ...state,
                        washer_name: selectedBooking?.washer_name,
                        washer_id: selectedBooking?.washer_id,
                        reAssignWasher: false, overlap: false
                    })}>
                        Cancel
                    </Button>
                    <Button color="danger" outline onClick={() =>
                        firestore().collection('bookings').doc(state.key).update({
                            washer_name: state?.washer_name,
                            washer_id: state?.washer_id,
                            updated: Date.now(),
                            reAssigned: Date.now(),
                            superAdminComment: state?.superAdminComment || ''

                        }).then(() => {
                            firestore().collection('logs').doc(state.key).set({
                                data: firestore.FieldValue.arrayUnion({
                                    title: 'reAssignWorker',
                                    created: Date.now(),
                                    oldWasherName: selectedBooking?.washer_name,
                                    oldWasherId: selectedBooking?.washer_id,
                                    washer_name: state?.washer_name,
                                    washer_id: state?.washer_id,
                                    createdBy: accessTypesObject?.[user?.accessType].name,
                                    createdByName: user?.displayName
                                }),
                                updated: Date.now()
                            }, { merge: true }).then(function () {

                            }).catch(error => error)

                            if (fromHistory) {
                                update();
                            } else {
                                toggleModal();
                                dismiss();

                            }

                        })
                    } >
                        Reassign Worker
                    </Button>{" "}
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={state?.reAssignDate}
                backdrop="static">
                <ModalHeader >{state?.overlap ? <div><span style={{
                    fontWeight: '700',
                    color: '#dc3545'
                }}>Overlap</span> </div> : "Reschedule Appointment"}</ModalHeader>
                <ModalBody>{state?.overlap ? "By doing this you will Overlap with worker appointments, are you sure you want to continue?" : "Are you sure you want to Reschedule this appointment?"}</ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={() => {
                        setState({
                            ...state,
                            reAssignDate: false,
                            bookingDate: selectedBooking.bookingDate,
                            bookingTime: selectedBooking.bookingTime,
                            overlap: false,
                        })
                    }}>
                        Cancel
                    </Button>
                    <Button color="danger" outline onClick={() =>
                        firestore().collection('bookings').doc(state.key).update({
                            bookingDate: state?.bookingDate,
                            bookingTime: state?.bookingTime,
                            updated: Date.now(),
                            adminReschedule: Date.now(),
                            superAdminComment: state?.superAdminComment || ''

                        }).then(() => {
                            firestore().collection('logs').doc(state?.key).set({
                                data: firestore.FieldValue.arrayUnion({
                                    title: 'rescheduleDate',
                                    created: Date.now(),
                                    oldBookingDate: selectedBooking?.bookingDate,
                                    bookingDate: state?.bookingDate,
                                    createdBy: accessTypesObject?.[user?.accessType].name,
                                    createdByName: user?.displayName
                                }),
                                updated: Date.now()
                            }, { merge: true })

                            if (fromHistory) {
                                update();
                            } else {
                                toggleModal();
                                dismiss();

                            }
                        })
                    } >
                        Reschedule
                    </Button>{" "}
                </ModalFooter>
            </Modal>

            <Logs dismiss={() => {
                setLogsOpen(false)
            }} selectedBooking={state} logsOpen={logsOpen} logs={logs} />

            <Modal
                isOpen={cancelAppointment}
                backdrop="static">
                <ModalHeader >Cancel Appointment</ModalHeader>
                <ModalBody>
                    <h6 >Reason of cancel</h6>
                    <ul className="list-unstyled mb-2 mt-2">
                        {['Worker Busy', 'Traffic', 'Other'].map((c, index) => {
                            return (
                                <NavItem key={index} style={{ cursor: 'pointer' }}>
                                    <div onClick={() => {

                                        setState({ ...state, selectedReason: c })
                                    }}>
                                        <div className="custom-control custom-radio">
                                            <input
                                                style={{ cursor: 'pointer' }}
                                                type="radio"
                                                className="custom-control-input"
                                                checked={state?.selectedReason === c}
                                            />
                                            <label style={{ cursor: 'pointer' }} className="custom-control-label">{c}</label>
                                        </div>
                                    </div>
                                </NavItem>
                            );
                        })}
                    </ul>
                    {state?.selectedReason === 'Other' ? <Input className="customInput"
                        value={state?.selectedReasonComment}
                        placeholder="Comment here" type="text" onChange={(e) => {
                            setState({ ...state, selectedReasonComment: e.target.value })
                        }} />
                        : ''}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={() => {
                        setCancelAppointment(false)
                    }}>
                        Dismiss
                    </Button>
                    <Button color="danger" onClick={() => {
                        firestore().collection('bookings').doc(selectedBooking.key).update({
                            canceledBy: 'Service Provider',
                            status: (selectedBooking.status === 'new' || selectedBooking.status === 'pending') ? 'rejected' : 'canceled',
                            cancelReason: state?.selectedReason === 'Other' ? state?.selectedReasonComment : state?.selectedReason || '',
                            updated: Date.now()
                        }).then(function () {
                            firestore().collection('logs').doc(selectedBooking.key).set({
                                data: firestore.FieldValue.arrayUnion({
                                    title: (selectedBooking.status === 'new' || selectedBooking.status === 'pending') ? 'rejectedAppointment' : 'canceledAppointment',
                                    created: Date.now(),
                                    createdBy: accessTypesObject?.[user?.accessType].name,
                                    createdByName: user?.displayName
                                }),
                                updated: Date.now()
                            }, { merge: true }).then(function () {
                                if (fromHistory) {
                                    update();
                                } else {
                                    toggleModal();
                                    dismiss();

                                }

                                setCancelAppointment(false)
                            }).catch(error => {
                                setCancelAppointment(false)
                            })

                        })
                    }}>
                        {state?.status === 'new' || state?.status === 'pending' ? 'Reject Appointment' : 'Cancel Appointment'}
                    </Button>{" "}
                </ModalFooter>
            </Modal>
        </Modal>
    );
};

export default Order;