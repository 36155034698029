import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import moment from 'moment';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { sortTimings } from '../../../../helpers/Utils';
const CustomInput = React.forwardRef((props, ref) => {
    return (
        <input
            onClick={props.onClick}
            value={props.value}
            type="text"
            className="customDatePicker"
            readOnly={true}
            ref={ref}
        />
    )
})
const TimeLogComp = ({ timings, dismiss }) => {

    return (
        <Modal
            isOpen={timings}
            style={{ boxShadow: 'none', maxWidth: '600px', top: '30px', overflow: 'hidden', borderRadius: '14px' }}
            backdrop="static"
            size="lg">
            <ModalHeader className='modal-header' >
                Timings Logs
            </ModalHeader>
            <ModalBody >
                {

                    Object.keys(sortTimings(timings||{}))?.map((item, index) => {
                        return (
                            <div key={index} className="timingsParent" style={{ flexDirection: 'row' }}>
                                <div className="dayParent">
                                    <div className="day">{item}</div>
                                    <Switch
                                        className="custom-switch custom-switch-primary"
                                        checked={timings[item].isOpen}
                                        disabled={true}
                                    />
                                </div>
                                {
                                    timings[item].isOpen &&
                                    <div className="dateContainer">
                                        {timings[item].timings.map((timing, index2) => {
                                            return (
                                                <div key={index2} className="date2Container" style={{ margin: '0 0 10px 10px' }}>
                                                    <DatePicker
                                                        customInput={<CustomInput />}
                                                        selected={(timing.startTime ? moment(timing.startTime).toDate() : moment().endOf('day').toDate())}
                                                        className="customDatePicker"
                                                        showTimeSelectOnly
                                                        showTimeSelect
                                                        disabled={true}
                                                        dateFormat="hh:mm a"
                                                        timeFormat="hh:mm a"
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        placeholderText={"Start"}
                                                    />
                                                    <DatePicker
                                                        customInput={<CustomInput />}
                                                        selected={(timing.endTime ? moment(timing.endTime).toDate() : moment().endOf('day').toDate())}
                                                        className="customDatePicker"
                                                        showTimeSelectOnly
                                                        showTimeSelect
                                                        timeIntervals={15}
                                                        disabled={true}
                                                        timeCaption="Time"
                                                        dateFormat="hh:mm a"
                                                        timeFormat="hh:mm a"
                                                        placeholderText={"End"}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                }

                            </div>
                        )
                    })
                }
            </ModalBody>
            <ModalFooter>
                <Button color='danger' onClick={() => dismiss()}>Dismiss</Button>
            </ModalFooter>
        </Modal >
    );
};

export default TimeLogComp;