import moment from 'moment';
import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import ReactTable from "react-table";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import DataTablePagination from "../../../components/DatatablePagination";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import { accessTypes, accessTypesObject } from "../../../helpers/Utils";
import './appointments.css';
import AddNewAppointment from "./components/AddNewAppointment";
import Order from "./components/order";
import TodoApplicationMenu from "./components/ordersFilter";
import { firestore } from '../../../helpers/Firebase';

const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}
const TheadComponent = props => null; // a component returning null (to hide) or you could write as per your requirement

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
const Appointments = () => {
  const { user } = useSelector(state => state.authUser)
  const { provider, washers, bookings, services, advServices, } = useSelector(state => state.providers)
  const [selectedFilter, setFilter] = useState({})
  const [selectedBooking, setSelectedBooking] = useState(false)
  const [selectedDate, setSelectedDate] = useState(moment().toDate())
  const [filteredBookings, setFilteredBookings] = useState([])
  const [activeServices, setActiveServices] = useState({})
  const [newOrder, setNewOrder] = useState(false)
  const [delayedAction, setDelayedAction] = useState(false)
  const [search, setSearchKeyword] = useState('')
  const [displayOptionsIsOpen, setDisplayOptionsIsOpen] = useState(false)

  useEffect(() => {
    let allServices = Object.values(services || {})?.concat(Object.values(advServices || {}))
    const updatedActiveServices = {};
    Object.values(washers || {}).forEach(washer => {
      if (washer.activeServices) {
        const activeServiceKeys = Object.keys(washer.activeServices);
        const filteredServices = allServices.filter(service => activeServiceKeys.includes(service.key));
        filteredServices.forEach(service => {
          updatedActiveServices[service.key] = {
            ...service,
            modifier: washer.activeServices[service.key],
            label: service.name,
            value: service.name
          };
        });
        setActiveServices((prevActiveServices) => {
          return { ...prevActiveServices, ...updatedActiveServices };
        });
      }
    });
  }, [services, advServices, washers])
  const getOriginalData = (key) => {
    let item = Object.values(bookings || {})?.find(i => i?.key === key)
    return item
  }
  const dataTableColumns = () => {
    return ([
      {
        width: 0,
        accessor: "key",
        show: false,
        sortMethod: (a, b) => {
          const originalA = getOriginalData(a);
          const originalB = getOriginalData(b);
          if (originalA?.delayed && !originalB?.delayed) {
            return -1;
          } else if (!originalA?.delayed && originalB?.delayed) {
            return 1;
          } else {
            return originalA?.bookingDate - originalB?.bookingDate;
          }
        },
        Cell: props => null
      },
      {
        width: deviceWidth() === 'desktop' ? 120 : 80,
        Header: "Booking Date",
        accessor: "bookingDate",
        Cell: props => <div className="datefieldText">{moment(props.value).format('DD/MM/YYYY')}<br />{moment(props.value).format('hh:mm A')}</div>
      },

      {
        Header: "Service Provider",
        accessor: "sp_name",
        sortable: false,
        show: accessTypes.includes(user?.accessType) ? true : false,
        Cell: props => <div className="serviceWasherfieldText">{props.value}</div>

      },
      {
        Header: "Customer",
        accessor: "customer_name",
        sortable: false,
        Cell: props => <div className="serviceWasherfieldText">{props.value}<br />{accessTypes?.includes(user?.accessType) ? props.original.customer_number : ''}</div>
      },
      {
        Header: "Service Name",
        accessor: "service_name",
        sortable: false,
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <div className="serviceWasherfieldText">{props.value}</div>
      },
      {
        Header: "Washer Name",
        accessor: "washer_name",
        sortable: false,
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <div className="serviceWasherfieldText">{props.value}</div>
      },
      {
        Header: "Status",
        accessor: "status",
        sortable: false,
        Cell: props => <div className={(
          props.original.status === 'pending' || props.original.status === 'new' ?
            "pendingStatusfieldText"
            : props.original.status === 'accepted' ?
              "acceptedStatusfieldText"

              : props.original.status === 'active' ?
                "processStatusfieldText"

                : props.original.status === 'completed' || props.original.status === 'notRated' ?
                  "completedStatusfieldText"

                  : props.original.status === 'rejected' ?
                    "rejectedStatusfieldText"

                    : props.original.status === 'rated' ?
                      "ratedStatusfieldText"

                      : props.original.status === 'canceled' ?
                        "rejectedStatusfieldText"
                        : ''
        )
        }>{props.value === 'new' ? 'Pending' : props.value === 'notRated' ? "Completed" : capitalize(props.value)}</div>
      },
      {
        Header: "Type",
        accessor: "type",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <div className={props.value === "onlineBooking" ? "onliefieldText" : "offlinefieldText"}>{props.value === "onlineBooking" ? 'Online' : 'Offline'}</div>
      },
      {
        width: deviceWidth() === 'desktop' ? 120 : 80,
        Cell: props => props?.original?.delayed ?
          <Badge
            className="mb-1"
            color={`danger`}
            pill>
            {(moment().to(moment(props?.original?.bookingDate)).replace(' ago', ' Delay'))}
          </Badge>
          : null

      },
      {
        accessor: "edit",
        width: 100,
        sortable: false,
        Cell: props => <div >
          <Button
            style={{ padding: '0.2rem 1rem' }}
            color="primary"
            size="sm"
            outline
            // className="top-right-button"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation();
              if (props?.original?.delayed) {
                setDelayedAction(props?.original)
              } else {
                setSelectedBooking(props.original)
              }
            }}
          >{props?.original?.delayed === "delayedActive" ? 'Start Service' : props?.original?.delayed === "delayedCompleted" ? 'Complete' : 'View'}
          </Button>
        </div>
      },
    ])
  }

  const toggleModal = () => {
    setNewOrder(false)
  };
  const dismiss = () => {
    setSelectedBooking(false)
    setNewOrder(false)

  };
  const filter = (selectedFilter) => {
    let filteredBookings = []
    filteredBookings = bookings?.filter(item => {
      if (item.delayed) {
        return true
      }
      return (
        (selectedFilter?.search ? (item?.customer_name?.toLowerCase().includes(selectedFilter?.search) || item?.customer_number?.toLowerCase().includes(selectedFilter?.search) || item?.service_name?.toLowerCase().includes(selectedFilter?.search) || item.status.toLowerCase().includes(selectedFilter?.search) || item?.washer_name?.toLowerCase().includes(selectedFilter?.search)) : true) &&
        (selectedFilter?.service?.length ? selectedFilter.service.includes(item?.service_id) : true) &&
        (selectedFilter?.providers?.length ? selectedFilter.providers.includes(item?.sp_id) : true) &&
        (selectedFilter?.washers?.length ? selectedFilter.washers.includes(item?.washer_id) : true) &&
        (selectedFilter?.status?.length ? selectedFilter.status.includes(item.status) : true) &&
        (moment(item.bookingDate).format('DD/MM/YYYY') === moment(selectedFilter?.selectedDate).format('DD/MM/YYYY'))
      )
    })
    setFilteredBookings(filteredBookings)
  }

  useEffect(() => {
    filter(selectedFilter)
    // eslint-disable-next-line 
  }, [selectedFilter, bookings, search])
  useEffect(() => {
    setFilter((prevState) => {
      return { ...prevState, selectedDate: selectedDate };
    });
  }, [selectedDate])
  if (newOrder) {
    return (
      <Row className="pb-5">
        <Colxx xxs="12">
          <Card className="rounded h-100">
            <CardBody>
              <AddNewAppointment bookings={bookings}
                activeServices={activeServices}
                washers={washers}
                toggleModal={toggleModal}
                dismiss={dismiss}
                selectedBooking={JSON.parse(JSON.stringify(selectedBooking))}
              />
            </CardBody>
          </Card>

        </Colxx>
      </Row>
    )
  }
  return (
    <Fragment>
      <Row className="app-row survey-app">
        <Colxx xxs="12">

          <div className="mb-2">
            <h1>Appointments</h1>
            <div className="text-zero top-right-button-container">
              {!accessTypes.includes(user?.accessType) ? <Button
                color="primary"
                style={{ borderRadius: '50px' }}
                size={deviceWidth() === 'desktop' ? "lg" : "md"}
                className="top-right-button mr-1"
                onClick={() => setNewOrder(true)}>

                Create Appointment
              </Button> : ''}
            </div>
          </div>

          <div className="mb-2">
            <Button
              color="empty"
              className="pt-0 pl-0 d-inline-block d-md-none"
              onClick={() => { setDisplayOptionsIsOpen(!displayOptionsIsOpen) }}
            >
              Display Options{" "}
              <i className="simple-icon-arrow-down align-middle" />
            </Button>

            <Collapse
              id="displayOptions"
              className="d-md-block mb-2"
              isOpen={displayOptionsIsOpen}
            >
              <div className="d-block d-md-inline-block">
                <div className="mr-1 float-md-left btn-group mb-1" style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <i className="simple-icon-arrow-left" style={{ cursor: 'pointer' }} onClick={() => setSelectedDate(moment(selectedDate).subtract(1, "days").toDate())} />
                  <DatePicker
                    selected={selectedDate}
                    className="customDatePickerBppkings"
                    onChange={date => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                  <i className="simple-icon-arrow-right" style={{ cursor: 'pointer' }} onClick={() => setSelectedDate(moment(selectedDate).add(1, "days").toDate())} />
                </div>
                <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={"Search"}
                    defaultValue={search}
                    onChange={(e) => {
                      setFilter({ ...selectedFilter, search: e.target.value.toLowerCase() })
                      setSearchKeyword(e.target.value.toLowerCase())
                    }}
                  />
                </div>
              </div>

            </Collapse>
          </div>
          <Separator className="mb-5" />
          <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
              filteredBookings?.length ?


                (deviceWidth() === 'mobile') ?
                  (
                    <ReactTable
                      data={filteredBookings}
                      columns={dataTableColumns()}
                      defaultPageSize={10}
                      pageSize={filteredBookings?.length > 10 ? 10 : filteredBookings?.length}
                      showPageJump={false}
                      showPageSizeOptions={false}
                      PaginationComponent={DataTablePagination}
                      className='custuomReactTable'
                      style={{ width: '100%' }}
                      defaultSorted={[
                        {
                          id: 'bookingDate',
                          desc: false,
                        },
                      ]}
                      TheadComponent={TheadComponent}
                    />
                  ) :
                  <ReactTable
                    data={filteredBookings}
                    columns={dataTableColumns()}
                    defaultPageSize={10}
                    pageSize={filteredBookings?.length > 50 ? 50 : filteredBookings?.length}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    PaginationComponent={DataTablePagination}
                    className='custuomReactTable'
                    style={{ width: '100%' }}

                    defaultSorted={[
                      {
                        id: "key",
                        desc: false
                      }
                    ]}
                    getTrGroupProps={(state, rowInfo, column, instance) => {
                      return {
                        onClick: e => {
                          setSelectedBooking(rowInfo.original)
                        },
                        style: rowInfo?.original?.delayed ? {
                          border: `1px solid red`,
                          borderRadius: `16px`,
                          background: `rgba(255, 0, 0, 0.02)`,
                        } : {}
                      }
                    }}
                  /> :
                <div >{"No Appointments"}</div>
            }
          </Row>
        </Colxx>
        <TodoApplicationMenu activeServices={activeServices}
          todoItems={bookings || []}
          bookingType={'Appointments'}
          filter={(selectedFilter) => {
            setFilter({ ...selectedFilter, search: search, selectedDate: selectedDate })
          }}
        />
        {selectedBooking && <Order
          bookings={bookings}
          washers={washers}
          providers={provider}
          activeServices={activeServices}
          toggleModal={toggleModal}
          dismiss={dismiss}
          selectedBooking={JSON.parse(JSON.stringify(selectedBooking))}
        />
        }
        <Modal
          isOpen={delayedAction}
          backdrop="static">
          <ModalHeader>Update Appointment</ModalHeader>
          <ModalBody>
            <div>Are you sure you want to update the status for this appointment?</div>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" outline onClick={() => {
              setDelayedAction(false)
            }}>
              Dismiss
            </Button>
            <Button color="primary" onClick={() => {
              let newStatus = (delayedAction.status === 'pending' || delayedAction.status === 'new' ? 'accepted' : delayedAction.status === 'accepted' ? 'active' : delayedAction.status === 'active' ? 'completed' : '')

              firestore().collection('bookings').doc(delayedAction.key).update({
                status: newStatus,
                updated: Date.now()
              }).then(function () {
                firestore().collection('logs').doc(delayedAction.key).set({
                  data: firestore.FieldValue.arrayUnion({
                    title: 'statusChanged',
                    created: Date.now(),
                    oldsStatus: delayedAction.status,
                    status: newStatus,
                    createdBy: accessTypesObject?.[user?.accessType].name,
                    createdByName: user?.displayName
                  }),
                  updated: Date.now()
                }, { merge: true }).then(function () {
                  setDelayedAction(false)
                }).catch(error => {
                  setDelayedAction(false)
                })

              })
            }}>
              {(delayedAction?.status === 'pending' || delayedAction?.status === 'new' ?
                "Accept"
                : delayedAction?.status === 'accepted' ?
                  "Start Service"
                  : delayedAction?.status === 'active' ?
                    "Complete"
                    : '')
              }
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </Row>
    </Fragment >
  );
};
export default Appointments;