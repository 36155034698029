import React, { Component } from "react";
import {
  Row,
  Button,
} from "reactstrap";
import { injectIntl } from "react-intl";
import Select from 'react-select'

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { accessTypes } from "../../helpers/Utils";

class ListPageHeading extends Component {
  constructor(props) {
    super();
    this.state = {
      dropdownSplitOpen: false,
    };
  }


  render() {
    const {
      onSearchKey,
      toggleModal,
      heading,
      accessType,
      washerList,
      providers,
      selectProvider,
      authUser,
      hideSeparator,
      sortBanners
    } = this.props;

    return (
      <Row>

        <Colxx xxs="6">

          <div className="d-flex align-items-start" style={{ flexDirection: 'column' }}>
            <h1 style={{ paddingBottom: '0' }}>
              {heading}
            </h1>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              {onSearchKey && <div
                // style={{ marginLeft: '12px' }}
                className="search-sm d-inline-block float-md-left mr-1 align-top">
                <input
                  type="text"
                  name="keyword"
                  id="search"
                  placeholder={"Search"}
                  onChange={e => onSearchKey(e)}
                />
              </div>}
              {washerList && accessTypes.includes(authUser?.user?.accessType) &&
                <Colxx xxs="12">
                  <Select
                    isClearable={true}
                    placeholder="Service Providers"
                    name="providers"
                    options={Object.values(providers)}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={val => { selectProvider(val) }}
                  /></Colxx>

              }
            </div>

          </div>
        </Colxx>
        <Colxx xxs="6">
          <div className="text-zero d-flex  justify-content-end" style={{ height: '100%', alignItems: 'flex-end' }}>
            {sortBanners && <Button
              color="dark"
              size="lg"
              outline
              className="top-right-button mr-2"
              onClick={() => sortBanners()}
            >
              Sort Banners
            </Button>}

            {
              accessType !== 'admin' && toggleModal &&
              <Button
                color="primary"
                size="lg"
                className="top-right-button"
                onClick={() => toggleModal()}
              >
                Create New
              </Button>
            }
          </div>


        </Colxx>
        {!hideSeparator && <Colxx xxs="12">
          <Separator className="mb-2 mt-2" />
        </Colxx>}

      </Row>
    );
  }
}

export default injectIntl(ListPageHeading);
