import moment from "moment";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { firestore } from "../../helpers/Firebase";
import { getFirstCharacters } from "../../helpers/Utils";
import Order from "../../views/app/appointments/components/order";

const ChatHeading = ({ name, thumb, wa_id, selectedUser }) => {
  let chatMessages = selectedUser?.chatMessages?.sort((msg1, msg2) => msg1?.created - msg2?.created);
  const lastMessage = chatMessages?.[chatMessages?.length - 1];

  const { washers, services, advServices, } = useSelector(state => state.providers)

  const [activeServices, setActiveServices] = useState({})
  const [selectedBooking, setSelectedBooking] = useState(null)
  const dismiss = () => {
    setSelectedBooking(false)
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center flex-row justify-content-between">
        <div className="d-flex align-items-center flex-row chat-heading">
          <div className="d-flex">
            <div
              style={{
                background: '#3498db',
                padding: '5px',
                color: '#fff',
                width: '50px',
                height: '50px',
                minWidth: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: '500',
                borderRadius: '200px',
                letterSpacing: '.06em'
              }}
              className="mr-3"
            >{getFirstCharacters(name || 'Chillapp Team')}</div>
          </div>
          <div className=" d-flex min-width-zero">
            <div className="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
              <div className="min-width-zero">
                <div className="list-item-heading mb-1 truncate ">{name}</div>
                {/* <div className="mb-0 truncate text-muted text-small">{selectedUser?.wa_id}</div> */}
                <p className="mb-0 truncate text-muted text-small">{moment(lastMessage?.created).format('DD/MM/YYYY hh:mm A')}</p>

                {/* {checkTimeRange() && <p className="mb-0 text-muted text-small">
                +{wa_id}
              </p>} */}
              </div>

            </div>
          </div>
        </div>
        {/* <div className="mb-0 mt-0 truncate" style={{
          fontSize: '10px',
          lineHeight: '1.2',
        }}>Order ID: {selectedUser?.orderNumber}</div> */}
        {selectedUser?.orderNumber ? <Button color="primary" size="sm" outline onClick={async () => {
          let allServices = Object.values(services || {})?.concat(Object.values(advServices || {}))
          const updatedActiveServices = {};
          Object.values(washers || {}).forEach(washer => {
            if (washer.activeServices) {
              const activeServiceKeys = Object.keys(washer.activeServices);
              const filteredServices = allServices.filter(service => activeServiceKeys.includes(service.key));
              filteredServices.forEach(service => {
                updatedActiveServices[service.key] = {
                  ...service,
                  modifier: washer.activeServices[service.key],
                  label: service.name,
                  value: service.name
                };
              });
              setActiveServices((prevActiveServices) => {
                return { ...prevActiveServices, ...updatedActiveServices };
              });
            }
          });
          let userBookingsSnap = await firestore().collection('bookings').where('orderNumber', '==', selectedUser?.orderNumber).get()
          const booking = {
            ...userBookingsSnap.docs[0].data(),
            id: userBookingsSnap.docs[0].id,
            key: userBookingsSnap.docs[0].id,
          }
          setSelectedBooking(booking)
        }}>
          View Order Details
        </Button> : ''}
      </div>

      <div className="separator mb-5" />

      {selectedBooking && <Order
        activeServices={activeServices}
        toggleModal={dismiss}
        dismiss={dismiss}
        selectedBooking={JSON.parse(JSON.stringify(selectedBooking))}
      />
      }
    </Fragment>
  );
};

export default ChatHeading;
