import parse from "html-react-parser";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { ContainsArabic, accessTypes, accessTypesObject, getFirstCharacters } from "../../helpers/Utils";

const RenderItemType = (msg, fallbackImage, setFallbackImage, sameSender) => {
  const rtl = ContainsArabic(msg?.text?.body);
  return <div >
    {
      ["ptt", 'audio'].includes(msg?.type) ?
        <div  >
          <audio
            style={{ border: '1px solid #cacaca', borderRadius: '30px', maxWidth: '300px', width: '60vw', }}
            controls
            src={msg?.url}
          ></audio>
        </div>
        : ["sticker", "image"].includes(msg?.type) ?
          <div>
            {
              fallbackImage ?
                <div>
                  <div className="nk-reply-from" style={{ color: '#000', fontSize: '14px' }}>Error while loading Content</div>
                </div>
                :
                <div
                  onClick={() => {
                    window.open(msg?.url, '_blank');
                  }}
                >
                  <img
                    width={'150px'} height={'150px'}
                    src={msg?.url}
                    onError={(err) => setFallbackImage(true)}
                    alt="Content deleted"
                    style={{ objectFit: 'cover', borderRadius: '24px', border: '1px solid #cacaca' }}
                  />
                </div>
            }
            {
              msg?.caption &&
              <div>{msg?.caption}</div>
            }
          </div>
          : ["document"].includes(msg?.type) ?
            <div>
              <a href={msg?.url || ''} rel="noopener noreferrer" target="_blank" width={'150px'} height={'150px'} style={{
                color: msg?.me ? '#fff' : '#050A30', display: 'flex', alignItems: 'center',
                background: '#FFFFFF',
                padding: '.5rem 1rem',
                borderRadius: '8px',
                borderBottomLeftRadius: !sameSender ? '0px' : '4px',

              }}  >
                <i className={'iconsminds-file'} style={{ fontSize: '22px', padding: '0 8px 0 0' }} />
                <div>
                  {
                    msg?.document?.filename &&
                    <div style={{
                      color: '#3498db',
                      textDecoration: 'underline'
                    }}>{msg?.document?.filename}</div>
                  }
                </div>
              </a>
            </div>
            : ["video"].includes(msg?.type) ?
              <div>
                <video src={msg?.url} controls style={{ borderRadius: '20px', width: '60vw', maxWidth: '220px' }} >
                </video>
              </div>
              : ["button"].includes(msg?.type) ?
                <div
                  style={{
                    background: '#FFFFFF',
                    padding: '.5rem 1rem',
                    borderRadius: '8px',
                    borderBottomLeftRadius: !sameSender ? '0px' : '4px'
                  }}
                  className={`d-inline-block`}
                >
                  <p style={{ overflowWrap: 'anywhere', direction: rtl ? 'rtl' : 'ltr', margin: '0' }}>{msg?.button?.text}</p>
                </div>
                : ["reaction"].includes(msg?.type) ?
                  <div
                    style={{
                      background: '#FFFFFF',
                      padding: '.5rem 1rem',
                      borderRadius: '8px',
                      borderBottomLeftRadius: !sameSender ? '0px' : '4px'
                    }}
                    className={`d-inline-block`}
                  >
                    <p style={{ overflowWrap: 'anywhere', direction: rtl ? 'rtl' : 'ltr', margin: '0' }}>{msg?.reaction?.emoji}</p>
                  </div>
                  : ["location"].includes(msg?.type) ?
                    <div>
                      <div
                        onClick={() => {
                          const googleMapsUrl = `https://www.google.com/maps?q=${msg?.location?.latitude},${msg?.location?.longitude}`;
                          window.open(googleMapsUrl, '_blank');
                        }}
                      >
                        <img alt=""
                          key={msg?.location?.latitude}
                          // eslint-disable-next-line
                          src={`https://maps.googleapis.com/maps/api/staticmap?center=${msg?.location?.latitude},${msg?.location?.longitude}&zoom=16&size=150x150&markers=color:red%7C${msg?.location?.latitude},${msg?.location?.longitude}&key=AIzaSyCExglJjHXgXzSGHJB1NmLll7NgjOG8_bc`}
                          // style={{ cursor: 'pointer', width: '150px', borderRadius: '150px', }}
                          style={{ objectFit: 'cover', borderRadius: '24px', cursor: 'pointer', width: '150px', border: '1px solid #cacaca' }}
                        />
                      </div>


                    </div> :
                    msg?.field === 'comments' ?
                      <div style={{ marginBottom: '4px' }}>
                        <div className="nk-reply-from" style={{ color: '#4d4d4d', fontSize: '10px' }}>
                          <div>{msg?.formatName} <span style={{ color: '#cacaca', fontSize: '10px' }}>  {moment(msg?.created).format('hh:mm A')}</span></div>
                          {msg?.text?.body}
                        </div>
                      </div>
                      :
                      <div
                        style={{
                          background: '#FFFFFF',
                          padding: '.5rem 1rem',
                          borderRadius: '8px',
                          borderBottomLeftRadius: !sameSender ? '0px' : '4px'
                        }}
                        className={`d-inline-block`}
                      >
                        <p style={{ overflowWrap: 'anywhere', direction: rtl ? 'rtl' : 'ltr', margin: '0' }}>
                          {typeof msg?.text?.body === 'string' ? parse((msg.text.body.replace(/</g, '&lt;').replace(/>/g, '&gt;')).replace(/\n/g, "<br>")) : ''}
                        </p>
                        {/* <p style={{ overflowWrap: 'anywhere', direction: rtl ? 'rtl' : 'ltr', margin: '0' }}>{msg?.text?.body}</p> */}
                      </div>
    }

  </div>
}
const MessageCard = ({ sender, item, sameSender }) => {
  const [fallbackImage, setFallbackImage] = useState(false)
  return (
    <Fragment>
      <div
        className={`d-flex ${!sameSender ? 'mb-3' : 'mb-1'} align-self-${sender ? "start" : "end"
          }`}
        style={sender ? { marginRight: '20%' } : { marginLeft: '20%' }}
      >
        {!sameSender && sender ? <div
          style={{
            background: '#3498db',
            padding: '5px',
            color: '#fff',
            width: '40px',
            height: '40px',
            minWidth: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '500',
            borderRadius: '200px',
            letterSpacing: '.06em'
          }}
          className="mr-2"
        >{getFirstCharacters(sender?.name)}</div> :
          <div
            style={{
              padding: '5px',
              width: '40px',
              height: '40px',
              minWidth: '40px',
            }}
            className="mr-2"
          />}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: sender ? 'flex-start' : 'flex-end' }}>
          {RenderItemType(item, fallbackImage, setFallbackImage, sameSender)}
          {!sameSender ?
            <div className="text-small mt-2" style={{ textAlign: sender ? "start" : "end", color: '#8094ae', fontWeight: '500' }}>
              {`${sender ? (sender?.name) : (item?.from + ' ' + (accessTypes?.includes(item?.role) ? '( Chill Support Team )' : accessTypesObject?.[item?.role]?.name ? `( ${accessTypesObject?.[item?.role]?.name} )` : ''))} - ${moment(item?.created).format('hh:mm A')} ${(item?.status) ? ' - ' + (item?.status).toUpperCase() : ''}`}


            </div>
            : null}
        </div>
      </div>
      <div className="clearfix" />
    </Fragment>
  );
};

export default MessageCard;
