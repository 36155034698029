import React, { useEffect, useState } from "react";
import { Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import ListPageHeading from "../../../containers/navs/ListPageHeading";

import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { firestore, functions } from '../../../helpers/Firebase'
import { useSelector } from "react-redux";
import Admin from "./admin";
import { accessTypesObject } from "../../../helpers/Utils";

const AdminsList = () => {
  const { user } = useSelector(state => state.authUser)
  const [admins, setAdmins] = useState([])
  const [filtererdAdmins, setFiltererdAdmins] = useState([])
  const [selectedUser, setSelectedUser] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteUser, setDeleteUser] = useState(false)


  const dataTableColumns = () => {
    return ([
      {
        Header: "Name",
        accessor: "name",
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },

      {
        Header: "Roles",
        accessor: "role",
        Cell: props => <p className="providerfieldText">{accessTypesObject?.[props.value]?.name}</p>
      },
      {
        width: 80,
        accessor: "delete",
        Cell: props => <p className="providerfieldText">
          <Button
            color="danger"
            size="sm"
            outline
            onClick={() => setDeleteUser(props.original)}
          >
            Delete
          </Button>
        </p>
      },
      {
        width: 70,
        accessor: "edit",
        Cell: props => <p className="providerfieldText">
          <Button
            color="primary"
            size="sm"
            outline
            className="top-right-button"
            onClick={() => onClickItem(props.original)}
          >
            Edit
          </Button>
        </p>
      }
    ])
  }
  const toggleModal = () => {
    setSelectedUser({ addNew: true })
  };

  const onClickItem = (item) => {
    setSelectedUser(item || {})
  };

  useEffect(() => {
    firestore().collection('admin').onSnapshot(adminSnap => {
      let data = adminSnap?.docs?.reduce((val, currentVal) => {
        val.push({ ...currentVal.data(), id: currentVal.id, key: currentVal.id })
        return val
      }, [])
      setAdmins(data)
      setFiltererdAdmins(data)
    })
  }, [])
  const onSearchKey = e => {
    let search = e.target.value.toLowerCase()
    let filtererdAdmins = admins.filter(item => {
      return item.name && (item.name).toLowerCase().includes(search.toLowerCase())
    })
    setFiltererdAdmins(filtererdAdmins)
  };

  const deleteUserFunc = async (user) => {
    const CreateAdmins = functions().httpsCallable('CreateAdmins');
    await CreateAdmins({ displayName: user.name, email: user.email, pw: user.password, role: user.role, type: 'delete', uid: user.key })
    firestore().collection('admin').doc(user.key).delete().then(() => {
      setDeleteUser(false)
      setLoading(false)
    })
  }


  return (
    <div>
      {loading && (
        <div className="loading" />
      )
      }

      <div className="disable-text-selection">
        <ListPageHeading
          heading="Users and Permissions"
          onSearchKey={onSearchKey}
          toggleModal={toggleModal}
          authUser={user}

        />
        {selectedUser && <Admin dismiss={() => setSelectedUser(false)}
          selectedUser={selectedUser} />}
        <Row className="survey-app">
          <Colxx xxs="12">

            {
              filtererdAdmins?.length ?
                <ReactTable
                  data={filtererdAdmins}
                  columns={dataTableColumns()}
                  defaultPageSize={10}
                  pageSize={filtererdAdmins?.length < 10 ? filtererdAdmins?.length : 10}
                  showPageJump={false}
                  showPageSizeOptions={false}
                  PaginationComponent={DataTablePagination}
                  className='custuomReactTable'
                />
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Items</div>
            }
          </Colxx>
        </Row>

        <Modal backdrop="static" isOpen={deleteUser}>
          <ModalHeader >
            Delete User
          </ModalHeader>
          <ModalBody>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2">Are you sure you want to delete this user?</Label>
              </Colxx>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setDeleteUser(false)}>Dismiss</Button>
            <Button color='danger' onClick={() => {
              deleteUserFunc(deleteUser)
              setLoading(true)
            }}>Delete</Button>
          </ModalFooter>

        </Modal>
      </div>
    </div>
  );
};

export default AdminsList;