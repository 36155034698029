import React, { Component, Fragment } from 'react';
import { Row, Button } from 'reactstrap';
import { Colxx, Separator } from '../../../../components/common/CustomBootstrap';
import IconCardsCarousel from '../../../../containers/dashboards/IconCardsCarousel';
import RecentOrders from '../../../../containers/dashboards/RecentOrders';
import TopWashers from '../../../../containers/dashboards/topWashers';
import { firestore } from '../../../../helpers/Firebase';
import { formatNumber } from '../../../../helpers/Utils';

class SelectedProvider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            totals: [],
            sortedWashers: false
        }
    }


    componentDidMount() {
        let { washers, provider } = this.props
        console.log("provider", provider);
        let { totals, bookings } = this.state
        let _this = this
        bookings = []
        let ref = firestore().collection('newAnalytics')
        ref.doc("SP").get().then(function (snapshot) {
            let data = snapshot.data()[provider?.key]
            totals = [
                { title: 'Total Washers', icon: "iconsminds-engineering", value: (washers && Object.values(washers)?.filter(w => w?.sp_id === provider?.key)?.length) || 0, color: "#00BCD4" },
                { title: 'Total Users', icon: "iconsminds-mens", value: formatNumber(data?.totalUsers) || 0, color: "#3F51B5" },
                { title: 'Total Sales', icon: "iconsminds-money-bag", value: formatNumber(data?.totalRevenu) || 0, color: "#278664" },
                { title: 'Average Rating', icon: "simple-icon-star", value: (Number(data?.totalRate?.rate || 0) / Number(data?.totalRate?.count || 1)).toFixed(2) || 0, color: "#FFE234" },
                { title: 'Total Bookings', icon: "iconsminds-basket-coins", value: formatNumber(data?.totalBookings) || 0, color: "#9C27B0" },
                { title: 'Total Online', icon: "simple-icon-check", value: formatNumber(data?.counters?.onlineBooking) || 0, color: "#4CAF50" },
                { title: 'Total Offline', icon: "simple-icon-power", value: formatNumber(data?.counters?.offlineBooking) || 0, color: "#dc3545" },
                { title: 'Total Canceled', icon: "simple-icon-close", value: formatNumber(data?.counters?.canceled) || 0, color: "#000000" },
                { title: 'Total Rejected', icon: "simple-icon-exclamation", value: formatNumber(data?.counters?.rejected) || 0, color: "#000000" },

            ]
            _this.setState({ totals, loading: false })
        })
        ref.doc("washer").get().then(function (snapshot) {
            let data = snapshot.data()
            let sorted = Object.keys(data).map(item => {
                return washers?.[item]?.sp_id === provider?.key && { ...washers?.[item], ...data[item] }
            }).filter(rec => rec)
            _this.setState({ sortedWashers: sorted?.sort((a, b) => b.totalBookings - a.totalBookings) })
        })

        firestore().collection('bookings').where('sp_id', '==', provider?.key).orderBy('created', 'desc').limit(6).get().then(function (snapshot) {
            snapshot.docs.forEach(doc => {
                bookings.push(doc.data())
            })
            _this.setState({ bookings })

        })

    }
    render() {
        let { provider, selectProvider, authUser } = this.props
        let { totals, sortedWashers, bookings, loading } = this.state

        return (
            (
                loading ? (
                    <div className="loading" />
                ) : (
                    <Fragment>
                        <Row className="survey-app">
                            <Colxx xxs="12">
                                <div className="mb-2">
                                    <Row>
                                        <Colxx xxs="6">
                                            <h1 className='m-0 p-0'>{provider.name}</h1>
                                        </Colxx>
                                        <Colxx xxs="6">
                                            <Button
                                                color="danger"
                                                className="btn-shadow float-right"
                                                size="lg"
                                                onClick={() => selectProvider(null)}>Dismiss</Button>
                                        </Colxx>
                                    </Row>
                                </div>
                                <Separator className="mb-5" />
                                <Row>
                                    <Colxx xxs="12" className="mt-2">
                                        {totals && totals.length && <IconCardsCarousel totals={totals} authUser={authUser} />
                                        }
                                    </Colxx>
                                    {bookings &&
                                        <Colxx xxs="12" md="6" className="mb-4">
                                            <RecentOrders authUser={authUser} bookings={bookings} />
                                        </Colxx>
                                    }
                                    {sortedWashers &&
                                        <Colxx xxs="12" md="6" className="mb-4">
                                            < TopWashers authUser={authUser} washers={sortedWashers} />
                                        </Colxx>
                                    }
                                </Row>


                            </Colxx>

                        </Row>
                    </Fragment>
                ))
        );
    }
}


export default SelectedProvider;
