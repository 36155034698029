import React, { Component } from 'react';
import IconCard from "../../components/cards/IconCard";
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';

class IconCardsCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      totals: []
    }
  }


  render() {
    let { totals } = this.props

    return (
      <Row className="icon-cards-row" >
       
        {totals.map((item, index) => {
          return (
            <Colxx key={`icon_card_${index}`} xxs="12" md="2"  >
              <IconCard {...item} className="mb-4" />
            </Colxx>
          );
        })}
      </Row>

    );
  }
}


export default IconCardsCarousel;
