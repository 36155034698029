/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" }
];
export const statusObject = {
  pending: {
    color: ' #3498db',
    title: 'Pending'
  },

  accepted: {
    color: ' #145388',
    title: 'Accepted'

  },

  active: {
    color: '#3195a5',
    title: 'Active'

  },

  rated: {
    color: '#b69329',
    title: 'Rated'

  },
  completed: {
    color: ' #3e884f',
    title: 'Completed'

  },
  notRated: {
    color: ' #3e884f',
    title: 'Completed'

  },
  rejected: {
    color: '#c43d4b',
    title: 'Rejected'

  },
  canceled: {
    color: '#c43d4b',
    title: 'Canceled'

  }
}

export const firebaseConfig = {
  apiKey: "AIzaSyCExglJjHXgXzSGHJB1NmLll7NgjOG8_bc",
  authDomain: "chill-wash.firebaseapp.com",
  projectId: "chill-wash",
  storageBucket: "chill-wash.appspot.com",
  messagingSenderId: "975435885787",
  appId: "1:975435885787:web:3ea7300b4bde7b229bb0fe",
  measurementId: "G-LMDBYJ76KM"
};
export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = "light.purple";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = true;
export const themeColorStorageKey = "__theme_color";