import React, { Component, Fragment } from "react";
import {
  Row, Button, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
} from "reactstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { firestore, functions } from '../../../helpers/Firebase'
import ReactTable from "react-table";
import ServiceImg from '../../../assets/css/img/services.png'
import DataTablePagination from "../../../components/DatatablePagination";
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import { NotificationManager } from "../../../components/common/react-notifications";
const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}
const TheadComponent = props => null; // a component returning null (to hide) or you could write as per your requirement

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsers: [],
      search: ""
    }
  }

  dataTableColumns() {
    let { authUser } = this.props
    return ([
      {
        accessor: "Service_image",
        width: deviceWidth() === 'desktop' ? 150 : 80,
        Cell: props => <div className="providerfieldText"><img style={{ width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={props.value && props.value[0] ? props.value[0] : ServiceImg} alt={props.value} /></div>
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Number",
        accessor: "number",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Email",
        accessor: "email",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        width: 80,
        accessor: "delete",
        show: (authUser?.user?.accessType === 'superAdmin') && deviceWidth() === 'desktop' ? true : false,

        Cell: props => <p className="providerfieldText">
          <Button
            color="danger"
            size="sm"
            outline
            onClick={() => this.setState({ deleteUser: true, selectedUser: props.original })}
          >
            Delete
          </Button>
        </p>
      }
    ])


  }
  deleteUser(user) {
    let { allUsers } = this.state
    var deleteUserFucntion = functions().httpsCallable('deleteUser');
    deleteUserFucntion({ user: user.key })
      .then((result) => {
        if (result.data.msg) {
          allUsers = allUsers.filter((record) => record.key !== user.key)
          this.setState({ allUsers, isLoading: false, search: '', deleteUser: false, selectedUser: false }, () => {
            firestore().collection(this.props.role === "customers" ? 'users' : 'washers').doc(user.key).delete().then(() => {
              NotificationManager.success(
                'User Deleted successfully',
                'Success',
                2000,
                null,
                ""
              )
            })
          })
        } else {
          NotificationManager.error(
            "Cannot process your request!",
            "Error",
            2000,
            null,
            ""
          )
        }
        // Read result of the Cloud Function.
      });


  }

  componentDidMount() {
    this.setState({ role: this.props.role }, () => {
      this.getUsersList()
    })
  }
  componentDidUpdate() {
    if (this.state.role !== this.props.role) {
      this.setState({ role: this.props.role }, () => {
        this.getUsersList()
      })
    }
  }
  getUsersList() {
    let _this = this
    let allUsers = [];
    firestore().collection(this.props.role === "customers" ? 'users' : 'washers').get().then(function (usersSnap) {
      usersSnap.forEach(function (doc) {
        let user = {}
        user = doc.data()
        user.key = doc.id
        allUsers.push(user);
      });
      _this.setState({ allUsers, isLoading: false })
    });
  }
  createNotification = (className) => {
    let cName = className || "";
    return (
      NotificationManager.error(
        "Please fill all information required!",
        "Error",
        2000,
        null,
        cName
      )
    )
  };

  toggleModal = () => {
    this.setState({ addNewService: true })
  };
  onSearchKey = e => {
    this.setState(
      {
        search: e.target.value.toLowerCase()
      }
    );
  };

  render() {

    let { allUsers, search, selectedUser, deleteUser } = this.state
    let filteredUsers = allUsers.filter(item => {
      return item.name ? (item?.name.toLowerCase().includes(search.toLowerCase()) || item.number.includes(search)) : false
    });

    return (
      this.state.isLoading ? (
        <div className="loading" />
      ) : (
        <Fragment>
          <div className="disable-text-selection">

            <ListPageHeading
              heading={this.props.role === "customers" ? 'Customers' : 'Washers'}
              onSearchKey={this.onSearchKey}
              toggleModal={this.toggleModal}
              accessType="admin"
            />

            <Row className="survey-app">
              <Colxx xxs="12">
                {
                  filteredUsers && filteredUsers.length ?

                    (deviceWidth() === 'mobile') ?
                      (
                        <ReactTable
                          data={filteredUsers}
                          columns={this.dataTableColumns()}
                          defaultPageSize={10}
                          pageSize={filteredUsers.length < 10 ? filteredUsers.length : 10}
                          showPageJump={false}
                          showPageSizeOptions={false}
                          PaginationComponent={DataTablePagination}
                          className='custuomReactTable'
                          TheadComponent={TheadComponent}
                        />
                      ) :
                      <ReactTable
                        data={filteredUsers}
                        columns={this.dataTableColumns()}
                        defaultPageSize={10}
                        pageSize={filteredUsers.length < 10 ? filteredUsers.length : 10}
                        showPageJump={false}
                        showPageSizeOptions={false}
                        PaginationComponent={DataTablePagination}
                        className='custuomReactTable' />
                    :




                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Users</div>
                }
              </Colxx>
            </Row>
            <Modal backdrop="static" isOpen={deleteUser}>
              <ModalHeader >
                Delete Users
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Colxx xxs="12" className="mb-4">
                    <Label className="mb-2">Are you sure you want to delete this user?</Label>
                  </Colxx>
                </Row>

              </ModalBody>
              <ModalFooter>
                <Button onClick={() => this.setState({ deleteUser: false, selectedUser: false })}>Dismiss</Button>
                <Button color='danger' onClick={() => this.setState({ isLoading: true }, () => { this.deleteUser(selectedUser) })}>Delete User</Button>
              </ModalFooter>

            </Modal>
          </div>
        </Fragment>
      )
    )
  }
}
const mapStateToProps = ({ providers, authUser }) => {
  return {
    authUser,
    providers
  };
};
export default injectIntl(connect(mapStateToProps)(Users));


