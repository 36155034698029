import React from "react";
import { Card, CardBody } from "reactstrap";

const IconCard = ({ className = "mb-4", icon, title, value, color }) => {
  return (
    <div className={`icon-row-item ${className}`}>
      <Card className="rounded" >
        <CardBody className="text-center">
          <i className={icon} style={{ color: color }} />
          <p className="card-text font-weight-semibold mb-0">
            {title}
          </p>
          <p className="lead text-center" style={{ color: color }}>{value}</p>
        </CardBody>
      </Card>
    </div>
  );
};

export default IconCard;
