import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import promocodes from "./promocodes.js";
import vouchers from "./vouchers.js"
import marketingVouchers from "./marketingVouchers.js"
import banners from "./banners.js";
import featuredProviders from "./featuredProviders.js";
const Marketing = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/marketing`} />
      <Route path={`${match.url}/promocodes`} component={promocodes} />
      <Route path={`${match.url}/banners`} component={banners} />
      <Route path={`${match.url}/featured`} component={featuredProviders} />
      <Route path={`${match.url}/vouchers`} component={vouchers} />
      <Route path={`${match.url}/marketing-vouchers`} component={marketingVouchers} />

      <Redirect to="/error" />
    </Switch>
  </div>
);

export default Marketing;
