import React, { Component } from "react";
import { Card, CardBody, CardTitle, Badge, Table } from "reactstrap";
import moment from 'moment'
import { accessTypes } from "../../helpers/Utils";
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
class RecentOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      totals: []
    }
  }

  render() {
    let { bookings ,authUser} = this.props
    console.log('authUser',authUser);
    let sortedBookings = []
    if (bookings.length) {
      sortedBookings = bookings.sort((a, b) => (a.bookingDate < b.bookingDate) ? 1 : ((b.bookingDate < a.bookingDate) ? -1 : 0))

    }

    return (
      <Card className="rounded" style={{ height: '100%' }}>
        <CardBody>
          <CardTitle>Recent Bookings</CardTitle>
          <Table borderless>
            <thead>
              <tr>
                <th className="text-semi-muted mb-2" >
                  Customer
                </th>
                <th className="text-semi-muted mb-2" >
                  Service
                </th>
                <th className="text-semi-muted mb-2">
                  Booking Date
                </th>
                <th className="text-semi-muted mb-2">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {
                sortedBookings?.map((order, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ verticalAlign: 'middle' }}>
                        {order.customer_name}
                        <br />
                        {accessTypes?.includes(authUser?.user?.accessType) ? order.customer_number : ''}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {order.service_name}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {moment(order.bookingDate).format('DD/MM/YYYY hh:mm A')}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <Badge
                          key={index}
                          color={(order.status === 'pending' || order.status === 'new' ?
                            "primary"
                            : order.status === 'accepted' ?
                              "secondary"

                              : order.status === 'active' ?
                                "info"

                                : order.status === 'completed' || order.status === 'notRated' ?
                                  "success"

                                  : order.status === 'rated' ?
                                    "warning"

                                    : order.status === 'rejected' || order.status === 'canceled' ?
                                      "danger"
                                      : ''
                          )
                          }
                          pill
                        >
                          {order.status === "notRated" ? 'Completed' : capitalize(order.status)}

                        </Badge>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

        </CardBody>
      </Card >
    );
  }
}
export default RecentOrders