import React, { Component, Fragment } from "react";
import {
  Row,
  Nav,
  TabContent,
  TabPane,
  Input,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,

} from "reactstrap";
import './washer.css'
import classnames from "classnames";
import { Colxx } from "../../../components/common/CustomBootstrap";
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { firestore, storage, functions } from '../../../helpers/Firebase'
import Locations from './components/locations';
import Select from "react-select";
import { components } from "react-select";
import { NotificationManager } from "../../../components/common/react-notifications";
import PhoneInput from 'react-phone-input-2'
import Services from './services/index.js'
import 'react-phone-input-2/lib/style.css'
import { accessTypes } from "../../../helpers/Utils";
import TimingsLogs from "./components/timingsLogs";
import WasherWallet from "./components/washerWallet.js";
const CustomSelectInput = props => {
  var customProps = Object.assign({}, props);
  delete customProps.autoCorrect;
  delete customProps.autoCapitalize;
  return <components.Input {...customProps} />;
};
const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}
const tabs = [{ name: 'General Information', key: 1 }, { name: 'Services', key: 2 },
{ name: 'Timings', key: 3 }, { name: 'Serving Areas', key: 4 }, { name: 'Worker Tips', key: 5 }]
class Washers extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.state = {
      sp_id: '',
      activeTab: 1,
      timings: {},
      name: '',
      name_ar: '',
      number: '',
      country_code: '',
      phoneNumber: '',
      location: {},
      profile_image: '',
      pictures: [],
      activeServices: {},
      servicesCopy: {},
      timingsCopy: {},
      servicesDescs: {},
      service: {},
      editedModifiers: {},
      changeNumber: false,
      servingAreasAlert: false,
      isLoading: true,
      saveDefultTimings: false,
      loading: false,
    };

  }
  createNotification = (className, error) => {
    let cName = className || "";
    return (
      NotificationManager.error(
        error,
        "Error",
        2000,
        null,
        cName
      )
    )
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  handleDateChange(date, selectedDay, index, type) {
    let { timings } = this.state
    // let optimizedMoment = moment(date).set('year', 1955).set('month', 0).set('date', 5);
    let optimizedMoment = moment("1955-01-05T" + moment(date).format('HH:mm')).valueOf()
    switch (type) {
      case 'start':
        timings[selectedDay].timings[index].startTime = optimizedMoment
        this.setState({ timings });
        break;
      case 'end':
        timings[selectedDay].timings[index].endTime = optimizedMoment
        this.setState({ timings });
        break;

      default:
        break;
    }
  };

  handleSwitchChange = (selectedDay, switchState) => {
    let { timings } = this.state
    timings[selectedDay].isOpen = switchState
    this.setState({ timings });
  };

  addTimings = (day) => {
    let { timings } = this.state
    // let optimizedMoment = moment().set('year', 1955).set('month', 0).set('date', 5);
    let optimizedMoment = moment("1955-01-05T" + moment().format('HH:mm'))
    timings[day].timings.push({ startTime: optimizedMoment.startOf('day').valueOf(), endTime: optimizedMoment.endOf('day').valueOf() });
    this.setState({ timings });
  };

  removeTimings = (day, timeIndex) => {
    let { timings } = this.state
    timings[day].timings.splice(timeIndex, 1);

    this.setState({ timings });
  };

  toggleTab(tab) {
    let { addNewWasher } = this.props

    if (addNewWasher && tab === 4) {
      this.setState({
        servingAreasAlert: true
      });
    } else {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab
        });
      }
    }
  }

  componentWillMount() {
    let { addNewWasher, selectedWasher, providers, authUser } = this.props
    let { name, name_ar, number, country_code, phoneNumber, timings, profile_image, sp_id, activeServices, servicesDescs } = addNewWasher ? this.state : JSON.parse(JSON.stringify(selectedWasher));;


    if (addNewWasher && authUser?.user?.accessType === 'admin') {
      sp_id = authUser.user.uid
    }
    this.setState({ selectedProvider: providers?.find(p => p?.key === sp_id) })

    // let optimizedMoment = moment().set('year', 1955).set('month', 0).set('date', 5);
    let optimizedMoment = moment("1955-01-05T" + moment().format('HH:mm'))
    if (addNewWasher || !timings) {
      timings = {
        Sunday: { timings: [{ startTime: optimizedMoment.startOf('day').valueOf(), endTime: optimizedMoment.endOf('day').valueOf() }], isOpen: false }
        ,
        Monday: { timings: [{ startTime: optimizedMoment.startOf('day').valueOf(), endTime: optimizedMoment.endOf('day').valueOf() }], isOpen: false }
        ,
        Tuesday: { timings: [{ startTime: optimizedMoment.startOf('day').valueOf(), endTime: optimizedMoment.endOf('day').valueOf() }], isOpen: false }
        ,
        Wednesday: { timings: [{ startTime: optimizedMoment.startOf('day').valueOf(), endTime: optimizedMoment.endOf('day').valueOf() }], isOpen: false }
        ,
        Thursday: { timings: [{ startTime: optimizedMoment.startOf('day').valueOf(), endTime: optimizedMoment.endOf('day').valueOf() }], isOpen: false }
        ,
        Friday: { timings: [{ startTime: optimizedMoment.startOf('day').valueOf(), endTime: optimizedMoment.endOf('day').valueOf() }], isOpen: false }
        ,
        Saturday: { timings: [{ startTime: optimizedMoment.startOf('day').valueOf(), endTime: optimizedMoment.endOf('day').valueOf() }], isOpen: false }
      }
    }

    const sorter = {
      "saturday": 1,
      "sunday": 2,
      "monday": 3,
      "tuesday": 4,
      "wednesday": 5,
      "thursday": 6,
      "friday": 7,
    };

    let tmp = [];
    Object.keys(timings).forEach(function (key) {
      let value = timings[key];
      let index = sorter[key.toLowerCase()];
      tmp[index] = {
        key: key,
        value: value
      };
    });

    let orderedData = {};
    tmp.forEach(function (obj) {
      orderedData[obj.key] = obj.value;
    });

    this.setState({
      name: name ? name : '',
      name_ar: name_ar ? name_ar : '',
      sp_id: sp_id ? sp_id : '',
      number: number ? number : '',
      country_code: country_code ? country_code : '',
      phoneNumber: phoneNumber ? phoneNumber : '',
      timings: orderedData,
      profile_image: profile_image ? profile_image : [],
      activeServices: activeServices ? activeServices : {},
      servicesCopy: JSON.parse(JSON.stringify(activeServices || {})),
      timingsCopy: JSON.parse(JSON.stringify(orderedData || {})),
      servicesDescs: servicesDescs ? servicesDescs : {},
      isLoading: false,
    })
  }
  compareTimings(timings1, timings2) {
    for (let day in timings1) {
      // Check if the day exists in the second timings object
      if (!(day in timings2)) {
        return false;
      }

      // Check if the timings array has the same length
      if (timings1[day].timings.length !== timings2[day].timings.length) {
        return false;
      }

      // Check if each timing object has the same start and end times
      for (let i = 0; i < timings1[day].timings.length; i++) {
        const timing1 = timings1[day].timings[i];
        const timing2 = timings2[day].timings[i];

        if (timing1.startTime !== timing2.startTime || timing1.endTime !== timing2.endTime) {
          return false;
        }
      }

      // Check if the isOpen value is the same
      if (timings1[day].isOpen !== timings2[day].isOpen) {
        return false;
      }
    }

    // If all values are the same, return true
    return true;
  }
  compareSerives(activeService1, activeService2) {
    // Check if the number of keys in the objects are different
    if (Object.keys(activeService1).length !== Object.keys(activeService2).length) {
      return false;
    }

    // Check if each key-value pair is identical in both objects
    for (let key in activeService1) {
      if (typeof activeService1[key] === 'object') {
        // Recursively compare sub-objects
        if (!this.compareSerives(activeService1[key], activeService2[key])) {
          return false;
        }
      } else if (activeService1[key] !== activeService2[key]) {
        return false;
      }
    }

    // If all key-value pairs are identical, return true
    return true;
  }
  submit() {
    let { addNewWasher, selectedWasher, updateWasher, authUser } = this.props
    let _this = this
    let { name, name_ar, number, timings, profile_image, sp_id, pictures, activeServices, country_code, phoneNumber, servicesDescs, servicesCopy, timingsCopy } = this.state
    if (addNewWasher) {
      if (number && number !== '' && name !== '' && name_ar !== '') {
        const authusers = functions().httpsCallable('setRoles');
        authusers({ role: 'washer', displayName: name, phoneNumber: phoneNumber }).then(function (result) {
          // Read result of the Cloud Function.
          if (result && result.data && !result.data.error) {
            let { user } = result.data
            let key = user.uid
            if (key) {
              if (pictures && pictures.length > 0 && pictures[0].name) {
                var metadata = {
                  cacheControl: 'public,max-age=300',
                };
                pictures.forEach(element => {
                  var storageRef = storage().ref().child('washers').child(key).child("logo").put(element.data, metadata);
                  storageRef.on('state_changed', function (snapshot) {
                  }, function (error) {
                    _this.setState({ isLoading: false })

                    // Handle unsuccessful uploads
                  }, function () {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                      firestore().collection('washers').doc(key).set({
                        name, name_ar,
                        number,
                        country_code,
                        phoneNumber,
                        sp_id: sp_id ? sp_id : authUser.user.uid,
                        timings,
                        profile_image: [downloadURL],
                        created: Date.now(),
                        activeServices,
                        servicesDescs,
                        active: true,
                      }).then(() => {
                        updateWasher()
                      })
                    })
                  })
                });
              } else {
                firestore().collection('washers').doc(key).set({
                  name, name_ar,
                  number,
                  country_code,
                  phoneNumber,
                  sp_id: sp_id ? sp_id : authUser.user.uid,
                  timings,
                  profile_image: profile_image && profile_image.length ? profile_image : [],
                  created: Date.now(),
                  activeServices,
                  servicesDescs,
                  active: true,
                }).then(() => {
                  updateWasher()
                })
              }
            }
          } else {
            if (result?.data?.error?.errorInfo?.code?.includes('invalid-phone-number')) {
              _this.createNotification("error", "Invalid phone number")
              _this.setState({ isLoading: false })
            } else if (result?.data?.error?.errorInfo?.code?.includes('auth/phone-number-already-exists')) {
              _this.createNotification("error", "This number already exists")
              _this.setState({ isLoading: false })
            }
          }
        });
      } else {
        _this.createNotification("error", "Please fill all requirements")
        _this.setState({ isLoading: false })

      }
    } else {
      let key = selectedWasher.key ? selectedWasher.key : ''
      if (key) {
        if (pictures && pictures.length > 0 && pictures[0].name) {
          var metadata = {
            cacheControl: 'public,max-age=300',
          };
          pictures.forEach(element => {
            var storageRef = storage().ref().child('washers').child(key).child("logo").put(element.data, metadata);
            storageRef.on('state_changed', function (snapshot) {
            }, function (error) {
              // Handle unsuccessful uploads
            }, function () {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                firestore().collection('washers').doc(key).update({
                  name, name_ar,
                  number,
                  country_code,
                  phoneNumber,
                  sp_id: sp_id ? sp_id : authUser.user.uid,
                  timings,
                  profile_image: [downloadURL],
                  activeServices,
                  servicesDescs,
                  updated: Date.now()
                }).then(() => {
                  let id = firestore().collection('washers').doc().id
                  let servicesIdentical = _this.compareSerives(activeServices, servicesCopy)

                  if (servicesCopy && Object.keys(servicesCopy).length && !servicesIdentical) {
                    firestore().collection('washers').doc(key).collection("servicesLogs").doc(id).set({
                      servicesCopy,
                      created: Date.now()
                    })
                  }
                  let timingsIdentical = this.compareTimings(timings, timingsCopy)
                  if (timingsCopy && Object.keys(timingsCopy).length && !timingsIdentical) {
                    firestore().collection('washers').doc(key).collection("timingsLogs").doc(id).set({
                      timingsCopy,
                      created: Date.now()
                    })
                  }
                  updateWasher()

                })
              })
            })
          });
        } else {
          firestore().collection('washers').doc(key).update({
            name, name_ar,
            number,
            country_code,
            phoneNumber,
            sp_id: sp_id ? sp_id : authUser.user.uid,
            timings,
            profile_image: profile_image && profile_image.length ? profile_image : [],
            activeServices,
            servicesDescs,
            updated: Date.now()

          }).then(() => {
            let id = firestore().collection('washers').doc().id
            let servicesIdentical = _this.compareSerives(activeServices, servicesCopy)

            if (servicesCopy && Object.keys(servicesCopy).length && !servicesIdentical) {
              firestore().collection('washers').doc(key).collection("servicesLogs").doc(id).set({
                servicesCopy,
                created: Date.now()
              })
            }
            let timingsIdentical = this.compareTimings(timings, timingsCopy)
            if (timingsCopy && Object.keys(timingsCopy).length && !timingsIdentical) {
              firestore().collection('washers').doc(key).collection("timingsLogs").doc(id).set({
                timingsCopy,
                created: Date.now()
              })
            }
            updateWasher()

          })
        }
      }
    }
  }
  onDrop(picture) {
    let _this = this;
    let pictures = []
    if (picture.length) {
      let lastPic = picture[picture.length - 1]
      Resizer.imageFileResizer(
        lastPic,
        720,
        1080,
        'jpeg',
        60,
        0,
        uri600 => {
          pictures.push({
            name: '600',
            data: uri600
          });
          _this.setState({ pictures })
        },
        'blob'
      );

    } else {
      _this.setState({ pictures: [], profile_image: [] })
    }
  }

  render() {
    let { name, name_ar, phoneNumber, timings, selectedProvider, changeNumber, pictures, servingAreasAlert, isLoading, saveDefultTimings, activeServices, servicesDescs, showTimingsLogs } = this.state
    let { addNewWasher, selectedWasher, onClickItem, providers, authUser, services, modifiers, variations, advServices } = this.props
    const CustomInput = React.forwardRef((props, ref) => {
      return (
        <input
          onClick={props.onClick}
          value={props.value}
          type="text"
          className="customDatePicker"
          readOnly={true}
          ref={ref}
        />
      )
    })
    return (
      (
        isLoading ? (
          <div className="loading" />
        ) : (
          <Fragment>
            <Row>
              <Colxx xxs="12">
                <h1>{addNewWasher ? 'New Worker' : selectedWasher.name}</h1>
                <Nav tabs className="separator-tabs ml-0 mb-5">
                  {
                    tabs.map(item => {
                      return (
                        <div
                          style={{ cursor: 'pointer' }}
                          key={item.key}
                          className={classnames({
                            active: this.state.activeTab === item.key,
                            "nav-link": true
                          })}
                          onClick={() => {
                            this.toggleTab(item.key);
                          }}
                        >
                          {item.name}
                        </div>
                      )
                    })
                  }
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId={1}>
                    <Card className="rounded">
                      <CardBody>
                        {
                          (accessTypes.includes(authUser.user.accessType) && addNewWasher) &&
                          <div>
                            <Row>
                              <Colxx xxs="12" className="mb-2">
                                <h4>Select Service Provider</h4>
                              </Colxx>
                            </Row>
                            <Row>
                              <Colxx xxs="12" md="6" className="mb-4">
                                <Select
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="form-field-name"
                                  value={selectedProvider}
                                  onChange={(e) => {
                                    this.setState({ selectedProvider: e, sp_id: e.key })
                                  }}
                                  options={providers}
                                />
                              </Colxx>

                            </Row>
                          </div>
                        }
                        <Row>
                          <Colxx xxs="12" className="mb-2">
                            <h4>General Information</h4>
                          </Colxx>
                        </Row>
                        <Row>
                          <Colxx xxs="12" md="6" className="mb-4">
                            <div className="mb-2" >Photo</div>
                            <ImageUploader
                              className={(pictures && pictures.length) ? 'w-100 hasImage' : 'w-100'}
                              withIcon={true}
                              label={'Max file size: 15mb, accepted: jpg|gif|png'}
                              buttonText='Choose images'
                              onChange={this.onDrop}
                              imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                              maxFileSize={15728640}
                              withPreview={true}
                              singleImage={true}
                              defaultImages={selectedWasher && selectedWasher.profile_image}
                            />
                          </Colxx>
                        </Row>
                        <Row>
                          <Colxx xxs="6" md="3" className="mb-4">
                            <div className="mb-2" >Name</div>
                            <Input type="text" autoCapitalize="characters" placeholder="e.g. washer" value={name} className="customInput" onChange={(e) => this.setState({ name: this.Capitalize(e.target.value) })} />
                          </Colxx>
                          <Colxx xxs="6" md="3" className="mb-4">
                            <div className="mb-2" style={{ textAlign: 'end' }} >الاسم</div>
                            <Input type="text" placeholder="e.g. washer" style={{ textAlign: 'end' }} value={name_ar} className="customInput" onChange={(e) => this.setState({ name_ar: e.target.value })} />
                          </Colxx>
                        </Row>

                        <Row>
                          <Colxx xxs="12" className="mb-2 mt-4">
                            <h4>Contact Information</h4>
                          </Colxx>
                        </Row>
                        <Row>
                          <Colxx xxs="12" md="6" className="mb-4">
                            <div className="mb-2" >Phone</div>
                            <PhoneInput
                              regions={['middle-east', 'north-africa']}
                              inputStyle={{ width: '100%', height: '42px', border: '1px solid #d7d7d7' }}
                              className="customInput"
                              country={'ae'}
                              placeholder="e.g. +971xxxxxx"
                              value={phoneNumber}
                              onChange={(value, data, event, formattedValue) =>
                                this.setState({ country_code: data.dialCode, number: value.slice(data.dialCode.length), phoneNumber: formattedValue })
                              }
                            />
                            {!addNewWasher && <div
                              color="primary"
                              className="float-left mt-2"
                              style={{ color: '#3498db', cursor: 'pointer', fontWeight: '500' }}
                              onClick={() => this.setState({ changeNumber: true })}>Change Number</div>
                            }
                          </Colxx>
                        </Row>

                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Services
                      addNewWasher={addNewWasher}
                      selectedWasher={selectedWasher}
                      services={services}
                      advServices={advServices}
                      modifiers={modifiers}
                      selectedProvider={selectedProvider}
                      providers={providers}
                      authUser={authUser}
                      variations={variations}
                      activeServices={activeServices}
                      servicesDescs={servicesDescs}
                      update={(e) => this.setState(e)} />
                  </TabPane>

                  <TabPane tabId={3}>
                    <Card className="rounded">
                      <CardBody>
                        <Row>
                          <Colxx xxs="12" >
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                              <div>

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                  <h4>Timings Schedule</h4>

                                  {
                                    selectedProvider?.defultSettings?.defultTimings !== timings ?
                                      <div style={{ display: "flex", alignItems: 'center' }}>
                                        <div style={{ cursor: 'pointer', fontSize: '10px', marginBottom: '.5rem', fontWeight: '600', color: '#007bff', marginLeft: '18px', border: '1px solid', padding: '2px 10px', borderRadius: '10px' }} onClick={() => this.copyTimings()}>Fill with defult timings</div>
                                        <div style={{ cursor: 'pointer', fontSize: '10px', marginBottom: '.5rem', fontWeight: '600', color: '#28a745', marginLeft: '18px', border: '1px solid', padding: '2px 10px', borderRadius: '10px' }} onClick={() => this.setState({ saveDefultTimings: true })}>Save to defult settings</div>
                                      </div>
                                      : <div style={{ cursor: 'pointer', fontSize: '10px', marginBottom: '.5rem', fontWeight: '600', color: '#28a745', marginLeft: '18px', border: '1px solid', padding: '2px 10px', borderRadius: '10px' }} onClick={() => this.setState({ saveDefultTimings: true })}>Save to defult settings</div>
                                  }
                                </div>
                                <p>Set working hours for your store</p>
                              </div>
                              {accessTypes.includes(authUser?.user?.accessType) ? <Button
                                color="primary"
                                className="btn-shadow float-right"
                                size="sm"
                                onClick={() => {
                                  this.setState({ showTimingsLogs: selectedWasher })
                                }
                                }>
                                Show Logs
                              </Button> : ''}
                            </div>


                          </Colxx>
                          <Colxx xxs="8" >

                            {

                              Object.keys(timings).map((item, index) => {
                                return (
                                  <div key={index} className="timingsParent" style={{ flexDirection: deviceWidth() === 'desktop' ? 'row' : 'column' }}>
                                    <div className="dayParent">
                                      <div className="day">{item}</div>
                                      <Switch
                                        className="custom-switch custom-switch-primary"
                                        checked={timings[item].isOpen}
                                        onChange={switchCheckedPrimary => {
                                          this.handleSwitchChange(item, switchCheckedPrimary)
                                        }}
                                      />
                                    </div>
                                    {
                                      timings[item].isOpen &&
                                      <div className="dateContainer">
                                        {timings[item].timings.map((timing, index2) => {
                                          return (
                                            <div key={index2} className="date2Container" style={{ margin: deviceWidth() === 'desktop' ? '0 0 10px 10px' : '0 0 10px 0px' }}>
                                              <DatePicker
                                                customInput={<CustomInput />}
                                                selected={(timing.startTime ? moment(timing.startTime).toDate() : moment().endOf('day').toDate())}
                                                className="customDatePicker"
                                                showTimeSelectOnly
                                                onChange={date => this.handleDateChange(date, item, index2, 'start')}
                                                showTimeSelect
                                                dateFormat="hh:mm a"
                                                timeFormat="hh:mm a"
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                placeholderText={"Start"}
                                                minTime={index2 > 0 ? moment().hours(moment(timings[item].timings[index2 - 1].endTime).hours()).minutes(moment(timings[item].timings[index2 - 1].endTime).minutes()).toDate() : moment().hours(moment().startOf('day').hours()).minutes(moment().startOf('day').minutes()).toDate()}
                                                maxTime={moment().hours(moment().endOf('day').hours()).minutes(moment().endOf('day').minutes()).toDate()}
                                              />
                                              <DatePicker
                                                customInput={<CustomInput />}
                                                selected={(timing.endTime ? moment(timing.endTime).toDate() : moment().endOf('day').toDate())}
                                                className="customDatePicker"
                                                showTimeSelectOnly
                                                onChange={date => { this.handleDateChange(date, item, index2, 'end') }}
                                                showTimeSelect
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="hh:mm a"
                                                timeFormat="hh:mm a"
                                                placeholderText={"End"}
                                                minTime={timing.startTime ? moment().hours(moment(timing.startTime).hours()).minutes(moment(timing.startTime).minutes()).toDate() : moment().startOf('day').toDate()}
                                                maxTime={moment().hours(moment().endOf('day').hours()).minutes(moment().endOf('day').minutes()).toDate()} />
                                              {index2 === 0 ? <div className="addTimings" onClick={() => { this.addTimings(item) }}><i className="simple-icon-plus" /> Add New Timings</div>
                                                : <div className="removeTimings" onClick={() => { this.removeTimings(item, index2) }}><i className="simple-icon-close" /></div>}
                                            </div>
                                          )
                                        })}
                                      </div>
                                    }

                                  </div>
                                )
                              })
                            }
                          </Colxx>
                        </Row>
                      </CardBody>
                    </Card>
                  </TabPane>

                  <TabPane tabId={4}>
                    <Card className="rounded">
                      <CardBody>
                        <Locations washer={selectedWasher} />
                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tabId={5}>
                    <WasherWallet washer={selectedWasher} />
                  </TabPane>
                </TabContent>
                <TimingsLogs washer={showTimingsLogs} dismiss={() => {
                  this.setState({ showTimingsLogs: false })
                }} />
                <Modal
                  isOpen={saveDefultTimings}
                  backdrop="static">
                  <ModalHeader >Defult Settings</ModalHeader>
                  <ModalBody>
                    <Row>
                      <Colxx xxs="12" >
                        <Label >Do you want to save curent setting as defult settings?</Label>
                      </Colxx>
                    </Row>
                  </ModalBody>

                  <ModalFooter >
                    <Button color="danger" className="top-right-button" onClick={() => this.setState({ saveDefultTimings: false })}>Don't Save</Button>
                    <Button color="primary" className="top-right-button" onClick={() => this.setState({ saveDefultTimings: false, loading: true }, () => this.saveAsDefultTimings())}>Save</Button>
                  </ModalFooter>
                </Modal>
                <Modal
                  isOpen={changeNumber}
                  backdrop="static">
                  <ModalHeader >Change Phone Number</ModalHeader>
                  <ModalBody>
                    <Row>
                      <Colxx xxs="12" >
                        <Label >Phone Number</Label>
                        <PhoneInput
                          regions={['middle-east', 'north-africa']}
                          inputStyle={{ width: '100%', height: '42px', border: '1px solid #d7d7d7' }}
                          className="customInput"
                          country={'ae'}
                          placeholder="e.g. +971xxxxxx"
                          value={phoneNumber}
                          onChange={(value, data, event, formattedValue) =>
                            this.setState({ country_code: data.dialCode, number: value.slice(data.dialCode.length), phoneNumber: formattedValue })
                          }
                        />
                      </Colxx>
                    </Row>
                  </ModalBody>
                  <ModalFooter >
                    <Button color="danger" outline onClick={() => this.setState({ changeNumber: false })}>
                      Cancel
                    </Button>
                    <Button color="primary" className="top-right-button" onClick={() => this.changeNumber()}>
                      Change
                    </Button>
                  </ModalFooter>
                </Modal>
                <Modal
                  isOpen={servingAreasAlert}
                  backdrop="static">
                  <ModalHeader >
                    Alert
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Colxx xxs="12" >
                        <Label >
                          Please save worker data before adding serving areas
                        </Label>
                      </Colxx>
                    </Row>
                  </ModalBody>
                  <ModalFooter >
                    <Button color="primary" className="top-right-button" onClick={() => this.setState({ servingAreasAlert: false })}>
                      Ok
                    </Button>
                  </ModalFooter>
                </Modal>

                {
                  this.state.activeTab !== 4 && <Button
                    color="primary"
                    className="btn-shadow float-right mt-4"
                    size="lg"
                    onClick={
                      addNewWasher ?
                        (
                          this.state.activeTab === 1 ?
                            () => this.setState({ activeTab: 2 }) :
                            this.state.activeTab === 2 ?
                              () => this.setState({ activeTab: 3 }) :
                              this.state.activeTab === 3 ?
                                () => this.setState({ isLoading: true }, this.submit)
                                : ''
                        ) :
                        () => this.setState({ isLoading: true }, this.submit)
                    }>
                    {
                      addNewWasher ?
                        this.state.activeTab === 1 ?
                          'Add Services' :
                          this.state.activeTab === 2 ?
                            'Add Timings' :
                            'Save Changes'
                        : 'Save Changes'

                    }
                  </Button>
                }
                <Button
                  color="danger"
                  className="btn-shadow float-right m-4"
                  size="lg"
                  onClick={() => onClickItem(null)}>
                  Dismiss
                </Button>

              </Colxx>
            </Row>
          </Fragment >
        ))
    )
  }

  saveAsDefultTimings() {
    let { selectedProvider, timings } = this.state

    if (selectedProvider.defultSettings) {
      selectedProvider.defultSettings.defultTimings = timings
    } else {
      selectedProvider.defultSettings = { defultTimings: timings }

    }
    firestore().collection('service_provider').doc(selectedProvider.key).update({
      [`defultSettings.defultTimings`]:
        timings

    }).then(() => {
      this.setState({ loading: false, selectedProvider })
    })

  }
  copyTimings() {
    let { selectedProvider, timings } = this.state
    if (selectedProvider && selectedProvider.defultSettings && selectedProvider.defultSettings.defultTimings) {
      timings = selectedProvider.defultSettings.defultTimings
      const sorter = {
        "saturday": 1,
        "sunday": 2,
        "monday": 3,
        "tuesday": 4,
        "wednesday": 5,
        "thursday": 6,
        "friday": 7,

      };

      let tmp = [];
      Object.keys(timings).forEach(function (key) {
        let value = timings[key];
        let index = sorter[key.toLowerCase()];
        tmp[index] = {
          key: key,
          value: value
        };
      });

      let orderedData = {};
      tmp.forEach(function (obj) {
        orderedData[obj.key] = obj.value;
      });

      this.setState({ timings: orderedData });
    }

  }

  changeNumber() {
    let { selectedWasher } = this.props
    let { phoneNumber, number, country_code } = this.state
    let _this = this
    let key = selectedWasher.key
    const changeNumber = functions().httpsCallable('updateRecords');
    changeNumber({ uid: key, prop: { phoneNumber: phoneNumber } }).then(function (result) {

      if (result && result.data && !result.data.error) {
        firestore().collection('washers').doc(key).update({
          number,
          country_code,
          phoneNumber,
          updated: Date.now()
        }).then(() => {
          _this.setState({ changeNumber: false })
        })
      } else {
        if (result.data.error.errorInfo && result.data.error.errorInfo.code.includes('invalid-phone-number')) {
          _this.createNotification("error", "Invalid phone number")
          _this.setState({ isLoading: false })
        } else if (result.data.error.errorInfo && result.data.error.errorInfo.code.includes('auth/phone-number-already-exists')) {
          _this.createNotification("error", "This number already exists")
          _this.setState({ isLoading: false })

        }
      }
    });
  }
}
export default (Washers);
