import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Input, Button } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import moment from "moment";
import { firestore } from '../../../helpers/Firebase'
import Select from 'react-select'
import { connect } from "react-redux";
import '../CRM/crmstyles.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import DataTablePagination from "../../../components/DatatablePagination";
import { accessTypes } from "../../../helpers/Utils";
import Subscription from "./components/subscription";
import { statusObject } from "../../../constants/defaultValues";

const canceledByOptions = [
  { label: 'All', value: ['Super Admin', 'Service Provider', 'Worker', 'Customer'] },
  { label: 'Super Admin', value: ['Super Admin'] },
  { label: 'Service Provider', value: ['Service Provider'] },
  { label: 'Worker', value: ['Worker'] },
  { label: 'Customer', value: ['Customer'] },
]
const canceledByReasons = [
  { label: 'All', value: ['Worker Busy', 'Traffic', 'Other'] },
  { label: 'Worker Busy', value: ['Worker Busy'] },
  { label: 'Traffic', value: ['Traffic'] },
  { label: 'Other', value: ['Other'] },
]
const canceledByCustomerReasons = [
  { label: 'All', value: ['Arriving late', 'Reschedule', 'Cancel from service provider', 'Other'] },
  { label: 'Arriving late', value: ['Arriving late'] },
  { label: 'Reschedule', value: ['Reschedule'] },
  { label: 'Cancel from service provider', value: ['Cancel from service provider'] },
  { label: 'Other', value: ['Other'] },
]


const startDate = new Date();
startDate.setDate(startDate.getDate() - 7);

class Subscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      serviceProvidersSelected: null,
      statusSelected: null,
      canceledBySelected: null,
      cancelReasonsSelected: null,
      servicesSelected: null,
      workersSelected: null,
      dateRange: [startDate, new Date()],
      subscriptions: [],
      filteredOrders: null,
      sales: 0,
      avgSales: 0,
      activeServices: {},
      selectedItem: false,
      numberSearch: '',
      mobileNumberSearch: '',
      loading: true,
      allServiceProviders: {},
      modifiers: {},

    };
  }
  dataTableColumns() {
    const { authUser } = this.props;
    return ([
      {
        Header: "Subscription Number",
        accessor: "orderNumber",
        Cell: props => <p className="list-item-heading custoumClass">{props.value}</p>
      },
      {
        Header: "Customer",
        accessor: "customer_name",
        Cell: props => <p className="list-item-heading custoumClass">{props.value}<br />{accessTypes.includes(authUser?.user?.accessType) ? props.original.customer_number : ''}</p>
      },
      {
        Header: "Service",
        accessor: "service_name",
        Cell: props => <p className="list-item-heading custoumClass">{props.value}<br />{`(${props?.original?.selectedPackage?.name})`}</p>
      },
      {
        Header: "Provider",
        accessor: "sp_name",
        show: (accessTypes.includes(authUser?.user?.accessType)) ? true : false,
        Cell: props => <p className="list-item-heading custoumClass">{props.value}</p>
      },
      {
        Header: "Worker",
        accessor: "washer_name",
        Cell: props => <p className="list-item-heading custoumClass">{props.value}</p>
      },
      // {
      //   Header: "Completed Orders",
      //   accessor: "bookings",
      //   Cell: props => <p className="list-item-heading custoumClass">{(props.value || [])?.filter(b => !['new', 'pending', 'accepted', 'active', 'canceled', 'rejected'].includes(b?.status))?.length}</p>
      // },
      // {
      //   Header: "Remaining Orders",
      //   accessor: "bookings",
      //   Cell: props => <p className="list-item-heading custoumClass">{(props.value || [])?.filter(b => ['new', 'pending', 'accepted', 'active'].includes(b?.status))?.length}</p>
      // },
      // {
      //   Header: "Canceled Orders",
      //   accessor: "bookings",
      //   Cell: props => <p className="list-item-heading custoumClass">{(props.value || [])?.filter(b => ['canceled', 'rejected'].includes(b?.status))?.length}</p>
      // },
      {
        Header: "Subscription Status",
        accessor: "status",
        Cell: props => <p className="list-item-heading custoumClass" style={{ color: statusObject?.[props.value]?.color }}>{statusObject?.[props.value]?.title}</p>
      },
      {
        Header: "Total",
        accessor: "totalPrice",
        Cell: props => <p className="list-item-heading custoumClass">{"AED " + Number(props.value).toFixed(2)}</p>
      },

      {
        Header: "Created",
        accessor: 'created',
        Cell: props => <div className="list-item-heading custoumClass" >{moment(props.value).format("DD/MM/YYYY")}<div style={{ fontSize: '10px' }}>{moment(props.value).format("hh:mmA")}</div></div>
      },
      {
        sortable: false,
        accessor: "view",
        width: 80,
        Cell: props => <div >
          <Button
            style={{ padding: '0.2rem 1rem' }}
            color="primary"
            size="sm"
            outline
            // className="top-right-button"
            onClick={() =>
              this.setState({
                selectedItem: props.original,
                selected: props.index,
              })}
          >View
          </Button>
        </div>
      },
    ])
  }
  update = () => {
    this.setState({
      loading: true
    }, () => {
      this.searchFunction()
    });
  };

  dismiss = () => {
    this.setState({
      selectedItem: false,
      editBooking: false
    });
  };

  componentDidMount() {
    this.getServices()
    this.searchFunction()
  }
  componentDidUpdate(previousProps) {
    let { services, advServices } = this.props
    if (previousProps.services !== services || previousProps.advServices !== advServices) {
      this.getServices()
    }
  }

  getServices() {
    let { activeServices, modifiers } = this.state
    let { washers, services, advServices } = this.props
    let _this = this
    firestore().collection('modifiers').get().then(function (modifiersSnapshot) {
      modifiersSnapshot.forEach(function (doc) {
        let modifier = doc.data()
        modifier.key = doc.id
        modifiers[doc.id] = modifier;
      });
      _this.setState({ modifiers, isLoading: false })

    });
    if (services && advServices) {
      let allServices = Object.values(services).concat(Object.values(advServices))
      if (washers && services) {
        Object.values(washers).map(washer => {
          return (
            (washer.activeServices) && (Object.keys(washer.activeServices).map(item => {
              return (
                // eslint-disable-next-line
                allServices.map(service => {
                  if (service.key === item) {
                    activeServices[service.key] = { ...service, modifier: washer.activeServices[item], label: service.name, value: service.name }
                    _this.setState({ activeServices })
                  }
                })
              )
            }))
          )
        })
      }
    }
  }
  filterFunc() {
    let { serviceProvidersSelected, servicesSelected, workersSelected, statusSelected, canceledBySelected, cancelReasonsSelected, filteredOrders, numberSearch, mobileNumberSearch } = this.state


    if (serviceProvidersSelected && serviceProvidersSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        let providersKeys = serviceProvidersSelected.map(item => {
          return item.key
        })
        return providersKeys.includes(item.sp_id)
      })
    }
    if (servicesSelected && servicesSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return servicesSelected.includes(item.service_id)
      })
    }
    if (workersSelected && workersSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return workersSelected.includes(item.washer_id)
      })
    }
    if (statusSelected && statusSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return statusSelected?.includes(item.status)
      })
    }
    if (canceledBySelected && canceledBySelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return canceledBySelected?.includes(item.canceledBy) && (item.status === 'canceled' || item.status === 'rejected')
      })
    }
    if (cancelReasonsSelected && cancelReasonsSelected.length) {
      let otherReasons = filteredOrders.map(item => {
        return !['Worker Busy', 'Traffic', 'Arriving late', 'Reschedule', 'Cancel from service provider'].includes(item.cancelReason) && (item.status === 'canceled' || item.status === 'rejected') ? item.cancelReason : false
      })

      if (cancelReasonsSelected.includes('Other')) {
        cancelReasonsSelected = [...cancelReasonsSelected, ...otherReasons]
      }
      filteredOrders = filteredOrders.filter((item) => {
        return cancelReasonsSelected?.includes(item?.cancelReason) && (item.status === 'canceled' || item.status === 'rejected')
      })
    }


    if (numberSearch) {
      filteredOrders = filteredOrders.filter((item) => {
        return (String(item.orderNumber)).includes(numberSearch)
      })
    }
    if (mobileNumberSearch) {
      filteredOrders = filteredOrders.filter((item) => {
        return (String(item.customer_number)).includes(mobileNumberSearch)
      })
    }


    let sales = 0
    let avgSales = 0
    filteredOrders.map(item => {
      return (
        (item.status !== 'canceled' && item.status !== 'rejected') && (
          sales += Number(item.totalPrice),
          avgSales = (sales / filteredOrders.length).toFixed(2)
        )
      )

    })

    this.setState({ filteredOrders, sales, avgSales })

  }
  handleNumberChange(event) {
    let { subscriptions } = this.state

    switch (event.target.name) {
      case 'number':
        this.setState({ numberSearch: event.target.value, filteredOrders: subscriptions, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'mobileNumber':
        this.setState({ mobileNumberSearch: event.target.value, filteredOrders: subscriptions, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      default:
        break;
    }


  }
  searchFunction = async () => {

    let { authUser } = this.props;
    let { dateRange } = this.state;

    let ref = firestore().collectionGroup('user_subscriptions')
    if (!accessTypes.includes(authUser?.user?.accessType)) {
      ref = ref.where("sp_id", "==", authUser?.user?.uid)
    }
    let userBookingsSnap = await ref.where('paid', '==', true).where('created', '>=', moment(dateRange[0]).startOf('day').toDate().getTime()).where('created', '<=', moment(dateRange[1]).endOf('day').toDate().getTime()).get()
    let asyncOptionsOnline = userBookingsSnap.docs.map(async res => {
      let bookingsSnap = await firestore().collection('bookings').where('subscription', '==', res?.id).get()
      let bookings = bookingsSnap?.docs?.map(r => { return { ...r.data(), key: r.id } }) || []

      return {
        ...res.data(),
        key: res.id,
        bookingId: res.id,
        bookings
      }

    })

    Promise.all(asyncOptionsOnline).then((ress) => {
      let sales = 0
      let avgSales = 0
      ress.map(item => {
        return (
          (item.status !== 'canceled' && item.status !== 'rejected') && (
            sales += Number(item?.totalPrice || 0),
            avgSales = (sales / ress.length).toFixed(2)
          )
        )

      })

      this.setState({ subscriptions: ress, filteredOrders: ress, avgSales, sales, loading: false }, () => {
        this.filterFunc()
      })
    })


  }

  handleChange(value, type) {
    let { subscriptions } = this.state

    switch (type.name) {

      case 'services':
        let servicesArray = []
        if (value) {
          value.map(item => {
            return (
              servicesArray.push(item.key)
            )
          })
        }
        this.setState({ servicesSelected: servicesArray, filteredOrders: subscriptions, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'workers':
        let workersArray = []
        if (value) {
          value.map(item => {
            return (
              workersArray.push(item.key)
            )
          })
        }
        this.setState({ workersSelected: workersArray, filteredOrders: subscriptions, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'status':
        let statusArray = []
        if (value) {
          value.map(item => {
            return (
              statusArray = statusArray.concat(item.value)
            )
          })
        }
        this.setState({ statusSelected: statusArray, filteredOrders: subscriptions, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'canceledBy':
        let canceledByArray = []
        if (value) {
          value.map(item => {
            return (
              canceledByArray = canceledByArray.concat(item.value)
            )
          })
        }
        this.setState({ canceledBySelected: canceledByArray, cancelReasonsSelected: null, filteredOrders: subscriptions, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'cancelReason':
        let cancelReasonArray = []
        if (value) {
          value.map(item => {
            return (
              cancelReasonArray = cancelReasonArray.concat(item.value)
            )
          })
        }
        this.setState({ cancelReasonsSelected: cancelReasonArray, filteredOrders: subscriptions, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;

      case 'providers':
        let providersArray = []

        if (value) {
          value.map(item => {
            return (
              item.key === 'allProviders' ? providersArray = item.value :
                providersArray.push(item)
            )
          })

        }
        this.setState({ serviceProvidersSelected: providersArray, filteredOrders: subscriptions, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;

      default:
        break;
    }
  }
  sortBookingsByObjectAndDate(filteredOrders) {
    // Flatten the bookings into a single array with objectId included
    const flattenedBookings = filteredOrders?.flatMap(obj => obj?.bookings?.map(booking => ({
      ...booking,
      subscriptionNumber: obj.orderNumber,
      completedOrders: obj?.bookings?.filter(b => !['new', 'pending', 'accepted', 'active'].includes(b?.status))?.length,
      remainingOrders: obj?.bookings?.filter(b => ['new', 'pending', 'accepted', 'active'].includes(b?.status))?.length
    })))

    // const flattenedBookings = arr.flatMap(obj => obj.bookings.map(booking => ({ ...booking, objectId: obj.id })));

    // Sort the bookings by object id and then by booking date
    flattenedBookings.sort((bookingA, bookingB) => {
      if (bookingA.subscriptionNumber !== bookingB.subscriptionNumber) {
        return bookingA.subscriptionNumber - bookingB.subscriptionNumber;
      } else {
        return new Date(bookingA.bookingDate) - new Date(bookingB.bookingDate);
      }
    });

    return flattenedBookings;
  }
  exportCSV() {
    const { authUser, providers } = this.props;
    let { filteredOrders } = this.state

    let subscriptionBookings = this.sortBookingsByObjectAndDate(filteredOrders)
    const csvRows = [];
    let headers = []
    headers = [
      'Order Ref.',
      'subscription Ref.',
      'Date of Service',
      'Name of Customer',
      'Customer Number',
      'Service Provider',
      'IBAN',
      'Account Number',
      'Account Name',
      'Branch',
      'Types of service',
      'Selected Package',
      'Number of washes',
      'Completed washes',
      'Remaining washes',
      'Type of Car/Vehicle',
      'Status',
      'Original Standard Fees',
      'Standard Fees Per Wash',
      'Original Discounts',
      'Discounts Per Wash',
      'Discount Code',
      'Original Total Fees',
      'Total Fees Per Wash',
      'Payment Type',
      'Charges',
      'Net fees'];
    if (!accessTypes.includes(authUser?.user?.accessType)) {
      headers.splice(3, 1);
    }
    // Add header row to CSV
    csvRows.push(headers.join(','));

    // Loop through each order and add it to the CSV
    for (const order of subscriptionBookings) {
      let carType = ""
      if (order.carDetail.length) {
        (order?.carDetail).map(car => {
          return (
            carType += car?.modelNumber?.category === "SUV" ? "SUV /" : 'SEDAN /'
          )
        })
      } else {
        carType = order?.modelNumber?.category === "SUV" ? "SUV " : 'SEDAN '
      }
      let paymentType = order.paymentType
      let charges = order.paymentType === "card" ? (order.price?.totalPrice * 0.0295).toFixed(2) : "--"
      let fees = order.paymentType === "card" ? (order.price?.totalPrice - charges) : "--"
      let provider = providers?.[order?.sp_id] || {}
      let status = (["notRated"].includes(order.status) ? "Completed" : order.status === "new" ? "Pending" : order.status).toUpperCase()

      const row = [
        order.orderNumber,
        order.subscriptionNumber,
        moment(order.bookingDate).format("DD/MM/YYYY hh:mmA"),
        order.customer_name,
        order.customer_number,
        order.sp_name,
        provider?.bankDetails?.IBAN,
        provider?.bankDetails?.accountNumber,
        provider?.bankDetails?.accountName,
        provider?.bankDetails?.branch,
        order.service_name,
        order?.selectedPackage?.name,
        order?.selectedPackage?.washesCount,
        order?.completedOrders,
        order?.remainingOrders,
        carType.slice(0, -1),
        status,
        Number(order.price?.servicePrice).toFixed(2),
        (Number(order.price?.servicePrice) / Number(order?.selectedPackage?.washesCount)).toFixed(2),
        order?.price?.discount || "--",
        (Number(order.price?.discount) / Number(order?.selectedPackage?.washesCount)).toFixed(2),
        order?.price?.code || "--",
        Number(order.price?.totalPrice).toFixed(2),
        (Number(order.price?.totalPrice) / Number(order?.selectedPackage?.washesCount)).toFixed(2),
        paymentType,
        charges,
        fees
      ];
      if (!accessTypes.includes(authUser?.user?.accessType)) {
        row.splice(3, 1);
      }
      csvRows.push(row.join(','));
    }

    // Combine all rows into a single CSV string
    const csvString = csvRows.join('\n');

    // Create a download link for the CSV file
    const link = document.createElement('a');
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString);
    link.download = 'subscriptions.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    const { washers, authUser, providers } = this.props;
    let { subscriptions, filteredOrders, sales, avgSales, selectedItem, dateRange, loading, activeServices, canceledBySelected, serviceProvidersSelected, modifiers } = this.state

    if (!subscriptions && !subscriptions.length) {
      return (
        <div className="d-flex flex-row mb-3 pb-3 text-center justify-content-center ">
          <div className='font-weight-bold'>No subscriptions yet!</div>
        </div>
      )
    }


    let allServiceProviders = {}
    if (providers && Object.keys(providers).length) {
      allServiceProviders.allProviders = { key: 'allProviders', value: Object.values(providers), label: "All Service Providers" }
      Object.keys(providers).map(rec => {
        return allServiceProviders[rec] = providers[rec]
      })
    }

    return (
      <Fragment>
        {(loading) &&
          <div className='loading'></div>
        }
        <Row>
          <Colxx xxs="12" >
            <div className='d-flex justify-content-between'>
              <div className="mb-2"><h1>Subscriptions</h1></div>
              <Button className='btn-sm' style={{ height: '35px' }} onClick={() => this.exportCSV()}>Export</Button>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row style={{ zIndex: '100000' }}>
          <Colxx xxs="12">
            <Card className="mb-4 rounded">
              <CardBody>
                <Row>
                  {[
                    { key: 'date', placeholder: 'Status', xxs: '12', type: 'datepicker', md: accessTypes.includes(authUser?.user?.accessType) ? "3" : '4' },
                    { key: 'providers', data: allServiceProviders && Object.values(allServiceProviders), value: serviceProvidersSelected && Object.values(serviceProvidersSelected), placeholder: 'Service Providers', xxs: '12', type: 'select', hide: !accessTypes.includes(authUser?.user?.accessType) },
                    { key: 'number', placeholder: 'Subscription Number', xxs: '6', type: 'number', md: accessTypes.includes(authUser?.user?.accessType) ? "3" : '4' },
                    { key: 'mobileNumber', placeholder: 'Mobile Number', xxs: '6', type: 'mobileNumber', md: accessTypes.includes(authUser?.user?.accessType) ? "3" : '4' },
                    // { key: 'status', data: statusOptions, placeholder: 'Status', xxs: '6', type: 'select' },
                    { key: 'services', data: activeServices && Object.values(activeServices), placeholder: 'Services', xxs: '6', type: 'select' },
                    { key: 'workers', data: washers && Object.values(washers), placeholder: 'Workers', xxs: '6', type: 'select' },
                    { key: 'canceledBy', data: canceledByOptions, placeholder: 'Canceled By', xxs: '12', hide: !accessTypes?.includes(authUser?.user?.accessType), type: 'select' },
                    { key: 'cancelReason', data: canceledBySelected?.includes('Customer') ? canceledByCustomerReasons : canceledByReasons, placeholder: 'Cancel Reason', xxs: '12', hide: !accessTypes?.includes(authUser?.user?.accessType) || canceledBySelected?.length !== 1 || canceledBySelected?.includes('Super Admin'), type: 'select' },

                  ].map((item, index) => {
                    return !item.hide && (
                      <Colxx key={index} className='mt-2' xxs={item.xxs} md={item.md || '3'}>
                        {item.type === 'select' ? <Select
                          placeholder={item.placeholder}
                          isMulti
                          name={item.key}
                          options={item.data}
                          value={item.value}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={this.handleChange.bind(this)}
                        /> : item.type === 'datepicker' ?
                          <DatePicker
                            onChange={(dates) => {
                              this.setState({ dateRange: dates }, () => {
                                if (dates[1]) {
                                  this.setState({ loading: true, selectEnds: true }, () => {
                                    this.searchFunction();
                                  })
                                }
                              })
                            }}
                            startDate={dateRange[0]}
                            endDate={dateRange[1]}
                            selectsRange={true}
                            dateFormat={'dd/MM/yyyy'}
                          /> :
                          <Input type={item.type}
                            style={{ borderRadius: '4px' }}
                            placeholder={item.placeholder}
                            name={item.key}
                            onChange={this.handleNumberChange.bind(this)} />
                        }
                      </Colxx>
                    )
                  })}
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card className="mb-4 rounded">
              <Row className="mt-4 pl-4 pr-4">
                <Colxx xxs="4" className='d-flex flex-column justify-content-center align-items-center'>
                  <div className="salesTitle">Revenue</div>
                  <div className="salesValue">{'AED ' + sales.toFixed(2)}</div>
                </Colxx>
                <Colxx xxs="4" className='d-flex flex-column justify-content-center align-items-center'>
                  <div className="salesTitle">Average subscription value</div>
                  <div className="salesValue">{'AED ' + Number(avgSales).toFixed(2)}</div>
                </Colxx>
                <Colxx xxs="4" className='d-flex flex-column justify-content-center align-items-center'>
                  <div className="salesTitle">Total subscriptions</div>
                  <div className="salesValue">{filteredOrders ? filteredOrders.length : subscriptions.length}</div>
                </Colxx>
              </Row>
              <Separator className="mb-4 mt-4" />
              <CardBody style={{ paddingTop: '0' }}>
                <ReactTable
                  style={{ zIndex: '0' }}
                  // className='custuomReactTable'
                  data={filteredOrders ? filteredOrders : subscriptions}
                  columns={this.dataTableColumns()}
                  pageSize={filteredOrders?.length < 50 ? filteredOrders.length : (this.state.pageSize || 50)}
                  showPageJump={false}
                  pageSizeOptions={[20, 50, 60, 80, 100, 200]}
                  onPageSizeChange={(size) => {
                    this.setState({ pageSize: size })
                  }}
                  defaultPageSize={50}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                  showPagination={true}
                />
              </CardBody>

              {selectedItem && <Subscription
                washers={washers}
                dismiss={this.dismiss}
                subscription={selectedItem}
                modifiers={modifiers}
                update={this.update} />}



            </Card>
          </Colxx>
        </Row >
      </Fragment >
    );
  }
}

const mapStateToProps = ({ providers, authUser }) => {
  return {
    authUser,
    providers: providers ? providers.provider : {},
    washers: providers ? providers.washers : {},
    services: providers ? providers.services : {},
    advServices: providers ? providers.advServices : {},
  };
};

export default connect(mapStateToProps)(Subscriptions);