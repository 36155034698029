import {
	CHAT_GET_CONTACTS,
	CHAT_CHANGE_CONVERSATION,
	CHAT_GET_CONVERSATIONS,
	CHAT_DELETE_MESSAGE,
	USER_FROM_REQUESTS,
	SET_MESSAGES_COUNT,
	SET_CHATS_COUNT,
	SET_CHATS_Filter


} from '../actions';

const INIT_STATE = {
	allContacts: [],
	selectedUser: null,
	messages: {}
};

function removeDuplicatesAndKeepLatest(array, key) {
	const uniqueObject = {};
	array.forEach((item) => {
		const itemKey = item[key];
		uniqueObject[itemKey] = item;
	});
	const uniqueArray = Object.values(uniqueObject);
	return uniqueArray;
}

const ApplyFilter = ({ state, data }) => {
	let { unread, } = data
	let newState = {
		contactsList: state?.allContacts
	}
	if (unread) {
		newState.contactsList = newState.contactsList?.filter(r => (r.newCount && r.newCount !== 0))
	}

	return newState
}

const ex = (state = INIT_STATE, action) => {
	switch (action.type) {

		case CHAT_GET_CONTACTS:
			let { contactsList: list } = ApplyFilter({ state: { ...state, allContacts: removeDuplicatesAndKeepLatest([...state.allContacts, ...action.payload], 'wa_id') }, data: state?.chatsFilter || {}, })

			return { ...state, allContacts: list };
		case CHAT_GET_CONVERSATIONS:
			return {
				...state, messages: {
					...state.messages,
					[action?.payload?.parent_id]: {
						...state.messages[action?.payload?.parent_id],
						[action?.payload?.id]: action?.payload,
					}
				}
			};
		case CHAT_DELETE_MESSAGE:
			let messages = state.messages[action?.payload?.parent_id]
			delete messages[action?.payload?.id]
			return {
				...state, messages: {
					...state.messages,
					[action?.payload?.parent_id]: messages
				}
			};
		case CHAT_CHANGE_CONVERSATION:
			return { ...state, selectedUser: action.payload };
		case SET_MESSAGES_COUNT:
			return { ...state, newMessagesCount: action.payload };
		case SET_CHATS_COUNT:
			return { ...state, newChatsCount: action.payload };
		case SET_CHATS_Filter:
			return { ...state, chatsFilter: action.payload, allContacts: [] };
		case USER_FROM_REQUESTS:
			return { ...state, userFromRequests: action.payload };

		default: return { ...state };
	}
}
export default ex;