import Switch from "rc-switch";
import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import ReactTable from "react-table";
import { Button, Card, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import DataTablePagination from "../../../components/DatatablePagination";
import { Colxx } from "../../../components/common/CustomBootstrap";
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import { firestore } from '../../../helpers/Firebase';
import Banner from "./components/banner";
import { getBannerName } from "../../../helpers/Utils";

const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}

class Banners extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require('mousetrap');
    this.state = {
      displayMode: "thumblist",
      totalPage: 1,
      search: "",
      isLoading: false,
      selectBanner: false,
      selectedBanner: {},
      sortBanners: false,

      filteredBanners: {},
      addNewBanner: false,
      deleteBanner: false,
      sortedBanners: []

    };
  }


  dataTableColumns() {
    let { banners, authUser } = this.props
    return ([
      {
        accessor: "active",
        width: 100,
        Cell: props => <p className="list-item-heading">
          <Switch
            className="custom-switch custom-switch-primary"
            checked={props.value}
            onChange={switchCheckedPrimary => {
              banners[props.original.key].active = switchCheckedPrimary
              this.setState({ isLoading: true })
              firestore().collection('banners').doc(props.original.key).update({ active: switchCheckedPrimary, updated: Date.now() });
            }}
          />
        </p>
      },
      {
        accessor: "img",
        width: deviceWidth() === 'desktop' ? 150 : 80,
        Cell: props => <div className="providerfieldText"><img style={{ width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={props?.value?.[0]} alt={props.value} /></div>
      },

      {
        Header: "Type",
        accessor: "type",
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Service",
        accessor: "name",
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Url",
        accessor: "url",
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },

      {
        width: 80,
        accessor: "delete",
        show: (authUser?.user?.accessType === 'superAdmin') && deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">
          <Button
            color="danger"
            size="sm"
            outline
            onClick={() => this.setState({ deleteBanner: true, selectedBanner: props.original })}
          >
            Delete
          </Button>
        </p>
      },
      {
        width: 70,
        accessor: "edit",
        Cell: props => <p className="providerfieldText">
          <Button
            color="primary"
            size="sm"
            outline
            className="top-right-button"
            onClick={() => this.onClickItem(props.original)}
          >
            Edit
          </Button>
        </p>
      }
    ])
  }
  deleteBanner(banner) {
    let _this = this
    firestore().collection('banners').doc(banner.key).delete().then(() => {
      _this.setState({ deleteBanner: false, selectedBanner: {}, isLoading: false })
    })
  }

  toggleModal = () => {
    let { selectBanner } = this.state
    this.setState({ selectBanner: !selectBanner, selectedBanner: {}, addNewBanner: true })
  };

  onSearchKey = e => {
    let { banners } = this.props
    let { filteredBanners } = this.state;
    let search = e.target.value.toLowerCase()
    filteredBanners = banners && Object.values(banners).filter(item => {
      return item.service && (item.service).toLowerCase().includes(search.toLowerCase())
    })

    this.setState({ search, filteredBanners }

    );
  };


  onClickItem = (banner) => {
    let { selectBanner } = this.state

    if (banner) {
      this.setState({ selectBanner: !selectBanner, selectedBanner: banner, addNewBanner: false })
    } else {
      this.setState({ selectBanner: !selectBanner, selectedBanner: {}, addNewBanner: false })
    }
  };

  render() {
    let { authUser, banners, services, advServices, providers } = this.props

    let {
      selectBanner,
      selectedBanner,
      addNewBanner,
      deleteBanner,
      sortBanners,
      sortedBanners,
    } = this.state;

    let filteredBanners = this.state.filteredBanners && Object.keys(this.state.filteredBanners).length ? this.state.filteredBanners : banners
    return (selectBanner ? <Banner
      authUser={authUser}
      selectedBanner={selectedBanner}
      onClickItem={this.onClickItem}
      addNewBanner={addNewBanner}
      banners={banners}
      providers={Object.values(providers)}
      services={Object.values(services).concat(Object.values(advServices))}
      updateBanner={(key) => this.setState({ selectBanner: false, selectedBanner: {}, isLoading: false })}

    /> :
      <Fragment>
        {!banners && (
          <div className="loading" />
        )
        }

        <div className="disable-text-selection">
          <ListPageHeading
            heading="Banners"
            onSearchKey={this.onSearchKey}
            toggleModal={this.toggleModal}
            authUser={authUser}
            sortBanners={() => this.setState({ sortBanners: true, sortedBanners: Object.values(banners)?.filter(b => b.active)?.sort((a, b) => a.index - b.index) })}

          />

          <Row className="survey-app">
            <Colxx xxs="12">

              {
                filteredBanners && Object.keys(filteredBanners).length ?
                  <ReactTable
                    data={Object.values(filteredBanners)}
                    columns={this.dataTableColumns()}
                    defaultPageSize={10}
                    pageSize={Object.keys(filteredBanners).length < 10 ? Object.keys(filteredBanners).length : 10}
                    showPageJump={false}
                    defaultSorted={[
                      {
                        id: "active",
                        desc: true
                      },
                    ]}
                    showPageSizeOptions={false}
                    PaginationComponent={DataTablePagination}
                    className='custuomReactTable'
                  />
                  :
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Banners</div>
              }
            </Colxx>
          </Row>

          <Modal backdrop="static" isOpen={deleteBanner}>
            <ModalHeader >
              Delete Banner
            </ModalHeader>
            <ModalBody>
              <Row>
                <Colxx xxs="12" className="mb-4">
                  <Label className="mb-2">Are you sure you want to delete this Banner?</Label>
                </Colxx>
              </Row>

            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.setState({ deleteBanner: false, selectedBanner: {} })}>Dismiss</Button>
              <Button color='danger' onClick={() => this.setState({ isLoading: true }, () => { this.deleteBanner(selectedBanner) })}>Delete Banner</Button>
            </ModalFooter>

          </Modal>
          {sortBanners && <Modal backdrop="static" isOpen={sortBanners}>
            <ModalHeader >
              Sort Banners
            </ModalHeader>
            <ModalBody>
              <Row>
                <Colxx xxs="12" className="mb-4">
                  <ReactSortable
                    list={sortedBanners}
                    setList={(newState) => {
                      this.setState({ sortedBanners: newState })
                    }}
                    tag="ul"
                    className="list-unstyled mt-2"
                    options={{
                      handle: ".handle"
                    }}>
                    {
                      sortedBanners?.map(item => {
                        return (
                          <li key={item.key} id={item.key} data-id={item.key} className='handle'>
                            <Card className={'mb-2'}>
                              <Row className='p-3'>
                                <Colxx xxs="12" className='d-flex align-items-center'>
                                  <span className=" badge badge-pill w-5 d-flex align-items-center">
                                    <i className='simple-icon-cursor-move' />
                                  </span>
                                  <img style={{ width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={item?.img?.[0]} alt={''} />
                                  <div className='ml-2' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{getBannerName(item)}</div>
                                </Colxx>
                              </Row>
                            </Card>
                          </li>
                        )
                      })
                    }
                  </ReactSortable>

                </Colxx>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.setState({ sortBanners: false })}>Dismiss</Button>
              <Button color='danger' onClick={() => this.setState({ isLoading: true }, () => {
                sortedBanners.map(async (banner, index) => {
                  await firestore().collection('banners').doc(banner.key).update({ index: index });
                  this.setState({ sortBanners: false, isLoading: false });
                })

              })}>Update Sorting</Button>
            </ModalFooter>

          </Modal>}
        </div>
      </Fragment>
    )
  }
}




const mapStateToProps = ({ providers, authUser }) => {
  return {
    authUser,
    providers: providers ? providers.provider : {},
    banners: providers ? providers.banners : {},
    services: providers ? providers.services : {},
    advServices: providers ? providers.advServices : {},
  };
};


export default injectIntl(connect(mapStateToProps, {})(Banners));
