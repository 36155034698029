import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import checkedImg from '../../../../assets/css/img/checked.png';
import checkImg from '../../../../assets/css/img/check.png';
function onClick(e) {
  if (e.target.id?.includes('trash')) {
    return e.preventDefault();
  }
  return true
}
const Addons = ({ item, price, index, car, selectedAddons, update }) => {
  const [upsellingCard] = useState((item?.key + (Math.random() * 100)))

  let car_model = car.car_model + ' ' + car?.modelNumber?.model

  useEffect(() => {
    let element = document.getElementById(upsellingCard)

    if (element) {
      element.addEventListener('click', onClick)
    }
    return () => {
      if (element) {
        element.removeEventListener('click', onClick)
      }
    }
    // eslint-disable-next-line
  }, [])
  return (
    <Link
      to="#"
      id={upsellingCard}
      key={index}
      style={{
        boxShadow: `0 2px 6px #E9E9E9`,
        minWidth: '141px',
        minHeight: '180px',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: '0',
        borderRadius: '8px',
        border: item.cars && item.cars[car_model] && item.cars[car_model].car_model === car_model && item.cars[car_model].count && item.cars[car_model].count > 0 ? '1px solid #55C9E8' : 'none',
        overflow: 'hidden',
        marginRight: '12px',

      }}
      onClick={(e) => {
        if (e.target.id === 'trash') {
          return
        }
        if (item.type && item.type === "Service") {
          if (item.cars && item.cars[car_model] && item.cars[car_model].car_model === car_model && item.cars[car_model].count && item.cars[car_model].count > 0) {
            item.newPrice = 0
            item.duration = 0
            delete item.cars[car_model]
          } else {
            item.newPrice = Object.values(item.price).filter(rec => rec.name === car?.modelNumber?.category)[0].price
            item.duration = Object.values(item.price).filter(rec => rec.name === car?.modelNumber?.category)[0].duration
            item.cars = item.cars ? item.cars : {}
            item.cars[car_model] = item.cars[car_model] ? item.cars[car_model] : {}
            item.cars[car_model] = { car_model: car_model, count: 1, price: item.newPrice, duration: item.duration, category: car?.modelNumber?.category }
          }
          selectedAddons[index] = item
          let addonsPrice = selectedAddons?.reduce((total, currentValue) => {
            let cumPrice = 0
            Object.values(currentValue.cars).map(item => {
              return (cumPrice += Number(item?.price) * Number(item?.count))
            })
            return ((total) + cumPrice)
          }, 0)

          price.addonsPrice = addonsPrice
          price.totalPrice = Number(price.servicePrice) + Number(price.addonsPrice) + Number(price?.variationPrice || 0)
          update({ selectedAddons, price })
        } else {
          item.cars = item.cars ? item.cars : {}
          item.cars[car_model] = item.cars[car_model] ? item.cars[car_model] : {}
          item.cars[car_model].count = item?.cars[car_model]?.count ? item?.cars[car_model]?.count : 0
          item.cars[car_model].count += 1

          item.cars[car_model] = { car_model: car_model, count: item.cars[car_model].count, price: item.price, duration: "00:00", category: car?.modelNumber?.category }

          selectedAddons[index] = item
          let addonsPrice = selectedAddons?.reduce((total, currentValue) => {
            let cumPrice = 0
            Object.values(currentValue.cars).map(item => {
              return (cumPrice += Number(item?.price) * Number(item?.count))
            })
            return ((total) + cumPrice)
          }, 0)

          price.addonsPrice = addonsPrice
          price.totalPrice = Number(price.servicePrice) + Number(price.addonsPrice) + Number(price?.variationPrice || 0)
          update({ selectedAddons, price })
        }

      }}
    >
      <img alt="" src={{ uri: item.img?.length ? item.img[0] : '' }}
        style={{
          width: '100%',
          height: '100px',
          backgroundColor: '#cacaca',
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px'
        }}
      />
      {item.cars && item.cars[car_model] && item.cars[car_model].car_model === car_model && item.cars[car_model].count && item.cars[car_model].count > 0 && item?.type !== "Service" ?
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0 6px',
            paddingRight: '8px'
          }}>
          <div
            style={{
              color: '#55C9E8',
              fontSize: '14px',
              fontWeight: '700',
              paddingTop: '12px',
              paddingBottom: '6px',
              paddingRight: '6px',
              textAlign: 'left'
            }}
          >{item.cars[car_model].count}x</div>
          <div
            style={{
              fontSize: '14px',
              paddingTop: '12px',
              paddingBottom: '6px',
              color: '#4D4D4D',
              textAlign: 'left',
              width: '75%'
            }}>
            {item.name}
          </div>
        </div> :
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0 6px',
            paddingRight: '8px'
          }}>
          <div
            style={{
              fontSize: '14px',
              paddingTop: '12px',
              paddingBottom: '6px',
              color: '#4D4D4D',
              textAlign: 'left',
              width: '100%'
            }}>
            {item.name}
          </div>
        </div>
      }

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '0 8px',
          paddingBottom: '8px',
          alignItems: 'center'
        }}>
        {(item.type && item.type === "Service") ?
          <div
            style={{
              color: '#55C9E8',
              fontSize: '16px',
              fontWeight: '600'
            }}
          >{item.price ? (Object.values(item.price).filter(rec => rec.name === car?.modelNumber?.category)[0].price + ' AED') : "---"}</div>
          :
          <div
            style={{
              color: '#55C9E8',
              fontSize: '16px',
              fontWeight: '600'
            }}
          >{item.price ? (item.price + ' AED') : '---'}</div>
        }

        {(item.type && item.type === "Service") ?
          ((item.cars && item.cars[car_model] && item.cars[car_model].car_model === car_model && item.cars[car_model].count && item.cars[car_model].count > 0) ?
            <img style={{ width: '15px', height: '15px' }} alt="" src={checkedImg} /> :
            <img style={{ width: '15px', height: '15px' }} alt="" src={checkImg} />)
          :
          (
            item.cars && item.cars[car_model] && item.cars[car_model].car_model === car_model && item.cars[car_model].count && item.cars[car_model].count > 0 &&
            <div onClick={() => {

              delete item.cars[car_model]
              selectedAddons[index] = item

              let addonsPrice = selectedAddons?.reduce((total, currentValue) => {
                let cumPrice = 0
                Object.values(currentValue.cars).map(item => {
                  return (cumPrice += Number(item?.price) * Number(item?.count))
                })
                return ((total) + cumPrice)
              }, 0)

              price.addonsPrice = addonsPrice
              price.totalPrice = Number(price.servicePrice) + Number(price.addonsPrice) + Number(price?.variationPrice || 0)
              update({ selectedAddons, price })
            }} id='trash' style={{ color: 'red', cursor: 'pointer', fontSize: '14px' }} className="simple-icon-trash" />

          )
        }
      </div>
    </Link>
  );
};

export default Addons;