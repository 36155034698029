import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Models from "./Models.js";
const Marketing = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/vehicles`} />
      <Route path={`${match.url}/cars`} render={() => <Models type={'multiple'} title="Car Models" selected='cars' />} />
      <Route path={`${match.url}/bikes`} render={() => <Models type={'standalone'} title="Bikes" selected='scooters' />} />
      <Route path={`${match.url}/caravans`} render={() => <Models type={'standalone'} title="Caravans" selected='caravans' />} />
      <Route path={`${match.url}/boats`} render={() => <Models type={'standalone'} title="Boats" selected='boats' />} />
      <Redirect to="/error" />
    </Switch>
  </div>
);

export default Marketing;
