import { Component } from "react";
class HandlePayment extends Component {

    componentDidMount() {
        var url = new URL(window.location.href);
        var tap_id = url.searchParams.get("tap_id");
        if (tap_id) {
            console.log("tap_id", tap_id)
        }
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(window.location.href)
        }
    }

}
export default HandlePayment;
