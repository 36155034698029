import React, { Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AppLayout from "../../layout/AppLayout";
import gogo from "./dashboard";
import Users from "./users";

import Providers from './providers';
import Services from './services';
import UpSelling from './up-selling';
import Admins from './admins';
import Washers from './washers';
import Requests from './appointments';
import Appointments from './appointments';
import Subscriptions from './subscriptions';
import VehiclesCategories from './vehicles';
import CRM from './CRM'
import Marketing from './marketing'
import { accessTypes, accessTypesObject, requestPermission } from "../../helpers/Utils";
import Chat from "./chat";
import audioFile from '../../helpers/sound.mp3';
import { firestore } from "../../helpers/Firebase";
import { setMessagesCount, setChatsCount, setUserFromRequests } from "../../redux/chat/actions";
const beep = new Audio(audioFile)

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
    let authUser = this.props.authUser;
    console.log("authUser", authUser);
    let _this = this;
    let unsubscribeMessagesRef;
    let unsubscribeCountRef;

    if (authUser) {
      let isFirstRun = true;
      let messagesRef = firestore().collectionGroup('messages')
      if (!accessTypes.includes(authUser?.accessType)) {
        messagesRef = messagesRef.where('provider_id', '==', authUser?.uid)
      }

      unsubscribeMessagesRef = messagesRef.orderBy("created", "desc")
        .limit(1)
        .onSnapshot(snap => {
          let data = snap.docs?.[0]?.data();

          if (!snap.empty && !isFirstRun && !Object.keys(accessTypesObject).includes(data?.role)) {
            let { from, created, sender, text } = data || {}
            if (created) {
              if (from && from !== 'Chill' && sender !== authUser?.uid) {
                beep.play().catch(err => {
                  console.log("errerr", err);
                })
                // Check if the browser supports notifications
                if (!("Notification" in window)) {
                  console.log("This browser does not support desktop notification");
                } else if (Notification.permission === "granted") {
                  console.log("Notification permission granted");

                  let notification = new Notification(`New message received from ${from}`, { body: text?.body || '' });
                  notification.onclick = function (event) {
                    event.preventDefault(); // prevent the browser from focusing the Notification's tab
                    window.focus(); // focus the browser tab where the application is running
                    _this.props.setUserFromRequests(`${(from).replace(/\D/g, '')}`)
                    _this.props.history.push('/app/chat'); // Change the URL without reloading the page
                    notification.close(); // close the notification
                  }
                } else if (Notification.permission === "denied") {
                  requestPermission()
                }
              }

            }
          }
          isFirstRun = false;
        });
      let newMessagesCountRef = firestore().collection('chats').where('newCount', '>', 0)
      if (!accessTypes.includes(authUser?.accessType)) {
        newMessagesCountRef = newMessagesCountRef.where('provider_id', '==', authUser?.uid)
      }

      unsubscribeCountRef = newMessagesCountRef.onSnapshot(snap => {
        if (!snap.empty) {

          let totalNewCount = 0;
          let totalChatsCount = 0;
          snap.docs.forEach(doc => {
            // totalNewCount += Number(doc?.data()?.newCount || 0);
            // totalChatsCount += Number(doc?.data()?.newCount || 0);
            const newCount = Number(doc?.data()?.newCount || 0);
            totalNewCount += newCount;
            if (newCount > 0) {
              totalChatsCount += 1;
            }
          });
          _this.props.setMessagesCount(totalNewCount);
          _this.props.setChatsCount(totalChatsCount);

        } else {
          _this.props.setMessagesCount(0);
          _this.props.setChatsCount(0);
        }
      });
    } else {
      if (unsubscribeMessagesRef) {
        unsubscribeMessagesRef()
      }
      if (unsubscribeCountRef) {
        unsubscribeCountRef()
      }
    }
  }

  render() {
    const { match, authUser } = this.props;
    return (
      <AppLayout>
        <Switch>

          <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
          <Route path={`${match.url}/dashboard`} component={gogo} />
          <Route path={`${match.url}/providers`} component={Providers} />
          <Route path={`${match.url}/crm`} component={CRM} />
          {accessTypes.includes(authUser?.accessType) && <Route path={`${match.url}/marketing`} component={Marketing} />}
          {accessTypes.includes(authUser?.accessType) && <Route path={`${match.url}/services`} component={Services} />}
          <Route path={`${match.url}/up-selling`} component={UpSelling} />

          {authUser?.accessType === 'superAdmin' && <Route path={`${match.url}/admins`} component={Admins} />}

          <Route path={`${match.url}/workers`} component={Washers} />
          <Route path={`${match.url}/orders`} component={Requests} />
          <Route path={`${match.url}/orders`} component={Appointments} />
          <Route path={`${match.url}/subscriptions`} component={Subscriptions} />
          <Route path={`${match.url}/users`} component={Users} />
          <Route path={`${match.url}/vehicles`} component={VehiclesCategories} />
          <Route path={`${match.url}/chat`} component={Chat} />

          <Redirect to="/error" />
        </Switch>


      </AppLayout>
    );
  }
}

const mapStateToProps = ({ menu, authUser }) => {
  const { containerClassnames } = menu;
  return {
    containerClassnames,
    authUser: authUser?.user

  };
};
const mapActionsToProps = (dispatch) => ({
  setMessagesCount: (user) => dispatch(setMessagesCount(user)),
  setChatsCount: (user) => dispatch(setChatsCount(user)),
  setUserFromRequests: (user) => dispatch(setUserFromRequests(user))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(App)
);
