import React, { Component, Fragment } from "react";
import {
  Row, Button, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Card
} from "reactstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { firestore } from '../../../helpers/Firebase'
import OutletImg from '../../../assets/css/img/CarWashCenter.png'
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import Select from "react-select";
import { ReactSortable } from "react-sortablejs";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}

class Providers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedProvider: {},
      editProvider: false,
      deleteProvider: false,
      featuredProviders: []
    };
  }

  componentDidUpdate(previousProps) {
    let { providers } = this.props
    if (previousProps.providers !== providers) {
      let featuredProviders = providers && Object.values(providers)?.filter(item => {
        return item?.featured?.index || item?.featured?.index === 0
      })?.sort((a, b) => (a?.featured?.index > b?.featured?.index) ? 1 : -1)
      this.setState({ featuredProviders })
    }
  }
  componentDidMount() {
    let { providers } = this.props
    let featuredProviders = providers && Object.values(providers)?.filter(item => {
      return item?.featured?.index || item?.featured?.index === 0
    })?.sort((a, b) => (a?.featured?.index > b?.featured?.index) ? 1 : -1)
    this.setState({ featuredProviders })
  }


  render() {
    let { providers } = this.props
    let { deleteProvider, featuredProviders, editProvider, selectedProvider } = this.state
    return (
      this.state.isLoading ? (
        <div className="loading" />
      ) : (
        <Fragment>
          <div className="disable-text-selection">

            <ListPageHeading
              heading="Featured Providers"
            />

            <Row className="survey-app">
              <Colxx xxs="12">
                <Select
                  isClearable={true}
                  placeholder="Provider Providers"
                  name="providers"
                  options={providers && Object.values(providers).length && Object.values(providers)?.filter(rec => !rec.featured)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={val => {
                    if (val) {
                      let provider = {
                        ...val, featured: {
                          index: featuredProviders.length,
                          endDate: moment().valueOf()
                        }
                      }
                      featuredProviders.push(provider)
                      this.setState({ featuredProviders: featuredProviders, editProvider: true, selectedProvider: JSON.parse(JSON.stringify(provider)) })
                    }
                  }}
                />
              </Colxx>
              <Colxx xxs="12">

                {
                  featuredProviders && featuredProviders.length ?
                    <>
                      <ReactSortable
                        list={featuredProviders}
                        setList={(newState) => {
                          this.setState({ featuredProviders: newState })
                        }}
                        tag="ul"
                        className="list-unstyled mt-4"
                        options={{
                          handle: ".handle"
                        }}>
                        {featuredProviders?.map((item, index) => {
                          return (
                            <li data-id={item.featured.index} key={index} xxs="12" sm="12" md="12" className="mb-4">
                              <Colxx data-id={item.index} key={index} xxs="12" sm="12" md="12" className="mb-4">
                                <Card className="rounded serviceCard">
                                  <Row className="d-flex align-items-center" style={{ justifyContent: 'space-between', width: '100%' }}>
                                    <Colxx xxs="1">
                                      <div className="badge badge-pill badge-primary handle" style={{
                                        height: '30px',
                                        width: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}>
                                        <i className="simple-icon-cursor-move" style={{ fontSize: '16px', color: "#fff" }} />
                                      </div>
                                    </Colxx>
                                    {deviceWidth() === 'desktop' ? <Colxx xxs="2">
                                      <div className="providerfieldText"><img style={{ width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={item.pictures && item.pictures[0] ? item.pictures[0] : OutletImg} alt={item.pictures} /></div>
                                    </Colxx>
                                      : ''
                                    }

                                    <Colxx xxs="2">
                                      <span>{item?.name}</span>
                                    </Colxx>
                                    {deviceWidth() === 'desktop' ? <Colxx xxs="2">
                                      <span>{item?.name_ar}</span>
                                    </Colxx>
                                      : ''
                                    }

                                    <Colxx xxs="2">
                                      <span>{moment(item?.featured?.endDate).format("DD/MM/YYYY hh:mm A")}</span>
                                    </Colxx>

                                    <Colxx xxs="3" >
                                      <Button
                                        color="primary"
                                        size="sm"
                                        className="float-right mr-2"
                                        outline
                                        onClick={() => this.setState({ editProvider: true, selectedProvider: item })}
                                      >
                                        Edit
                                      </Button>
                                      <Button
                                        color="danger"
                                        size="sm"
                                        className="float-right mr-2"
                                        outline
                                        onClick={() => this.setState({ deleteProvider: true, selectedProvider: item })}
                                      >
                                        Remove
                                      </Button>
                                    </Colxx>
                                  </Row>
                                </Card>
                              </Colxx>
                            </li>
                          )
                        })

                        }
                      </ReactSortable>
                      <Button
                        color="primary"
                        className="btn-shadow float-right mt-2 mb-4"
                        size="lg"
                        onClick={() => {
                          this.setState({ isLoading: true }, () => {
                            featuredProviders.map((item, index) => {
                              return (
                                firestore().collection('service_provider').doc(item.key).update({
                                  featured: item?.featured ? { ...item?.featured, index: index } : false,
                                  updated: Date.now(),
                                }).then(() => {
                                  this.setState({ isLoading: false })
                                })
                              )
                            })
                          })

                        }
                        }>Save Changes</Button></>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '600px' }}>No Featured Providers</div>
                }

              </Colxx>
            </Row>
            <Modal backdrop="static" isOpen={deleteProvider}>
              <ModalHeader >
                Remove Featured Provider
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Colxx xxs="12" className="mb-4">
                    <Label className="mb-2">Are you sure you want to remove this featured provider?</Label>
                  </Colxx>
                </Row>

              </ModalBody>
              <ModalFooter>
                <Button onClick={() => this.setState({ deleteProvider: false, selectedProvider: {} })}>Dismiss</Button>
                <Button color='danger' onClick={() => this.setState({ isLoading: true }, () => {
                  firestore().collection('service_provider').doc(selectedProvider.key).update({
                    featured: false,
                    updated: Date.now(),
                  }).then(() => {
                    this.setState({ isLoading: false, selectedProvider: {}, deleteProvider: false })
                  })
                })}>Remove Provider</Button>
              </ModalFooter>

            </Modal>
            <Modal backdrop="static" isOpen={editProvider}>
              <ModalHeader >
                Edit Featured Provider
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Colxx xxs="12" className="mb-4">
                    <div>End date and time</div>
                    <DatePicker
                      popperClassName="redatepicker"
                      selected={(moment(selectedProvider?.featured?.endDate || new Date()).toDate())}
                      onChange={date => {
                        let featured = { ...selectedProvider?.featured, endDate: moment(date).valueOf() }
                        selectedProvider.featured = featured
                        this.setState({ selectedProvider })
                      }}
                      showTimeSelect
                      timeFormat="hh:mm a"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="dd/MM/yyyy hh:mm a"
                    />
                  </Colxx>
                </Row>

              </ModalBody>
              <ModalFooter>
                <Button color='danger' onClick={() => this.setState({ editProvider: false, selectedProvider: {} })}>Dismiss</Button>
                <Button onClick={() => this.setState({ isLoading: true }, () => {
                  firestore().collection('service_provider').doc(selectedProvider.key).update({
                    featured: selectedProvider?.featured || false,
                    updated: Date.now(),
                  }).then(() => {
                    this.setState({ isLoading: false, selectedProvider: {}, editProvider: false })
                  })
                })}>Edit Provider</Button>
              </ModalFooter>

            </Modal>
          </div>
        </Fragment>
      )

    )
  }
}
const mapStateToProps = ({ providers }) => {
  return {
    providers: providers ? providers.provider : {},
  };
};
export default injectIntl(connect(mapStateToProps)(Providers));


