import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import { firestore } from '../../../../helpers/Firebase';

import moment from "moment";
import { createNotification, createVoucherDynamicLink } from "../../../../helpers/Utils";

const Voucher = ({
    selectedVoucher,
    dismiss
}) => {

    const [updatedVoucher, UpdateVoucher] = useState(JSON.parse(JSON.stringify(selectedVoucher)))
    const [isLoading, setLoading] = useState(false)

    const submit = async (voucher) => {
        setLoading(true)
        let id = voucher?.key || firestore().collection('vouchers').doc().id
        let ref = firestore().collection('vouchers').doc(id)
        voucher.key = id
        if (voucher?.addNew) {
            voucher.active = true
            voucher.created = Date.now()
        }
        delete voucher.addNew
        voucher.updated = Date.now()
        voucher.link = await createVoucherDynamicLink({ voucher })

        await ref.set(voucher, { merge: true })

        setLoading(false)
        dismiss()
        createNotification('success', `Voucher ${updatedVoucher?.addNew ? 'created' : 'updated'} successfully`)
    }

    return (

        <Modal backdrop="static" size='lg' isOpen={selectedVoucher}>
            <ModalHeader >
                {updatedVoucher?.addNew ? 'New Voucher' : 'Edit Voucher'}
            </ModalHeader>
            <ModalBody>

                {isLoading ? (
                    <div className="loading" />
                ) : ''}
                <Row>

                    {[
                        { key: 'title', title: 'Title', type: 'text' },
                        { key: 'amount', title: 'Voucher Amount', type: 'number' },
                        { key: 'minAmount', title: 'Minimum Order Value to Redeem Voucher', type: 'number', optional: true },
                        { key: 'exp', title: 'Expiry Date', type: 'datepicker' },
                        { key: 'numberOfSplits', title: 'Number of Splits for Voucher Amount', type: 'number', optional: true },

                    ].map(r => {
                        return !r.hide && (
                            <Colxx xxs="12" md="12" className='mb-3'>
                                <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'flex-start' }}>
                                    <div>{r.title}</div>
                                    {r.optional && <span
                                        style={{ fontWeight: '100', backgroundColor: '#d4d4d461' }}
                                        className={`ml-1 badge badge-pill badge-light badge-top-left`}
                                    >
                                        Optional
                                    </span>}
                                </div>
                                {r.type === 'datepicker' ?
                                    <DatePicker
                                        onChange={(date) => {
                                            UpdateVoucher({ ...updatedVoucher, [r.key]: moment(date).valueOf() })
                                        }}
                                        selected={updatedVoucher?.[r?.key]}
                                        dateFormat={'dd/MM/yyyy'}
                                    /> :
                                    <Input
                                        type={r.type}
                                        autoCapitalize="characters"
                                        value={updatedVoucher?.[r.key]}
                                        className="customInput"
                                        onChange={(e) => {
                                            UpdateVoucher({ ...updatedVoucher, [r.key]: e.target.value })
                                        }} />
                                }
                            </Colxx>
                        )
                    })
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    className="btn-shadow float-right"
                    size="lg"
                    onClick={() => dismiss()}>
                    Dismiss
                </Button>
                <Button
                    color="primary"
                    className="btn-shadow float-right"
                    disabled={isLoading || !updatedVoucher?.amount || !updatedVoucher?.exp}
                    size="lg"
                    onClick={() => {
                        submit(JSON.parse(JSON.stringify(updatedVoucher)))
                    }}>
                    {updatedVoucher?.addNew ? 'Create Voucher' : 'Update'}
                </Button>
            </ModalFooter>
        </Modal>

    );
};

export default Voucher;