import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Creatable } from "react-select";
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import { firestore, functions } from "../../../../helpers/Firebase";
import { createNotification } from "../../../../helpers/Utils";

const CarModel = ({ selected, dismiss, selectedBrand }) => {
    const [state, setState] = useState(selectedBrand)
    const [model, setModel] = useState(false)
    const [newModifier, setNewModifier] = useState(false)
    const { modifiers } = useSelector(state => state.providers)
    return (
        <Modal
            isOpen={true}
            backdrop="static"
            size="lg">
            <ModalHeader toggle={dismiss}>
                <h6 className="font-weight-bold m-0">{state?.addNew ? 'New Brand' : state?.name}</h6>
            </ModalHeader>
            <ModalBody>
                {[{
                    key: 'generalInfo',
                    title: 'General Information',
                    show: state?.addNew,
                    data: [
                        { key: 'name', title: 'Brand Name', width: '12', type: 'text' },
                    ]
                }].map((rec, recIndex) => {
                    return rec.show && (
                        <Row key={recIndex}>
                            {rec.data.map((item, index) => {
                                return (
                                    <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                        <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                            <div>{item.title}</div>
                                        </div>
                                        <Input
                                            type={item.type}
                                            placeholder={"e.g. " + item.title}
                                            value={state?.[item.key]}
                                            className="customInput"
                                            onChange={(e) => {
                                                setState({ ...state, [item.key]: e.target.value })
                                            }} />
                                    </Colxx>
                                )
                            })}
                        </Row>
                    )
                })}
                <Table borderless>
                    <thead>
                        <tr>
                            <th className="text-semi-muted mb-2" style={{ verticalAlign: 'middle', }}>
                                Model Name
                            </th>
                            <th className="text-semi-muted mb-2" style={{ verticalAlign: 'middle', }}>
                                Category
                            </th>
                            <th className="text-semi-muted mb-2">
                                <Button className={'float-right'} size='sm' color="primary" outline onClick={() => setModel({ addNew: true })}>
                                    Add New
                                </Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {state?.models?.length > 0 ? (
                            state.models.map((rec, recIndex) => (
                                <tr key={recIndex}>
                                    <td style={{ verticalAlign: 'middle', fontWeight: '600' }}>{rec.model}</td>
                                    <td style={{ verticalAlign: 'middle', fontWeight: '600' }}>{rec.category}</td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                        <Button className={'float-right'} size='sm' color="primary" outline onClick={() =>
                                            setModel({ ...rec, brand: state?.key })}>
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center" style={{ height: '150px', verticalAlign: 'middle' }}>No Models</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

            </ModalBody>
            <ModalFooter>
                <Button color="danger" outline onClick={() => dismiss()}>
                    Dismiss
                </Button>
                <Button color="primary" disabled={!state?.name} outline onClick={async () => {
                    let models = state?.models?.map(m => { return { category: m?.category, model: m?.model } })
                    await firestore().collection('cars_models').doc(selected).set({ [state?.name]: models || [] }, { merge: true });
                    dismiss()
                }}>
                    {state?.addNew ? 'Create' : 'Update'}
                </Button>
            </ModalFooter>
            {model && <Modal
                isOpen={model}
                backdrop="static"
                size="md">
                <ModalHeader >
                    <h6 className="font-weight-bold m-0 mb-2">{model?.addNew ? "New Model" : "Edit Model"}</h6>
                </ModalHeader>
                <ModalBody>
                    <Row >
                        {[
                            { key: 'model', title: 'Model Name', width: '12', type: 'text', hide: !model?.addNew },
                            { key: 'category', title: 'Category', width: '12', type: 'select', },
                        ].map((item, index) => {
                            return !item?.hide && (
                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                    <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                        <div>{item.title}</div>
                                    </div>
                                    {item.type === 'select' ? <Creatable
                                        components={{ Input: CustomSelectInput }}
                                        placeholder={'Select Model'}
                                        value={Object.values(modifiers || {}).find(r => r.name === model.category) || ''}
                                        options={Object.values(modifiers || {})?.filter(m => m.category === selected)?.map(rec => {
                                            return rec
                                        })}
                                        onChange={val => {
                                            setModel({ ...model, category: val.name })
                                        }}
                                        onCreateOption={(inputValue) => {
                                            setNewModifier({ name: inputValue })
                                        }}

                                    /> : <Input
                                        type={item.type}
                                        placeholder={"e.g. " + item.title}
                                        value={model?.[item.key]}
                                        className="customInput"
                                        onChange={(e) => {
                                            setModel({ ...model, [item.key]: e.target.value })
                                        }} />}
                                </Colxx>
                            )
                        })}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" outline onClick={() => setModel(false)}>
                        Dismiss
                    </Button>
                    <Button color="primary" disabled={!model?.category || !model?.model} outline onClick={async () => {
                        if (model?.addNew) {
                            setState({ ...state, models: [...(state.models || []), model] })
                            setModel(false)
                        } else {
                            let updateCars = functions().httpsCallable('updateCars')
                            let result = await updateCars({ brand: model?.brand, model: model?.model, category: model?.category })
                            if (result?.data?.success) {
                                dismiss()
                            } else {
                                createNotification('error', 'An error occured while updating this vehicle, please try again', 'Error');
                                setModel(false)
                            }
                        }
                    }}>
                        {model?.addNew ? "Create" : "Update"}
                    </Button>
                </ModalFooter>
            </Modal >}
            <Modal backdrop="static" isOpen={newModifier}>
                <ModalHeader >
                    Create new vehicle type
                </ModalHeader>
                <ModalBody>
                    <Row >
                        {[
                            { key: 'name', title: 'Category Name', width: '12', type: 'text' },
                            { key: 'name_ar', title: 'Category Arabic Name', width: '12', type: 'text' },
                        ].map((item, index) => {
                            return !item?.hide && (
                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                    <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                        <div>{item.title}</div>
                                    </div>
                                    <Input
                                        type={item.type}
                                        placeholder={"e.g. " + item.title}
                                        value={newModifier?.[item.key]}
                                        className="customInput"
                                        onChange={(e) => {
                                            setNewModifier({ ...newModifier, [item.key]: e.target.value })
                                        }} />
                                </Colxx>
                            )
                        })}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => setNewModifier(false)}>Cancel</Button>
                    <Button
                        disabled={!newModifier?.name}
                        onClick={async () => {
                            let id = firestore().collection('modifiers').doc().id;
                            await firestore().collection('modifiers').doc(id).set({ ...newModifier, category: selected });
                            setModel({ ...model, category: newModifier?.name })
                            setNewModifier(false)
                        }}>Add</Button>
                </ModalFooter>

            </Modal>
        </Modal >
    )
};

export default CarModel;