import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { Row } from "reactstrap";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { getContacts, setSelectedUser, getChatMessages, deleteChatMessage, setUserFromRequests } from "../../../redux/chat/actions";
import ChatApplicationMenu from "../../../containers/applications/ChatApplicationMenu";
import ChatHeading from "../../../components/applications/ChatHeading";
import MessageCard from "../../../components/applications/MessageCard";
import SaySomething from "../../../components/applications/SaySomething";
import { useDispatch, useSelector } from "react-redux";
import { firestore, functions } from "../../../helpers/Firebase";
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchChats, compareContacts, createNotification, deviceWidth } from "../../../helpers/Utils";
import moment from "moment";
import { debounce } from 'lodash';

const Chat = () => {
  const { user } = useSelector(state => state.authUser);
  const { provider } = useSelector(state => state.providers);
  const { allContacts, selectedUser, messages, userFromRequests, chatsFilter } = useSelector(state => state.chatApp);
  const [messageInput, setMessageInput] = useState('');
  const [lastVisibleChat, setLastVisibleChat] = useState(null);
  const [hasMoreChats, setHasMoreChats] = useState(true);
  const [disableSendButton, setDisableSendButton] = useState(false);
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState('');

  const handleSearchContact = e => {
    debounce_fun(e)

  };
  var debounce_fun = debounce(async function (e) {
    setSearchKey(e || '')
  }, 1500);
  useEffect(() => {
    if (searchKey) {
      (async () => {
        let results = await SearchChats({ user, searchText: searchKey })
        setLastVisibleChat(results[results.length - 1])
        setHasMoreChats(results.length ? true : false)
        getInitialData(results, true)
      })()
    }
    // eslint-disable-next-line
  }, [searchKey, user]);
  useEffect(() => {
    if (userFromRequests) {
      (async () => {
        let results = await SearchChats({ user, searchText: userFromRequests })
        setLastVisibleChat(results[results.length - 1])
        setHasMoreChats(results.length ? true : false)
        getInitialData(results, true)
      })()
    }

    // eslint-disable-next-line
  }, [user, userFromRequests]);

  const getInitialData = useMemo(() => {
    return async (snap, fromSearch) => {
      let docs = fromSearch ? snap : snap?.docs;
      if (!docs?.length) {
        dispatch(setSelectedUser(undefined));
        dispatch(setUserFromRequests(undefined));
        return dispatch(getContacts([]));
      }

      let data = await Promise.all(docs?.map(async rec => {
        let post = rec.data();
        let ref = firestore().collection('chats').doc(rec.id).collection('messages');
        if (user?.accessType === 'admin') {
          ref = ref.where('provider_id', '==', user?.uid);
        }
        let messagesSnap = await ref.orderBy("created", "desc").limit(10).get();
        let chatMessages = messagesSnap.docs.map((d) => {
          let parent_id = d.ref.parent.parent.id;
          let chatData = { ...d.data(), parent_id };
          dispatch(getChatMessages(chatData));
          return chatData;
        });
        const lastVisible = messagesSnap.docs[messagesSnap.docs.length - 1];
        let sortedMessages = chatMessages?.sort((msg1, msg2) => msg1?.created - msg2?.created);
        const lastMessage = sortedMessages?.[sortedMessages?.length - 1];
        let exp = post?.exp && !['create_new_session', 'pending_order', 'booking_confirmed']?.includes(lastMessage?.template_name) ? moment(post?.exp).isAfter(moment()) : true;
        return ({ ...post, lastVisible, id: rec.id, isInfiniteLoading: chatMessages.length, exp: !exp, chatMessages });
      }));

      // If there is userFromRequests, find user from data and run setSelectedUser if no selected user or the previous selected user is not equal to userFromRequests
      if (userFromRequests) {
        const userToSelect = data.find(r => r?.id === userFromRequests);
        if (!selectedUser || selectedUser?.id !== userFromRequests) {
          dispatch(setSelectedUser(userToSelect));
        }
        dispatch(setUserFromRequests(undefined));

      }

      dispatch(getContacts(data?.filter(r => r)?.sort(compareContacts)));
    }
  }, [dispatch, user, userFromRequests, selectedUser]);
  useEffect(() => {
    let collectionGroupRef = firestore().collectionGroup('messages')
    if (user?.accessType === 'admin') {
      collectionGroupRef = collectionGroupRef.where('provider_id', '==', user?.uid)
    }
    collectionGroupRef
      .orderBy("created", "desc")
      .limit(1)
      .onSnapshot(snap => {
        if (!snap.empty) {
          let parent_id = snap.docs?.[0].ref.parent.parent.id
          dispatch(getChatMessages({ ...snap.docs[0].data(), parent_id }))
        }
      });

    (async () => {
      let ref = firestore().collection('chats')
      if (user?.accessType === 'admin') {
        ref = ref.where('provider_id', '==', user?.uid)
      }
      if (chatsFilter?.unread) {
        ref = ref.where('newCount', '>', 0)
      } else {
        ref = ref.orderBy("updated", "desc")
      }
      let snap = await ref
        .limit(100).get()
      setLastVisibleChat(snap.docs[snap.docs.length - 1])
      setHasMoreChats(snap.docs.length ? true : false)
      getInitialData(snap)
    })()
    // eslint-disable-next-line
  }, [user, chatsFilter])
  useEffect(() => {
    let ref = firestore().collection('chats')
    if (user?.accessType === 'admin') {
      ref = ref.where('provider_id', '==', user?.uid)
    }
    let chatsRef = ref.orderBy('updated', 'desc').limit(1).onSnapshot(async snap => {
      if (!snap.empty) {
        getInitialData(snap)
      }
    })
    return () => {
      if (chatsRef) {
        chatsRef()
      }
    }
    // eslint-disable-next-line
  }, [])
  const onInfiniteLoad = useCallback(async () => {
    if (selectedUser?.id) {
      let ref = firestore().collection('chats').doc(selectedUser.id).collection('messages')
      if (user?.accessType === 'admin') {
        ref = ref.where('provider_id', '==', user?.uid)
      }

      let snap = await ref
        .orderBy("created", "desc")
        .startAfter(selectedUser?.lastVisible)
        .limit(10).get()
      snap.docs.map((d) => {
        let parent_id = d.ref.parent.parent.id
        return dispatch(getChatMessages({ ...d.data(), parent_id }))
      })

      const lastVisible = snap.docs[snap.docs.length - 1];

      let updateUser = { ...selectedUser, lastVisible: lastVisible, isInfiniteLoading: snap.docs.length ? true : false }
      let updateContacts = allContacts?.reduce((val, currentVal) => {
        if (currentVal.id === selectedUser.id) {
          currentVal = updateUser
        }
        val.push(currentVal)
        return val
      }, [])
      dispatch(setSelectedUser(updateUser))

      dispatch(getContacts(updateContacts?.sort(compareContacts)))
    }
  }, [allContacts, dispatch, selectedUser, user])

  const onLoadMoreContacts = useCallback(async () => {
    let ref = firestore().collection('chats')
    if (user?.accessType === 'admin') {
      ref = ref.where('provider_id', '==', user?.uid)
    }
    if (chatsFilter?.unread) {
      ref = ref.where('newCount', '>', 0)
    } else {
      ref = ref.orderBy("updated", "desc")
    }

    let snap = await ref
      .startAfter(lastVisibleChat).limit(100).get()
    setLastVisibleChat(snap.docs[snap.docs.length - 1])
    setHasMoreChats(snap.docs.length ? true : false)
    getInitialData(snap)

    // eslint-disable-next-line
  }, [user, lastVisibleChat, chatsFilter])

  const handleChatInputPress = useCallback(async e => {
    if (e.key === "Enter") {
      setDisableSendButton(true)
      let message = JSON.parse(JSON.stringify(messageInput))
      if (message.length > 0 && !disableSendButton && selectedUser?.id) {
        dispatch(getChatMessages({
          created: Date.now(),
          from: user?.accessType === 'admin' ? Object.values(provider)?.[0]?.name : (user?.displayName || "Chill"),
          role: user?.accessType,
          sender: user.uid,
          id: '===spam',
          status: "Created",
          text: {
            body: message,
          },
          type:
            "text",
          parent_id: selectedUser.id
        }))
        setMessageInput("")

        let addMessage = functions().httpsCallable("SendWhatsappMessage");
        addMessage({ text: message, phoneNumber: selectedUser.id, from: user?.accessType === 'admin' ? Object.values(provider)?.[0]?.name : user?.displayName }).then(() => {
          dispatch(deleteChatMessage({ id: '===spam', parent_id: selectedUser.id }))
          setDisableSendButton(false)
        }).catch(error => {
          console.log(error)
          dispatch(deleteChatMessage({ id: '===spam', parent_id: selectedUser.id }))
          createNotification("error", error.message)
          setDisableSendButton(false)

        })
      }
    }
  }, [messageInput, selectedUser, disableSendButton, dispatch, user, provider])
  useEffect(() => {
    if (selectedUser) {
      let chatMessages = Object.values(messages?.[selectedUser?.id] || {})?.sort((msg1, msg2) => msg1?.created - msg2?.created);
      const lastMessage = chatMessages?.[chatMessages?.length - 1];
      if (lastMessage?.template_name === 'create_new_session') {
        setDisableSendButton({ text: 'Waiting For Customer Reply' })
      } else {
        setDisableSendButton(false)
      }
    }
  }, [selectedUser, messages])
  function addDateMessages(messages) {
    const sortedMessages = [...messages].sort((a, b) => a.created - b.created);
    const messagesWithDates = [];
    let currentDate = null;

    for (const message of sortedMessages) {
      const messageDate = moment(message.created).format('MMM DD, YYYY');

      if (messageDate !== currentDate) {
        messagesWithDates.push({ type: 'date', content: messageDate, created: moment(message.created).startOf('day').valueOf() });
        currentDate = messageDate;
      }

      messagesWithDates.push(message);
    }

    return messagesWithDates;
  }
  return (
    <Fragment>
      <Row className="app-row survey-app">
        <Colxx xxs="12" >
          <ChatApplicationMenu onLoadMoreContacts={onLoadMoreContacts} hasMoreChats={hasMoreChats} handleSearchContact={handleSearchContact} searchKey={searchKey} />
        </Colxx>
        <Colxx xxs="12" className="chat-app-main">
          {selectedUser && <ChatHeading
            name={selectedUser?.name}
            selectedUser={selectedUser}
            thumb={'selectedUser?.thumb'}
            wa_id={selectedUser?.wa_id}
          />}

          {Object.values(messages?.[selectedUser?.id] || {}).length ?
            <div
              id="scrollableDiv"
              className="hide-scrollbar"

              style={{
                height: deviceWidth() === 'desktop' ? '66vh' : '75vh',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
            >
              <InfiniteScroll
                dataLength={Object.values(messages?.[selectedUser?.id] || {})?.length}
                next={onInfiniteLoad}
                style={{ display: 'flex', flexDirection: 'column-reverse', padding: '20px', }}
                inverse={true}
                height={'100%'}
                className="hide-scrollbar"
                hasMore={selectedUser?.isInfiniteLoading}
                loader={<div><div style={{ position: 'relative' }} className="loading" /></div>}
                scrollableTarget="scrollableDiv"
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>No more messages</b>
                  </p>
                }>

                {Object.values(messages?.[selectedUser?.id] || {}).length ? addDateMessages(Object.values(messages?.[selectedUser?.id] || {}))?.sort((a, b) => b?.created - a?.created)?.map((item, index, array) => {
                  if (item.type === 'date') {
                    return (
                      <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                        <hr style={{ flex: 1 }} />
                        <div style={{ margin: '0 10px', fontSize: '0.8rem', color: 'gray' }}>{item.content}</div>
                        <hr style={{ flex: 1 }} />
                      </div>
                    );
                  }
                  const sender = allContacts?.find(x => x.id === item.from);
                  const previousMessage = array[index - 1];
                  const sameSender = previousMessage && previousMessage.from === item.from;
                  return <MessageCard
                    key={index}
                    sender={sender}
                    item={item}
                    currentUserid={user?.uid}
                    sameSender={sameSender}
                  />
                }) : ''}
              </InfiniteScroll>
            </div>
            :
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px', width: '100%' }}>{selectedUser ? "No Messages" : "No chat selected"}</div>
          }
          {selectedUser && <SaySomething
            placeholder={'Type Something...'}
            messageInput={messageInput}
            setMessageInput={setMessageInput}
            handleChatInputPress={handleChatInputPress}
            handleSendButtonClick={handleChatInputPress}
            disableSendButton={disableSendButton}
          />}
        </Colxx>
      </Row>
    </Fragment>
  );
};

export default Chat;
