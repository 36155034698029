import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Badge, CustomInput, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import ApplicationMenu from "../../components/common/ChatMenu";
import { firestore } from "../../helpers/Firebase";
import { accessTypes, compareContacts, FormatTimestamp, getFirstCharacters } from "../../helpers/Utils";
import { getContacts, setChatsFilter, setSelectedUser, setUserFromRequests } from "../../redux/chat/actions";
import { MenuItem } from "react-contextmenu";

const ChatApplicationMenu = ({ onLoadMoreContacts, hasMoreChats, handleSearchContact, searchKey }) => {
  const { allContacts, chatsFilter } = useSelector(state => state.chatApp)
  const [data, setData] = useState(allContacts);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const { user } = useSelector(state => state.authUser)

  const dispatch = useDispatch()

  const handleContactClick = (user) => {
    firestore().collection('chats').doc(user.id).update({ newCount: 0 })

    const updatedUser = { ...user, newCount: 0 };
    dispatch(setSelectedUser(updatedUser));

    // Update the user in allContacts
    const updatedContacts = allContacts.map(contact =>
      contact.id === user.id ? updatedUser : contact
    );
    setData(updatedContacts);
    dispatch(getContacts(updatedContacts?.sort(compareContacts)))

    dispatch(setUserFromRequests(undefined));
  }
  useEffect(() => {
    let filteredData = allContacts
    if (searchKey !== "") {
      filteredData = filteredData?.filter(c => (c.name)?.toLowerCase()?.includes(searchKey?.toLowerCase()) || (c.wa_id)?.toLowerCase()?.includes(searchKey?.toLowerCase()))
    }
    setData(filteredData?.sort(compareContacts));
  }, [allContacts, searchKey]);

  return (
    <ApplicationMenu>
      <div className="pt-4 pr-4 pl-4 pb-0">
        <div className="form-group d-flex align-items-center">
          <input
            type="text"
            className="form-control rounded mr-1"
            // placeholder={messages["menu.search"]}
            // value={searchKey}
            onChange={e => handleSearchContact(e.target.value)}
          />
          <Dropdown isOpen={dropdownOpen} toggle={toggle} >

            <DropdownToggle size='sm' color="primary" className='p-1'>
              <i className="iconsminds-filter-2" style={{ fontSize: '18px' }} />
            </DropdownToggle>
            <DropdownMenu left size='xl'>
              <DropdownItem header>
                Filters
              </DropdownItem>
              <MenuItem >
                <CustomInput
                  className="custom-checkbox "
                  type="checkbox"
                  id="checkAll"
                  checked={chatsFilter?.unread ? true : false}
                  onChange={() => {
                    let cloned = JSON.parse(JSON.stringify(chatsFilter || {}))
                    if (cloned.unread) {
                      delete cloned.unread
                    } else {
                      cloned.unread = true
                    }
                    dispatch(setChatsFilter(cloned))
                  }}
                  label={<div style={{ cursor: 'pointer', }}>Unread Chats</div>}
                />
              </MenuItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <InfiniteScroll
        className="pt-2 pr-4 pl-4 pb-2"
        dataLength={data?.length || 0}
        next={onLoadMoreContacts}
        height={'100%'}
        hasMore={hasMoreChats}
        loader={<div><div style={{ position: 'relative' }} className="loading" /></div>}
        scrollableTarget="scrollableChats"
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>No more chats</b>
          </p>
        }
      >
        {
          data?.map((item, index) => {
            // let chatMessages = item?.chatMessages?.sort((msg1, msg2) => msg1?.created - msg2?.created);
            // const lastMessage = chatMessages?.[chatMessages?.length - 1];
            return (
              <div
                key={index}
                className="d-flex flex-row mb-3 border-bottom pb-3"
              >
                <NavLink
                  className="d-flex"
                  to="#"
                  style={{ width: '100%' }}
                  onClick={() => handleContactClick(item)}
                >
                  <div
                    style={{
                      background: '#3498db',
                      padding: '5px',
                      color: '#fff',
                      width: '40px',
                      height: '40px',
                      minWidth: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: '500',
                      borderRadius: '200px',
                      letterSpacing: '.06em'
                    }}
                    className="mr-1"
                  >
                    {getFirstCharacters(item?.name || 'Chillapp Team')}

                  </div>
                  <div className="ml-2 mr-2 pl-0 align-self-center d-flex flex-column" style={{ flex: 1 }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                      <p className="mb-0 truncate" style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        fontSize: '14px',
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',

                      }}>{item?.name}
                      </p>
                      <p className="mb-0 truncate text-muted text-extra-small">{FormatTimestamp(item?.updated)}</p>

                    </div>

                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                      {accessTypes.includes(user?.accessType) && <p className="mb-0 truncate" style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                        textOverflow: 'ellipsis',

                      }}>{item?.wa_id}</p>}
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                        <span style={{
                          color: '#f44336',
                          fontSize: '10px',
                          fontWeight: '600',
                          letterSpacing: '.06em',
                          textTransform: 'uppercase'

                        }}>{item.exp ?
                          'Expired' : ''
                          }</span>
                        {item.newCount ? <Badge color="danger" style={{
                          aspectRatio: '1',
                          width: '15px',
                          height: '15px',
                          padding: '4px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '20px'
                        }}>{item.newCount}</Badge> : ''
                        }
                      </div>
                    </div>
                  </div>

                </NavLink>
              </div>
            );
          })}

      </InfiniteScroll>
    </ApplicationMenu>
  )
};

export default ChatApplicationMenu;