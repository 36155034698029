
let INIT_STATE = {}
const ex = (state = INIT_STATE, action) => {

    switch (action.type) {
        case "GET_PROVIDERS_LIST":
            return { ...state, loading: true, provider: action.payload };
        case "UPDATE_PROVIDER":
            return { ...state, loading: true, provider: action.payload };
        case "GET_WASHER_LIST":
            return { ...state, loading: true, washers: action.payload };
        case "UPDATE_WASHER":
            return { ...state, loading: true, washers: action.payload };
        case "GET_APPOINTMENT_LIST":
            return { ...state, loading: true, bookings: action.payload };
        case "UPDATE_UPSELLING":
            return { ...state, loading: true, up_selling: action.payload };
        case "GET_UPSELLING_LIST":
            return { ...state, loading: true, up_selling: action.payload };
        case "GET_SERVICES_LIST":
            return { ...state, loading: true, services: action.payload };
        case "GET_MODIFIERS":
            return { ...state, loading: true, modifiers: action.payload };
        case "UPDATE_SERVICES_LIST":
            return { ...state, loading: true, services: action.payload };
        case "GET_ADV_SERVICES_LIST":
            return { ...state, loading: true, advServices: action.payload };
        case "GET_VARIATIONS_LIST":
            return { ...state, loading: true, variations: action.payload };
        case "GET_BANNERS_LIST":
            return { ...state, loading: true, banners: action.payload };
        case "GET_CUSTOMERS_VOUCHERS":
            return { ...state, loading: true, customersVouchers: action.payload };
        case "GET_VOUCHERS":
            return { ...state, loading: true, vouchers: action.payload };
        default: return { ...state };
    }

}
export default ex;
