import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import Select from "react-select";
import { NavItem } from "reactstrap";
import ApplicationMenu from "../../../../components/common/ApplicationMenu";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";


const OrdersFilter = ({ todoItems, bookingType, activeServices, filter }) => {
  const { provider, washers, } = useSelector(state => state.providers)

  const [selectedFilter, setFilter] = useState({ status: bookingType === "Appointments" ? ['accepted', 'active', 'completed', 'notRated'] : ['new', 'pending'], selected: bookingType?.toLowerCase() })

  useEffect(() => {
    filter(selectedFilter)
    // eslint-disable-next-line
  }, [selectedFilter])
  return (
    <ApplicationMenu >
      <PerfectScrollbar>
        <div className="p-4">
          {[{
            title: 'Requests',
            key: 'requests',
            show: bookingType === 'Requests',
            data: [
              { key: 'requests', type: 'status', title: 'All Requests', status: ['new', 'pending'] },

            ]
          },
          {
            title: 'Appointments',
            key: 'appointments',
            show: bookingType === 'Appointments',
            data: [
              { key: 'appointments', type: 'status', title: 'All Appointments', status: ['accepted', 'active', 'completed', 'notRated'] },
              { key: 'accepted', type: 'status', title: "Accepted", status: ['accepted'] },
              { key: 'active', type: 'status', title: "Active", status: ['active'] },
              { key: 'completed', type: 'status', title: "Completed", status: ['completed', 'notRated'] },
            ]
          },
          ].map((rec, recIndex) => {
            return rec.show && (
              <div key={recIndex}>
                <p className="text-muted text-small mb-2">{rec.title}</p>
                <ul className="list-unstyled mb-4">
                  {rec.data.map((c, index) => {
                    return (
                      <NavItem key={index}
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                      >
                        <div onClick={() => {
                          setFilter({
                            ...selectedFilter,
                            [c.type]: c.status,
                            selected: c.key,
                          })
                        }}>
                          <div className="custom-control custom-radio">
                            <input
                              style={{ cursor: 'pointer' }}
                              type="radio"
                              className="custom-control-input"
                              checked={selectedFilter?.selected === c.key}
                            />
                            <label style={{ cursor: 'pointer' }} className="custom-control-label">{c.title}</label>
                          </div>
                        </div>
                        <span className="float-right">
                          {todoItems && todoItems.filter(x => c.status.includes(x.status)).length}
                        </span>
                      </NavItem>
                    );
                  })}
                </ul>
              </div>
            )
          })}
          {
            [{
              title: 'Services',
              key: 'service',
              data: activeServices,
            }, {
              title: 'Service Providers',
              key: 'providers',
              data: provider,
            },
            {
              title: 'Washers',
              key: 'washers',
              data: washers,
            },
            ].map((rec, index) => {
              return (
                <div key={index}>
                  <p className="text-muted text-small mb-1">{rec.title}</p>
                  <div className="mb-4">
                    <Select
                      isMulti
                      components={{ Input: CustomSelectInput }}
                      className="react-select"
                      classNamePrefix="react-select"
                      name="form-field-name"
                      options={rec.data && Object.values(rec.data)?.map(rec => { return ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.key }) })}
                      // value={this.state.selectedWasher}
                      isClearable={true}
                      onChange={val => {
                        setFilter({ ...selectedFilter, [rec.key]: val.map(v => v.key) })
                      }}
                    />
                  </div>
                </div>
              )
            })
          }
        </div>
      </PerfectScrollbar>
    </ApplicationMenu >
  );
};

export default OrdersFilter;