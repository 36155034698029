import React, { useState, useRef } from 'react';
import { GoogleMap, LoadScript, StandaloneSearchBox, Marker } from '@react-google-maps/api';

const libraries = ['places'];

const containerStyle = {
    width: '100%',
    height: '400px'
};


function MapWithMarker({ center, updateLatLng }) {
    const [map, setMap] = useState(null);
    const searchBoxRef = useRef(null);

    const onLoad = ref => {
        searchBoxRef.current = ref;
    };

    const onPlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        const place = places[0];
        if (place) {
            const { geometry } = place;
            if (geometry && geometry.location) {
                updateLatLng({
                    lat: geometry.location.lat(),
                    lng: geometry.location.lng()
                });
            }
        }
    };

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyCExglJjHXgXzSGHJB1NmLll7NgjOG8_bc"
            libraries={libraries}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                defaultZoom={15}

                onLoad={map => setMap(map)}
                onDragEnd={() => {
                    let center = map?.getCenter()?.toJSON()
                    if (center) {
                        updateLatLng(center)

                    }
                }}
                options={{ disableDefaultUI: true }}
            >
                <StandaloneSearchBox
                    onLoad={onLoad}
                    onPlacesChanged={onPlacesChanged}
                >
                    <input
                        type="text"
                        placeholder="Search places"
                        style={{
                            boxSizing: 'border-box',
                            border: '1px solid transparent',
                            width: '240px',
                            height: '32px',
                            padding: '0 12px',
                            borderRadius: '3px',
                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                            fontSize: '14px',
                            outline: 'none',
                            textOverflow: 'ellipses',
                            position: 'absolute',
                            top: '10px',
                        }}
                    />
                </StandaloneSearchBox>
                <Marker position={center} />
            </GoogleMap>
        </LoadScript>
    );
}

export default MapWithMarker;
