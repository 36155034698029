import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import ChatApp from "./chat";

const Applications = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
    <Route path={`${match.url}/`} component={ChatApp} />

      <Redirect to="/error" />
    </Switch>
  </div>
);
export default Applications;
