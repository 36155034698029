/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";



//Providers
export const SET_PROVIDERS_LIST = "SET_PROVIDERS_LIST";
export const GET_PROVIDERS_LIST = "GET_PROVIDERS_LIST";

/* CHAT APP*/
export const CHAT_GET_CONTACTS = "CHAT_GET_CONTACTS";
export const CHAT_GET_CONVERSATIONS = "CHAT_GET_CONVERSATIONS";
export const CHAT_CHANGE_CONVERSATION = "CHAT_CHANGE_CONVERSATION";
export const CHAT_DELETE_MESSAGE = "CHAT_DELETE_MESSAGE"
export const USER_FROM_REQUESTS = "USER_FROM_REQUESTS"
export const SET_MESSAGES_COUNT = "SET_MESSAGES_COUNT"
export const SET_CHATS_COUNT = "SET_CHATS_COUNT"
export const SET_CHATS_Filter = "SET_CHATS_Filter"

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./providers/actions";


