import React, { Component } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import moment from 'moment'

const NotificationItem = (notification) => {
  return (
    <div className="d-flex flex-row mb-3 pb-3 border-bottom">

      <div className="pl-3 pr-2">
        <a href={"/app/orders/requests"}>
          <p className="font-weight-medium mb-1">{notification.notification.customer_name}</p>
          <p className="text-muted mb-0 text-small">{moment(notification.notification.bookingDate).format('DD/MM/YYYY hh:mm A')}</p>
        </a>
      </div>
    </div>
  );
};

class TopnavNotifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationBookings: []
    };
  }
  componentWillReceiveProps(nextProps) {
    let { bookings } = this.props
    if (bookings !== nextProps.bookings) {
      this.getBookings(nextProps.bookings)
    }
  }
  getBookings(bookings) {
    let { notificationBookings } = this.state

    notificationBookings = Object.values(bookings).filter(item => {
      return item.status === 'new'
    })
    this.setState({ notificationBookings })
  }
  componentDidMount() {
    let { bookings } = this.props
    if (bookings) {
      this.getBookings(bookings)
    }

  }
  render() {
    let { authUser } = this.props
    let { notificationBookings } = this.state
    return (
      <div className="position-relative d-inline-block">
        <UncontrolledDropdown className="dropdown-menu-right">
          <DropdownToggle
            className="header-icon notificationButton"
            color="empty"
          >
            <i className="simple-icon-bell" />
            <span className="count">{notificationBookings.length}</span>
          </DropdownToggle>
          <DropdownMenu
            className="position-absolute mt-3 scroll"
            right
            id="notificationDropdown"
          >
            {
              notificationBookings && notificationBookings.length ? <PerfectScrollbar
                option={{ suppressScrollX: true, wheelPropagation: false }}
              >
                {notificationBookings.map((notification, index) => {
                  return <NotificationItem key={index} notification={notification} authUser={authUser} />;
                })}
              </PerfectScrollbar> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Notifications</div>
            }

          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  };
}
const mapStateToProps = ({ providers, authUser }) => {
  return {
    authUser,
    bookings: providers ? providers.bookings : {},
  };
};

export default connect(mapStateToProps)(TopnavNotifications);


