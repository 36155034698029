import React, { useEffect, useState } from 'react';
import { Button, CustomInput, Input, Modal, ModalBody, ModalFooter, ModalHeader, NavItem, Row } from 'reactstrap';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";

import { colors, emirates } from '../../../../helpers/Utils';
import { firestore } from '../../../../helpers/Firebase';
const makeid = (length) => { var result = ''; var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; var charactersLength = characters.length; for (var i = 0; i < length; i++) { result += characters.charAt(Math.floor(Math.random() * charactersLength)); } return result; }
const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
const AddCar = ({ addCar, submit, dismiss }) => {
    const [advancedDetails, setAdvancedDetails] = useState(false)
    const [vehicleModels, setVehicleModels] = useState({})
    const [selectedBrandModels, setSelectedBrandModels] = useState([])
    const [newCar, updateCar] = useState({})

    useEffect(() => {
        let vehicleModels = {}
        firestore().collection('cars_models').get().then(function (carsSnapshot) {
            carsSnapshot.forEach(function (doc) {
                let model = {}
                let cars = Object.keys(doc.data()).map((item, index) => {
                    let car = {}
                    car.key = index
                    car.label = item
                    car.value = item
                    return car
                })

                model.cars = cars
                model.data = doc.data()
                model.key = doc.id
                model.value = capitalize(doc.id)
                model.label = capitalize(doc.id)
                vehicleModels[doc.id] = model;

            });
            setVehicleModels(vehicleModels)
        });
    }, [])
    return (
        <Modal isOpen={addCar}>
            <ModalHeader>
                Add Car
            </ModalHeader>
            <ModalBody>
                <CustomInput
                    className="custom-checkbox mb-2 d-inline-block"
                    type="checkbox"
                    id="checkAll"
                    checked={advancedDetails}
                    onChange={(e) => {
                        setAdvancedDetails(e.target.checked)
                    }}
                    label={<div style={{ cursor: 'pointer', fontWeight: '700' }}>Advanced car details</div>}
                />
                <Row>

                    {[
                        { key: 'selectedType', title: 'Vehicle type *', width: '12', type: 'navItem', data: ['Sedan', 'SUV', '2 weeler', '3 weeler', 'Off Road', 'ATV'], hide: advancedDetails },
                        { key: 'selectedType', title: 'Vehicle type *', width: '4', type: 'select', data: Object.values(vehicleModels || {}), hide: !advancedDetails },
                        { key: 'car_model', title: 'Car Brand *', width: '4', type: 'select', data: newCar?.selectedType?.cars || [], hide: !advancedDetails },
                        { key: 'modelNumber', title: 'Car Model *', width: '4', type: 'select', data: selectedBrandModels || [], hide: !advancedDetails },
                        { key: 'emirate', title: 'Emirate', width: '4', type: 'select', data: emirates, hide: !advancedDetails },
                        { key: 'plateNumber', title: 'Plate Number', placeholder: 'A 111', width: '4', type: 'text', data: emirates, hide: !advancedDetails },
                        { key: 'car_color', title: 'Car Color', width: '4', type: 'select', data: colors, hide: !advancedDetails },
                    ].map((item, index) => {
                        return !item.hide && (
                            <Colxx key={index} xxs="12" md={item.width} className="mb-3">
                                <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                    <div>{item.title}</div>
                                </div>
                                {item.type === 'select' ?
                                    <Select
                                        components={{ Input: CustomSelectInput }}
                                        isClearable
                                        value={newCar?.[item.key] ? (item?.data?.find(rec => (newCar?.[item.key]?.label || newCar?.[item.key]?.model) === rec.label || newCar?.[item.key] === rec.label) || '') : ''}
                                        options={item?.data}
                                        onChange={val => {
                                            switch (item.key) {
                                                case 'selectedType':
                                                    updateCar({ [item.key]: val })
                                                    setSelectedBrandModels([])
                                                    break;
                                                case 'car_model':
                                                    let selectedBrandModels = []
                                                    if (val) {
                                                        vehicleModels[(newCar?.selectedType?.label).toLowerCase()].data[val.label].map(item => {
                                                            let carModel = {}
                                                            carModel.key = item.model
                                                            carModel.label = item.model
                                                            carModel.value = item.model
                                                            carModel.year = item.year
                                                            carModel.category = item.category
                                                            return (selectedBrandModels.push(carModel))
                                                        })
                                                    }

                                                    setSelectedBrandModels(selectedBrandModels)
                                                    updateCar({ ...newCar, [item.key]: val?.label || '', modelNumber: '', vehicle: '' })

                                                    break;
                                                case 'modelNumber':
                                                    updateCar({
                                                        ...newCar, [item.key]: {
                                                            category: val?.category || '',
                                                            year: val?.year || '',
                                                            model: val?.label || '',
                                                        },
                                                        vehicle: val?.category || '',
                                                    })

                                                    break;
                                                default:
                                                    updateCar({ ...newCar, [item.key]: val || '' })
                                                    break;
                                            }
                                        }}
                                    />
                                    : item.type === 'navItem' ? <ul className="list-unstyled mt-2 mb-0" style={{ display: "flex", flexWrap: 'wrap' }}>
                                        {item?.data?.map((item, index) => {
                                            return (
                                                <Colxx key={index} xxs="6" md="5">
                                                    <NavItem style={{ cursor: 'pointer' }}>
                                                        <div onClick={() => {
                                                            updateCar({
                                                                ...newCar,
                                                                modelNumber: {
                                                                    category: item,
                                                                    model: "N/A",
                                                                    year: "N/A"
                                                                },
                                                                vehicle: item,

                                                            })
                                                        }
                                                        }>
                                                            <div className="custom-control custom-radio">
                                                                <input
                                                                    style={{ cursor: 'pointer' }}
                                                                    type="radio"
                                                                    className="custom-control-input"
                                                                    checked={newCar?.vehicle === item}
                                                                />
                                                                <label style={{ cursor: 'pointer' }} className="custom-control-label">{item}</label>
                                                            </div>
                                                        </div>
                                                    </NavItem>
                                                </Colxx>

                                            );
                                        })}
                                    </ul> : <Input
                                        type={item.type}
                                        placeholder={"e.g. " + (item?.placeholder || item?.title)}
                                        value={newCar?.[item.key] || ''}
                                        className="customInput"
                                        onChange={(e) => {
                                            updateCar({ ...newCar, [item.key]: e.target.value })
                                        }} />}
                            </Colxx>

                        )
                    })}
                </Row>

            </ModalBody>
            <ModalFooter>
                <Button color='danger' onClick={() => dismiss()}>Dismiss</Button>
                <Button color='primary'
                    onClick={() => {
                        let post = {
                            car_color: newCar?.car_color?.label || "N/A",
                            car_model: newCar?.car_model || makeid(6) || "N/A",
                            modelNumber: newCar?.modelNumber,
                            plateNumber: `${newCar?.emirate?.label || 'N/A'} ${newCar?.plateNumber || 'N/A'}`,
                            vehicle: newCar?.vehicle || 'N/A',
                            vehicleId: makeid(6)
                        }
                        submit(post)
                    }}

                >Add</Button>

            </ModalFooter>
        </Modal>
    );
};

export default AddCar;