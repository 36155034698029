import React, { Fragment, useState } from "react";
import {
    Row,
    Button,
    Card,
    CardBody,
    Input,
} from "reactstrap";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import { firestore, storage } from '../../../../helpers/Firebase'

import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";

const Banner = ({
    addNewBanner,
    onClickItem,
    services,
    providers,
    selectedBanner,
    updateBanner }) => {

    const [updatedBanner, UpdateBanner] = useState(JSON.parse(JSON.stringify(selectedBanner)))
    const [isLoading, setLoading] = useState(false)
    const [pictures, setPicture] = useState(false)
    const [pictures_ar, setPicturAr] = useState(false)
    const types = [{ key: 'url', title: 'URL', value: 'URL', label: 'URL', isUrl: true },
    { key: 'service', title: 'Service', value: 'Service', label: 'Service', isUrl: false },
    { key: 'serviceProvider', title: 'Service Provider', value: 'Service Provider', label: 'Service Provider', isUrl: false },
    { key: 'navigation', title: 'Navigation', value: 'Navigation', label: 'Navigation', isUrl: false }]

    const submit = (banner) => {
        delete banner?.value
        delete banner?.label
        var metadata = {
            cacheControl: 'public,max-age=300',
        };

        let key = banner.key || firestore().collection('banners').doc().id
        if (key) {
            if ((pictures && pictures.length > 0 && pictures[0].name) || (pictures_ar && pictures_ar.length > 0 && pictures_ar[0].name)) {
                if (pictures?.length) {
                    pictures.forEach(element => {
                        var storageRef = storage().ref().child('banners').child(key).child("logo").put(element.data, metadata);
                        storageRef.on('state_changed', function (snapshot) {
                        }, function (error) {

                        }, function () {
                            storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                                if (addNewBanner) {
                                    banner.created = Date.now()
                                    banner.active = true
                                }
                                banner.updated = Date.now()
                                banner.img = [downloadURL]

                                firestore().collection('banners').doc(key).set(banner).then(() => {
                                    updateBanner(key)
                                })
                            })
                        })
                    });
                }
                if (pictures_ar?.length) {
                    pictures_ar.forEach(element => {
                        var storageRef = storage().ref().child('banners').child(key).child("logo_ar").put(element.data, metadata);
                        storageRef.on('state_changed', function (snapshot) {
                        }, function (error) {

                        }, function () {
                            storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                                if (addNewBanner) {
                                    banner.created = Date.now()
                                    banner.active = true

                                }
                                banner.updated = Date.now()
                                banner.img_ar = [downloadURL]

                                firestore().collection('banners').doc(key).set(banner).then(() => {
                                    updateBanner(key)
                                })
                            })
                        })
                    });
                }

            } else {
                if (addNewBanner) {
                    banner.created = Date.now()
                    banner.active = true
                }
                banner.updated = Date.now()
                banner.img = banner?.img || []
                firestore().collection('banners').doc(key).set(banner).then(() => {
                    updateBanner(key)
                })
            }
        }
    }
    const onDrop = (picture, key) => {
        let pictures = []
        if (picture.length) {
            let lastPic = picture[picture.length - 1]
            Resizer.imageFileResizer(
                lastPic,
                1920,
                1080,
                'jpeg',
                60,
                0,
                uri600 => {
                    pictures.push({
                        name: '600',
                        data: uri600
                    });
                    if (key === 'img') {
                        setPicture(pictures)
                    } else {
                        setPicturAr(pictures)
                    }
                },
                'blob'
            );
        } else {
            if (key === 'img') {
                setPicture([])
            } else {
                setPicturAr([])
            }
            UpdateBanner({ ...updatedBanner, [key]: [] })
        }
    }
    return (
        isLoading ? (
            <div className="loading" />
        ) : (
            <Fragment>
                <Row>
                    <Colxx xxs="12">
                        <h1>{addNewBanner ? 'New Banner' : updatedBanner?.name}</h1>


                        <Card className="rounded">
                            <CardBody>
                                <Row>
                                    <Colxx xxs="12" className="mb-2">
                                        <h4>General Information</h4>
                                    </Colxx>
                                </Row>
                                <Row>
                                    <Colxx xxs="12" md="3" className="mb-4">
                                        <div className="mb-2" >Banner</div>
                                        <ImageUploader
                                            className={'w-100'}
                                            withIcon={true}
                                            label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                            buttonText='Choose images'
                                            onChange={e => onDrop(e, 'img')}
                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                            maxFileSize={15728640}
                                            withPreview={true}
                                            singleImage={true}
                                            defaultImages={updatedBanner && updatedBanner?.img}
                                        />
                                    </Colxx>
                                    <Colxx xxs="12" md="3" className="mb-4">
                                        <div className="mb-2" >Arabic Banner</div>
                                        <ImageUploader
                                            className={'w-100'}
                                            withIcon={true}
                                            label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                            buttonText='Choose images'
                                            onChange={e => onDrop(e, 'img_ar')}
                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                            maxFileSize={15728640}
                                            withPreview={true}
                                            singleImage={true}
                                            defaultImages={updatedBanner && updatedBanner?.img_ar}
                                        />
                                    </Colxx>
                                </Row>

                                <Row >
                                    <Colxx xxs="12" md="6" >
                                        <div className="mb-2" >Select Banner Type *</div>
                                        <Select
                                            components={{ Input: CustomSelectInput }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="form-field-name"
                                            value={types.find(r => r.key === updatedBanner?.type) || ''}
                                            options={types}
                                            onChange={val => {
                                                UpdateBanner({ ...updatedBanner, isUrl: val.isUrl, type: val.key })

                                            }}
                                        />
                                    </Colxx>
                                </Row>

                                <Row>

                                    {[
                                        { key: 'url', title: 'URL', isUrl: true, show: updatedBanner.type === 'url' },
                                        { key: 'service', title: 'Service', isUrl: false, data: services, show: updatedBanner.type === 'service' },
                                        { key: 'serviceProvider', title: 'Service Provider', isUrl: false, data: providers, show: updatedBanner.type === 'serviceProvider' },
                                        { key: 'url', title: 'Navigation', isUrl: false, show: updatedBanner.type === 'navigation' },
                                    ].map(r => {
                                        return r.show && (
                                            <Colxx xxs="12" md="6" className="mb-4 mt-4">
                                                <div className="mb-2" >{r.title} *</div>
                                                {r.key === 'url' ?
                                                    <Input
                                                        type="text"
                                                        autoCapitalize="characters"
                                                        value={updatedBanner.url}
                                                        className="customInput"
                                                        onChange={(e) => {
                                                            let update = JSON.parse(JSON.stringify(updatedBanner))
                                                            delete update?.serviceProvider
                                                            delete update?.service
                                                            delete update?.value
                                                            delete update?.label
                                                            UpdateBanner({ ...update, url: e.target.value })
                                                        }} />
                                                    :
                                                    <Select
                                                        components={{ Input: CustomSelectInput }}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        name="form-field-name"
                                                        options={r.data}
                                                        value={(r.data)?.find(rec => rec.key === updatedBanner?.[r.key])}
                                                        onChange={val => {
                                                            let update = JSON.parse(JSON.stringify(updatedBanner))
                                                            if (r.key === 'service') {
                                                                delete update?.serviceProvider
                                                            } else {
                                                                update.service = 'BWDoG0hGapBg3Mitbs35'
                                                            }
                                                            delete update?.url
                                                            delete update?.value
                                                            delete update?.label

                                                            UpdateBanner({ ...update, [r.key]: val.key, name: val.name })
                                                        }}
                                                    />}
                                            </Colxx>
                                        )
                                    })
                                    }
                                </Row>
                            </CardBody>
                        </Card>

                        <Button
                            color="primary"
                            className="btn-shadow float-right mt-4"
                            size="lg"
                            onClick={() => {
                                setLoading(true)
                                submit(JSON.parse(JSON.stringify(updatedBanner)))
                            }}>
                            Save Changes
                        </Button>
                        <Button
                            color="danger"
                            className="btn-shadow float-right m-4"
                            size="lg"
                            onClick={() => onClickItem(null)}>
                            Dismiss
                        </Button>

                    </Colxx>
                </Row>
            </Fragment >
        )
    );
};

export default Banner;