import React, { Component, Fragment } from "react";
import {
    Row,
    Nav,
    TabContent,
    TabPane,
    Input,
    Button,
    Card,
    CardBody,
    Label,

} from "reactstrap";
import classnames from "classnames";
import { Colxx } from "../../../components/common/CustomBootstrap";
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import { firestore, storage } from '../../../helpers/Firebase'
import { NotificationManager } from "../../../components/common/react-notifications";
import moment from "moment";

import Select from "react-select";
import { components } from "react-select";
import { accessTypes } from "../../../helpers/Utils";

const CustomSelectInput = props => {
    var customProps = Object.assign({}, props);
    delete customProps.autoCorrect;
    delete customProps.autoCapitalize;
    return <components.Input {...customProps} />;
};
const tabs = [{ name: 'General Information', key: 1 }]
class UpSelling extends Component {
    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
        this.submit = this.submit.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.state = {
            activeTab: 1,
            name: '',
            img: '',
            pictures: [],
            isLoading: true,
            loading: false,
            selectedUpSelling: {},
        };

    }
    createNotification = (className, error) => {
        let cName = className || "";
        return (
            NotificationManager.error(
                error,
                "Error",
                2000,
                null,
                cName
            )
        )
    };

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }



    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }

    }


    componentWillMount() {
        let { selectedUpSelling } = this.props
        selectedUpSelling.type = selectedUpSelling.type ? selectedUpSelling.type : "Product"
        this.setState({
            selectedUpSelling: JSON.parse(JSON.stringify(selectedUpSelling)),

            isLoading: false,
        })
    }
    submit() {
        let { addNewUpSelling, updateUpSelling, authUser } = this.props
        let { selectedUpSelling } = this.state

        let _this = this
        let { pictures } = this.state
        var metadata = {
            cacheControl: 'public,max-age=300',
        };
        if (addNewUpSelling) {
            // if (name !== '') {

            // Read result of the Cloud Function.
            let key = firestore().collection('up_selling').doc().id
            if (key) {
                if (pictures && pictures.length > 0 && pictures[0].name) {

                    pictures.forEach(element => {
                        var storageRef = storage().ref().child('up_selling').child(key).child("logo").put(element.data, metadata);
                        storageRef.on('state_changed', function (snapshot) {
                        }, function (error) {
                            _this.setState({ isLoading: false })

                            // Handle unsuccessful uploads
                        }, function () {

                            selectedUpSelling.img = []
                            selectedUpSelling.created = Date.now()
                            selectedUpSelling.active = true
                            if (authUser.user.accessType === 'admin') {
                                selectedUpSelling.sp_id = authUser.user.uid
                            }
                            storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                                selectedUpSelling.img = [downloadURL]
                                delete selectedUpSelling.cars
                                firestore().collection('up_selling').doc(key).set(selectedUpSelling).then(() => {
                                    updateUpSelling()

                                })
                            })
                        })
                    });
                } else {
                    selectedUpSelling.created = Date.now()
                    selectedUpSelling.active = true
                    selectedUpSelling.img = []
                    if (authUser.user.accessType === 'admin') {
                        selectedUpSelling.sp_id = authUser.user.uid
                    }
                    delete selectedUpSelling.cars
                    firestore().collection('up_selling').doc(key).set(selectedUpSelling).then(() => {
                        updateUpSelling()

                    })
                }
            }
        } else {
            let key = selectedUpSelling.key ? selectedUpSelling.key : ''
            if (key) {
                if (pictures && pictures.length > 0 && pictures[0].name) {
                    pictures.forEach(element => {
                        var storageRef = storage().ref().child('up_selling').child(key).child("logo").put(element.data, metadata);
                        storageRef.on('state_changed', function (snapshot) {
                        }, function (error) {
                            // Handle unsuccessful uploads
                        }, function () {
                            storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                                selectedUpSelling.updated = Date.now()
                                selectedUpSelling.img = [downloadURL]
                                if (authUser.user.accessType === 'admin') {
                                    selectedUpSelling.sp_id = authUser.user.uid
                                }
                                delete selectedUpSelling.cars
                                firestore().collection('up_selling').doc(key).update(selectedUpSelling).then(() => {
                                    updateUpSelling()

                                })
                            })
                        })
                    });
                } else {
                    selectedUpSelling.updated = Date.now()
                    selectedUpSelling.img = selectedUpSelling.img && selectedUpSelling.img.length ? selectedUpSelling.img : []
                    if (authUser.user.accessType === 'admin') {
                        selectedUpSelling.sp_id = authUser.user.uid
                    }
                    delete selectedUpSelling.cars
                    firestore().collection('up_selling').doc(key).update(selectedUpSelling).then(() => {
                        updateUpSelling()

                    })
                }
            }
        }


    }
    onDrop(picture) {
        let { selectedUpSelling } = this.state

        let _this = this;
        let pictures = []
        if (picture.length) {
            let lastPic = picture[picture.length - 1]
            Resizer.imageFileResizer(
                lastPic,
                720,
                1080,
                'jpeg',
                60,
                0,
                uri600 => {
                    pictures.push({
                        name: '600',
                        data: uri600
                    });
                    _this.setState({ pictures })
                },
                'blob'
            );

        } else {
            selectedUpSelling.img = []
            _this.setState({ pictures: [], selectedUpSelling })
        }
    }

    render() {
        let { isLoading, selectedUpSelling, pictures } = this.state
        let { addNewUpSelling, onClickItem, providers, authUser, modifiers } = this.props

        let options = []
        let startDuration = '00:05';
        for (var i = 0; i < 96; i++) {
            var obj = { label: startDuration, value: startDuration, key: i + 1 };
            startDuration = moment(startDuration, 'HH:mm').add('5', 'minutes').format('HH:mm');
            options.push(obj);
        }
        return (
            (
                isLoading ? (
                    <div className="loading" />
                ) : (
                    <Fragment>
                        <Row>
                            <Colxx xxs="12">
                                <h1>{addNewUpSelling ? 'New up selling' : selectedUpSelling.name}</h1>
                                <Nav tabs className="separator-tabs ml-0 mb-5">
                                    {
                                        tabs.map(item => {
                                            return (
                                                <div
                                                    style={{ cursor: 'pointer' }}
                                                    key={item.key}
                                                    className={classnames({
                                                        active: this.state.activeTab === item.key,
                                                        "nav-link": true
                                                    })}
                                                    onClick={() => {
                                                        this.toggleTab(item.key);
                                                    }}
                                                >
                                                    {item.name}
                                                </div>
                                            )
                                        })
                                    }
                                </Nav>

                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId={1}>
                                        <Card className="rounded">
                                            <CardBody>


                                                <Row>
                                                    <Colxx xxs="12" className="mb-2">
                                                        <h4>General Information</h4>
                                                    </Colxx>
                                                </Row>
                                                <Row>
                                                    <Colxx xxs="12" md="6" className="mb-4">
                                                        <div className="mb-2" >Image</div>
                                                        <ImageUploader
                                                            className={'w-100'}
                                                            withIcon={true}
                                                            label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                                            buttonText='Choose images'
                                                            onChange={this.onDrop}
                                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                            maxFileSize={15728640}
                                                            withPreview={true}
                                                            singleImage={true}
                                                            defaultImages={selectedUpSelling && selectedUpSelling.img}
                                                        />
                                                    </Colxx>
                                                </Row>
                                                <Row>
                                                    <Colxx xxs="6" md="3" className="mb-4">
                                                        <div className="mb-2" >Name</div>
                                                        <Input
                                                            type="text"
                                                            autoCapitalize="characters"
                                                            placeholder="e.g. Name"
                                                            value={selectedUpSelling.name}
                                                            className="customInput"
                                                            onChange={(e) => {
                                                                selectedUpSelling.name = this.Capitalize(e.target.value)
                                                                this.setState({ selectedUpSelling })
                                                            }} />
                                                    </Colxx>
                                                    <Colxx xxs="6" md="3" className="mb-4">
                                                        <div className="mb-2" >Arabic Name</div>
                                                        <Input
                                                            type="text"
                                                            autoCapitalize="characters"
                                                            placeholder="e.g. Arabic Name"
                                                            value={selectedUpSelling.name_ar}
                                                            className="customInput"
                                                            onChange={(e) => {
                                                                selectedUpSelling.name_ar = e.target.value
                                                                this.setState({ selectedUpSelling })
                                                            }} />
                                                    </Colxx>

                                                </Row>
                                                <Row>
                                                    <Colxx xxs="12" md="6" className="mb-4">
                                                        <div className="mb-2" >Select Type</div>
                                                        <Select
                                                            components={{ Input: CustomSelectInput }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            name="form-field-name"
                                                            value={selectedUpSelling.type ? {
                                                                key: selectedUpSelling.type.toLowerCase(),
                                                                label: selectedUpSelling.type,
                                                                value: selectedUpSelling.type
                                                            } : ''}
                                                            onChange={(e) => {
                                                                selectedUpSelling.type = e.label
                                                                selectedUpSelling.price = e.label === "Product" ? "" : {}
                                                                this.setState({ selectedUpSelling })
                                                            }}
                                                            options={['Product', "Service"].map(rec => { return { key: rec.toLowerCase(), label: rec, value: rec } })}
                                                        />


                                                    </Colxx>
                                                </Row>
                                                {selectedUpSelling.type === "Product" ? <Row>
                                                    <Colxx xxs="12" md="6" className="mb-4">
                                                        <div className="mb-2" >Price (AED)</div>
                                                        <Input
                                                            type="number"
                                                            autoCapitalize="characters"
                                                            placeholder="e.g. 50"
                                                            value={selectedUpSelling.price}
                                                            className="customInput"
                                                            onChange={(e) => {
                                                                selectedUpSelling.price = e.target.value
                                                                this.setState({ selectedUpSelling })
                                                            }} />
                                                    </Colxx>

                                                </Row> :
                                                    <Row>
                                                        <Colxx xxs="12" md="6" className="mb-4">
                                                            <div className="mb-2" >Modifiers</div>
                                                            <Select
                                                                components={{ Input: CustomSelectInput }}
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                styles={{ width: '50%' }}
                                                                placeholder="Add Modifiers"
                                                                name="form-field-name"
                                                                value=""
                                                                options={Object.values(modifiers).filter(rec => !selectedUpSelling.price[rec.key])}
                                                                onChange={(e) => {
                                                                    selectedUpSelling.price = selectedUpSelling.price ? selectedUpSelling.price : {}
                                                                    selectedUpSelling.price[e.key] = { name: e.name, key: e.key }
                                                                    this.setState({ selectedUpSelling })
                                                                }}
                                                            />
                                                            {
                                                                selectedUpSelling.price && Object.values(selectedUpSelling.price).map((item, index) => {
                                                                    return (
                                                                        <div key={index} className="timingsParent mt-2">
                                                                            <Row>
                                                                                <Colxx xxs="6" md="3" >
                                                                                    <Label className="dayParent">{item.name}</Label>
                                                                                </Colxx>
                                                                                <Colxx xxs="4" >
                                                                                    <Input className="customInput"
                                                                                        value={selectedUpSelling.price && selectedUpSelling.price[item.key] ? selectedUpSelling.price[item.key].price : ''}
                                                                                        placeholder="e.g. 10 AED" type="number" onChange={(e) => this.handleServicePriceChange(e, item, 'price')} />
                                                                                </Colxx>
                                                                                <Colxx xxs="4" >
                                                                                    <Select
                                                                                        components={{ Input: CustomSelectInput }}
                                                                                        className="react-select"
                                                                                        classNamePrefix="react-select"
                                                                                        name="form-field-name"
                                                                                        value={selectedUpSelling.price && selectedUpSelling.price[item.key] && selectedUpSelling.price[item.key].duration ? { value: selectedUpSelling.price[item.key].duration, label: selectedUpSelling.price[item.key].duration, key: index } : ''}
                                                                                        placeholder="e.g. 00:05"
                                                                                        onChange={(e) => this.handleServicePriceChange(e, item, 'duration')}
                                                                                        options={options}
                                                                                    />
                                                                                </Colxx>
                                                                                <Colxx xxs="1" className="mt-3" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    delete selectedUpSelling.price[item.key]
                                                                                    this.setState({ selectedUpSelling })
                                                                                }} >
                                                                                    <i className="simple-icon-close" />
                                                                                </Colxx>
                                                                            </Row>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Colxx>
                                                    </Row>
                                                }
                                                {(accessTypes.includes(authUser.user.accessType)) && <Row>
                                                    <Colxx xxs="12" md="6" className="mb-4">
                                                        <div className="mb-2" >Select Service Provider</div>
                                                        <Select
                                                            components={{ Input: CustomSelectInput }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            name="form-field-name"
                                                            value={providers[selectedUpSelling.sp_id]}
                                                            onChange={(e) => {
                                                                selectedUpSelling.sp_id = e.key

                                                                this.setState({ selectedProvider: e, selectedUpSelling })
                                                            }}
                                                            options={Object.values(providers)}
                                                        />


                                                    </Colxx>

                                                </Row>}


                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                </TabContent>

                                <Button
                                    color="primary"
                                    className="btn-shadow float-right mt-4"
                                    size="lg"
                                    disabled={!selectedUpSelling?.name || (addNewUpSelling ? !pictures?.length : !selectedUpSelling?.img?.length && !pictures?.length)}
                                    onClick={() => this.setState({ isLoading: true }, this.submit)}>
                                    Save Changes
                                </Button>
                                <Button
                                    color="danger"
                                    className="btn-shadow float-right m-4"
                                    size="lg"
                                    onClick={() => onClickItem(null)}>
                                    Dismiss
                                </Button>

                            </Colxx>
                        </Row>
                    </Fragment >
                ))
        )
    }
    handleServicePriceChange = (e, modifier, type) => {
        let { selectedUpSelling } = this.state

        switch (type) {
            case 'price':
                if (selectedUpSelling.price[modifier.key]) {
                    selectedUpSelling.price[modifier.key].price = e.target.value
                } else {
                    selectedUpSelling.price[modifier.key] = { price: e.target.value }
                }
                this.setState({ selectedUpSelling });

                break;
            case 'duration':
                if (selectedUpSelling.price[modifier.key]) {
                    selectedUpSelling.price[modifier.key].duration = e.value
                } else {
                    selectedUpSelling.price[modifier.key] = { duration: e.value }
                }
                this.setState({ selectedUpSelling });

                break;
            default:
                break;
        }

    };
}


export default (UpSelling);
