import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import orderHistory from "./orderHistory.js";
import customers from "./customers.js";
import './crmstyles.css'
const Users = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/crm`} />
      <Route path={`${match.url}/customers`} component={customers} />
      <Route path={`${match.url}/orders`} component={orderHistory} />
      <Redirect to="/error" />
    </Switch>
  </div>
);

export default Users;
