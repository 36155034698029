import React, { Component, Fragment } from "react";
import {
    Row,
    Button,
    Collapse,
    Input,
    Label,
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";
import Script from 'react-load-script';
import { NotificationManager } from "../../../../components/common/react-notifications";

import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import { firestore } from '../../../../helpers/Firebase'

import axios from 'axios'
import Map from './map'
import { createNotification, getPlaceId, makeid } from "../../../../helpers/Utils";
import { getCenter } from "geolib";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

class Start extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collaps: {},
            openVideo: false,
            list: null,
            search: '',
            loading: false

        };
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);

    }

    componentWillReceiveProps(prop) {
        if (prop.washer !== this.props.washer) {
            this.getList(prop.washer)
        }
    }
    componentWillMount() {
        let { washer } = this.props
        if (washer && washer.key) {
            this.getList(washer)
        }
    }
    getList(washer) {

        let key = washer.key

        firestore().collection('washers').doc(key).collection('locations').get().then(results => {
            let locations = {}
            results.forEach(list => {

                let data = list.data()
                data.id = list.id
                locations[list.id] = data
            })
            this.setState({ locations })

        })
    }


    searchId(object, id) {
        for (const key in object) {
            if (object?.[key]?.hasOwnProperty('areas_list')) {
                for (let i = 0; i < object?.[key]?.areas_list?.length; i++) {
                    if (object?.[key]?.areas_list?.[i]?.id === id) {
                        return object[key].areas_list[i];
                    }
                }
            }
        }
        return null;
    }
    render() {
        let { locations, list, selectedArea } = this.state
        return (
            <Fragment>
                <Row className="survey-app">
                    <Colxx xxs="12">
                        <div className="mb-2">
                            <h1>
                                Locations
                            </h1>
                            <div className="text-zero top-right-button-container">
                                <Button
                                    color="primary"
                                    size="lg"
                                    className="top-right-button"
                                    onClick={() => this.setState({ list: {} })}>Add Areas
                                </Button>
                            </div>

                        </div>
                        <Separator className="mb-5" />
                        <Row>
                            <Colxx xxs="12" className="mb-3">
                                {
                                    locations && Object.keys(locations).map(key => {
                                        let item = locations[key]

                                        return (
                                            <div key={key} className='rounded mb-4' style={{ boxShadow: 'none' }}>
                                                <div className='d-flex justify-content-between m-4'>
                                                    <h3 >
                                                        {item.time.value + ' ' + item.time.label + " Serving Areas"}
                                                    </h3>
                                                    <div>
                                                        <Button style={{ margin: '4px' }} outline color='danger' onClick={() => this.setState({ confirmDelete: key })} size='xs'>Delete</Button>

                                                        <Button outline color='dark' onClick={() => this.setState({ list: JSON.parse(JSON.stringify(item)) })} size='xs'>Edit</Button>
                                                    </div>
                                                </div>
                                                {
                                                    item.areas && Object.keys(item.areas).map(areaTitle => {
                                                        let areas = item.areas[areaTitle].list ? item.areas[areaTitle].list : item.areas[areaTitle]
                                                        return (
                                                            <div key={areaTitle} className="mb-2">
                                                                <Row className='ml-5'>
                                                                    <h4>{areaTitle}</h4>
                                                                </Row>
                                                                <Collapse isOpen={true} className='ml-5'>
                                                                    {
                                                                        areas.map((area, i) => {
                                                                            return (
                                                                                <Row key={i} className='ml-2 mb-2 d-flex align-items-center'>
                                                                                    <i className="simple-icon-location-pin  mr-1" />
                                                                                    <div className="mr-2">{area.name ? area.name : area}</div>
                                                                                    {area?.place_id && <Button outline color='primary' onClick={async () => {
                                                                                        let mapref = await firestore().collection('servingLocations').doc(area?.place_id).get()
                                                                                        if (mapref.exists) {
                                                                                            this.setState({ selectedArea: { ...area, pathCoordinates: mapref.data()?.geojson } })
                                                                                        } else {

                                                                                        }
                                                                                    }} size='xs'>Show On Map</Button>}
                                                                                </Row>
                                                                            )
                                                                        })
                                                                    }

                                                                </Collapse>
                                                            </div>

                                                        )
                                                    })
                                                }
                                                <Separator />
                                            </div>
                                        )
                                    })
                                }
                            </Colxx>
                            {list && this.renderAddNew()}
                        </Row>
                        {
                            this.state.confirmDelete && <Modal isOpen={true}>
                                <ModalBody>Are you sure you want to delete this area group?</ModalBody>
                                <ModalFooter>
                                    <Button color='danger' onClick={() => this.setState({ confirmDelete: false })}>Cancel</Button>
                                    <Button onClick={() => this.deleteLoaction(this.state.confirmDelete)}>Confirm</Button>
                                </ModalFooter>

                            </Modal>
                        }
                    </Colxx>
                </Row>
                {selectedArea && <Map selectedArea={selectedArea}
                    dismiss={() => this.setState({ selectedArea: false })}
                    addLocation={(newCoordinates) => this.addLocation({
                        ...selectedArea.newLocation,
                        data: {
                            ...selectedArea.newLocation?.data,
                            geojson: {
                                ...selectedArea.newLocation?.data?.geojson,
                                coordinates: newCoordinates ? [newCoordinates] : selectedArea.newLocation?.data?.geojson?.coordinates,
                                type: newCoordinates ? 'Polygon' : selectedArea.newLocation?.data?.geojson?.type
                            }
                        }
                    })} />}
            </Fragment>
        )
    }
    deleteLoaction(locationsKey) {
        let { locations } = this.state
        let { washer } = this.props

        let key = washer.key

        firestore().collection('washers').doc(key).collection('locations').doc(locationsKey).delete().then(() => {
            let deletedlocations = locations?.[locationsKey]?.areas_list?.map(area => area.id);;
            if (deletedlocations?.length) {
                deletedlocations.map(async (r) => {
                    await firestore().collection('servingLocations').doc(r).update({ workers: firestore.FieldValue.arrayRemove(key), })
                })
            }
            delete locations[locationsKey]
            this.setState({ locations, confirmDelete: false }, () => {

                NotificationManager.success(
                    "",
                    "Area group deleted successfully",
                    4000,
                    null,
                    null,
                )
            })
        })
    }
    renderAddNew() {
        let { list, collaps } = this.state

        return (
            <Modal wrapClassName="modal-right"
                backdrop="static" isOpen={true}>
                <ModalHeader>
                    Add new Areas
                </ModalHeader>
                <ModalBody>
                    <div className='mb-4'>
                        <Label>
                            {"Select areas you serve"}
                        </Label>
                        <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCExglJjHXgXzSGHJB1NmLll7NgjOG8_bc&libraries=places&language=en"
                            onLoad={this.handleScriptLoad}
                        />
                        <Input
                            id='autocomplete'
                            type="text"
                            value={this.state.search}
                            onChange={event => {
                                this.setState({ search: [event.target.value] });
                            }}

                        />
                    </div>

                    {
                        list && list.areas && Object.keys(list.areas).map(areaTitle => {
                            return (
                                <div id={areaTitle} key={areaTitle}>
                                    <Card >
                                        <Row className='p-3 ' onClick={() => this.setState({ collaps: { [areaTitle]: !collaps[areaTitle] } })}>
                                            <span className=" badge badge-pill handle mr-1">
                                                <i className={collaps[areaTitle] ? "simple-icon-arrow-down" : "simple-icon-arrow-right"} />
                                            </span>
                                            <div>{areaTitle}</div>


                                        </Row>
                                        <Collapse isOpen={collaps[areaTitle]}>
                                            {
                                                (list.areas[areaTitle].list ? list.areas[areaTitle].list : list.areas[areaTitle]).map((area, index) => {
                                                    return (
                                                        <div id={index} key={index} className='pl-3 pr-3'>
                                                            <Row className='justify-content-between pl-5 pr-2 pb-1'>

                                                                {area.name ? area.name : area}

                                                                <span className=" badge badge-pill mr-1 ml-3" >
                                                                    <i className="simple-icon-trash mr-2"
                                                                        onClick={() => {
                                                                            let deletedlocations = list?.deletedlocations || []
                                                                            deletedlocations.push(area.place_id);

                                                                            let areas_list = list?.areas_list?.filter(r => area.place_id !== r.id);
                                                                            let areas = list?.areas || {};

                                                                            const newlist = areas[areaTitle].list || areas[areaTitle];
                                                                            newlist.splice(index, 1);
                                                                            if (!newlist.length) {
                                                                                delete areas[areaTitle];
                                                                            }
                                                                            this.setState({ list: { ...list, deletedlocations, areas_list, areas } })
                                                                        }} />
                                                                    {area?.place_id && <i className="simple-icon-eye" onClick={async () => {
                                                                        let mapref = await firestore().collection('servingLocations').doc(area?.place_id).get()
                                                                        if (mapref.exists) {
                                                                            this.setState({ selectedArea: { ...area, pathCoordinates: mapref.data()?.geojson } })
                                                                        } else {
                                                                            let coordinates;
                                                                            let geojson = list.allLocations?.[area?.place_id]?.geojson
                                                                            if (geojson?.type === "MultiPolygon") {
                                                                                coordinates = {}
                                                                                geojson && geojson.coordinates && geojson.coordinates.map(recc => {
                                                                                    let polys = recc?.[0]?.reduce((val, currnetVal) => {
                                                                                        let latitude = currnetVal?.[1]
                                                                                        let longitude = currnetVal?.[0]
                                                                                        val.push({ latitude, longitude })
                                                                                        return val
                                                                                    }, [])
                                                                                    return coordinates[makeid()] = polys
                                                                                })
                                                                            } else {
                                                                                coordinates = geojson?.coordinates?.[0]?.reduce((val, currnetVal) => {
                                                                                    let latitude = currnetVal?.[1]
                                                                                    let longitude = currnetVal?.[0]
                                                                                    val.push({ latitude, longitude })
                                                                                    return val
                                                                                }, [])
                                                                            }

                                                                            this.setState({ selectedArea: { ...area, pathCoordinates: { ...geojson, coordinates } } })
                                                                        }
                                                                    }} />}

                                                                </span>

                                                            </Row>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </Collapse>

                                    </Card>

                                </div>

                            )
                        })
                    }
                    {
                        list && <div className='mt-4'>
                            <Label>Enter estimated arrival time for this group:</Label>
                            <InputGroup className='mb-4'>
                                <Input
                                    placeholder={'10 min'}
                                    type='number'
                                    id='estimatedTime'
                                    value={list.time && list.time.value}
                                    onChange={(e) => {
                                        if (list.time) {
                                            list.time.value = e.target.value
                                            list.time.label = 'min'
                                        }
                                        else {
                                            list.time = { value: e.target.value, label: 'min' }
                                        }
                                        this.setState({ list })
                                    }}
                                />
                                <UncontrolledDropdown addonType="append">
                                    <DropdownToggle caret outline color="primary">
                                        {
                                            list.time && list.time.label ? list.time.label : 'min'
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            ['min'].map(item => {
                                                return (
                                                    <DropdownItem key={item} onClick={() => {
                                                        if (list.time) {
                                                            list.time.label = item
                                                        }
                                                        else {
                                                            list.time = { label: item }
                                                        }
                                                        this.setState({ list })
                                                    }

                                                    }>
                                                        {item}
                                                    </DropdownItem>
                                                )
                                            })
                                        }

                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </InputGroup>

                        </div>
                    }
                    <div className='mt-4'>
                        <Label>Minimum order value:</Label>
                        <Input
                            placeholder={'100 AED'}
                            type='number'
                            id='minOrder'
                            value={list && list.minOrder}
                            onChange={(e) => {
                                list.minOrder = e.target.value
                                this.setState({ list })
                            }}
                        />
                    </div>
                    <div className='mt-4 d-flex align-items-center justify-content-between'>
                        <Label className={'m-0'} >Enable Package wash for this group</Label>
                        <Switch
                            className="custom-switch custom-switch-primary"
                            checked={list?.supportSubscriptions}
                            onChange={switchCheckedPrimary => {
                                list.supportSubscriptions = switchCheckedPrimary
                                this.setState({ list })

                            }}
                        />
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => this.setState({ list: false })}>Cancel</Button>
                    <Button disabled={!list || !list.areas} onClick={() => this.addList()}>Save</Button>
                </ModalFooter>


            </Modal>
        )
    }
    addList() {
        let { list, locations } = this.state
        let { washer } = this.props
        let post = {
            areas_list: list?.areas_list || [],
            areas: list.areas,
            time: list.time ? list.time : {
                label: "min",
                value: "30"
            },
            minOrder: list.minOrder ? list.minOrder : false,
            washer_id: washer.key,
            supportSubscriptions: list?.supportSubscriptions || false
        }
        let key = washer.key
        let locationID = list?.id || firestore().collection('washers').doc(key).collection('locations').doc().id;

        firestore().collection('washers').doc(key).collection('locations').doc(locationID).set(post).then((result) => {
            post.id = locationID
            locations[locationID] = post
            if (list?.deletedlocations?.length) {
                list.deletedlocations.map(async (r) => {
                    await firestore().collection('servingLocations').doc(r).update({ workers: firestore.FieldValue.arrayRemove(key), })
                })
            }
            if (list?.allLocations) {
                Object.keys(list?.allLocations || {}).map(async (r) => {
                    let location = list?.allLocations?.[r]
                    let coordinates;
                    if (location?.geojson?.type === "MultiPolygon") {
                        coordinates = {}
                        location.geojson && location.geojson.coordinates && location.geojson.coordinates.map((recc, index) => {
                            let polys = recc?.[0]?.reduce((val, currnetVal) => {
                                let latitude = currnetVal?.[1]
                                let longitude = currnetVal?.[0]
                                val.push({ latitude, longitude })
                                return val
                            }, [])
                            return coordinates["polygon_" + String(index)] = polys
                        })
                    } else {
                        coordinates = location?.geojson?.coordinates?.[0]?.reduce((val, currnetVal) => {
                            let latitude = currnetVal?.[1]
                            let longitude = currnetVal?.[0]
                            val.push({ latitude, longitude })
                            return val
                        }, [])
                    }

                    let post = {
                        display_name: location?.display_name,
                        geojson: { ...location?.geojson, coordinates },
                        osm_id: location?.osm_id,
                        osm_type: location?.osm_type,
                        place_id: location?.place_id,
                        type: location?.type,

                    }
                    await firestore().collection('servingLocations').doc(r).set({ ...post, workers: firestore.FieldValue.arrayUnion(key), }, { merge: true })
                })
            }
            this.setState({ list: false, locations })
        })
    }
    handleScriptLoad() {
        // Declare Options For Autocomplete 
        // var circle = new google.maps.Circle(
        //     { center: { lat: 25.1874756, lng: 55.2529987 }, radius: 20000 });
        var options = {
            types: ['geocode', 'establishment'],
            // types: ['(regions)'],
            componentRestrictions: { country: 'ae' },
            // language: 'en'
        };

        // Initialize Google Autocomplete 
        /*global google*/
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            options);
        // this.autocomplete.setBounds(circle.getBounds());

        // Avoid paying for data that you don't need by restricting the 
        // set of place fields that are returned to just the address
        // components and formatted address
        this.autocomplete.setFields(['address_components',
            'formatted_address', 'geometry', 'type', 'name', 'vicinity', 'place_id']);
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed',
            this.handlePlaceSelect);
    }

    async handlePlaceSelect() {
        const country = localStorage.getItem('country');
        const addressObject = this.autocomplete.getPlace();
        const mapArea = addressObject.name;
        let { formatted_address, name, geometry, types } = addressObject;
        const paramArray = formatted_address?.split(country === 'Egypt' ? ', ' : ' - ');
        const { list } = this.state;
        let data;
        if (types?.includes('airport')) {
            formatted_address = name

        }
        const polygonResults = await axios.get(`https://nominatim.openstreetmap.org/search.php?q=${formatted_address}&polygon_geojson=1&format=json`);
        data = polygonResults?.data?.find(r => ['boundary', 'place', 'aeroway', 'landuse'].includes(r.class) && ['town', 'suburb', 'administrative', 'neighbourhood', 'aerodrome', 'residential'].includes(r.type) && ["Polygon", "MultiPolygon"]?.includes(r?.geojson?.type));

        if (!data) {
            formatted_address = formatted_address?.split(' - ')?.join(' , ');

            const polygonResults = await axios.get(`https://nominatim.openstreetmap.org/search.php?q=${formatted_address}&polygon_geojson=1&format=json`);

            data = polygonResults?.data?.find(r => ['boundary', 'place', 'aeroway', 'landuse'].includes(r.class) && ['town', 'suburb', 'administrative', 'neighbourhood', 'aerodrome', 'residential'].includes(r.type) && ["Polygon", "MultiPolygon"]?.includes(r?.geojson?.type));

            if (!data) {

                const bound = [
                    {
                        latitude: addressObject.geometry.viewport.getNorthEast().lat(),
                        longitude: addressObject.geometry.viewport.getNorthEast().lng(),
                    },
                    {
                        latitude: addressObject.geometry.viewport.getSouthWest().lat(),
                        longitude: addressObject.geometry.viewport.getNorthEast().lng(),
                    },
                    {
                        latitude: addressObject.geometry.viewport.getSouthWest().lat(),
                        longitude: addressObject.geometry.viewport.getSouthWest().lng(),
                    },
                    {
                        latitude: addressObject.geometry.viewport.getNorthEast().lat(),
                        longitude: addressObject.geometry.viewport.getSouthWest().lng(),
                    },
                ];
                const center = getCenter(bound);
                let place_id = await getPlaceId(center.latitude, center.longitude, name)
                if (!place_id) {
                    return createNotification('error', 'Could not get this location', 'Error');
                }
                const polygonResults = await axios.get(`https://nominatim.openstreetmap.org/details.php?place_id=${place_id}&polygon_geojson=1&format=json`);

                data = { ...polygonResults?.data, display_name: polygonResults?.data?.localname, geojson: polygonResults?.data?.geometry }

                if (!data) {
                    return createNotification('error', 'Could not get this location', 'Error');
                }
            }

        }

        const polygon = String(data?.place_id);
        const existingPlace = this.searchId(this.state.locations, polygon);
        if (!["Polygon", "MultiPolygon"]?.includes(data?.geojson?.type)) {
            // return createNotification('error', 'Could not get this location', 'Error');
        }
        if (existingPlace) {
            return createNotification('error', 'This location is already added in another cluster!', 'Error');
        }
        let coordinates;
        let geojson = data?.geojson
        if (geojson?.type === "MultiPolygon") {
            coordinates = {}
            geojson && geojson.coordinates && geojson.coordinates.map(recc => {
                let polys = recc?.[0]?.reduce((val, currnetVal) => {
                    let latitude = currnetVal?.[1]
                    let longitude = currnetVal?.[0]
                    val.push({ latitude, longitude })
                    return val
                }, [])
                return coordinates[makeid()] = polys
            })
        } else if (geojson?.type === 'Polygon') {
            coordinates = geojson?.coordinates?.[0]?.reduce((val, currnetVal) => {
                let latitude = currnetVal?.[1]
                let longitude = currnetVal?.[0]
                val.push({ latitude, longitude })
                return val
            }, [])
        } else {
            coordinates = [{ latitude: geometry?.location?.lat(), longitude: geometry?.location?.lng(), }]
        }



        this.setState({
            list,
            search: '',
            selectedArea: {
                name: mapArea,
                pathCoordinates: { ...geojson, coordinates },
                newLocation: { data, addressObject, paramArray, geometry, polygon, mapArea, list }
            }
        })
    }

    async addLocation(locationData) {
        let _this = this
        let { data, addressObject, paramArray, geometry, polygon, mapArea, list } = locationData
        const polygonTitle = data?.display_name;
        list.areas_list = list.areas_list || [];
        list.areas_list.push({ id: polygon, title: polygonTitle });
        list.allLocations = list?.allLocations || {};
        list.allLocations[polygon] = data;

        let address = addressObject?.address_components.reduce((value, currentValue) => {
            if (currentValue.types.includes("neighborhood")) {
                value.area = currentValue.short_name
            }
            if (!value.area && currentValue.types.includes("sublocality")) {
                value.area = currentValue.short_name
            }
            if (currentValue.types.includes("route")) {
                value.street = currentValue.short_name
            }
            if (currentValue.types.includes("administrative_area_level_1")) {
                value.emirate = currentValue.short_name
            }

            return value
        }, {})
        if (paramArray.length > 1) {
            const areaTitle = paramArray[paramArray.length - 2];
            const res = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    place_id: addressObject.place_id,
                    key: 'AIzaSyCExglJjHXgXzSGHJB1NmLll7NgjOG8_bc',
                    language: 'ar',
                },
            });
            const addressComponents = res.data.results[0].address_components;
            const nameAr = addressComponents?.[0]?.short_name || '';
            const sublocalityAr = addressComponents?.find(item => item?.types?.includes('locality') || item?.types?.includes('administrative_area_level_1'))?.short_name || '';

            const newArea = {
                place_id: polygon,
                name: mapArea,
                name_ar: nameAr,
                latitude: geometry.location.lat(),
                longitude: geometry.location.lng(),
                viewport: {
                    south_west: {
                        lng: addressObject.geometry.viewport.getSouthWest().lng(),
                        lat: addressObject.geometry.viewport.getSouthWest().lat(),
                    },
                    north_East: {
                        lng: addressObject.geometry.viewport.getNorthEast().lng(),
                        lat: addressObject.geometry.viewport.getNorthEast().lat(),
                    },
                },
            };

            if (list.areas && list.areas[areaTitle] && list.areas[areaTitle].list) {
                list.areas[areaTitle].list.push(newArea);
            } else if (list.areas) {
                list.areas[areaTitle] = {
                    list: [newArea],
                    name_ar: sublocalityAr,
                    name: address?.emirate || areaTitle,
                };
            } else {
                list.areas = {
                    [areaTitle]: {
                        list: [newArea],
                        name: address?.emirate || areaTitle,
                        name_ar: sublocalityAr,
                    },
                };
            }
            _this.setState({
                list,
                search: '',
                collaps: { [areaTitle]: true },
                selectedArea: false
            });
        }
    }


}

export default (Start);