import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Label,
    Row, Card, CardBody, ModalHeader
} from "reactstrap";

import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import moment from 'moment'
// import { useSelector } from "react-redux";
import DetailsTable from "../../appointments/components/receipt";
import { firestore, functions } from "../../../../helpers/Firebase";
import { statusObject } from "../../../../constants/defaultValues";
import OrderModal from "../../appointments/components/order";
import { useSelector } from "react-redux";
import { accessTypes } from "../../../../helpers/Utils";

const Subscription = ({ subscription, dismiss, modifiers, washers, update }) => {
    const { user } = useSelector(state => state.authUser)
    const { provider, bookings } = useSelector(state => state.providers)
    const [loading, setLoading] = useState(false)
    const [rejectModal, setReject] = useState(false)
    const [orders, setOrders] = useState([])
    const [selectedOrder, setSelectedOrder] = useState(false)
    useEffect(() => {
        async function fetchData() {
            firestore().collection('bookings').where('subscription', '==', subscription?.id).onSnapshot(snap => {
                let docs = snap.docs.map(res => {
                    return {
                        ...res.data(),
                        key: res.id,
                        bookingId: res.id,
                    }
                })
                setOrders(docs)
            })
        }
        fetchData();

    }, [subscription])
    return (


        <Modal
            isOpen={subscription}
            // toggle={dismiss}
            backdrop="static"
            size="lg">
            {loading && (
                <div className="loading" />
            )}
            <div>

                <Row className="invoice-react">
                    <Colxx xxs="12" >
                        <ModalHeader toggle={dismiss}>
                            <div className="d-flex flex-column justify-flex-start" style={{ alignItems: 'flex-start' }} >
                                {/* <div className='badge badge-pill mb-2' style={{ backgroundColor: status[subscription.status]?.color, color: 'white', borderRadius: '4px' }} >{status[subscription.status]?.label}</div> */}
                                <h6 className="font-weight-bold m-0 mb-2">Order ID: {subscription?.orderNumber}</h6>
                            </div>

                        </ModalHeader>
                        <Card className="invoice-contents" style={{ height: 'auto', background: 'transparent', boxShadow: 'none' }}>
                            <CardBody className="d-flex flex-column justify-content-between">
                                <div className="d-flex flex-column">
                                    {[
                                        {
                                            key: 'contactInfo', title: 'Contact Information',
                                            data: [{ title: 'Customer Name', width: '6', data: subscription?.customer_name },
                                            { title: 'Payment Method', width: '6', data: subscription?.paymentMethod?.type },
                                            { title: 'Customer Number', width: '12', data: subscription?.customer_number, hide: !accessTypes.includes(user?.accessType) },
                                            { title: 'Customer Address', width: '12', data: subscription?.place?.address },
                                            ]
                                        },
                                        {
                                            key: 'vehicleInfo', title: `Vehicles Details - ${subscription?.carDetail?.length + " vehicle(s)"}`,
                                            data: subscription?.carDetail || []
                                        },
                                        {
                                            key: 'addressInfo', title: 'Subscription Details',

                                            data: [
                                                { title: 'Subscription ID', width: '4', data: subscription?.orderNumber },
                                                { title: 'Subscription Status', width: '4', color: statusObject?.[subscription.status]?.color, data: statusObject?.[subscription.status]?.title },
                                                { title: 'Created at', width: '4', color: '#3498db', data: moment(subscription?.created).format('DD/MM/YYYY hh:mm A') },
                                                { title: 'Service', width: '4', data: subscription?.service_name },
                                                { title: 'Selected Package', width: '4', data: subscription?.selectedPackage?.name },
                                                { title: 'Number of washes', width: '4', data: subscription?.selectedPackage?.washesCount },
                                                { title: 'Service Provider', width: '4', data: subscription?.sp_name },
                                                { title: 'Worker', width: '4', data: subscription?.washer_name },
                                                { title: 'Auto Renewal Status', width: '4', data: subscription?.autoRenewal ? 'Enabled' : 'Disabled' },
                                            ]
                                        },
                                        {
                                            key: 'ordersInfo', title: `Subscription Schedule`,
                                            data: orders || []
                                        },
                                    ].map((item, recIndex) => {
                                        return (
                                            <div key={recIndex}>
                                                <Row>
                                                    <Colxx xxs="12" className="mb-2">
                                                        <h6 style={{ fontWeight: 'bold' }}>{item.title}</h6>
                                                    </Colxx>
                                                    {item.key === 'ordersInfo' ? item?.data?.sort((a, b) => a.bookingDate - b.bookingDate)?.map((order, index) => {
                                                        return (
                                                            <Colxx md="12" key={index} className='mb-4' >
                                                                <Card className={'rounded'}>
                                                                    <CardBody className="d-flex align-items-center justify-content-between p-3">
                                                                        <div className="font-weight-bold">{moment(order.bookingDate).format('DD/MM/YYYY')}<br />{moment(order.bookingDate).format('hh:mm A')}</div>
                                                                        {/* <div>{order.sp_name}</div> */}
                                                                        <div className="font-weight-bold">{order.customer_name}</div>
                                                                        <div className="font-weight-bold">{order.service_name}</div>
                                                                        {/* <div>{order.washer_name}</div> */}
                                                                        <div className="font-weight-bold" style={{ color: statusObject?.[order.status]?.color }}>{statusObject?.[order.status]?.title}</div>
                                                                        <Button
                                                                            style={{ padding: '0.2rem 1rem' }}
                                                                            color="primary"
                                                                            size="sm"
                                                                            outline
                                                                            // className="top-right-button"
                                                                            onClick={() => { setSelectedOrder(order) }}
                                                                        >View
                                                                        </Button>
                                                                    </CardBody>
                                                                </Card>
                                                            </Colxx>
                                                        )

                                                    }) :
                                                        item.key === 'vehicleInfo' ? item?.data?.map((car, index) => {
                                                            return (
                                                                <Colxx md="6" key={index} className='mb-4' >
                                                                    <div style={{ padding: '8px', border: '1px solid #cacaca', borderRadius: '4px', margin: '4px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                        <div>{car?.car_model}{" "}{car.modelNumber?.model}{" "}{car.modelNumber?.category}</div>
                                                                        <div>{car.plateNumber}</div>
                                                                    </div>
                                                                </Colxx>
                                                            )

                                                        }) :

                                                            item.data.map((rec, index) => {
                                                                return !rec.hide && (
                                                                    <Colxx xxs="12" md={rec.width} key={index}>
                                                                        <Label className="mb-0  text-semi-muted">{rec.title}:</Label>
                                                                        <p className="font-weight-bold" id={rec.id} style={{ color: rec?.color }}>{rec.data ? rec?.data : '--'}</p>
                                                                    </Colxx>
                                                                )
                                                            })}
                                                </Row>
                                                <Separator className="pt-1 mb-5" />

                                            </div>

                                        )
                                    })}
                                    {[

                                        { title: 'Details', width: '12', id: 'services', },
                                    ].map((rec, recIndex) => {
                                        return !rec.hide && (
                                            <div key={recIndex}>
                                                <h6 style={{ fontWeight: 'bold' }}>{rec.title}</h6>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <DetailsTable
                                                        modifiers={modifiers}
                                                        carDetail={subscription?.carDetail}
                                                        selectedBooking={subscription}
                                                        washer={Object.values(washers).find((record) => record?.key === subscription?.washer_id)}
                                                        service={subscription?.service_id}
                                                        price={subscription?.price}
                                                        fromSubscription={true}
                                                    />


                                                </div>

                                            </div>

                                        )
                                    })}
                                </div>

                            </CardBody>
                        </Card>
                        <ModalFooter>

                            {!['canceled'].includes(subscription?.status) && <Button className='m-1 float-right'
                                color="danger"
                                onClick={() => {
                                    setReject(true)

                                }}
                            >
                                Cancel Subscription
                            </Button>}
                            <Button className='m-1 float-right'
                                color="primary"
                                onClick={() => {
                                    dismiss()
                                }}
                            >
                                Dismiss
                            </Button>
                        </ModalFooter>
                    </Colxx>
                </Row>

            </div>
            {selectedOrder && <OrderModal
                providers={provider}
                bookings={bookings}
                // activeServices={activeServices}
                activeServices={[]}
                washers={washers}
                toggleModal={() => {
                    setSelectedOrder(false)
                }}
                dismiss={() => {
                    setSelectedOrder(false)
                }}
                modalOpen={selectedOrder}
                selectedBooking={selectedOrder}
                editBooking={true}
                update={() => {
                    setSelectedOrder(false)
                    update()
                }}
            />}
            <Modal
                isOpen={rejectModal}
                backdrop="static">
                <ModalHeader >{<div><span style={{
                    fontWeight: '700',
                    color: '#dc3545'
                }}>Cancel Subscription</span> </div>}</ModalHeader>
                <ModalBody>
                    Are you sure you want to cancel this subscription?
                    {rejectModal?.msg}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={() => setReject(false)}>
                        Dismiss
                    </Button>
                    <Button disabled={loading} color="danger" outline onClick={async () => {
                        setLoading(true)

                        let result = await functions().httpsCallable('CancelSubscription')({ subscription: subscription?.id, uid: subscription?.customer_id });
                        if (result) {
                            setLoading(false)
                            setReject(false)
                            update()
                        }
                    }} >Cancel Subscription</Button>
                </ModalFooter>
            </Modal>



        </Modal >
    );
};

export default Subscription;