import React, { Component, Fragment } from "react";
import { Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import UpSelling from "./upSelling";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { firestore } from '../../../helpers/Firebase'
import Switch from "rc-switch";
import { accessTypes } from "../../../helpers/Utils";

const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}

class UpSeLlingList extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require('mousetrap');
    this.state = {
      displayMode: "thumblist",
      totalPage: 1,
      search: "",
      isLoading: false,
      selectUpSelling: false,
      selectedUpSelling: {},
      filteredUpSellings: {},
      addNewUpSelling: false,
      deleteUpSelling: false,
      modifiers: {},

    };
  }


  dataTableColumns() {
    let _this = this
    let { up_selling, providers, authUser } = this.props
    return ([
      {
        accessor: "active",
        width: 100,
        Cell: props => <p className="list-item-heading">
          <Switch
            className="custom-switch custom-switch-primary"
            checked={props.value}
            onChange={switchCheckedPrimary => {
              up_selling[props.original.key].active = switchCheckedPrimary
              this.setState({ isLoading: true })
              firestore().collection('up_selling').doc(props.original.key).update({ active: switchCheckedPrimary, updated: Date.now() }).then(() => {
                _this.getUpsellingsList()
              })
            }}
          />
        </p>
      },

      {
        width: 150,
        Header: "Name",
        accessor: "name",
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        width: 150,
        Header: "Arabic Name",
        accessor: "name_ar",
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Service Provider",
        accessor: "sp_id",
        width: 150,
        show: accessTypes.includes(authUser.user.accessType),
        Cell: props => <p className="providerfieldText">{providers && providers[props.value] && providers[props.value].name}</p>
      },
      {
        Header: "Type",
        accessor: "type",
        width: 150,
        Cell: props => <p className="providerfieldText">{props.value ? props.value : "Product"}</p>
      },

      {
        Header: "Price",
        accessor: "price",
        Cell: props => <div className="providerfieldText">{props.original.type ?
          (
            props.original.type === "Product" ?
              props.value + " AED" :
              Object.keys(props.value).map((modifier, index) => {
                return (
                  <div key={index}>
                    {props.value[modifier]?.name + " ( Price: " + props.value[modifier].price + " AED , Duration: " + props.value[modifier].duration + " )"}
                    <br />
                  </div>)
              })
          )
          : props.value + " AED"}</div>
      },

      {
        width: 80,
        accessor: "delete",
        show:(authUser?.user?.accessType === 'superAdmin')&& deviceWidth() === 'desktop' ? true : false,
        
        Cell: props => <p className="providerfieldText">
          <Button
            color="danger"
            size="sm"
            outline
            onClick={() => this.setState({ deleteUpSelling: true, selectedUpSelling: props.original })}
          >
            Delete
          </Button>
        </p>
      },
      {
        width: 70,
        accessor: "edit",
        Cell: props => <p className="providerfieldText">
          <Button
            color="primary"
            size="sm"
            outline
            className="top-right-button"
            onClick={() => this.onClickItem(props.original)}
          >
            Edit
          </Button>
        </p>
      }
    ])
  }
  deleteUpSelling(upSelling) {
    let _this = this
    firestore().collection('up_selling').doc(upSelling.key).delete().then(() => {
      _this.getUpsellingsList()
    })
  }



  toggleModal = () => {
    let { selectUpSelling } = this.state
    this.setState({ selectUpSelling: !selectUpSelling, selectedUpSelling: {}, addNewUpSelling: true })
  };



  onClickItem = (upSelling) => {
    let { selectUpSelling } = this.state

    if (upSelling) {
      this.setState({ selectUpSelling: !selectUpSelling, selectedUpSelling: upSelling, addNewUpSelling: false })
    } else {
      this.setState({ selectUpSelling: !selectUpSelling, selectedUpSelling: {}, addNewUpSelling: false })
    }
  };
  getUpsellingsList() {
    let { up_selling } = this.props
    let { filteredUpSellings, search, selectedProvider } = this.state
    let _this = this

    // filteredUpSellings = up_selling && Object.values(up_selling)

    filteredUpSellings = up_selling && Object.values(up_selling).filter(item => {
      return item.name && (item.name).toLowerCase().includes(search.toLowerCase()) && (selectedProvider ? item.sp_id === selectedProvider?.key : true)
    })

    _this.setState({ filteredUpSellings, deleteUpSelling: false, selectedUpSelling: {}, isLoading: false, selectUpSelling: false })
  }
  componentDidUpdate(previousProps) {
    let { up_selling } = this.props

    if (previousProps.up_selling !== up_selling) {
      this.getUpsellingsList()
    }
  }
  componentWillMount() {
    let { modifiers } = this.state
    let _this = this

    firestore().collection('modifiers').get().then(function (snapshot) {
      snapshot.forEach(function (doc) {
        let modifier = {}
        modifier = doc.data()
        modifier.key = doc.id
        modifier.price = '100'
        modifier.duration = '00:15'
        modifier.value = doc.data().name
        modifier.label = doc.data().name
        modifiers[doc.id] = modifier;
      });
      _this.setState({ modifiers: modifiers, isLoading: false })
    });
    this.getUpsellingsList()
  }
  selectProvider = (provider) => {
    let { up_selling } = this.props
    let { filteredUpSellings } = this.state

    if (provider) {
      let id = provider.key
      filteredUpSellings = Object.values(up_selling).filter(item => {
        return item.sp_id && (item.sp_id === id)
      })
      this.setState({ filteredUpSellings, selectedProvider: provider });

    } else {
      this.setState({ filteredUpSellings: up_selling, selectedProvider: false });
    }

  };

  onSearchKey = e => {
    let { up_selling } = this.props

    let { filteredUpSellings } = this.state
    let search = e.target.value.toLowerCase()
    filteredUpSellings = Object.values(up_selling).filter(item => {
      return item.name && (item.name).toLowerCase().includes(search.toLowerCase())
    })
    this.setState({ filteredUpSellings, search }
    );
  };

  render() {
    let { authUser, up_selling, providers } = this.props

    let {
      selectUpSelling,
      selectedUpSelling,
      addNewUpSelling,
      deleteUpSelling,
      filteredUpSellings,
      modifiers
    } = this.state;

    return (selectUpSelling ?
      <UpSelling
        modifiers={modifiers}
        authUser={authUser}
        providers={providers}
        selectedUpSelling={selectedUpSelling}
        onClickItem={this.onClickItem}
        addNewUpSelling={addNewUpSelling}
        up_selling={up_selling}
        updateUpSelling={() => this.getUpsellingsList()}

      />
      :
      <Fragment>
        {!up_selling && (
          <div className="loading" />
        )
        }

        <div className="disable-text-selection">
          <ListPageHeading
            heading="Up Sellings"
            onSearchKey={this.onSearchKey}
            toggleModal={this.toggleModal}
            authUser={authUser}
            washerList={true}
            providers={providers || {}}
            selectProvider={this.selectProvider}

          />

          <Row className="survey-app">
            <Colxx xxs="12">

              {
                filteredUpSellings && Object.keys(filteredUpSellings).length ?
                  <ReactTable
                    data={Object.values(filteredUpSellings)}
                    columns={this.dataTableColumns()}
                    defaultPageSize={10}
                    pageSize={Object.keys(filteredUpSellings).length < 10 ? Object.keys(filteredUpSellings).length : 10}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    PaginationComponent={DataTablePagination}
                    className='custuomReactTable'
                  />
                  :
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Items</div>
              }
            </Colxx>
          </Row>

          <Modal backdrop="static" isOpen={deleteUpSelling}>
            <ModalHeader >
              Delete Item
            </ModalHeader>
            <ModalBody>
              <Row>
                <Colxx xxs="12" className="mb-4">
                  <Label className="mb-2">Are you sure you want to delete this item?</Label>
                </Colxx>
              </Row>

            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.setState({ deleteUpSelling: false, selectedUpSelling: {} })}>Dismiss</Button>
              <Button color='danger' onClick={() => this.setState({ isLoading: true }, () => { this.deleteUpSelling(selectedUpSelling) })}>Delete UpSelling</Button>
            </ModalFooter>

          </Modal>
        </div>
      </Fragment>
    )
  }
}


const mapStateToProps = ({ providers, authUser }) => {
  return {
    authUser,
    up_selling: providers ? providers.up_selling : {},
    providers: providers ? providers.provider : {},
  };
};



export default injectIntl(connect(mapStateToProps)(UpSeLlingList));
