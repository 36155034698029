import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { Card, Row, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Button, Table, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import ReactTable from "react-table";
import { Colxx } from '../../../../components/common/CustomBootstrap';
import Switch from "rc-switch";


const CustomSelectInput = props => {
    var customProps = Object.assign({}, props);
    delete customProps.autoCorrect;
    delete customProps.autoCapitalize;
    return <components.Input {...customProps} />;
};
const deviceWidth = () => {
    const width = window.innerWidth;
    const breakpoint = 820;
    return width < breakpoint ? 'mobile' : 'desktop';
}
const Subscriptions = ({
    selectedOutlet,
    subscriptions,
    services,
    update }) => {


    const [service, setService] = useState(false)

    const packages = {
        package1: { index: 1, name: 'Once per week', key: 'package1', washesCount: 4, active: true },
        package2: { index: 2, name: 'Twice per week', key: 'package2', washesCount: 8, active: true },
        package3: { index: 3, name: '3 times per week', key: 'package3', washesCount: 12, active: true }
    }

    let activeServicesColumns = () => {
        return ([

            {
                width: deviceWidth() === 'desktop' ? 150 : 100,
                Header: "Name",
                accessor: "name",
                Cell: props => <p className="washerfieldText">{props.value}</p>
            },


            {
                Header: "Packages",
                accessor: "packages",
                show: deviceWidth() === 'desktop' ? true : false,

                Cell: props => <p className="washerfieldText">{Object.keys(props.value || {}).sort((a, b) => packages?.[a]?.index - packages?.[b]?.index)?.map(c => {
                    return (<div>{`${packages?.[c]?.name}: ${props.value?.[c]?.discount}%`}</div>)
                })}</p>
            },

            {
                width: deviceWidth() === 'desktop' ? 95 : 90,
                accessor: "edit",
                Cell: props => <div className="washerfieldText">
                    <Button
                        color="danger"
                        size="sm"
                        outline
                        className="top-right-button"
                        onClick={() => {
                            subscriptions[props.original.key] = { ...subscriptions[props.original.key], deleted: true };

                            update(subscriptions);
                        }}>
                        Remove
                    </Button>
                </div>
            },
            {
                width: deviceWidth() === 'desktop' ? 70 : 75,
                accessor: "edit",
                Cell: props => <div className="washerfieldText">
                    <Button
                        color="primary"
                        size="sm"
                        outline
                        className="top-right-button"
                        onClick={() => {
                            setService(props.original)
                        }}>
                        Edit
                    </Button>
                </div>
            }
        ])
    }
    let UpdateService = () => {
        subscriptions[service.key] = {
            sp_id: selectedOutlet?.key,
            packages: service?.packages
        }
        setService(false)
        update(subscriptions)
    }

    return (
        <Row className='h-100'>
            <Colxx xxs="12" md="12" >
                <Card className="rounded h-100">
                    <CardBody>
                        <h4>Subscriptions</h4>
                        <p>Enable subscription for different services</p>
                        <Select
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            classNamePrefix="react-select"
                            styles={{ width: '50%' }}
                            placeholder="Add Services"
                            name="form-field-name"
                            options={services && Object.values(services).filter(item => {
                                return !subscriptions?.[item.key] || subscriptions?.[item.key]?.deleted
                            })}
                            onChange={(e) => {
                                setService(e)
                            }}
                        />
                        {

                            Object.values(subscriptions || {})?.length ? <ReactTable
                                data={Object.keys(subscriptions || {})?.filter(r => !subscriptions[r]?.deleted)?.map((service) => {
                                    return (
                                        {
                                            key: service,
                                            name: services[service]?.name,
                                            packages: subscriptions[service]?.packages,
                                        })
                                })}
                                columns={activeServicesColumns()}
                                defaultPageSize={Object.values(subscriptions || {})?.length}
                                pageSize={Object.values(subscriptions || {})?.length}
                                showPageJump={false}
                                showPageSizeOptions={false}
                                showPagination={false}
                                className='servicesTable'
                            /> :
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px 0' }}>No types selected</div>


                        }
                    </CardBody>
                </Card>
            </Colxx>

            <Modal
                size='lg'
                isOpen={service}
                backdrop="static">
                <ModalHeader >Edit packages for {service?.name}</ModalHeader>
                <ModalBody>
                    <Table borderless>
                        <thead>
                            <tr>
                                <th className="text-semi-muted mb-2" style={{ width: '50px' }} />
                                <th className="text-semi-muted mb-2" style={{ width: '130px' }}>
                                    Package Name
                                </th>
                                <th className="text-semi-muted mb-2">
                                    Total Washes
                                </th>
                                <th className="text-semi-muted mb-2">
                                    Discount Percent{'(%)'}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.values(packages || {}).sort((a, b) => a?.index - b?.index)?.map((item, itemIndex) => {
                                    return (
                                        <tr key={itemIndex}>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <Switch
                                                    className="custom-switch custom-switch-primary"
                                                    checked={service?.packages?.[item.key]?.active}
                                                    onChange={switchCheckedPrimary => {
                                                        setService({ ...service, packages: { ...service.packages, [item.key]: { ...item, ...service?.packages?.[item.key], active: switchCheckedPrimary } } })
                                                    }}
                                                />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                {item.name}
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                {item.washesCount} washes
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>%</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type={'number'}
                                                        placeholder={"e.g. 30%"}
                                                        value={service?.packages?.[item.key]?.discount}
                                                        className="customInput"
                                                        onChange={(e) => {
                                                            if (e.target.value) {
                                                                setService({ ...service, packages: { ...service.packages, [item.key]: { ...item, ...service?.packages?.[item.key], discount: e.target.value, active: true } } })
                                                            } else {
                                                                let packages = service.packages
                                                                delete packages?.[item.key]
                                                                setService({ ...service, packages })
                                                            }
                                                        }} />

                                                </InputGroup>

                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </ModalBody >
                <ModalFooter >
                    <Button color="danger" outline onClick={() => {
                        setService(false)
                    }}>Cancel</Button>
                    <Button color="primary" className="top-right-button" onClick={() =>
                        UpdateService()
                    }>{'Save'}</Button>
                </ModalFooter>
            </Modal >
        </Row >
    );
};

export default Subscriptions;