import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
// import sagas from "./sagas";
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';

// const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk,promiseMiddleware];

export function configureStore(initialState) {

    const store = createStore(
        reducers,
        undefined,
        applyMiddleware(...middlewares)
    );

    // sagaMiddleware.run(sagas);

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
