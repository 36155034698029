import React, { Fragment, useEffect, useState } from 'react';
import ReactTable from 'react-table';
import NewModel from "./components/newModel";
import { useSelector } from 'react-redux';
import { Button, Row } from "reactstrap";
import DataTablePagination from '../../../components/DatatablePagination';
import { Colxx } from '../../../components/common/CustomBootstrap';
import ListPageHeading from '../../../containers/navs/ListPageHeading';
import { firestore } from '../../../helpers/Firebase';

const Models = ({ title, selected }) => {
  const { user } = useSelector(state => state.authUser)

  const [loading, setLoading] = useState(true)
  const [models, setModels] = useState([])
  const [newModel, setNewModel] = useState(false)
  const [filteredModels, setFilteredModels] = useState([])

  useEffect(() => {
    firestore().collection('cars_models').doc(selected).onSnapshot(snapshot => {
      let data = snapshot.data();
      let vehicles = []

      vehicles = Object.keys(data || {})?.map(c => {
        return { key: c, name: c, models: data[c] }
      })

      setModels(vehicles)
      setFilteredModels(vehicles)
      setLoading(false)
    })
  }, [selected])

  const dataTableColumns = () => {
    return ([

      {
        Header: "Brand",
        accessor: "name",
        Cell: props => <p style={{textTransform:'capitalize'}} className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Models",
        accessor: "models",
        Cell: props => <p className="providerfieldText">{(props.value)?.length} models</p>
      },

      {
        width: 70,
        accessor: "edit",
        Cell: props => <p className="providerfieldText">
          <Button
            color="primary"
            size="sm"
            outline
            className="top-right-button"
            onClick={() => {
              setNewModel(props.original)
            }}
          >
            Edit
          </Button>
        </p>
      }
    ])
  }

  const onSearchKey = e => {
    let search = e.target.value.toLowerCase()
    let filteredModels = models.filter(item => {
      return item.name && (item.name).toLowerCase().includes(search.toLowerCase())
    })
    setFilteredModels(filteredModels)

  };

  return (
    <Fragment>
      {loading?.length && (
        <div className="loading" />
      )
      }
      {newModel ? <NewModel selected={selected} selectedBrand={newModel} dismiss={() => setNewModel(false)} /> : ''}
      <div className="disable-text-selection">
        <ListPageHeading
          heading={title}
          onSearchKey={onSearchKey}
          toggleModal={() => {
            setNewModel({ addNew: true })

          }}
          authUser={user}
        />

        <Row className="survey-app">
          <Colxx xxs="12">

            {
              filteredModels && Object.keys(filteredModels).length ?
                <ReactTable
                  data={filteredModels}
                  columns={dataTableColumns()}
                  defaultPageSize={10}
                  pageSize={filteredModels?.length < 10 ? filteredModels?.length : 10}
                  showPageJump={false}
                  showPageSizeOptions={false}
                  PaginationComponent={DataTablePagination}
                  className='custuomReactTable'
                />
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px' }}>No {title}</div>
            }
          </Colxx>
        </Row>


      </div>
    </Fragment>
  );
};

export default Models;