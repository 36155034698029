import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Input, Row } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import { useSelector } from "react-redux";
import { firestore } from "../../../helpers/Firebase";
import moment from "moment";
import { accessTypes } from "../../../helpers/Utils";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import { debounce } from 'lodash'
import Customer from "./components/Customer";



const Customers = () => {
  const { user } = useSelector(state => state.authUser)
  const [allUsers, setAllUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [lastVisible, setLastVisible] = useState(false)
  const [search, setSearch] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedCustomer, setSelectedCustomer] = useState(false)

  const dataTableColumns = () => [
    {
      Header: "Name",
      accessor: "name",
      Cell: props => <p className="list-item-heading">{props.value}</p>
    },
    {
      Header: "Phone Number",
      accessor: "number",
      Cell: props => <p className="list-item-heading">{props.value}</p>
    },

    {
      Header: "Orders Placed",
      accessor: "ordersPlaced",
      Cell: props => <p className="list-item-heading">{props.value}</p>
    },
    {
      Header: "First Order At",
      accessor: "firstOrder",
      Cell: props => <div className="list-item-heading" >{moment(props.value).format("DD/MM/YYYY")}<div style={{ fontSize: '10px' }}>{moment(props.value).format("hh:mmA")}</div></div>
    },
    {
      Header: "Last Order At",
      accessor: "lastOrder",
      Cell: props => <div className="list-item-heading" >{moment(props?.value || '').format("DD/MM/YYYY")}<div style={{ fontSize: '10px' }}>{moment(props?.value || '').format("hh:mmA")}</div></div>
    },
    {
      Header: "Total Revenue",
      accessor: "revenue",
      Cell: props => <p className="list-item-heading">{"AED " + (props?.value)?.toFixed(2)}</p>
    },

  ];

  const getUsers = async (type, number) => {
    let ref = firestore().collection('users')
    setLoading(true)
    console.log('type', type);
    console.log('number', number);

    switch (type) {
      case 'loadmore':
        ref = ref.orderBy("created_date", "desc").startAfter(lastVisible)

        break;
      case 'number':
        let searchQuery = Number(number)
        searchQuery = String(searchQuery).replace(/\s/g, '');

        if (String(searchQuery).startsWith("971")) {
          var temp = String(searchQuery).substring(3, String(searchQuery).length);
          searchQuery = temp;
        }
        ref = ref.where('number', '>=', String(searchQuery)).where('number', '<=', String(searchQuery) + '\uf8ff')
          .orderBy('number')
        break;

      default:
        ref = ref.orderBy("created_date", "desc")

        break;
    }
    let snap = await ref.limit(100).get()
    let data = await Promise.all(snap?.docs?.map(async res => {
      let ref = firestore().collection('bookings')
      if (!accessTypes.includes(user?.accessType)) {
        ref = ref.where("sp_id", "==", user?.uid)
      }
      let userNumber = Number(res.data().number)

      let userBookingsSnap = await ref.where('customer_number', '==', String(userNumber)).get()
      let userBookingsSnap2 = await ref.where('customer_number', '==', String("0" + Number(userNumber))).get()

      let userBookings = [...userBookingsSnap.docs, ...userBookingsSnap2.docs].map(r => { return { ...r.data(), key: r.id } }) || []
      let createdArray = userBookings.map(r => r.created)
      let totalRevenue = userBookings?.map(r => (r?.totalPrice && r?.status !== 'canceled' && r?.status !== 'rejected' ? (r?.totalPrice * 1) : 0))?.reduce((a, b) => a + b, 0)
      if (res.exists && !userBookingsSnap.empty) {
        return {
          ...res.data(),
          id: res.id,
          key: res.id,
          ordersPlaced: createdArray.length,
          firstOrder: createdArray.length ? Math.min.apply(null, createdArray) : new Date().getTime(),
          lastOrder: createdArray.length ? Math.max.apply(null, createdArray) : new Date().getTime(),
          revenue: totalRevenue,
          number: res.data().phoneNumber,
          orders: userBookings
        }
      }
      return false

    }))
    const last = snap.docs[snap.docs.length - 1];
    setLastVisible(last)
    if (number) {
      let allUsers = data?.filter(rec => rec)
      setFilteredUsers(allUsers)
      setAllUsers(allUsers)
    } else {
      let allUsers = filteredUsers?.concat(data)?.filter(rec => rec)
      setFilteredUsers(allUsers)
      setAllUsers(allUsers)
    }
    setLoading(false)
  }


  useEffect(() => {
    async function fetchData() {
      await getUsers()
    }
    fetchData();
    // eslint-disable-next-line
  }, [])


  var debounce_fun = debounce(async function (e) {
    setFilteredUsers([])
    setAllUsers([])
    setSearch({ ...search, number: e })
    if (e.length) {
      await getUsers('number', e)
    } else {
      await getUsers()
    }
  }, 1000);

  useEffect(() => {
    let filtered = allUsers?.filter(item => {
      return (
        (search?.name?.length ? String(item.name).toLowerCase().includes(search?.name?.toLowerCase()) : true)
      )
    })
    setFilteredUsers(filtered)
  }, [search, allUsers])
  return (
    <Fragment>
      {(loading) &&
        <div className='loading'></div>
      }
      <Row>
        <Colxx xxs="12">
          <div className="mb-2"><h1>Customers</h1></div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12">
          <Card className="mb-4 rounded">
            <CardBody>
              <Row>
                <Colxx xxs="12" md="4" className='mt-1'>
                  <Input type="number" style={{ borderRadius: '4px' }} placeholder="Mobile Number" name="number" onChange={(e) => {
                    debounce_fun(e.target.value)
                  }} />
                </Colxx>
                <Colxx xxs="12" md="4" className='mt-1'>
                  <Input type="text" style={{ borderRadius: '4px' }} placeholder="Customer Name" name="name" onChange={(e) => {
                    setSearch({ ...search, name: e.target.value })
                  }} />
                </Colxx>
              </Row>

            </CardBody>
          </Card>
        </Colxx>
        <Colxx xxs="12">
          <Card className="mb-4 rounded">
            <CardBody>
              {filteredUsers || allUsers ? <ReactTable
                data={filteredUsers ? filteredUsers : allUsers}
                showPageJump={false}
                defaultPageSize={10}
                columns={dataTableColumns()}
                onPageChange={async (page) => {
                  let pageCount = Math.ceil(filteredUsers?.length / 10)
                  if ((page + 1) === pageCount && !search?.number) {
                    await getUsers('loadmore')
                  }
                }
                }
                getTrGroupProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: e => {
                      setSelectedCustomer(rowInfo.original)
                    },

                  }
                }}
                PaginationComponent={DataTablePagination}
                showPageSizeOptions={false}
              /> : <div className="d-flex flex-row mb-3 pb-3 text-center justify-content-center ">
                <div className='font-weight-bold'>No Customers yet!</div>
              </div>}
            </CardBody>
          </Card>
        </Colxx>
        {selectedCustomer && <Customer
          customer={selectedCustomer}
          dismiss={() => setSelectedCustomer(false)}
        />
        }
      </Row>
    </Fragment>
  );
};

export default Customers;
