import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Users from "./users";

const Gogo = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Route path={`${match.url}/customers`} render={()=><Users role="customers" />} />
      <Route path={`${match.url}/washers`} render={()=><Users role="washers" />} />
      <Redirect to="/error" />
    </Switch>
  </div>
);
export default Gogo;
