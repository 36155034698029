import React, { useEffect, useState } from "react";
import {
    Button,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    Nav,
    Row,
    TabContent,
    TabPane
} from "reactstrap";

import classnames from "classnames";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Rating from "../../../../components/common/Rating";
import { firestore } from "../../../../helpers/Firebase";
import { CalculatePoints, Capitalize, getAmountColor, getAmountSign, getDescription, getTitle } from "../../../../helpers/Utils";

const Customer = ({
    customer,
    dismiss,
}) => {

    const [activeTab, setActiveTab] = useState(1)
    const [customerWallet, setCustomerWallet] = useState([])
    const [tabs] = useState([
        { name: 'Personal Details', key: 1 },
        { name: 'Customer Orders', key: 2 },
        { name: 'Customer Wallet', key: 3 },
    ])
    useEffect(() => {
        if (customer) {
            async function fetchData() {
                const customerWalletSnap = await firestore().collection('users').doc(customer.id).collection('walletLog').get()
                let data = customerWalletSnap.docs.map(rec => {
                    return { ...rec.data(), id: rec.id }
                })
                data = data?.sort((a, b) => b.created - a.created)?.filter(t => !t?.voided)
                setCustomerWallet(data)
            }
            fetchData();
        }
    }, [customer])
    const MyStatusCell = (order) => {
        let { status, canceledBy, cancelReason } = order;

        const getStatusClassName = (status) => {
            const classNameMap = {
                new: 'pendingStatusfieldText',
                pending: 'pendingStatusfieldText',
                accepted: 'acceptedStatusfieldText',
                active: 'processStatusfieldText',
                completed: 'completedStatusfieldText',
                notRated: 'completedStatusfieldText',
                rejected: 'rejectedStatusfieldText',
                rated: 'ratedStatusfieldText',
                canceled: 'rejectedStatusfieldText',
            };
            return classNameMap[status] || '';
        };

        const renderStatusText = () => {
            if (status === 'new') {
                return 'Pending';
            }
            if (status === 'notRated') {
                return 'Completed';
            }
            if (status === 'rated') {
                return (
                    <div className='hoverRating'>
                        {Capitalize(status)}
                        <i style={{ color: '#4a4a4a', marginLeft: '4px' }} className="simple-icon-info" /><br />
                        <Rating total={5} rating={order?.rate} />
                        <div className='ratingComment2' style={{
                            width: '200px',
                            zIndex: 100000000
                        }}>
                            {order?.comment || 'No Comment'}
                            {order?.rate ? <div >Average Rate: {order?.rate}</div> : ''}
                            {order?.serviceRate ? <div className="d-flex align-items-center">Service Rate: <Rating total={5} rating={order?.serviceRate} style={{ margin: '4px 8px' }} /></div> : ''}
                            {order?.workerRate ? <div className="d-flex align-items-center">Worker Rate: <Rating total={5} rating={order?.workerRate} style={{ margin: '4px 8px' }} /></div> : ''}
                            {order?.arrivalTimingRate ? <div className="d-flex align-items-center">Arrival Timing Rate: <Rating total={5} rating={order?.arrivalTimingRate} style={{ margin: '4px 8px' }} /></div> : ''}
                        </div>
                    </div>
                );
            }
            if (status === 'canceled' || status === 'rejected') {
                return (
                    <div className='hoverCanceled'>
                        {Capitalize(status)}
                        <i style={{ color: '#4a4a4a', marginLeft: '4px' }} className="simple-icon-info" /><br />
                        <div className='canceledComment2' style={{
                            width: '200px',
                            zIndex: 100000000
                        }}>
                            {canceledBy && (
                                <div>
                                    {status === 'rejected' ? 'Rejected by:' : 'Canceled By:'} {canceledBy} <br />
                                    {cancelReason ? `Reason: ${cancelReason}` : 'No Comment'}
                                </div>
                            )}
                            {!canceledBy && 'No Comment'}
                        </div>
                    </div>
                );
            }
            return Capitalize(status);
        };

        const className = getStatusClassName(status);
        const statusText = renderStatusText();

        return <div className={className}>{statusText}</div>;
    }
    return (

        <Modal size="lg" backdrop="static" isOpen={customer} >

            <ModalBody>
                <h3>{'Customer Information'}</h3>

                <Row>
                    <Colxx xxs="12">
                        <Nav tabs className="separator-tabs ml-0 mb-5">
                            {
                                tabs.map(item => {
                                    return !item.hide && (
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            key={item.key}
                                            className={classnames({
                                                active: activeTab === item.key,
                                                "nav-link": true
                                            })}
                                            onClick={() => {
                                                setActiveTab(item.key);
                                            }}>
                                            {item.name}
                                        </div>
                                    )
                                })
                            }
                        </Nav>

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>

                                {[{
                                    key: 'personalDetails', title: 'Customer Details',
                                    data: [
                                        { key: 'name', title: 'Name', width: '6', type: 'text', data: customer?.name },
                                        { key: 'number', title: 'Mobile number', width: '6', type: 'text', data: customer?.number },
                                        { key: 'email', title: 'Email', width: '6', type: 'text', data: customer?.email }
                                    ]
                                },
                                {
                                    key: 'orderDetails', title: 'Orders Details',
                                    data: [
                                        { key: 'firstOrder', title: 'First Order', width: '6', type: 'text', data: moment(customer?.firstOrder).format('DD/MM/YYYY hh:mm A') },
                                        { key: 'lastOrder', title: 'Last Order', width: '6', type: 'text', data: moment(customer?.lastOrder).format('DD/MM/YYYY hh:mm A') },
                                        { key: 'totalOrders', title: 'Total Orders', width: '6', type: 'text', data: customer?.orders?.length },
                                        { key: 'totalAmount', title: 'Total Revenue', width: '6', type: 'text', data: `AED ${customer?.revenue}` },
                                    ]
                                }
                                ].map((rec, recIndex) => {
                                    return rec?.data?.length ? (
                                        <Row key={recIndex}>
                                            <Colxx xxs="12" className="mt-3">
                                                <h4>{rec.title}</h4>
                                            </Colxx>
                                            {rec.data.map((item, index) => {
                                                return (

                                                    <Colxx xxs="12" md={item.width || '6'} key={index}>
                                                        <Label className="mb-0  text-semi-muted">{rec.key === 'location' ? `Address ${index + 1}` : item.title}:</Label>
                                                        <p className="font-weight-bold" id={item.id} style={{ fontSize: '0.8rem' }}>{(item?.data || '--')}</p>
                                                    </Colxx>
                                                )
                                            })}
                                        </Row>
                                    ) : ''
                                })}

                            </TabPane>
                            <TabPane tabId={2}>
                                <PerfectScrollbar
                                    option={{ suppressScrollX: true, wheelPropagation: false }}
                                >
                                    <div className="p-4 pt-0" style={{ height: '500px' }}>
                                        {customer?.orders?.sort((a, b) => b.created - a.created)?.map((item, index) => {
                                            return (
                                                <>
                                                    <Row className={`d-flex align-items-center ${index !== 0 && "mt-4"}`}>

                                                        <Colxx xxs="2" style={{ fontWeight: '700' }}>Order Number</Colxx>
                                                        <Colxx xxs="2" style={{ fontWeight: '700' }}>Provider</Colxx>
                                                        <Colxx xxs="2" style={{ fontWeight: '700' }}>Service</Colxx>
                                                        <Colxx xxs="2" style={{ fontWeight: '700' }}>Appointment Date</Colxx>
                                                        <Colxx xxs="2" style={{ fontWeight: '700' }}>Status</Colxx>
                                                        <Colxx xxs="2" style={{ fontWeight: '700' }}>Amount</Colxx>
                                                    </Row>
                                                    <Row key={index} className="d-flex align-items-center">

                                                        <Colxx xxs="2">
                                                            <span>#{item?.orderNumber}</span>
                                                        </Colxx>
                                                        <Colxx xxs="2">
                                                            <span>{item?.sp_name}</span>
                                                        </Colxx>
                                                        <Colxx xxs="2">
                                                            <span>{item?.service_name}</span>
                                                        </Colxx>
                                                        <Colxx xxs="2">
                                                            <span>{moment(item?.bookingDate).format('DD/MM/YYYY hh:mm A')}</span>
                                                        </Colxx>
                                                        <Colxx xxs="2">
                                                            <MyStatusCell {...item} />
                                                        </Colxx>
                                                        <Colxx xxs="2">
                                                            <span>AED {item?.price?.totalPrice}</span>
                                                        </Colxx>

                                                    </Row>
                                                    <Separator className="mt-4" />
                                                </>
                                            )
                                        })}
                                    </div>
                                </PerfectScrollbar>
                            </TabPane>
                            <TabPane tabId={3}>

                                <Row className={'justify-content-center pr-4 pl-4'}>
                                    <Colxx xxs="3" className='d-flex flex-column justify-content-center align-items-start'>
                                        <div className="salesTitle">Total</div>
                                        <div className="salesValue">{'AED ' + CalculatePoints(customerWallet).total.toFixed(2)}</div>
                                    </Colxx>
                                    <Colxx xxs="3" className='d-flex flex-column justify-content-center align-items-start'>
                                        <div className="salesTitle">Total Credit</div>
                                        <div className="salesValue">{'AED ' + CalculatePoints(customerWallet).totalCredit.toFixed(2)}</div>
                                    </Colxx>
                                    <Colxx xxs="3" className='d-flex flex-column justify-content-center align-items-start'>
                                        <div className="salesTitle">Total Cashback</div>
                                        <div className="salesValue">{'AED ' + CalculatePoints(customerWallet).totalCashback.toFixed(2)}</div>
                                    </Colxx>
                                    <Colxx xxs="3" className='d-flex flex-column justify-content-center align-items-start'>
                                        <div className="salesTitle">Total Vouchers</div>
                                        <div className="salesValue">{'AED ' + CalculatePoints(customerWallet).totalVouchers.toFixed(2)}</div>
                                    </Colxx>
                                </Row>
                                <Separator className="mt-4" />

                                <PerfectScrollbar
                                    option={{ suppressScrollX: true, wheelPropagation: false, }}
                                >
                                    <div className="p-4" style={{ height: '500px' }}>
                                        {customerWallet?.sort((a, b) => b.created - a.created)?.filter(t => !t?.voided)?.map((item, index) => {
                                            return (
                                                <>
                                                    <Row className={`d-flex align-items-center ${index !== 0 && "mt-4"}`}>

                                                        <Colxx xxs="3" style={{ fontWeight: '700' }}>Type</Colxx>
                                                        <Colxx xxs="3" style={{ fontWeight: '700' }}>Description</Colxx>
                                                        <Colxx xxs="3" style={{ fontWeight: '700' }}>Created</Colxx>
                                                        <Colxx xxs="3" style={{ fontWeight: '700' }}>Amount</Colxx>
                                                    </Row>
                                                    <Row key={index} className="d-flex align-items-center">

                                                        <Colxx xxs="3">
                                                            <span>{getTitle(item)}</span>
                                                        </Colxx>
                                                        <Colxx xxs="3">
                                                            <span>{getDescription(item) || '--'}</span>
                                                        </Colxx>
                                                        <Colxx xxs="3">
                                                            <span>{moment(item?.created).format('DD/MM/YYYY hh:mm A')}</span>
                                                        </Colxx>
                                                        <Colxx xxs="3">
                                                            <span style={{ fontWeight: 'bold', color: getAmountColor(item) }}>{`${getAmountSign(item)} AED ${Number(item.amount).toFixed(2)}`}</span>

                                                        </Colxx>
                                                    </Row>
                                                    <Separator className="mt-4" />
                                                </>
                                            )
                                        })}
                                    </div>
                                </PerfectScrollbar>
                            </TabPane>


                        </TabContent>

                    </Colxx>
                </Row>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    className="btn-shadow float-right"
                    size="lg"
                    onClick={() => dismiss()}>
                    Dismiss
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default Customer;