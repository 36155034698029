import { createAction } from 'redux-action';

// export const getList = createAction("GET_PROVIDERS_LIST", user => {
//     return getListFunction(user).then(response => {
//         return response
//     }).catch(error => error)
// });

// const getListFunction = (user) => {
//     return new Promise((success, fail) => {
//         let { accessType, uid } = user
//         let allServiceProviders = {}
//         let ref = firestore().collection('service_provider')
//         if (accessType && uid && accessType !== 'superAdmin') {
//             ref = ref.where('key', '==', uid)
//         }
//         ref.get().then(function (querySnapshot) {
//             querySnapshot.forEach(function (doc) {
//                 if (!doc.data().deActivated) {
//                     // doc.data() is never undefined for query doc snapshots
//                     let serviceProvider = doc.data();
//                     serviceProvider.key = doc.id
//                     serviceProvider.value = doc.data().name
//                     serviceProvider.label = doc.data().name
//                     allServiceProviders[doc.id] = serviceProvider
//                 }
//             });
//             success(allServiceProviders);
//         });
//     })
// }


// export const updateProvider = createAction("UPDATE_PROVIDER", (key, oldList) => {
//     return new Promise((success, fail) => {
//         firestore().collection('service_provider').doc(key).get().then((result) => {
//             let data = result.data()
//             data.key = result.id
//             oldList[result.id] = data
//             success(oldList)
//         })
//     }).then(response => {
//         return response
//     }).catch(error => error)
// });

// export const getWasherList = createAction("GET_WASHER_LIST", user => {
//     return getWashersFunction(user).then(response => {
//         return response
//     }).catch(error => error)
// });
// export const updateWasher = createAction("UPDATE_WASHER", (washerKey, oldList) => {
//     return new Promise((success, fail) => {
//         firestore().collection('washers').doc(washerKey).get().then((result) => {
//             let data = result.data()
//             data.key = result.id
//             data.value = result.data().name
//             data.label = result.data().name
//             oldList[result.id] = data
//             success(oldList)
//         })
//     }).then(response => {
//         return response
//     }).catch(error => error)
// });

// function getWashersFunction(user) {
//     return new Promise((success, fail) => {
//         let { accessType, uid } = user
//         let allWashers = {}
//         let ref = firestore().collection('washers')
//         if (accessType !== "superAdmin") {
//             ref = ref.where("sp_id", "==", uid)
//         }
//         ref.get().then(function (querySnapshot) {
//             if (querySnapshot.empty) {
//                 success(allWashers)
//             }
//             querySnapshot.forEach(function (doc) {
//                 if (!doc.data().deActivated && doc.data().name) {
//                     let serviceProvider = {};
//                     serviceProvider = doc.data();
//                     serviceProvider.key = doc.id
//                     serviceProvider.value = doc.data().name
//                     serviceProvider.label = doc.data().name
//                     serviceProvider.activeServices = doc.data().activeServices

//                     allWashers[doc.id] = serviceProvider
//                 }

//             });
//             success(allWashers)
//         });

//     })
// }



// export const getUpSeLlingList = createAction("GET_UPSELLING_LIST", user => {
//     return getUpSellingFunction(user).then(response => {
//         return response
//     }).catch(error => error)
// });
// export const updateUpSelling = createAction("UPDATE_UPSELLING", (upSellingKey, oldList) => {
//     return new Promise((success, fail) => {
//         firestore().collection('up_selling').doc(upSellingKey).get().then((result) => {

//             let data = result.data()
//             data.key = result.id
//             data.value = result.data().name
//             data.label = result.data().name
//             oldList[result.id] = data
//             success(oldList)
//         })
//     }).then(response => {
//         return response
//     }).catch(error => error)
// });

// function getUpSellingFunction(user) {
//     return new Promise((success, fail) => {
//         let { accessType, uid } = user
//         let allUpSellings = {}
//         let ref = firestore().collection('up_selling')
//         if (accessType !== "superAdmin") {
//             ref = ref.where("sp_id", "==", uid)
//         }
//         ref.get().then(function (querySnapshot) {
//             if (querySnapshot.empty) {
//                 success(allUpSellings)
//             }
//             querySnapshot.forEach(function (doc) {

//                 // if (doc.data().name) {
//                 let upSelling = {};
//                 upSelling = doc.data();
//                 upSelling.key = doc.id

//                 allUpSellings[doc.id] = upSelling
//                 // }

//             });
//             success(allUpSellings)
//         });

//     })
// }

export const getList = createAction("GET_PROVIDERS_LIST", list => {
    return list
});

export const getWasherList = createAction("GET_WASHER_LIST", list => {
    return list

});
export const getUpSeLlingList = createAction("GET_UPSELLING_LIST", list => {
    return list

});

export const getServices = createAction("GET_SERVICES_LIST", list => {
    return list
});
export const getModifiers = createAction("GET_MODIFIERS", list => {
    return list
});
export const getAdvServices = createAction("GET_ADV_SERVICES_LIST", list => {
    return list
});
export const getVariations = createAction("GET_VARIATIONS_LIST", list => {
    return list
});
export const getBanners = createAction("GET_BANNERS_LIST", list => {
    return list
});
export const getAppointmentsList = createAction("GET_APPOINTMENT_LIST", allBookings => {
    return allBookings
});
export const getCustomersVouchers = createAction("GET_CUSTOMERS_VOUCHERS", list => {
    return list
});
export const getVouchers = createAction("GET_VOUCHERS", list => {
    return list
});