import moment from "moment";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import ReactTable from "react-table";
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import DataTablePagination from "../../../components/DatatablePagination";
import { Colxx } from "../../../components/common/CustomBootstrap";
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import { firestore } from '../../../helpers/Firebase';
import { deviceWidth } from "../../../helpers/Utils";
import Voucher from "./components/voucher";

const Vouchers = () => {
  const { user } = useSelector(state => state.authUser);
  const { customersVouchers } = useSelector(state => state.providers);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(false);
  const [filteredVouchers, setFilteredVouchers] = useState({});
  const [deleteVoucher, setDeleteVoucher] = useState(false);

  const dataTableColumns = useCallback(() => ([
    {
      Header: "Customer",
      accessor: "customer",
      Cell: props => <p className="providerfieldText">{props.value}</p>
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: props => <p className="providerfieldText">{props.value}</p>
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: props => <p className="providerfieldText">{Number(props.value).toFixed(2)}</p>
    },
    {
      Header: "Created",
      accessor: "created",
      Cell: props => <p className="providerfieldText">{moment(props.value).format('DD/MM/YYYY')}</p>
    },
    {
      Header: "Expiration Date",
      accessor: "exp",
      Cell: props => <p className="providerfieldText">{moment(props.value).format('DD/MM/YYYY')}</p>
    },
    {
      Header: "Redeemed",
      accessor: "redeemed",
      Cell: props => <p className="providerfieldText">{props.value ? moment(props.value).format('DD/MM/YYYY') : '--'}</p>
    },
    {
      width: 80,
      accessor: "delete",
      show: (user?.accessType === 'superAdmin') && deviceWidth() === 'desktop' ? true : false,
      Cell: props => (
        <p className="providerfieldText">
          <Button
            color="danger"
            size="sm"
            disabled={props.original?.redeemed}
            outline
            onClick={() => {
              setDeleteVoucher(props.original);
            }}
          >
            Delete
          </Button>
        </p>
      )
    },
    {
      width: 70,
      accessor: "edit",
      Cell: props => (
        <p className="providerfieldText">
          <Button
            color="primary"
            size="sm"
            outline
            disabled={props.original?.redeemed}
            onClick={() => {
              setSelectedVoucher(props.original);
            }}
          >
            Edit
          </Button>
        </p>
      )
    }
  ]), [user,]);

  const deleteVoucherHandler = useCallback((voucher) => {
    let ref = firestore().collection('users').doc(voucher?.userID).collection('walletLog').doc(voucher?.key)
    ref.delete().then(() => {
      setDeleteVoucher(false);
      setIsLoading(false);
    });
  }, []);

  const onSearchKey = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filtered = customersVouchers && Object.values(customersVouchers).filter(item =>
      item.service && item.service.toLowerCase().includes(searchValue)
    );
    setFilteredVouchers(filtered);
  };

  const filteredVoucherList = filteredVouchers && Object.keys(filteredVouchers).length ? filteredVouchers : customersVouchers;

  return (
    <>
      {
        isLoading && (
          <div className="loading" />
        )}

      <div className="disable-text-selection">
        <ListPageHeading
          heading= "Vouchers / Compensations"
          onSearchKey={onSearchKey}
          toggleModal={() => {
            setSelectedVoucher({ addNew: true });
          }
          }
        />

        <Row className="survey-app">
          <Colxx xxs="12">
            {filteredVoucherList && Object.keys(filteredVoucherList).length ?
              <ReactTable
                data={Object.values(filteredVoucherList)}
                columns={dataTableColumns()}
                defaultPageSize={10}
                pageSize={Object.keys(filteredVoucherList).length < 10 ? Object.keys(filteredVoucherList).length : 10}
                showPageJump={false}
                defaultSorted={[
                  { id: "created", desc: true },
                ]}
                showPageSizeOptions={false}
                PaginationComponent={DataTablePagination}
                className='custuomReactTable'
              />
              :
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Vouchers</div>
            }
          </Colxx>
        </Row>

        {selectedVoucher && (
          <Voucher
            selectedVoucher={selectedVoucher}
            dismiss={() => {
              setSelectedVoucher(false);
              setIsLoading(false);
            }
            }
          />
        )}

        <Modal backdrop="static" isOpen={deleteVoucher}>
          <ModalHeader>
            Delete Voucher
          </ModalHeader>
          <ModalBody>
            <Row>
              <Colxx xxs="12">
                <Label >Are you sure you want to delete this Voucher?</Label>
              </Colxx>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => {
              setDeleteVoucher(false);
            }}>Dismiss</Button>
            <Button color='danger' onClick={() => {
              setIsLoading(true);
              deleteVoucherHandler(deleteVoucher);
            }}>Delete Voucher</Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};



export default Vouchers;
