import React from 'react';
import { CustomInput } from 'reactstrap';

const DetailsTable = ({ modifiers, carDetail, selectedBooking, washer, service, price, addonsOptimized, manageOrder, removeCar, onlinePayment, setOnlinePayment, fromSubscription }) => {
    let rows = [];

    if (carDetail?.length) {
        carDetail.forEach((rec, index) => {
            let category = rec?.modelNumber?.category;
            let carModel = rec?.car_model + " " + rec?.modelNumber?.model;
            let id = Object.values(modifiers).find((record) => record.name === category)?.key;
            let servicePrice = 0;

            if (selectedBooking?.washingType === 'waterlessWash') {
                if (price?.services && !manageOrder) {
                    servicePrice = (price?.services?.[id]?.waterlessPrice * 1);
                } else {
                    servicePrice = (washer?.activeServices?.[service]?.[id]?.waterlessPrice * 1);
                }
            } else if (selectedBooking?.washingType === 'steamWash') {
                if (price?.services && !manageOrder) {
                    servicePrice = (price?.services?.[id]?.steamWashPrice * 1);
                } else {
                    servicePrice = (washer?.activeServices?.[service]?.[id]?.steamWashPrice * 1);
                }
            } else {
                if (price?.services && !manageOrder) {
                    servicePrice = (price?.services?.[id]?.price * 1) + (price?.variPrice?.[id] || 0);
                } else {
                    servicePrice = (washer?.activeServices?.[service]?.[id]?.price * 1) + (price?.variPrice?.[id] || 0);
                }
            }

            let Variations = { darkness: 'Tint Level', frontSide: 'Selected Sides', brand: 'Package', heatRejection: 'Heat Rejection' }

            rows.push(
                <tr key={index}>
                    <td style={{ fontWeight: '700' }}>{carModel + " - " + category}</td>
                    <td style={{ fontWeight: '700' }}>{`${fromSubscription ? (selectedBooking?.selectedPackage?.washesCount || '1') : '1'}x ${selectedBooking.service_name} ${selectedBooking?.washingType ? (selectedBooking?.washingType === 'waterlessWash' ? '( Waterless Wash )' : selectedBooking?.washingType === 'steamWash' ? '( Steam Wash )' : '( Pressure Wash )') : ''}`} </td>
                    <td style={{ fontWeight: '700' }}>{(servicePrice * (fromSubscription ? Number(selectedBooking?.selectedPackage?.washesCount || 1) : 1)).toFixed(2) || '0'} AED</td>
                    {/* {(manageOrder && carDetail.length > 1 && selectedBooking?.paymentType === 'cash' && !['completed', 'rated', 'canceled', 'rejected'].includes(selectedBooking?.status)) ? <td style={{ fontWeight: '700' }}>
                        <i className="simple-icon-trash ml-2 mt-2"
                            style={{ cursor: 'pointer', flex: '0.2', fontSize: '16px', textAlign: 'end', color: '#dc3545' }}
                            onClick={() => {
                                removeCar(carModel)
                            }} />
                    </td>
                        : ''} */}
                </tr>
            );

            if (selectedBooking?.variation && Object.keys(selectedBooking?.variation).length) {
                Object.keys(selectedBooking?.variation).forEach((variation, variationIndex) => {
                    rows.push(
                        <tr key={`${index}-${variationIndex}-variation`} className="variation-row">
                            <td style={{ paddingTop: '6px', paddingBottom: '6px' }}></td>
                            <td style={{ fontSize: '12px', color: '#919191', paddingTop: '6px', paddingBottom: '6px' }}>{Variations?.[variation]}: <span style={{ color: '#4A4A4A', fontWeight: '600', }}>{selectedBooking?.variation[variation]}</span></td>
                            <td ></td>
                        </tr>
                    )
                });
            }

            if (addonsOptimized?.[carModel]) {
                Object.values(addonsOptimized[carModel]).forEach((addon, addonIndex) => {
                    rows.push(
                        <tr key={`${index}-${addonIndex}-addon`}>
                            <td style={{ paddingTop: '6px', paddingBottom: '6px' }}></td>
                            <td style={{ fontSize: '12px', color: '#919191', paddingTop: '6px', paddingBottom: '6px' }}>{addon.count + "x " + addon.name} <span style={{ color: '#4A4A4A', fontWeight: '600', fontSize: '10px' }}>{"(Addon)"}</span></td>
                            <td style={{ fontSize: '12px', paddingTop: '6px', paddingBottom: '6px', fontWeight: '700' }}>{(Number(addon.count) * Number(addon.price)).toFixed(2)} AED</td>
                        </tr>
                    )
                });
            }
        });
    }
    return (
        <div className="table-responsive" style={{ border: '1px solid #f3f3f3' }}>
            <table className="table">
                <thead>
                    <tr>
                        <th>Vehicle Model</th>
                        <th>Service</th>
                        <th>Price</th>
                        {/* {(manageOrder && carDetail.length > 1 && selectedBooking?.paymentType === 'cash' && !['completed', 'rated', 'canceled', 'rejected'].includes(selectedBooking?.status)) ? <th>
                        </th>
                            : ''} */}
                    </tr>
                </thead>

                <tbody>
                    {rows}
                    {/* {price?.code && price?.discount ?
                        <tr>
                            <td colSpan="2" style={{ fontWeight: '700' }}>Discount: <span style={{ fontWeight: 'normal' }}>{price.code === 'loyalty' ? <div >Loyalty points</div> : <div >Promo Code: {price.code}</div>}</span>
                            </td>
                            <td style={{ fontWeight: '700' }}>-{Number(price.discount).toFixed(2)} AED</td>
                        </tr>
                        : ''
                    } */}
                    {price?.discount ?
                        <>
                            <tr >
                                <td colSpan="2" style={{ fontWeight: '700' }} >{'Total Discount'}</td>
                                <td style={{ fontWeight: '700' }} >- {Number(price?.discount).toFixed(2)} AED</td>
                            </tr>
                            {Number(price?.redeemedPoints) > 0 ? <tr >
                                <td colSpan="2" style={{ fontSize: '12px' }}>{'Loyalty Points'}</td>
                                <td style={{ fontSize: '12px' }} >- {Number(price?.redeemedPoints).toFixed(2)} AED</td>
                            </tr> : <></>}
                            {Number(price?.redeemedPromoCode) > 0 ? <tr >
                                <td colSpan="2" style={{ fontSize: '12px' }} >{'Promo code:'} <span style={{ fontWeight: '700' }}>{price?.promoCode}</span></td>
                                <td style={{ fontSize: '12px' }}>- {Number(price?.redeemedPromoCode).toFixed(2)} AED</td>
                            </tr> : <></>}
                            {Number(price?.redeemedCredit) > 0 ? <tr >
                                <td colSpan="2" style={{ fontSize: '12px' }}>{'Wallet credit'}</td>
                                <td style={{ fontSize: '12px' }}>- {Number(price?.redeemedCredit).toFixed(2)} AED</td>
                            </tr> : <></>}
                            {Number(price?.redeemedVoucher) > 0 ? <tr >
                                <td colSpan="2" style={{ fontSize: '12px' }}  >{'Voucher'}</td>
                                <td style={{ fontSize: '12px' }}>- {Number(price?.redeemedVoucher).toFixed(2)} AED</td>
                            </tr> : <></>}

                        </> :
                        <div />
                    }
                    {price?.outstanding ?
                        <tr>
                            <td colSpan="2" style={{ fontWeight: '700' }}>
                                <div>Outstanding:</div>
                                <span style={{ fontWeight: 'normal' }}>
                                    {manageOrder && price?.outstanding > 0 ? <CustomInput
                                        className="custom-checkbox d-inline-block"
                                        type="checkbox"
                                        id="checkAll"
                                        checked={onlinePayment}
                                        onChange={(e) => {
                                            setOnlinePayment(e.target.checked)
                                        }}
                                        label={<div style={{ cursor: 'pointer' }}>Outstanding amount will be collected online </div>}
                                    /> : ''}
                                </span>

                            </td>
                            <td style={{ fontWeight: '700' }}>{Number(price.outstanding).toFixed(2)} AED</td>
                        </tr>
                        : ''
                    }
                    <tr>
                        <td colSpan="2" style={{ fontWeight: '700' }}>Total Price:</td>
                        <td style={{ fontWeight: '700' }}>{Number(price.totalPrice).toFixed(2)} AED</td>
                    </tr>
                    {price?.washerTip ? <tr>
                        <td colSpan="2" style={{ fontWeight: '700' }}>
                            <div>Worker Tip:</div>
                            <span style={{ fontWeight: 'normal' }}>
                                Kindly note that worker tip is not calculated in the total price<br /> and should be paid directly to the worker.
                            </span>
                        </td>
                        <td style={{ fontWeight: '700', color: '#3498db' }}>{(Number(price.washerTip / 100) * 0.9705).toFixed(2)} AED</td>
                    </tr> : ''}
                </tbody>
            </table>
        </div>
    );
};

export default DetailsTable;