import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { firestore } from '../../../../helpers/Firebase';
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from 'moment';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomInput = React.forwardRef((props, ref) => {
    return (
        <input
            onClick={props.onClick}
            value={props.value}
            type="text"
            className="customDatePicker"
            readOnly={true}
            ref={ref}
        />
    )
})
const startDate = new Date();
startDate.setDate(startDate.getDate() - 7);
const BusyTimingsLogs = ({ washer, dismiss }) => {
    const [logs, setLogs] = useState([])
    const [dateRange, setDateRange] = useState([startDate, new Date()])

    useEffect(() => {
        if (washer?.key) {
            async function fetchData() {
                let logsSnap = await firestore().collection('washers').doc(washer.key).collection('busyTimingsLogs').where('created', '>=', moment(dateRange[0]).startOf('day').toDate().getTime()).where('created', '<=', moment(dateRange[1]).endOf('day').toDate().getTime()).get()
                let data = logsSnap?.docs?.reduce((val, currentVal) => {
                    val.push({ ...currentVal.data(), id: currentVal.id, key: currentVal.id })
                    return val
                }, [])
                setLogs(data)
            }
            fetchData();

        }

    }, [washer, dateRange])
    const generateMsg = (log) => {
        let msg = ''
        let createdBy = `${log?.createdByName || ''} ${log.createdBy}`
        switch (log.title) {
            case "Applied Busy Timings":
                msg = createdBy + ` applied busy timings from ${moment(log?.busyTimings?.startTime).format("DD/MM/YYYY hh:mm A")} to ${moment(log?.busyTimings?.endTime).format("DD/MM/YYYY hh:mm A")}`
                break;
            case "Removed Busy Timings":
                msg = createdBy + ' removed busy timings'
                break;
            default:
                break;
        }
        return msg
    }
    return (
        <Modal
            isOpen={washer}
            style={{ boxShadow: 'none', maxWidth: '600px', top: '30px', overflow: 'hidden', borderRadius: '14px' }}
            backdrop="static"
            size="lg">
            <ModalHeader className='modal-header' >
                Busy Timings Logs
            </ModalHeader>
            <ModalBody >
                <DatePicker
                    customInput={<CustomInput />}
                    onChange={(dates) => {
                        if (dates.length === 2)
                            setDateRange(dates)
                    }}
                    selectsRange={true}
                    startDate={dateRange?.[0]}
                    endDate={dateRange?.[1]}
                    dateFormat="dd/MM/yyyy"
                />
                <div className="dashboard-logs" style={{ marginTop: '24px' }}>

                    {logs?.length ? <PerfectScrollbar
                        option={{ suppressScrollX: true, wheelPropagation: false }}>
                        <table className="table table-sm table-borderless">
                            <tbody>
                                {logs?.sort((a, b) => b.created - a.created)?.map((log, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <span style={{ borderColor: '#3e884f' }}
                                                    className={`log-indicator align-middle #3e884f`}
                                                />
                                            </td>
                                            <td>
                                                <span className="font-weight-medium">
                                                    {generateMsg(log)}
                                                </span>
                                            </td>
                                            <td className="text-right" style={{ width: '29%' }}>
                                                <span className="text-muted">{moment(log.created).format("DD/MM/YYYY hh:mm A")}</span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </PerfectScrollbar> :
                        <div style={{
                            width: '100%',
                            fontSize: '18px',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: '600'
                        }}>No data to show</div>
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => dismiss()}>Dismiss</Button>
            </ModalFooter>
        </Modal >
    );
};

export default BusyTimingsLogs;