import Switch from "rc-switch";
import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import ReactTable from "react-table";
import { Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import OutletImg from '../../../assets/css/img/CarWashCenter.png';
import { Colxx } from "../../../components/common/CustomBootstrap";
import DataTablePagination from "../../../components/DatatablePagination";
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import { firestore } from '../../../helpers/Firebase';
import IntlMessages from "../../../helpers/IntlMessages";
import Outlet from "./outlet";
import './outletsList.css';

import moment from "moment";
import { MenuItem } from "react-contextmenu";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { accessTypes, accessTypesObject, deviceWidth } from "../../../helpers/Utils";
import SelectedProvider from "../dashboard/components/SelectedServiceProvider";
const providersStatusOptions = [{ key: "all", value: "All", label: "All" }, { key: "active", value: "Active", label: "Active" }, { key: "closed", value: "Closed", label: "Closed" }]
const providersSubscriptionStatus = [{ key: "all", value: "All", label: "All" }, { key: "active", value: "Enabled", label: "Enabled" }, { key: "disabled", value: "Disabled", label: "Disabled" }]

const TheadComponent = props => null; // a component returning null (to hide) or you could write as per your requirement

const OutletsList = () => {
  const { user } = useSelector(state => state.authUser);
  const { provider: providers, washers, services } = useSelector(state => state.providers);
  const [admins, setAdmins] = useState([])

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOutlet, setSelectedOutlet] = useState(false);
  const [addNewBranch, setAddNewBranch] = useState(false);
  const [deleteServiceProvider, setDeleteServiceProvider] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filter, setFilter] = useState({
    providersStatus: 'all',
    joiningRange: false,
    expiringRange: false,
    renewalRange: false,
    subscriptionState: 'all',
    responsibleAgent: false
  });
  const [filteredBranches, setFilteredBranches] = useState(Object.values(providers || {}));
  const [selectedProvider, setSelectedProvider] = useState(false);
  const [analyticsData, setAnalyticsData] = useState({});
  const [subscriptionData, setSubscriptionData] = useState({})
  const toggle = () => setDropdownOpen(!dropdownOpen);
  useEffect(() => {
    firestore().collection('admin').onSnapshot(adminSnap => {
      let data = adminSnap?.docs?.reduce((val, currentVal) => {
        val.push({ ...currentVal.data(), id: currentVal.id, key: currentVal.id })
        return val
      }, [])
      setAdmins(data)
    })
  }, [])
  useEffect(() => {
    if (search.length) {
      let filteredBranches = Object.values(providers || {}).filter(item =>
      (
        item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
        (item?.number?.replaceAll(' ', '')?.toLowerCase()?.includes(search.toLowerCase())) ||
        (item?.uniqueID?.includes(search)))
      );
      setFilteredBranches(filteredBranches)

    } else {
      setFilteredBranches(Object.values(providers || {}))
    }
  }, [search, providers])
  useEffect(() => {
    firestore().collection('newAnalytics').doc("SP").get().then(snap => {
      setAnalyticsData(snap?.data() || {})
    }
    )

  }, [])
  useEffect(() => {
    let subscriptionsSnapshot = firestore().collectionGroup('subscriptions').onSnapshot(snapshot => {
      let subscriptions = snapshot.docs.map(doc => doc.ref.parent.parent.id)
      // setFilteredBranches(Object.values(providers))
      setSubscriptionData(subscriptions)
    })
    return () => subscriptionsSnapshot()
  }, [])
  const filterProvidersList = () => {
    const { providersStatus, joiningRange, expiringRange, subscriptionState } = filter;
    let filteredBranches = Object.values(providers || {})?.filter(provider => {
      const isActive = providersStatus && providersStatus !== 'all'
        ? (providersStatus === 'active' ? provider.active : !provider?.active)
        : true;

      const isJoiningDateValid = joiningRange ? (provider?.joiningDate
        ? (joiningRange?.length ? moment(provider.joiningDate).isBetween(moment(joiningRange[0]), moment(joiningRange[1])) : true)
        : false) : true;

      const isExpiryDateValid = expiringRange ? (provider?.expiryDate
        ? (expiringRange?.length ? moment(provider.expiryDate).isBetween(moment(expiringRange[0]), moment(expiringRange[1])) : true)
        : false) : true;

      const isRenewalDateValid = filter?.renewalRange ? (provider?.renewalDate
        ? (filter?.renewalRange?.length ? moment(provider.renewalDate).isBetween(moment(filter.renewalRange[0]), moment(filter.renewalRange[1])) : true)
        : false) : true;

      const isSubscriptionEnabled = subscriptionState && subscriptionState !== 'all'
        ? (subscriptionState === 'active' ? subscriptionData?.includes(provider?.key || provider?.id) : !subscriptionData?.includes(provider?.key || provider?.id))
        : true;

      const isResponsibleAgent = filter?.responsibleAgent ? (provider?.agent === filter?.responsibleAgent) : true;

      return isActive && isExpiryDateValid && isJoiningDateValid && isRenewalDateValid && isSubscriptionEnabled && isResponsibleAgent;
    });
    setFilteredBranches(filteredBranches)
  }
  useEffect(() => {
    filterProvidersList()
    // eslint-disable-next-line
  }, [filter, providers])
  const dataTableColumns = () => {
    let reviewData = analyticsData
    return ([
      {
        sortable: false,
        accessor: "active",
        width: 80,
        show: accessTypes.includes(user.accessType),
        Cell: props => <p className="list-item-heading">
          <Switch
            className="custom-switch custom-switch-primary"
            checked={props.value}
            onChange={(switchCheckedPrimary, event) => {
              event.stopPropagation(); // Stop event propagation
              providers[props.original.key].active = switchCheckedPrimary
              setIsLoading(true)
              firestore().collection('service_provider').doc(props.original.key).update({
                active: switchCheckedPrimary,
                updated: Date.now()
              })
            }}
          />
        </p>
      },
      {
        sortable: false,
        accessor: "pictures",
        width: deviceWidth() === 'desktop' ? 100 : 80,
        Cell: props => <div className="providerfieldText"><img style={{ width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={props.value && props.value[0] ? props.value[0] : OutletImg} alt={props.value} /></div>
      },
      {
        Header: "ID",
        accessor: "uniqueID",
        show: accessTypes.includes(user?.accessType),
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },

      {
        Header: "Arabic Name",
        accessor: "name_ar",
        show: !accessTypes.includes(user?.accessType) && deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Email",
        accessor: "email",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Mobile Number",
        accessor: "number",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Joining Date",
        accessor: "joiningDate",
        show: accessTypes.includes(user?.accessType) && deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value ? moment(props.value).format('DD/MM/YYYY') : '--'}</p>
      },
      {
        Header: "Expiry Date",
        accessor: "expiryDate",
        show: accessTypes.includes(user?.accessType) && deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value ? moment(props.value).format('DD/MM/YYYY') : '--'}</p>
      },
      {
        Header: 'Renewal Date',
        accessor: 'renewalDate',
        show: accessTypes.includes(user?.accessType) && deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value ? moment(props.value).format('DD/MM/YYYY') : '--'}</p>
      },
      {
        Header: "Average Rating",
        accessor: "key",
        show: (deviceWidth() === 'desktop' ? true : false) && accessTypes.includes(user?.accessType),
        sortMethod: (a, b) => {
          let providerData = reviewData?.[a]
          let scoreA = (Number(providerData?.totalRate?.rate || 0) / Number(providerData?.totalRate?.count || 1)).toFixed(2) || 0
          let providerDataB = reviewData?.[b]
          let scoreB = (Number(providerDataB?.totalRate?.rate || 0) / Number(providerDataB?.totalRate?.count || 1)).toFixed(2) || 0
          return scoreB - scoreA; // Sort by review score within the same status
        },
        Cell: props => {
          let providerData = reviewData?.[props?.value]
          let score = (Number(providerData?.totalRate?.rate || 0) / Number(providerData?.totalRate?.count || 1)).toFixed(2) || 0
          return (
            <p className="providerfieldText">{score}</p>
          )
        }
      },
      user?.accessType === 'superAdmin' ? {
        width: 80,
        show: deviceWidth() === 'desktop' ? true : false,
        accessor: "delete",
        sortable: false,
        Cell: props => <p className="providerfieldText">
          <Button
            color="danger"
            size="sm"
            outline
            onClick={(e) => {
              e.stopPropagation()
              setDeleteServiceProvider(props.original)
            }}>
            Delete
          </Button>
        </p>
      } : {
        width: 0,
        accessor: "delete",
      },
      {
        width: 70,
        accessor: "edit",
        sortable: false,
        Cell: props => <p className="providerfieldText">
          <Button
            color="primary"
            size="sm"
            outline
            className="top-right-button"
            onClick={(e) => {
              e.stopPropagation()
              setSelectedOutlet(props.original)
            }}
          >
            <IntlMessages id="Edit" />
          </Button>
        </p>
      }
    ])
  }

  const exportCSV = () => {

    let prefix = "data:text/csv;charset=utf-8,";

    let header = "ID,Service provider, Email, Number, IBAN, Account Number, Account Name, Bank Name, active";

    let csvContent = header + "\r\n";

    filteredBranches.map(item => {
      csvContent += `${item.uniqueID}, ${item.name}, ${item?.email || '--'}, ${item?.number || '--'}, ${item?.bankDetails?.IBAN || '--'}, ${item?.bankDetails?.accountNumber || '--'}, ${item?.bankDetails?.accountName || '--'}, ${item?.bankDetails?.branch || '--'}, ${item.active}\r\n`;

      return false
    })
    var encodedUri = prefix + encodeURIComponent(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "ServiceProvidersList.csv");
    document.body.appendChild(link);
    link.click();
  }


  // if (selectedProvider) {
  //   return <SelectedProvider authUser={user} provider={selectedProvider} washers={washers} selectProvider={(selectedProvider) => {
  //     this.setState({ selectedProvider })
  //   }} />
  // }
  return (
    selectedProvider ?
      <SelectedProvider authUser={user} provider={selectedProvider} washers={washers} selectProvider={(selectedProvider) => {
        setSelectedProvider(selectedProvider)
      }} />

      : selectedOutlet ? <Outlet
        selectedOutlet={selectedOutlet}
        dismiss={() => setSelectedOutlet(false)}
        addNewBranch={addNewBranch}
        services={services}
        admins={admins}
      /> :
        <Fragment>
          <div className="disable-text-selection">

            {isLoading ?
              <div className="loading" /> : ''
            }
            <ListPageHeading
              heading={user?.accessType === 'admin' ? "Profile" : "Service Providers"}
              onSearchKey={(e) => {
                setSearch(e.target.value)
              }
              }
              toggleModal={() => {
                setAddNewBranch(true)
                setSelectedOutlet({})
              }}
              accessType={user.accessType}
            />


            <Row className="survey-app">
              {accessTypes.includes(user?.accessType) ? <Colxx xxs="12" md="4">

                <Dropdown isOpen={dropdownOpen} toggle={toggle} >

                  <DropdownToggle size='sm' color="primary">
                    Filter Service Providers
                  </DropdownToggle>
                  <DropdownMenu bottom size='xl' style={{ width: '350px' }}>

                    <DropdownItem header>
                      Filter By Status
                    </DropdownItem>
                    <MenuItem >
                      <Select
                        components={{ Input: CustomSelectInput }}
                        className="basic-multi-select"
                        placeholder="Filter Service Providers"
                        value={providersStatusOptions.find(option => option.key === filter?.providersStatus)}
                        options={providersStatusOptions}
                        onChange={val => {
                          setFilter({ ...filter, providersStatus: val.key })

                        }}
                      />
                    </MenuItem>
                    <DropdownItem header>
                      Filter By Joining Date
                    </DropdownItem>
                    <MenuItem >
                      <DatePicker
                        onChange={(dates) => {
                          let filterDates = dates?.filter(date => date)
                          setFilter({ ...filter, joiningRange: filterDates })
                        }}
                        placeholderText="Select Date"
                        isClearable={true}
                        startDate={filter?.joiningRange?.[0]}
                        endDate={filter?.joiningRange?.[1]}
                        selectsRange={true}
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </MenuItem>
                    <DropdownItem header>
                      Filter By Expiry Date
                    </DropdownItem>
                    <MenuItem >
                      <DatePicker
                        onChange={(dates) => {
                          let filterDates = dates?.filter(date => date)
                          setFilter({ ...filter, expiringRange: filterDates })

                        }}

                        isClearable={true}
                        placeholderText="Select Date"
                        startDate={filter?.expiringRange?.[0]}
                        endDate={filter?.expiringRange?.[1]}
                        selectsRange={true}
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </MenuItem>
                    <DropdownItem header>
                      Filter By Renewal Date
                    </DropdownItem>
                    <MenuItem >
                      <DatePicker
                        onChange={(dates) => {
                          let filterDates = dates?.filter(date => date)
                          setFilter({ ...filter, renewalRange: filterDates })

                        }}

                        isClearable={true}
                        placeholderText="Select Date"
                        startDate={filter?.renewalRange?.[0]}
                        endDate={filter?.renewalRange?.[1]}
                        selectsRange={true}
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </MenuItem>
                    <DropdownItem header>
                      Filter By Package wash Enabled
                    </DropdownItem>
                    <MenuItem >
                      <Select
                        components={{ Input: CustomSelectInput }}
                        className="basic-multi-select"
                        placeholder="Filter Subscription Enabled"
                        value={providersSubscriptionStatus.find(option => option.key === filter?.subscriptionState)}
                        options={providersSubscriptionStatus}
                        onChange={val => {
                          setFilter({ ...filter, subscriptionState: val.key })
                        }}
                      />

                    </MenuItem>
                    <DropdownItem header>
                      Filter By Responsible Agent
                    </DropdownItem>
                    <MenuItem >
                      <Select
                        components={{ Input: CustomSelectInput }}
                        className="basic-multi-select"
                        placeholder="Filter Subscription Enabled"
                        value={admins?.map(rec => {
                          return (filter?.responsibleAgent === (rec.key)) ?
                            ({ ...rec, value: rec.name, label: rec.name, key: rec.id || rec.key }) : ''
                        })
                        }
                        options={admins?.map(rec => {
                          return ({ ...rec, value: rec.name, label: rec.name, key: rec.id || rec.key })
                        })}
                        getOptionLabel={option => {
                          return (
                            <div>
                              {option.name}
                              <Badge color={'primary'} className={'ml-2'} style={{ fontSize: '0.6rem' }} pill>
                                {accessTypesObject?.[option.role]?.name}
                              </Badge>
                            </div>
                          )
                        }}
                        getOptionValue={(option) => { return option.key || option.id }}
                        onChange={val => {
                          setFilter({ ...filter, responsibleAgent: val.key })
                        }}
                      />

                    </MenuItem>
                  </DropdownMenu>
                </Dropdown>
              </Colxx> : ''}
              {user?.accessType === 'superAdmin' ? <Colxx xxs="12" md="8">
                <Button className='btn-sm float-right' style={{ height: '35px' }} onClick={() => exportCSV()}>Export</Button>
              </Colxx> : <></>}
              <Colxx xxs="12">
                {
                  filteredBranches && filteredBranches.length ?
                    <ReactTable
                      data={filteredBranches}
                      columns={dataTableColumns()}
                      defaultPageSize={10}
                      pageSize={filteredBranches.length < 10 ? filteredBranches.length : 10}
                      showPageJump={false}
                      showPageSizeOptions={false}
                      PaginationComponent={DataTablePagination}
                      className='custuomReactTable'
                      TheadComponent={(deviceWidth() === 'mobile') ? TheadComponent : undefined}
                      getTdProps={(state, rowInfo, column, instance) => {
                        return {
                          onClick: (e, handleOriginal) => {
                            // Your action here. For example, logging the row and column
                            // If you want to trigger the original onClick event as well
                            let providerAnalyticsData = analyticsData?.[rowInfo?.original?.key]
                            setSelectedProvider({ ...rowInfo.original, ...providerAnalyticsData })
                            if (handleOriginal) {
                              handleOriginal();
                            }
                          }
                        };
                      }}
                    />
                    :
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Service Providers</div>
                }
              </Colxx>
            </Row>
            <Modal backdrop="static" isOpen={deleteServiceProvider}>
              <ModalHeader >Delete Service Provider</ModalHeader>
              <ModalBody>
                <Row>
                  <Colxx xxs="12" className="mb-4">
                    <Label className="mb-2">Are you sure you want to delete this Service Provider?</Label>
                  </Colxx>
                </Row>

              </ModalBody>
              <ModalFooter>
                <Button onClick={() => setDeleteServiceProvider(false)}>Dismiss</Button>
                <Button color='danger' onClick={() => {
                  setIsLoading(true)
                  firestore().collection('service_provider').doc(deleteServiceProvider.key).update({
                    deActivated: true,
                    updated: Date.now(),
                    active: false
                  }).then(() => {
                    setIsLoading(false)
                    setDeleteServiceProvider(false)
                  })
                }}>Delete Service Provider</Button>
              </ModalFooter>

            </Modal>

          </div>
        </Fragment>

  );
};

export default OutletsList;