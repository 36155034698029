import LogRocket from 'logrocket';
import moment from "moment";
import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import NotificationContainer from "./components/common/react-notifications/NotificationContainer";
import "./helpers/Firebase";
import { auth, firestore } from "./helpers/Firebase";
import { accessTypes, createNotification, getDirection } from "./helpers/Utils";
import AppLocale from "./lang";
import {
  getAdvServices,
  getAppointmentsList,
  getBanners,
  getList,
  getModifiers,
  getServices,
  getUpSeLlingList,
  getVariations,
  getCustomersVouchers,
  getWasherList,
  loginUserSuccess,
  logoutUser,
  getVouchers
} from "./redux/actions";
import main from "./views";
import app from "./views/app";
import error from "./views/error";
import ProcessPayment from './views/handlePayment';
// import Payment from './views/payment.js';
import user from "./views/user";

if (window.location.hostname !== 'localhost') {
  LogRocket.init('5eafnv/chill');
}

const AuthRoute = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authUser?.uid ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/user/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
};

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      deactivatedProvider: false
    }
  }

  componentWillMount() {
    let unsubscribe;

    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add("rtl");
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.body.classList.remove("rtl");
    }

    auth().onAuthStateChanged(user => {
      let _this = this
      let currentDate = moment().subtract(7, 'days').valueOf()
      if (user) {
        this.setState({ loading: true }, () => {
          user.getIdTokenResult(true).then(async result => {
            let { role } = result.claims

            if (!['superAdmin', 'finance', 'customerService', 'marketing', 'admin'].includes(role)) {
              await auth().signOut()
              localStorage.removeItem('user_id');
              localStorage.removeItem('role');
              this.props.loginUserSuccess({})
              this.setState({ loading: false })
              return createNotification("error", 'This user is not authorized to login to dashboard', "Error")
            }

            let post = {
              email: user.email,
              uid: user.uid,
              displayName: user.displayName,
              accessType: role,
              phoneNumber: user.phoneNumber
            }
            LogRocket.identify(user.uid, {
              name: user.displayName,
              email: user.email
            });

            if (["admin"].includes(role)) {
              firestore().collection('service_provider').doc(user.uid).onSnapshot(async function (snap) {
                let providerData = snap.data()
                if ((providerData.expiryDate && moment().isAfter(moment(providerData.expiryDate))) || providerData?.deActivated) {
                  // if ((providerData.expiryDate && moment().isAfter(moment(providerData.expiryDate))) || providerData?.deActivated || !providerData?.active) {
                  await auth().signOut()
                  localStorage.removeItem('user_id');
                  localStorage.removeItem('role');
                  _this.props.loginUserSuccess({})
                  _this.setState({ loading: false, deactivatedProvider: true })
                  return
                }
              })
              let snap = await firestore().collection('service_provider').doc(user.uid).get()
              let providerData = snap.data()
              if ((providerData.expiryDate && moment().isAfter(moment(providerData.expiryDate))) || providerData?.deActivated) {
                // if ((providerData.expiryDate && moment().isAfter(moment(providerData.expiryDate))) || providerData?.deActivated || !providerData?.active) {
                await auth().signOut()
                localStorage.removeItem('user_id');
                localStorage.removeItem('role');
                _this.props.loginUserSuccess({})
                _this.setState({ loading: false, deactivatedProvider: true })
                return
              }
            }

            let ref = firestore().collection('bookings')
            if (!accessTypes.includes(role)) {
              ref = ref.where("sp_id", "==", user.uid)
            }
            let unsubRef;

            unsubscribe = ref.where("status", 'not-in', ['rejected', 'canceled', 'rated', 'completed', 'notRated']).onSnapshot(async function (querySnapshot) {
              if (unsubRef) {
                unsubRef()
              }
              unsubRef = ref.where("status", 'in', ['completed', 'notRated']).where('bookingDate', '>=', currentDate).onSnapshot(function (completedSnapshot) {
                if (querySnapshot.empty && completedSnapshot.empty) {
                  _this.props.getAppointmentsList([])
                }
                let data = [...querySnapshot.docs, ...completedSnapshot.docs]?.map(rec => {
                  return ({ ...rec.data(), id: rec.id, key: rec.id })
                });
                data = data?.reduce((val, currentVal) => {
                  let now = moment()
                  let start = moment(currentVal?.bookingDate)
                  let diffStart = start.diff(now, 'minutes')
                  if (diffStart < 20 && currentVal?.status === 'accepted' && currentVal?.type !== 'offlineBooking') {
                    currentVal.delayed = 'delayedActive'
                  } else if (diffStart < -60 && currentVal?.status === 'active' && currentVal?.type !== 'offlineBooking') {
                    currentVal.delayed = 'delayedCompleted'
                  }
                  val.push(currentVal)
                  return val
                }, []);
                _this.props.getAppointmentsList(data || [])
              })

            });


            firestore().collectionGroup('walletLog').where('type', '==', 'voucher').onSnapshot(async function (querySnapshot) {
              let data = querySnapshot?.docs?.map(rec => {
                return ({ ...rec.data(), id: rec.id, key: rec.id })
              });
              _this.props.getCustomersVouchers(data)
            });

            this.props.loginUserSuccess(post)

            let db = [
              { ref: 'service_provider', func: 'getList' },
              { ref: 'washers', func: 'getWasherList' },
              { ref: 'up_selling', func: 'getUpSeLlingList' },
              { ref: 'services', func: 'getServices' },
              { ref: 'modifiers', func: 'getModifiers' },
              { ref: 'advServices', func: 'getAdvServices' },
              { ref: 'tintingVariations', func: 'getVariations' },
              { ref: 'banners', func: 'getBanners' },
              { ref: 'vouchers', func: 'getVouchers' }
            ];

            db.forEach(itm => {
              let ref2 = firestore().collection(itm.ref)

              if (itm.ref === "service_provider" && !accessTypes.includes(role)) {
                ref2 = ref2.where("key", "==", user.uid)

              } else if (!accessTypes.includes(role) && itm.ref !== "services" && itm.ref !== "advServices" && itm.ref !== "modifiers") {
                ref2 = ref2.where("sp_id", "==", user.uid)
              }

              ref2.onSnapshot(snap => {
                let allData = {}

                snap.forEach(rec => {
                  let data = rec.data()

                  if (!data.deActivated) {
                    allData[rec.id] = { ...data, key: rec.id, value: data.name, label: data.name }
                    return false
                  }
                })
                this.props[itm.func](allData)
                this.setState({ loading: false })

              })
            })
            // } else {
            //   localStorage.removeItem('user_id');
            //   localStorage.removeItem('accessType');
            //   this.props.loginUserSuccess({})
            //   this.setState({ loading: false })
            // }
          })
        })

      } else {
        if (unsubscribe) {
          unsubscribe()
        }


        localStorage.removeItem('user_id');
        localStorage.removeItem('accessType');
        this.props.loginUserSuccess({})
        this.setState({ loading: false })
      }
    })
  }

  render() {
    const { locale, loginUser } = this.props;
    let { loading, deactivatedProvider } = this.state
    const currentAppLocale = AppLocale[locale];

    if (loading) {
      return <div className="loading" />
    }
    return (
      <div className="h-100">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <React.Fragment>
            <Modal backdrop="static" isOpen={deactivatedProvider}>
              <ModalHeader >Dear Service Provider</ModalHeader>
              <ModalBody>
                <Label className="mb-2">
                  Be informed that your account has been deactivated or your subscription has expired. Please contact us to reactivate or renew your subscription.
                </Label>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => this.setState({ deactivatedProvider: false })}>Dismiss</Button>
              </ModalFooter>

            </Modal>

            <NotificationContainer />
            {/* {isMultiColorActive && <ColorSwitcher />} */}
            <Router>
              <Switch>
                {/* <Route path="/pay" component={Payment} /> */}
                <Route path='/processpayment' component={ProcessPayment} />
                <AuthRoute path="/app" authUser={loginUser} component={app} />
                {
                  loginUser && loginUser.uid && <Redirect to='/app' />
                }
                <Route path="/user" component={user} />
                <Route path="/error" exact component={error} />
                <Route path="/" exact component={main} />
                <Redirect to="/error" />
              </Switch>
            </Router>
          </React.Fragment>
        </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { user } = authUser;
  const { locale } = settings;
  return {
    loginUser: user, locale,
  };
};
const mapActionsToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
  loginUserSuccess: (user) => dispatch(loginUserSuccess(user)),
  getList: (user) => dispatch(getList(user)),
  getWasherList: (user) => dispatch(getWasherList(user)),
  getAppointmentsList: (bookings) => dispatch(getAppointmentsList(bookings)),
  getUpSeLlingList: (user) => dispatch(getUpSeLlingList(user)),
  getServices: (user) => dispatch(getServices(user)),
  getModifiers: (user) => dispatch(getModifiers(user)),
  getAdvServices: (user) => dispatch(getAdvServices(user)),
  getVariations: (user) => dispatch(getVariations(user)),
  getBanners: (user) => dispatch(getBanners(user)),
  getCustomersVouchers: (user) => dispatch(getCustomersVouchers(user)),
  getVouchers: (user) => dispatch(getVouchers(user))
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(App);
