import React, { Component } from "react";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import WasheImg from '../../assets/css/img/washer.png'

class TopWashers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  render() {
    let { washers } = this.props

    return (
      <Card className="rounded" style={{ height: '100%' }}>
        <CardBody>
          <CardTitle className="mb-2">Top Washers</CardTitle>
          <p className="text-muted mb-5">based on number of bookings</p>
          <Table borderless>
            <thead>
              <tr>
                <th className="text-semi-muted mb-2" style={{ width: '50px' }} />
                <th className="text-semi-muted mb-2" >
                  Name
                </th>
                <th className="text-semi-muted mb-2">
                  Number
                </th>
                <th className="text-semi-muted mb-2">
                  Total Booking
                </th>
              </tr>
            </thead>
            <tbody>
              {
                washers?.map((washer, index) => {
                  return (
                    <tr key={index}>
                      <td >
                        <img
                          style={{ maxWidth: '50px', objectFit: 'cover', borderRadius: '8px', boxShadow: '0 0px 4px lightgray', padding: '4px' }}
                          src={washer?.profile_image ? washer?.profile_image[0] : WasheImg}
                          alt={washer?.profile_image ? washer?.profile_image[0] : WasheImg}
                          className="list-thumbnail border-0"
                        />
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {washer.name}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {washer.number}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {washer.totalBookings}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}
export default TopWashers