import React, { useState } from "react";
import {
    Button,
    Modal,
    ModalFooter,
    Input,
    Row, Card, CardBody, ModalHeader
} from "reactstrap";
import { Colxx } from "../../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { firestore, functions } from "../../../helpers/Firebase";
import { createNotification } from "../../../helpers/Utils";

const Admin = ({ dismiss, selectedUser }) => {
    const [user, updateUser] = useState(selectedUser || {})
    const [loading, setLoading] = useState(false)
    const roles = [
        { key: 'superAdmin', value: 'Super Admin', label: 'Super Admin', },
        { key: 'finance', value: 'Finance', label: 'Finance', },
        { key: 'customerService', value: 'Customer Service', label: 'Customer Service', },
        { key: 'marketing', value: 'Marketing', label: 'Marketing', }
    ]
    const submit = async (user) => {
        setLoading(true)
        const CreateAdmins = functions().httpsCallable('CreateAdmins');
        if (user.addNew) {
            let result = await CreateAdmins({ displayName: user.name, email: user.email, pw: user.password, role: user.role, type: 'new' })
            delete user.addNew
            delete user.password
            if (!result?.data?.error) {
                user.key = result?.data?.user
                firestore().collection('admin').doc(result?.data?.user).set(user, { merge: true }).then(() => {
                    setLoading(false)
                    dismiss()
                })
            } else {
                setLoading(false)
                createNotification('error', result?.data?.error?.errorInfo?.message, 'Error');
            }
        } else {
            let result = await CreateAdmins({ displayName: user.name, email: user.email, role: user.role, type: 'update', uid: user.key })
            delete user.addNew
            delete user.password
            if (!result?.data?.error) {
                firestore().collection('admin').doc(user.key).set(user, { merge: true }).then(() => {
                    setLoading(false)
                    dismiss()
                })
            } else {
                setLoading(false)
                createNotification('error', result?.data?.error?.errorInfo?.message, 'Error');
            }
        }
    }
    return (
        <Modal
            isOpen={true}
            // toggle={dismiss}
            backdrop="static"
            size="lg">
            <div>
                {loading && (
                    <div className="loading" />
                )}
                <Row className="invoice-react">
                    <Colxx xxs="12" >
                        <ModalHeader toggle={dismiss}>
                            <div className="d-flex flex-column justify-flex-start" style={{ alignItems: 'flex-start' }} >
                                <h6 className="font-weight-bold m-0 mb-2">Edit User</h6>
                            </div>
                        </ModalHeader>
                        <Card className="invoice-contents" style={{ height: 'auto', background: 'transparent', boxShadow: 'none' }}>
                            <CardBody className="d-flex flex-column justify-content-between">
                                <div className="d-flex flex-column">
                                    {[{
                                        key: 'generalInfo', title: 'General Information', data: [
                                            { key: 'name', title: 'Name', value: '', width: '12' },
                                            { key: 'email', title: 'Email', value: '', width: '12', type: 'email' },
                                            { key: 'password', title: 'Password', value: '', width: '12', hide: !user.addNew, type: 'password' },
                                            { key: 'role', title: 'Access Type', value: '', width: '12', type: 'select' },
                                        ]
                                    },
                                    ].map((rec, recIndex) => {
                                        return (
                                            <Row key={recIndex} >
                                                {/* <Colxx xxs="12" className="mb-2">
                                                    <h4>{rec.title}</h4>
                                                </Colxx> */}
                                                {rec.data.map((item, index) => {
                                                    return !item.hide && (
                                                        <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                            <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                                                <div>{item.title}</div>

                                                            </div>
                                                            {item.type === 'select' ?
                                                                <Select
                                                                    components={{ Input: CustomSelectInput }}
                                                                    // className="basic-multi-select"
                                                                    placeholder={item.title}
                                                                    value={roles.find(r => r.key === user.role) || {}}
                                                                    options={roles.map(rec => {
                                                                        return rec
                                                                    })}
                                                                    onChange={val => {
                                                                        updateUser({ ...user, [item.key]: val.key })

                                                                    }}
                                                                />
                                                                : <Input
                                                                    type={item.type}
                                                                    placeholder={"e.g. " + item.title}
                                                                    value={user?.[item.key] || ''}
                                                                    className="customInput"
                                                                    onChange={(e) => {
                                                                        updateUser({ ...user, [item.key]: e.target.value })

                                                                    }} />}
                                                        </Colxx>
                                                    )
                                                })}
                                            </Row>
                                        )
                                    })}
                                </div>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
            </div>

            <ModalFooter>
                <Button color="danger" outline onClick={() => dismiss()}>
                    Dismiss
                </Button>
                <Button disabled={!user.name || !user.email || !user.role || (user.addNew ? !user.password : false)} color="primary" outline onClick={() => {
                    submit(user)
                }}>
                    Submit
                </Button>{" "}
            </ModalFooter>
        </Modal >
    )
};

export default Admin;