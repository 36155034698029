import React, { Component, Fragment } from "react";
import {
    Row,
    Card,
    CardBody,
} from "reactstrap";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import moment from "moment";
import Select from "react-select";
import { components } from "react-select";
import { NotificationManager } from "../../../../components/common/react-notifications";
import CarWash from './carWash'
import WindowTint from './windowTint'

import 'react-phone-input-2/lib/style.css'
const CustomSelectInput = props => {
    var customProps = Object.assign({}, props);
    delete customProps.autoCorrect;
    delete customProps.autoCapitalize;
    return <components.Input {...customProps} />;
};

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sp_id: '',
            timings: {},
            name: '',
            name_ar: '',
            number: '',
            country_code: '',
            phoneNumber: '',
            location: {},
            profile_image: '',
            pictures: [],

            changeNumber: false,
            servicesCat: {}
        };

    }
    createNotification = (className, error) => {
        let cName = className || "";
        return (
            NotificationManager.error(
                error,
                "Error",
                2000,
                null,
                cName
            )
        )
    };


    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    componentWillMount() {
        let { services, advServices } = this.props
        let { servicesCat } = this.state
        servicesCat.carWash = { services: services, key: 'carWash', value: 'Car Wash', label: 'Car Wash' }
        servicesCat.windowTint = { services: advServices, key: 'windowTint', value: 'Window Tint', label: 'Window Tint' }

        this.setState({
            servicesCat,
            selectedServiceCat: { services: services, key: 'carWash', value: 'Car Wash', label: 'Car Wash' }
        }, () => {
            this.getAllActiveServices()
        })
    }


    getAllActiveServices() {
        let { selectedServiceCat } = this.state
        let { services, modifiers, advServices, activeServices } = this.props

        let allActiveServices = []
        let windowTintService = advServices['T6AWbnnnGwU1W81HMBUZ']

        switch (selectedServiceCat.key) {
            case 'carWash':
                Object.keys(activeServices).map(item => {
                    let service = {}
                    return (
                        (services[item]) && (
                            service.key = services[item].key,
                            service.serviceModifiers = services[item].modifiers,
                            service.name = services[item].name,
                            service.name_ar = services[item].name_ar,
                            service.washingService = services[item]?.washingService,
                            service.index = services[item]?.index,
                            service.modifiers = Object.keys(activeServices[item]).map((modifier, index) => {

                                return (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <div style={{ marginRight: '8px' }}>
                                            {modifiers[modifier]?.name}
                                        </div>
                                        <div>
                                            {
                                                JSON.stringify([
                                                    { key: 'price', title: 'Price' },
                                                    { key: 'duration', title: 'Duration' },
                                                    { key: 'waterlessPrice', title: 'Waterless Price' },
                                                    { key: 'waterlessDuration', title: 'Waterless Duration' },
                                                    { key: 'steamWashPrice', title: 'Steam Wash Price' },
                                                    { key: 'steamWashDuration', title: 'Steam Wash Duration' },
                                                ].map((rec, index) => {
                                                    const value = activeServices?.[item]?.[modifier]?.[rec?.key];
                                                    return value && (
                                                        rec?.title + ": " + value
                                                    );
                                                }).filter(Boolean).join(', '))
                                            }
                                        </div>

                                        {/* {modifiers[modifier]?.name + " ( Price: " + activeServices[item][modifier].price + " AED , Duration: " + activeServices[item][modifier].duration + " )"} */}
                                        <br />
                                    </div>)
                            }),
                            allActiveServices.push(service)
                        )
                    )
                })
                this.setState({ allActiveServices })

                break;
            case 'windowTint':
                Object.keys(activeServices).map(item => {
                    return (
                        (advServices[item]) && (
                            Object.keys(activeServices[item]).map((modifier) => {
                                return (
                                    allActiveServices.push({ ...modifiers[modifier], price: activeServices[item][modifier].price, duration: activeServices[item][modifier].duration, variations: activeServices[item][modifier].variations })
                                )
                            })
                        )
                    )
                })
                this.setState({ allActiveServices, windowTintService })

                break;
            default:
                break;
        }

    }


    render() {
        let { selectedServiceCat, allActiveServices, windowTintService } = this.state
        let { selectedWasher, services, advServices, modifiers, update, activeServices, servicesDescs, variations, selectedProvider } = this.props
        let options = []
        let startDuration = '00:15';
        for (var i = 0; i < 26; i++) {
            var obj = { label: startDuration, value: startDuration, key: i + 1 };
            startDuration = moment(startDuration, 'HH:mm').add('15', 'minutes').format('HH:mm');
            options.push(obj);
        }

        return (

            <Fragment>
                <Row className='h-100'>
                    <Colxx xxs="12" style={{ minHeight: '400px' }}>
                        <Row className='h-100'>
                            <Colxx xxs="12" md="3" className="mb-4 mt-4">
                                <Card className="rounded h-100">
                                    <CardBody>
                                        <h4>Services Categories</h4>
                                        <Select
                                            components={{ Input: CustomSelectInput }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="form-field-name"
                                            options={Object.values(this.state.servicesCat).filter(rec => {
                                                return rec.key !== 'carWash' && !activeServices['T6AWbnnnGwU1W81HMBUZ']
                                            })}
                                            value={""}
                                            onChange={val => {
                                                activeServices['T6AWbnnnGwU1W81HMBUZ'] = {}
                                                this.setState({ activeServices })
                                            }}
                                        />

                                        {
                                            Object.values(this.state.servicesCat).map((item) => {
                                                return (item.key === 'carWash' || activeServices['T6AWbnnnGwU1W81HMBUZ']) && (
                                                    <div key={item.id} id={item.id} data-id={item.id}>
                                                        <Row className='mt-2 p-2 d-flex align-items-center mb-2' style={{ justifyContent: 'flex-start', borderRadius: "4px", background: selectedServiceCat && selectedServiceCat.key === item.key ? '#d3d3d352' : 'none' }}>
                                                            <span className=" badge badge-pill w-5 d-flex align-items-center" >
                                                                <i className='simple-icon-cursor-move' />
                                                            </span>

                                                            <div onClick={() => {
                                                                // let selectedItems = item.items && item.items.length ? item.items.map(rec => rec.key) : []
                                                                this.setState({ selectedServiceCat: item, allActiveServices: [] }, () => {
                                                                    this.getAllActiveServices()
                                                                })
                                                            }} style={{ cursor: 'pointer', fontSize: '14px', fontWeight: '500' }} className="w-60">{item.value}</div>

                                                            {item.key === 'windowTint' && <div className="badge badge-pill handle d-flex align-items-center btn"
                                                                onClick={() => {
                                                                    delete activeServices['T6AWbnnnGwU1W81HMBUZ']
                                                                    this.setState({
                                                                        activeServices,
                                                                        selectedServiceCat: { services: services, key: 'carWash', value: 'Car Wash', label: 'Car Wash' }
                                                                    }, () => {
                                                                        this.getAllActiveServices()
                                                                    })
                                                                }}>
                                                                <i className='simple-icon-trash' style={{ fontSize: '16px', color: '#dc3545' }} />
                                                            </div>}
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </CardBody>
                                </Card>
                            </Colxx>
                            {(selectedServiceCat?.key) === 'carWash' ?
                                <Colxx xxs="12" md="9" className="mb-4 mt-4">
                                    <CarWash
                                        activeServices={activeServices}
                                        selectedProvider={selectedProvider}
                                        allActiveServices={allActiveServices}
                                        services={services}
                                        options={options}
                                        servicesDescs={servicesDescs}
                                        modifiers={modifiers}
                                        update={(e) => {
                                            update(e)
                                            this.getAllActiveServices()
                                        }} />

                                </Colxx>
                                : (selectedServiceCat?.key) === 'windowTint' ?
                                    <Colxx xxs="12" md="9" className="mb-4 mt-4">

                                        <WindowTint
                                            selectedWasher={selectedWasher}
                                            activeServices={activeServices}
                                            selectedProvider={selectedProvider}
                                            allActiveServices={allActiveServices}
                                            services={advServices}
                                            service={windowTintService}
                                            options={options}
                                            variations={variations}
                                            servicesDescs={servicesDescs}
                                            modifiers={modifiers}
                                            update={(e) => {
                                                update(e)
                                                this.getAllActiveServices()
                                            }} />
                                    </Colxx>
                                    : ''
                            }

                            {/* {selectedServiceCat?.key === 'carWash' ? */}

                        </Row>

                    </Colxx>
                </Row>
            </Fragment >

        )
    }
}


export default (Services);
