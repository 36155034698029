import moment from 'moment';
import React, { Fragment, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import ReactTable from "react-table";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Collapse,
  Row
} from "reactstrap";
import DataTablePagination from "../../../components/DatatablePagination";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import { accessTypes } from "../../../helpers/Utils";
import './appointments.css';
import AddNewAppointment from "./components/AddNewAppointment";
import Order from "./components/order";
import TodoApplicationMenu from "./components/ordersFilter";

const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}
const TheadComponent = props => null; // a component returning null (to hide) or you could write as per your requirement

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
const Requests = () => {
  const { user } = useSelector(state => state.authUser)
  const { provider, washers, bookings, services, advServices, } = useSelector(state => state.providers)
  const [selectedFilter, setFilter] = useState({})
  const [selectedBooking, setSelectedBooking] = useState(false)
  const [filteredBookings, setFilteredBookings] = useState([])
  const [activeServices, setActiveServices] = useState({})
  const [newOrder, setNewOrder] = useState(false)
  const [search, setSearchKeyword] = useState('')

  const [displayOptionsIsOpen, setDisplayOptionsIsOpen] = useState(false)

  useEffect(() => {
    let allServices = Object.values(services || {})?.concat(Object.values(advServices || {}))
    const updatedActiveServices = {};
    Object.values(washers || {}).forEach(washer => {
      if (washer.activeServices) {
        const activeServiceKeys = Object.keys(washer.activeServices);
        const filteredServices = allServices.filter(service => activeServiceKeys.includes(service.key));
        filteredServices.forEach(service => {
          updatedActiveServices[service.key] = {
            ...service,
            modifier: washer.activeServices[service.key],
            label: service.name,
            value: service.name
          };
        });
        setActiveServices((prevActiveServices) => {
          return { ...prevActiveServices, ...updatedActiveServices };
        });
      }
    });
  }, [services, advServices, washers])

  const dataTableColumns = () => {
    return ([
      {
        width: 0,
        accessor: "new",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => (
          props.original.status === 'new' &&
          <div className="newBookingBadge">
            <Badge
              className="mb-1"
              color={`success`}
              pill>
              New
            </Badge>
          </div>
        )
      },
      {
        width: 0,
        accessor: "created",
        Cell: props => (
          props.original.status === 'new' &&
          <div className="createdBadge">
            <Badge
              className="mb-1"
              color={`primary`}
              pill>
              {(moment(props.original.created).to(moment()).replace('in ', '')) + " Ago"}
            </Badge>
          </div>
        )
      },
      {
        width: deviceWidth() === 'desktop' ? 120 : 80,
        Header: "Booking Date",
        accessor: "bookingDate",
        Cell: props => <div className="datefieldText">{moment(props.value).format('DD/MM/YYYY')}<br />{moment(props.value).format('hh:mm A')}</div>
      },
      {
        Header: "Service Provider",
        accessor: "sp_name",
        show: accessTypes.includes(user?.accessType) ? true : false,
        Cell: props => <div className="serviceWasherfieldText">{props.value}</div>

      },
      {
        Header: "Customer",
        accessor: "customer_name",
        Cell: props => <div className="serviceWasherfieldText">{props.value}<br />{accessTypes?.includes(user?.accessType) ? props.original.customer_number : ''}</div>
      },
      {
        Header: "Service Name",
        accessor: "service_name",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <div className="serviceWasherfieldText">{props.value}</div>
      },
      {
        Header: "Washer Name",
        accessor: "washer_name",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <div className="serviceWasherfieldText">{props.value}</div>
      },

      {

        Header: "Status",
        accessor: "status",
        Cell: props => <div className={(
          props.original.status === 'pending' || props.original.status === 'new' ?
            "pendingStatusfieldText"
            : props.original.status === 'accepted' ?
              "acceptedStatusfieldText"

              : props.original.status === 'active' ?
                "processStatusfieldText"

                : props.original.status === 'completed' || props.original.status === 'notRated' ?
                  "completedStatusfieldText"

                  : props.original.status === 'rejected' ?
                    "rejectedStatusfieldText"

                    : props.original.status === 'rated' ?
                      "ratedStatusfieldText"

                      : props.original.status === 'canceled' ?
                        "rejectedStatusfieldText"
                        : ''
        )
        }>{props.value === 'new' ? 'Pending' : props.value === 'notRated' ? "Completed" : capitalize(props.value)}</div>
      },
      {
        Header: "Type",
        accessor: "type",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <div className={props.value === "onlineBooking" ? "onliefieldText" : "offlinefieldText"}>{props.value === "onlineBooking" ? 'Online' : 'Offline'}</div>
      },

      {
        accessor: "edit",
        width: 100,
        Cell: props => <div >
          <Button
            style={{ padding: '0.2rem 1rem' }}
            color="primary"
            size="sm"
            outline
            // className="top-right-button"
            onClick={() => {
              setSelectedBooking(props.original)
            }}
          >View
          </Button>
        </div>
      },
    ])
  }

  const toggleModal = () => {
    setNewOrder(false)
  };
  const dismiss = () => {
    setSelectedBooking(false)
    setNewOrder(false)
  };

  const filter = (selectedFilter) => {
    let filteredBookings = []
    filteredBookings = Object.values(bookings || {})?.filter(item => {
      return (
        (selectedFilter?.service?.length ? selectedFilter.service.includes(item?.service_id) : true) &&
        (selectedFilter?.providers?.length ? selectedFilter.providers.includes(item?.sp_id) : true) &&
        (selectedFilter?.washers?.length ? selectedFilter.washers.includes(item?.washer_id) : true) &&
        (selectedFilter?.status?.length ? selectedFilter.status.includes(item.status) : true)
      )
    })?.filter(item => {
      return selectedFilter?.search ? (item?.customer_name?.toLowerCase().includes(selectedFilter?.search) || item?.customer_number?.toLowerCase().includes(selectedFilter?.search) || item?.service_name?.toLowerCase().includes(selectedFilter?.search) || item.status.toLowerCase().includes(selectedFilter?.search) || item?.washer_name?.toLowerCase().includes(selectedFilter?.search)) : true
    })
    setFilteredBookings(filteredBookings)
  }
  useEffect(() => {
    filter(selectedFilter)
    // eslint-disable-next-line 
  }, [selectedFilter, bookings, search])

  return (
    <Fragment>
      {!newOrder ? <Row className="app-row survey-app">
        <Colxx xxs="12">

          <div className="mb-2">
            <h1>New Requests</h1>
            <div className="text-zero top-right-button-container">
              {!accessTypes.includes(user?.accessType) ? <Button
                color="primary"
                style={{ borderRadius: '50px' }}
                size={deviceWidth() === 'desktop' ? "lg" : "md"}
                className="top-right-button mr-1"
                onClick={() => setNewOrder(true)}>

                Create Appointment
              </Button> : ''}
            </div>
          </div>

          <div className="mb-2">
            <Button
              color="empty"
              className="pt-0 pl-0 d-inline-block d-md-none"
              onClick={() => { setDisplayOptionsIsOpen(!displayOptionsIsOpen) }}
            >
              Display Options{" "}
              <i className="simple-icon-arrow-down align-middle" />
            </Button>

            <Collapse
              id="displayOptions"
              className="d-md-block mb-2"
              isOpen={displayOptionsIsOpen}
            >
              <div className="d-block d-md-inline-block">

                <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={"Search"}
                    defaultValue={search}
                    onChange={(e) => {
                      setFilter({ ...selectedFilter, search: e.target.value.toLowerCase() })
                      setSearchKeyword(e.target.value.toLowerCase())
                    }}
                  />
                </div>
              </div>

            </Collapse>
          </div>
          <Separator className="mb-5" />
          <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
              filteredBookings && filteredBookings.length ?
                (deviceWidth() === 'mobile') ?
                  (
                    <ReactTable
                      data={filteredBookings}
                      columns={dataTableColumns()}
                      defaultPageSize={10}
                      pageSize={filteredBookings.length > 10 ? 10 : filteredBookings.length}
                      showPageJump={false}
                      showPageSizeOptions={false}
                      PaginationComponent={DataTablePagination}
                      className='custuomReactTable'
                      style={{ width: '100%' }}
                      defaultSorted={[
                        {
                          id: "bookingDate",
                          desc: false
                        }
                      ]}
                      TheadComponent={TheadComponent}
                    />
                  ) :
                  <ReactTable
                    data={filteredBookings}
                    columns={dataTableColumns()}
                    defaultPageSize={10}
                    pageSize={filteredBookings.length > 10 ? 10 : filteredBookings.length}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    PaginationComponent={DataTablePagination}
                    className='custuomReactTable'
                    style={{ width: '100%' }}
                    defaultSorted={[
                      {
                        id: "bookingDate",
                        desc: false
                      }
                    ]} /> :
                <div >{"No Requests"}</div>
            }
          </Row>
        </Colxx>
        <TodoApplicationMenu activeServices={activeServices}
          bookingType={'Requests'}
          todoItems={Object.values(bookings || {})}
          filter={(selectedFilter) => {
            setFilter({ ...selectedFilter, search: search })
          }}
        />
        {selectedBooking && <Order
          bookings={bookings}
          washers={washers}
          providers={provider}
          activeServices={activeServices}
          toggleModal={toggleModal}
          dismiss={dismiss}
          selectedBooking={JSON.parse(JSON.stringify(selectedBooking))}
        />
        }
      </Row> :

        <Row className="pb-5">
          <Colxx xxs="12">
            <Card className="rounded h-100">
              <CardBody>
                <AddNewAppointment bookings={bookings}
                  activeServices={activeServices}
                  washers={washers}
                  toggleModal={toggleModal}
                  dismiss={dismiss}
                  selectedBooking={JSON.parse(JSON.stringify(selectedBooking))}
                />
              </CardBody>
            </Card>

          </Colxx>
        </Row>
      }

    </Fragment >
  );
};

export default Requests;