import React, { Component, Fragment } from "react";
import { Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import '../providers/outletsList.css'
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import Washer from "./washer";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import { Colxx } from "../../../components/common/CustomBootstrap";
import WasherImg from '../../../assets/css/img/washer.png';
import { firestore } from '../../../helpers/Firebase'
import Switch from "rc-switch";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { NotificationManager } from "../../../components/common/react-notifications";
import { accessTypes, accessTypesObject } from "../../../helpers/Utils";
import BusyTimingsLogs from "./components/busyTimingsLogs";

const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}
const TheadComponent = props => null; // a component returning null (to hide) or you could write as per your requirement

const endDate = new Date();
endDate.setDate(endDate.getDate() + 3);
class WashersList extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require('mousetrap');
    this.state = {
      displayMode: "thumblist",
      totalPage: 1,
      search: "",
      isLoading: true,
      selectWasher: false,
      selectedWasher: {},
      allWashers: [],
      filteredWashers: [],
      addNewWasher: false,
      startDate: new Date(),
      endDate: endDate,

      providers: [],
      modifiers: {},
      deleteWasher: false,
      loading: true,
      activateWorker: false,
      permenant: false,
      minTime: this.calculateMinTime(new Date())

    };
  }
  calculateMinTime = date => {
    let isToday = moment(date).isSame(moment(), 'day');
    if (isToday) {
      let nowAddOneHour = moment(new Date()).add({ hours: 1 }).toDate();
      return nowAddOneHour;
    }
    return moment().startOf('day').toDate();
  }


  dataTableColumns() {
    let { authUser } = this.props
    return ([
      {
        // Header:
        //   <Switch
        //     className="custom-switch custom-switch-primary"
        //     checked={filterBoolean}
        //     onChange={switchCheckedPrimary => {
        //       _this.setState({ filterBoolean: switchCheckedPrimary })
        //       _this.filterActiveWashers(switchCheckedPrimary)
        //     }}
        //   />
        // ,

        sortable: false,
        accessor: "active",
        width: 80,
        Cell: props => <p className="list-item-heading">
          <Switch
            className="custom-switch custom-switch-primary"
            checked={props.value}
            onChange={switchCheckedPrimary => {
              if (!switchCheckedPrimary) {
                this.setState({ activateWorker: props.original })
              } else {
                firestore().collection('washers').doc(props.original.key).update({ active: switchCheckedPrimary, busyTimings: false, updated: Date.now() })
              }
            }}
          />
        </p>
      },
      {
        sortable: false,
        accessor: "profile_image",
        width: deviceWidth() === 'desktop' ? 150 : 80,
        Cell: props => <div className="washerfieldText"><img style={{ width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={props.value && props.value[0] ? props.value[0] : WasherImg} alt={props.value} /></div>
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: props => <p className="washerfieldText">{props.value}</p>
      },
      {
        Header: "Arabic Name",
        accessor: "name_ar",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="washerfieldText">{props.value}</p>
      },
      {
        Header: "Service Provider",
        accessor: "providerName",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="washerfieldText">{props.value}</p>
      },
      {
        Header: "Mobile Number",
        accessor: "phoneNumber",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="washerfieldText">{props.value}</p>
      },
      {
        Header: "Last Updated",
        accessor: "updated",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="washerfieldText">{moment(props.value).format('DD/MM/YYYY hh:mm A')}</p>
      },
      {
        Header: "Busy Timings",
        accessor: "busyTimings",
        show: deviceWidth() === 'desktop' ? true : false,
        width: deviceWidth() === 'desktop' ? 200 : 80,
        Cell: props => props.value && moment().isBefore(moment(props.value?.endTime)) ? <p className="washerfieldText">
          {"From " + moment(props.value?.startTime).format('DD/MM/YYYY hh:mm A')}<br />{"To " + moment(props.value?.endTime).format('DD/MM/YYYY hh:mm A')}
          {accessTypes.includes(authUser?.user?.accessType) ? <div
            style={{ fontSize: '12px', color: '#3498db', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontWeight: '600' }}
            onClick={() => this.setState({ busyTimngsLogs: props.original })}
          >
            Show Logs <i className="simple-icon-eye" style={{ marginLeft: '8px' }} />
          </div> : ''}
        </p> : <> {accessTypes.includes(authUser?.user?.accessType) ? <div
          style={{ fontSize: '12px', color: '#3498db', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontWeight: '600' }}
          onClick={() => this.setState({ busyTimngsLogs: props.original })}
        >
          Show Logs <i className="simple-icon-eye" style={{ marginLeft: '8px' }} />
        </div> : ''}</>
      },
      {
        sortable: false,
        width: 80,
        accessor: "delete",
        // show: deviceWidth() === 'desktop' ? true : false,
        show: (authUser?.user?.accessType === 'superAdmin') && deviceWidth() === 'desktop' ? true : false,

        Cell: props => <p className="washerfieldText">
          <Button
            color="danger"
            size="sm"
            outline
            onClick={() => this.setState({ deleteWasher: true, selectedWasher: props.original })}
          >
            Delete
          </Button>
        </p>
      },
      {
        sortable: false,
        width: 70,
        accessor: "edit",
        Cell: props => <p className="washerfieldText">
          <Button
            color="primary"
            size="sm"
            outline
            className="top-right-button"
            onClick={() => this.onClickItem(props.original)}
          >
            Edit
          </Button>
        </p>
      }
    ])
  }
  deleteWasher(washer) {
    let _this = this
    firestore().collection('washers').doc(washer.key).update({
      deActivated: true,
      updated: Date.now(),
      active: false
    }).then(() => {
      _this.setupWashers()
    })
  }
  componentWillMount() {
    let { modifiers } = this.state
    let _this = this
    this.setupWashers()
    firestore().collection('modifiers').get().then(function (snapshot) {
      snapshot.forEach(function (doc) {
        let modifier = {}
        modifier = doc.data()
        modifier.key = doc.id
        modifier.price = '100'
        modifier.duration = '00:15'
        modifier.value = doc.data().name
        modifier.label = doc.data().name
        modifiers[doc.id] = modifier;
      });
      _this.setState({ modifiers: modifiers, isLoading: false })
    });
  }
  componentDidUpdate(previousProps) {
    let { providers } = this.props
    let { selectWasher } = this.state
    if (previousProps?.providers?.washers !== providers?.washers && !selectWasher) {
      this.setupWashers()
    }
  }
  setupWashers() {
    let { providers } = this.props
    let { washers, provider } = providers
    let { search, selectedProvider } = this.state


    let providersArray = provider && Object.keys(provider).map(key => {

      let serviceProvider = provider[key]
      serviceProvider.key = key
      serviceProvider.label = serviceProvider.name
      serviceProvider.value = serviceProvider.name

      return (serviceProvider)
    })

    let allWashers = washers && Object.values(washers).filter(washer => {
      washer.providerName = provider[washer?.sp_id] ? provider[washer.sp_id].name : ''
      return washer && washer.name
    })
    let filteredWashers = allWashers?.filter(item => {
      return ((item?.name)?.toLowerCase()?.includes(search.toLowerCase()) || (item?.phoneNumber)?.toLowerCase()?.includes(search.toLowerCase())) && (selectedProvider ? item.sp_id === selectedProvider?.key : true)
    })

    this.setState({ providers: providersArray, allWashers, filteredWashers, isLoading: false, selectWasher: false, selectedWasher: {}, addNewWasher: false, deleteWasher: false })
  }

  toggleModal = () => {
    let { selectWasher } = this.state
    this.setState({ selectWasher: !selectWasher, selectedWasher: {}, addNewWasher: true })
  };

  onSearchKey = e => {
    let { filteredWashers, allWashers } = this.state
    let search = e.target.value.toLowerCase()
    filteredWashers = allWashers?.filter(item => {
      return (item?.name)?.toLowerCase()?.includes(search.toLowerCase()) || (item?.phoneNumber)?.toLowerCase()?.includes(search.toLowerCase())
    })
    this.setState({ filteredWashers, search }
    );
  };
  selectProvider = (provider) => {
    let { filteredWashers, allWashers } = this.state

    if (provider) {
      let id = provider.key
      filteredWashers = allWashers.filter(item => {
        return item.sp_id && (item.sp_id === id)
      })
      this.setState({ filteredWashers, selectedProvider: provider });

    } else {

      this.setState({ filteredWashers: allWashers, selectedProvider: false });

    }

  };
  filterActiveWashers(active) {
    let { filteredWashers, allWashers } = this.state
    switch (active) {
      case 'all':
        filteredWashers = allWashers

        break;
      case 'active':
        filteredWashers = allWashers.filter(item => item.active)

        break;
      case 'closed':
        filteredWashers = allWashers.filter(item => !item.active)
        break;

      default:
        break;
    }
    this.setState({ filteredWashers: active ? filteredWashers : allWashers });
  }

  onClickItem = (washer) => {
    let { selectWasher } = this.state

    if (washer) {
      this.setState({ selectWasher: !selectWasher, selectedWasher: washer, addNewWasher: false })
    } else {
      this.setState({ selectWasher: !selectWasher, selectedWasher: {}, addNewWasher: false })
    }
  };


  render() {
    let { authUser, services, advServices, variations } = this.props
    const {
      allWashers,
      selectWasher,
      selectedWasher,
      addNewWasher,
      providers,
      modifiers,
      deleteWasher,
      activateWorker,
      filteredWashers,
      startDate,
      endDate,
      minTime
    } = this.state;


    return (selectWasher ? <Washer
      allWashers={allWashers}
      services={services}
      advServices={advServices}
      variations={variations}
      modifiers={modifiers}
      authUser={authUser}
      providers={providers}
      selectedWasher={selectedWasher}
      onClickItem={this.onClickItem}
      addNewWasher={addNewWasher}

      updateWasher={() => this.setupWashers()}




    /> :
      <Fragment>
        {this.state.isLoading && (
          <div className="loading" />
        )
        }

        <div className="disable-text-selection">
          <ListPageHeading
            heading="Workers"
            onSearchKey={this.onSearchKey}
            toggleModal={this.toggleModal}
            washerList={true}
            authUser={authUser}
            providers={providers || {}}
            selectProvider={this.selectProvider}

          />

          <Row className="survey-app">
            <Colxx xxs="3">
              <Select
                components={{ Input: CustomSelectInput }}
                className="basic-multi-select"
                placeholder="Filter washers"
                options={[{ key: "all", value: "All", label: "All" }, { key: "active", value: "Active", label: "Active" }, { key: "closed", value: "Closed", label: "Closed" }]}
                onChange={val => {
                  this.filterActiveWashers(val.key)
                }}
              />
            </Colxx>
            <Colxx xxs="12">

              {
                filteredWashers && filteredWashers.length ?


                  (deviceWidth() === 'mobile') ?
                    (
                      <ReactTable
                        data={filteredWashers}
                        columns={this.dataTableColumns()}
                        defaultPageSize={10}
                        pageSize={filteredWashers.length < 10 ? filteredWashers.length : 10}
                        showPageJump={false}
                        showPageSizeOptions={false}
                        PaginationComponent={DataTablePagination}
                        className='custuomReactTable'
                        TheadComponent={TheadComponent}
                      />
                    ) :
                    <ReactTable
                      data={filteredWashers}
                      columns={this.dataTableColumns()}
                      defaultPageSize={10}
                      pageSize={filteredWashers.length < 10 ? filteredWashers.length : 10}
                      showPageJump={false}
                      showPageSizeOptions={false}
                      PaginationComponent={DataTablePagination}
                      className='custuomReactTable'
                    />
                  :
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Washers</div>
              }
            </Colxx>
          </Row>
          <BusyTimingsLogs washer={this.state.busyTimngsLogs} dismiss={() => this.setState({ busyTimngsLogs: false })} />
          <Modal backdrop="static" isOpen={deleteWasher}>
            <ModalHeader >
              Delete Washer
            </ModalHeader>
            <ModalBody>
              <Row>
                <Colxx xxs="12" className="mb-4">
                  <Label className="mb-2">Are you sure you want to delete this Washer?</Label>
                </Colxx>
              </Row>

            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.setState({ deleteWasher: false, selectedWasher: {} })}>Dismiss</Button>
              <Button color='danger' onClick={() => this.setState({ isLoading: true }, () => { this.deleteWasher(selectedWasher) })}>Delete Washer</Button>
            </ModalFooter>

          </Modal>
          {
            activateWorker && <Modal isOpen={true}>
              <ModalHeader>Are you sure you want to Deactivate {activateWorker.name}</ModalHeader>
              <ModalBody>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <h4 style={{ fontSize: '14px', fontWeight: '700' }}>{"Set the worker status"}</h4>
                    <div>This immediately will turn On or Off the worker</div>
                  </div>
                  <Switch
                    placeholder="Permenant deactivation"
                    title="Permenant deactivation"
                    className="custom-switch custom-switch-primary"
                    checked={this.state.permenant}
                    onChange={switchCheckedPrimary => {
                      this.setState({ permenant: switchCheckedPrimary })
                    }}
                  />
                </div>

                {!this.state.permenant &&
                  <div style={{ marginTop: '32px' }}>
                    <h4 style={{ fontSize: '14px', fontWeight: '700' }}>{"Or schedule busy timings below"}</h4>
                    <Row>
                      <Colxx xxs="12" md="6" style={{ position: 'auto' }}>
                        <div>Start date and time</div>
                        <DatePicker
                          selected={startDate}
                          popperClassName="popperClass"
                          onChange={(date) => this.setState({ startDate: date, minTime: this.calculateMinTime(date) })}
                          selectsStart
                          startDate={startDate}
                          minDate={moment().toDate()}
                          minTime={minTime}
                          maxTime={moment().endOf('day').toDate()}
                          endDate={endDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          className="customDatePicker"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="dd/MM/yyyy hh:mm a"
                        />
                      </Colxx>
                      <Colxx xxs="12" md="6">
                        <div>End date and time</div>
                        <DatePicker
                          selected={endDate}
                          popperClassName="popperClass"
                          onChange={(date) => this.setState({ endDate: date })}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          className="customDatePicker"
                          minDate={startDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="dd/MM/yyyy hh:mm a"
                        />
                      </Colxx>
                    </Row>
                  </div>}

              </ModalBody>
              <ModalFooter>
                <Button outline color='danger' className='m-1' onClick={() => this.setState({ activateWorker: false })}>Cancel</Button>
                <Button color='primary ' className='m-1' onClick={() => {
                  let post = {
                    active: !this.state.permenant,
                    updated: Date.now(),
                    busyTimings: false
                  }
                  if (!this.state.permenant) {
                    post.busyTimings = { startTime: moment(startDate).toDate().getTime(), endTime: moment(endDate).toDate().getTime() }
                  }

                  firestore().collection('washers').doc(activateWorker.key).update(post).then(() => {
                    if (this.state.permenant) {
                      NotificationManager.success(
                        "",
                        "Worker deactivated successfully",
                        5000,
                        null,
                        null,
                      )
                    } else {
                      let id = firestore().collection('washers').doc().id
                      firestore().collection('washers').doc(activateWorker.key).collection("busyTimingsLogs").doc(id).set({
                        busyTimings: post.busyTimings,
                        created: Date.now(),
                        title: 'Applied Busy Timings',
                        createdBy: accessTypesObject?.[authUser?.user?.accessType].name,
                        createdByName: authUser?.user?.displayName
                      }, { merge: true }).then(() => {
                        NotificationManager.success(
                          "",
                          "Worker deactivated successfully from " + moment(startDate).format('DD/MM/YYYY hh:mm a') + " to " + moment(endDate).format('DD/MM/YYYY hh:mm a'),
                          5000,
                          null,
                          null,
                        )
                      })

                    }

                    this.setState({ activateWorker: false, permenant: undefined })
                  })

                }}>Deactivate</Button>
              </ModalFooter>
            </Modal>
          }
        </div>
      </Fragment>
    )
  }
}



const mapStateToProps = ({ authUser, providers }) => {
  return {
    authUser,
    providers,
    services: providers ? providers.services : {},
    advServices: providers ? providers.advServices : {},
    variations: providers ? providers.variations : {}
  };
};

export default injectIntl(connect(mapStateToProps)(WashersList));
