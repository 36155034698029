import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import services from "./services";

const Gogo = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Route path={`${match.url}/`} component={services} />
      <Redirect to="/error" />
    </Switch>
  </div>
);
export default Gogo;
