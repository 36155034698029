import React, { Fragment, useEffect, useState } from "react";
import Resizer from 'react-image-file-resizer';
import ImageUploader from 'react-images-upload';
import { useSelector } from "react-redux";
import Select, { Creatable } from "react-select";
import { ReactSortable } from "react-sortablejs";
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import ServiceImg from '../../../assets/css/img/services.png';
import { Colxx } from "../../../components/common/CustomBootstrap";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import { firestore, storage } from '../../../helpers/Firebase';
import { createDynamicLink, createNotification, deviceWidth, filterSortedObject, getAllOptions, sortObjectByCategory } from "../../../helpers/Utils";
import './services.css';

const Services = () => {
  // const { user } = useSelector(state => state.authUser)
  const { services, advServices, modifiers } = useSelector(state => state.providers)
  const [selectedService, setSelectedService] = useState(false)
  const [filteredServices, setFilteredServices] = useState([])
  const [externalServices, setExternalServices] = useState([])

  const [isLoading, setLoading] = useState(false)
  const [deleteService, setDeleteService] = useState(false)
  const [servicePictures, setServicePictures] = useState([])
  const [serviceImage, setServiceImage] = useState([])
  const [newModifier, setNewModifier] = useState(false)
  console.log('selectedService', selectedService);
  console.log('servicePictures', servicePictures);



  useEffect(() => {
    async function fetchData() {
      let externalServices = await firestore().collection('externalServices').get()
      let externalServicesData = {}
      externalServices.forEach(doc => {
        externalServicesData[doc.id] = { key: doc.id, ...doc.data(), external: true }
      })
      console.log(externalServicesData)
      setExternalServices(externalServicesData)
    }
    fetchData();
  }, [])

  useEffect(() => {
    let allServices = services && advServices && externalServices && Object.values(services || {}).concat(Object.values(advServices || {})).concat(Object.values(externalServices || {}))
    let filteredServices = allServices?.length && allServices?.sort((a, b) => (a?.index > b?.index) ? 1 : -1)
    setFilteredServices(filteredServices)
  }, [services, advServices, externalServices])

  const deleteServiceFunc = (service) => {
    firestore().collection("services").doc(service.key).delete().then(function () {
      firestore().collection("washers").get().then(function (washerSnapshot) {
        washerSnapshot.forEach(function (doc) {
          if (doc.data().activeServices) {
            let activeServices = doc.data().activeServices
            if (activeServices[service.key]) {
              delete activeServices[service.key]
              firestore().collection("washers").doc(doc.id).update({ activeServices: activeServices }).then(function () {
                setDeleteService(false)
                setLoading(false)
              })
            }
          }
        });
        setDeleteService(false)
        setLoading(false)
      });
    }).catch(function (error) {
      setDeleteService(false)
      setLoading(false)
    });
  }

  const submit = async (general) => {
    let id = general.key || firestore().collection('services').doc().id;

    if (general) {
      if (general.addNew) {
        general.active = true
        general.created = Date.now()
      }
      delete general.addNew
      general.updated = Date.now()

      await firestore().collection(getCollectionName(general)).doc(id).set(general, { merge: true })
      var metadata = {
        cacheControl: 'public,max-age=300',
      };
      if (servicePictures && servicePictures.length > 0 && servicePictures[0].name) {

        servicePictures.forEach(element => {
          var storageRef = storage().ref().child(getCollectionName(general)).child(id).child("logo").put(element.data, metadata);
          storageRef.on('state_changed', function (snapshot) {
          }, function (error) {
          }, function () {
            storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              firestore().collection(getCollectionName(general)).doc(id).update({
                Service_image: [downloadURL],
                updated: Date.now(),
              }).then(() => {
                setLoading(false)
              })
            }
            )
          })
        });
      }
      if (serviceImage && serviceImage.length > 0 && serviceImage[0].name) {
        serviceImage.forEach(element => {
          var storageRef = storage().ref().child(getCollectionName(general)).child(id).child("image").put(element.data, metadata);
          storageRef.on('state_changed', function (snapshot) {
          }, function (error) {
          }, function () {
            storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              firestore().collection(getCollectionName(general)).doc(id).update({
                image: [downloadURL],
                updated: Date.now(),
              }).then(() => {
                setLoading(false)
              })
            }
            )
          })
        });
      }
    }
    setLoading(false)
    setSelectedService(false)
  }

  const onDrop = (picture, type) => {
    let pictures = []
    if (picture.length) {
      let lastPic = picture[picture.length - 1]
      Resizer.imageFileResizer(
        lastPic,
        1080,
        1440,
        'png',
        90,
        0,
        uri600 => {
          pictures.push({
            name: '600',
            data: uri600
          });
          switch (type) {
            case 'Service_image':
              setServicePictures(pictures)
              break;
            case 'image':
              setServiceImage(pictures)
              break;
            default:
              break;
          }
          setSelectedService({ ...selectedService, })
        },
        'blob'
      );

    } else {
      switch (type) {
        case 'Service_image':
          setServicePictures(pictures)
          break;
        case 'image':
          setServiceImage(pictures)
          break;
        default:
          break;
      }
      setSelectedService({ ...selectedService, })
    }
  }
  const getCollectionName = (service) => {
    if (service.advservice) {
      return 'advServices'
    } else if (service.external) {
      return 'externalServices'
    } else {
      return 'services'
    }
  }

  function createObjectsFromArray(stringsArray) {
    return stringsArray.map(str => ({ name: str, value: str, label: str, key: str }));
  }
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span style={{ fontSize: '14px' }}>{data?.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  return (
    isLoading ? (
      <div className="loading" />
    ) : (
      <Fragment>
        <div className="disable-text-selection">

          <ListPageHeading
            heading="Services"
            toggleModal={() => setSelectedService({ addNew: true })}
          />

          <Row className="survey-app">
            <Colxx xxs="12">
              {
                filteredServices && filteredServices.length ?
                  <>
                    <ReactSortable
                      list={JSON.parse(JSON.stringify(filteredServices))}
                      setList={(newState) => {
                        setFilteredServices(newState);
                      }}

                      tag="ul"
                      className="list-unstyled mt-2"
                      options={{
                        handle: ".handle"
                      }}>
                      {filteredServices?.map((item, index) => {
                        return (
                          <li data-id={item.index} key={index} xxs="12" sm="12" md="12" className="mb-4">
                            <Colxx data-id={item.index} key={index} xxs="12" sm="12" md="12" className="mb-4">
                              <Card className="rounded serviceCard">
                                <div className="providerfieldText"><img style={{ width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={item?.Service_image?.[0] || ServiceImg} alt={''} /></div>
                                <div className="providerfieldText">{item.name}</div>
                                {deviceWidth() === 'desktop' ? <div className="providerfieldText">{item.name_ar}</div> : ''}
                                <p className="providerfieldText"></p>
                                <p className="providerfieldText"></p>

                                <div className="buttonsClass">
                                  {/* {!item.advservice && (user?.accessType === 'superAdmin') && <Button
                                    className="deleteServieButton"
                                    color="danger"
                                    size="sm"
                                    outline
                                    onClick={() => setDeleteService(item)}>Delete</Button>} */}

                                  <Button
                                    color="primary"
                                    size="sm"
                                    outline
                                    className="top-right-button"
                                    onClick={() => setSelectedService(JSON.parse(JSON.stringify(item)))}>Edit</Button>
                                </div>
                              </Card>
                            </Colxx>
                          </li>
                        )
                      })
                      }
                    </ReactSortable>
                    <Button
                      color="primary"
                      className="btn-shadow float-right mt-2 mb-4"
                      size="lg"
                      onClick={() => {
                        filteredServices.map((r, index) => {
                          return (firestore().collection(getCollectionName(r)).doc(r.key).update({
                            index,
                            updated: Date.now(),
                          })
                          )
                        })
                      }
                      }>Save new sorting</Button></>
                  : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Services</div>
              }
            </Colxx>
          </Row>
        </div>
        {selectedService && <Modal wrapClassName="modal-right" backdrop="static" isOpen={selectedService}>
          <div className="modal-header"  >
            <h5 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }} className="modal-title">
              {selectedService?.addNew ?
                "Create New Service" :
                "Edit Service"
              }
            </h5>


          </div>
          <ModalBody>
            <Row>
              {[
                { key: 'service_link', title: 'Normal Service Dynamic Link', width: '12', type: 'link', hide: selectedService?.addNew },
                { key: 'service_link', title: 'Waterless Service Dynamic Link', width: '12', type: 'link', hide: selectedService?.addNew || !selectedService.washingService },
                { key: 'Service_image', title: 'Service Image', width: '12', type: 'img' },
                { key: 'image', title: 'Home Screen Image', width: '12', type: 'img' },
                { key: 'name', title: 'Name', width: '12', type: 'text' },
                { key: 'name_ar', title: 'Arabic Name', width: '12', type: 'text' },
                { key: 'modifiers', title: 'Select Modifiers', width: '12', data: Object.values(sortObjectByCategory(modifiers || {}) || {}), type: 'creatable', },
                { key: 'heatLevels', title: 'Heat Rejection Levels', width: '12', data: createObjectsFromArray(selectedService?.heatLevels || []), type: 'creatable', hide: !selectedService.advservice },
                { key: 'tintLevels', title: 'Tint Levels', width: '12', data: createObjectsFromArray(selectedService?.tintLevels || []), type: 'creatable', hide: !selectedService.advservice },
                { key: 'brands', title: 'Brands', width: '12', data: createObjectsFromArray(selectedService?.brands || []), type: 'creatable', hide: !selectedService.advservice },
                { key: 'madeIn', title: 'Made In Countries', width: '12', data: createObjectsFromArray(selectedService?.madeIn || []), type: 'creatable', hide: !selectedService.advservice },
                { key: 'defaultBrand', title: 'Default Brand', width: '12', data: createObjectsFromArray(selectedService?.brands || []), type: 'select', hide: !selectedService.advservice },
                { key: 'defaultTintLevel', title: 'Default Tint Level', width: '12', data: createObjectsFromArray(selectedService?.tintLevels || []), type: 'select', hide: !selectedService.advservice },
              ].map((item, index) => {
                return !item.hide && (
                  <Colxx key={index} xxs="12" md={item.width} className={item?.type === 'link' ? 'mb-2' : "mb-4"}>
                    <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                      <div>{item.title}</div>
                      {item?.type === 'link' && <Button
                        color='primary'
                        size='xs'
                        onClick={async () => {
                          let link = await createDynamicLink({ service: selectedService, washingType: 'waterlessWash' })
                          navigator.clipboard.writeText(link);
                          createNotification("success", "Copied to clipboard", link)
                        }}
                      >Copy</Button>}
                    </div>
                    {item.type === 'img' ? <ImageUploader
                      className='w-100'
                      withIcon={true}
                      label={'Max file size: 15mb, accepted: jpg|gif|png'}
                      buttonText='Choose images'
                      onChange={(picture) => onDrop(picture, item.key)}
                      imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                      maxFileSize={15728640}
                      withPreview={true}
                      singleImage={true}
                      defaultImages={selectedService?.[item.key]}
                    /> : item.type === 'creatable' ?
                      <>
                        <Creatable
                          formatGroupLabel={formatGroupLabel}
                          components={{ Input: CustomSelectInput }}
                          className="react-select"
                          options={(item.key === 'modifiers' ? Object.values(filterSortedObject(item?.data, selectedService?.[item.key])) : item.data)?.filter(i => !selectedService?.[item.key]?.includes(i.key))}
                          value=''
                          onChange={val => {
                            let newData = selectedService?.[item.key] || []
                            newData.push(val.key)
                            setSelectedService({ ...selectedService, [item.key]: newData, })
                          }}
                          onCreateOption={(inputValue) => {
                            if (item.key === 'modifiers') {
                              setNewModifier({ name: inputValue })
                            } else {
                              let newData = selectedService?.[item.key] || []
                              newData.push(inputValue)
                              setSelectedService({ ...selectedService, [item.key]: newData, })
                            }
                          }}
                        />
                        <div className="mb-2" style={
                          {
                            minHeight: 'auto',
                            borderRadius: '3px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            flexWrap: 'wrap',
                          }
                        }>
                          {
                            (item.key === 'modifiers' ? getAllOptions(item.data) : item?.data)?.filter(i => selectedService?.[item.key]?.includes(i.key))?.map((i, index) => {
                              return (
                                <div key={index} style={{
                                  margin: '5px',
                                  padding: ' 5px 10px',
                                  border: '1px solid #d7d7d7',
                                  borderRadius: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}>
                                  <div style={{ marginRight: '8px' }}>{i?.name}</div>
                                  <i style={{ cursor: 'pointer' }} className="simple-icon-close" onClick={() => {
                                    let newData = selectedService?.[item.key] || []
                                    newData = newData?.filter(el => {
                                      return (el !== i.key)
                                    });
                                    setSelectedService({ ...selectedService, [item.key]: newData, })
                                  }} />
                                </div>
                              )
                            })
                          }
                        </div>
                      </> : item.type === 'select' ?
                        <Select
                          components={{ Input: CustomSelectInput }}
                          className="react-select"
                          value={item?.data?.find(i => selectedService?.[item.key] === i.key) || ''}
                          onChange={(e) => {
                            setSelectedService({ ...selectedService, [item.key]: e.value, })
                          }}
                          options={item?.data}
                        /> : item?.type === 'text' ? <Input
                          type={item.type}
                          placeholder={"e.g. " + (item?.placeholder || item?.title)}
                          value={selectedService?.[item.key]}
                          className="customInput"
                          onChange={(e) => {
                            setSelectedService({ ...selectedService, [item.key]: e.target.value, })
                          }} /> : ''}

                  </Colxx>
                )
              })}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color='danger' onClick={() => setSelectedService(false)}>Cancel</Button>
            <Button color='primary'
              disabled={!selectedService?.modifiers?.length || selectedService?.name === "" || selectedService?.name_ar === ""} onClick={() => {
                setLoading(true)
                submit(selectedService)
              }}>Save</Button>

          </ModalFooter>

        </Modal>}
        <Modal backdrop="static" isOpen={deleteService}>
          <ModalHeader >
            Delete Service
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete this service?
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setDeleteService(false)}>Dismiss</Button>
            <Button color='danger' onClick={() => {
              setLoading(true)
              deleteServiceFunc(deleteService)
            }}>Delete Service</Button>
          </ModalFooter>
        </Modal>

        <Modal backdrop="static" isOpen={newModifier}>
          <ModalHeader >
            Create new vehicle type
          </ModalHeader>
          <ModalBody>
            <Row >
              {[
                { key: 'name', title: 'Category Name', width: '12', type: 'text' },
                { key: 'name_ar', title: 'Category Arabic Name', width: '12', type: 'text' },
              ].map((item, index) => {
                return !item?.hide && (
                  <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                    <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                      <div>{item.title}</div>
                    </div>
                    <Input
                      type={item.type}
                      placeholder={"e.g. " + item.title}
                      value={newModifier?.[item.key]}
                      className="customInput"
                      onChange={(e) => {
                        setNewModifier({ ...newModifier, [item.key]: e.target.value })
                      }} />
                  </Colxx>
                )
              })}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color='danger' onClick={() => setNewModifier(false)}>Cancel</Button>
            <Button
              color='primary'
              disabled={!newModifier?.name}
              onClick={async () => {
                let id = firestore().collection('modifiers').doc().id;
                await firestore().collection('modifiers').doc(id).set(newModifier);
                let newData = selectedService?.modifiers || []
                newData.push(id)
                setSelectedService({ ...selectedService, modifiers: newData, })
                setNewModifier(false)
              }}>Add</Button>
          </ModalFooter>

        </Modal>
      </Fragment>
    )
  );
};

export default Services;