import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { Card, Row, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Button, Label } from 'reactstrap';
import ReactTable from "react-table";

import { Colxx } from '../../../../components/common/CustomBootstrap';
import Variation from './variation';

const CustomSelectInput = props => {
    var customProps = Object.assign({}, props);
    delete customProps.autoCorrect;
    delete customProps.autoCapitalize;
    return <components.Input {...customProps} />;

};
const deviceWidth = () => {
    const width = window.innerWidth;
    const breakpoint = 820;
    return width < breakpoint ? 'mobile' : 'desktop';
}
const WindowTint = ({
    selectedWasher,
    allActiveServices,
    activeServices,
    variations,
    service,
    options,
    modifiers,
    update }) => {

    const [carType, setCarType] = useState({})
    const [addCarType, setAddCarType] = useState(false)
    const [editCarType, setEditCarType] = useState(false)

    const [editVariation, setEditVariation] = useState(false)
    const [addVariation, setAddVariation] = useState(false)
    const [addAvailableVariation, setAddAvailableVariation] = useState(false)
    const [selectedVariation, setSelectedVariation] = useState({})

    let activeServicesColumns = () => {
        return ([

            {
                width: deviceWidth() === 'desktop' ? 150 : 100,
                Header: "Name",
                accessor: "name",
                Cell: props => <p className="washerfieldText">{props.value}</p>
            },
            // {
            //     width: 150,
            //     Header: "Arabic Name",
            //     accessor: "name_ar",
            //     show: deviceWidth() === 'desktop' ? true : false,

            //     Cell: props => <p className="washerfieldText">{props.value}</p>
            // },
            // {
            //     width: 150,
            //     Header: "Price",
            //     accessor: "price",
            //     show: deviceWidth() === 'desktop' ? true : false,

            //     Cell: props => <p className="washerfieldText">{props.value} AED</p>
            // },
            {
                Header: "Duration",
                accessor: "duration",
                show: deviceWidth() === 'desktop' ? true : false,

                Cell: props => <p className="washerfieldText">{props.value}</p>
            },

            {
                width: deviceWidth() === 'desktop' ? 95 : 90,
                accessor: "edit",
                Cell: props => <div className="washerfieldText">
                    <Button
                        color="danger"
                        size="sm"
                        outline
                        className="top-right-button"
                        onClick={() => {
                            if (Object.keys(activeServices[service.key]).length > 1) {
                                delete activeServices[service.key][props.original.key];
                            } else {
                                delete activeServices[service.key];
                            }
                            update({ activeServices });
                        }}>
                        Remove
                    </Button>
                </div>
            },
            {
                width: deviceWidth() === 'desktop' ? 70 : 75,
                accessor: "edit",
                Cell: props => <div className="washerfieldText">
                    <Button
                        color="primary"
                        size="sm"
                        outline
                        className="top-right-button"
                        onClick={() => {
                            setEditCarType(true)
                            setCarType(props.original)
                        }}>
                        Edit
                    </Button>
                </div>
            }
        ])
    }
    let UpdateService = () => {
        if (activeServices[service.key]) {
            activeServices[service.key][carType.key] = {
                price: "0",
                duration: carType?.duration,
                variations: carType?.variations?.filter(item => variations?.[item]) || []
            }
        } else {
            activeServices[service.key] = {
                [carType.key]: {
                    price: "0",
                    duration: carType?.duration,
                    variations: carType?.variations?.filter(item => variations?.[item]) || []
                }
            }
        }


        setCarType({})
        setAddCarType(false)
        setEditCarType(false)

        update({ activeServices })
    }

    return (
        <Row className='h-100'>
            <Colxx xxs="12" md="12" >
                <Card className="rounded h-100">
                    <CardBody>
                        <h4>Window Tint Service</h4>
                        <p>Enable window tint service for different vechicle types</p>
                        <Select
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            classNamePrefix="react-select"
                            styles={{ width: '50%' }}
                            placeholder="Add Vehicle Types"
                            name="form-field-name"
                            options={modifiers && Object.values(modifiers).filter(item => {
                                return !Object.keys(activeServices?.[service?.key] || {})?.includes(item.key) && service?.modifiers?.includes(item.key)
                            })}
                            onChange={(e) => {
                                setCarType(e)
                                setAddCarType(true)
                            }}
                        />
                        {

                            allActiveServices && allActiveServices.length ? <ReactTable
                                data={allActiveServices}
                                columns={activeServicesColumns()}
                                defaultPageSize={allActiveServices.length}
                                pageSize={allActiveServices.length}
                                showPageJump={false}
                                showPageSizeOptions={false}
                                showPagination={false}
                                className='servicesTable'
                            /> :
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px 0' }}>No types selected</div>


                        }
                    </CardBody>
                </Card>
            </Colxx>

            <Modal
                size='lg'
                isOpen={addCarType || editCarType}
                backdrop="static">
                <ModalHeader >Edit vehicle type price, duration and variations</ModalHeader>
                <ModalBody>
                    <Row className="d-flex align-items-center">
                        <Colxx xxs="12" md="6" >
                            <h2 >{carType?.name}</h2>
                        </Colxx>
                    </Row>
                    <Row>
                        {/* <Colxx xxs="6" className="mt-2" >
                            <Label >Default Price</Label>
                            <Input className="customInput"
                                value={carType?.price}
                                placeholder="e.g. 10 AED" type="number"
                                onChange={(e) => {
                                    let newPrice = JSON.parse(JSON.stringify(carType))
                                    newPrice.price = e.target.value
                                    setCarType(newPrice)
                                }}
                            />
                        </Colxx> */}
                        <Colxx xxs="6" className="mt-2">
                            <Label >Default Duration</Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="form-field-name"
                                value={carType?.duration ? { value: carType?.duration, label: carType?.duration, key: carType?.duration } : ''}
                                placeholder="e.g. 00:15"
                                onChange={(e) => {
                                    let newDuration = JSON.parse(JSON.stringify(carType))
                                    newDuration.duration = e.value
                                    setCarType(newDuration)
                                }}
                                options={options}
                            />
                        </Colxx>
                    </Row >


                    <Row>
                        <Colxx xxs="12" className="mt-4" >
                            <Label >Variations</Label>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{
                                    width: '50%', flex: '1',
                                    marginRight: '10px'
                                }}>
                                    <Select
                                        components={{ Input: CustomSelectInput }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        placeholder="Select Variations"
                                        name="form-field-name"
                                        options={variations && Object.values(variations).filter(item => {
                                            return !carType?.variations?.includes(item.key) && item.worker_id === selectedWasher.key
                                        })}
                                        onChange={(e) => {


                                            setSelectedVariation({ ...variations[e.key], key: e.key })
                                            setEditVariation(true)
                                            setAddAvailableVariation(true)
                                        }}
                                    />
                                </div>

                                <Button color="primary" className="top-right-button" onClick={() => {
                                    setAddVariation(true)
                                }}>Add New
                                </Button>
                            </div>
                            {
                                carType?.variations?.filter(item => variations?.[item])?.map((item, index) => {
                                    return (
                                        <Card key={index} className="mt-4 rounded">
                                            <CardBody className='pr-4 pl-4 pb-2 pt-2'>
                                                <Row className="d-flex align-items-center">
                                                    <Colxx xxs="6">
                                                        <div style={{
                                                            fontSize: '16px',
                                                            fontWeight: '400'
                                                        }} >{variations?.[item]?.name}</div>
                                                    </Colxx>
                                                    <Colxx xxs="6" className="d-flex" style={{ justifyContent: 'flex-end', alignItems: 'center' }}>

                                                        <div style={{
                                                            fontSize: '18px',
                                                            color: '#dc3545',
                                                            cursor: 'pointer',
                                                            width: '25px',
                                                            display: 'flex',
                                                            marginRight: '10px'
                                                        }} onClick={() => {
                                                            let newCarType = JSON.parse(JSON.stringify(carType))
                                                            newCarType.variations = newCarType.variations.filter(rec => rec !== item)
                                                            setCarType(newCarType)
                                                        }}><i className="simple-icon-close" /></div>
                                                        <div style={{
                                                            fontSize: '18px',
                                                            color: '#3498db',
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            width: '25px'
                                                        }} onClick={() => {
                                                            setSelectedVariation({ ...variations[item], key: item })
                                                            setEditVariation(true)
                                                        }}>
                                                            <i className="simple-icon-pencil" />
                                                        </div>
                                                    </Colxx>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    )
                                })
                            }
                        </Colxx>
                    </Row>
                </ModalBody >
                <ModalFooter >
                    <Button color="danger" outline onClick={() => {
                        setEditCarType(false)
                        setAddCarType(false)
                    }}>Cancel</Button>
                    <Button color="primary" className="top-right-button" onClick={() =>
                        UpdateService()
                    }>{editCarType ? 'Save Service' : 'Add Service'}</Button>
                </ModalFooter>
            </Modal >
            {(addVariation || editVariation) && <Variation
                service={service}
                carType={carType?.name}
                carKey={carType?.key}
                selectedWasher={selectedWasher}
                addVariation={addVariation}
                editVariation={editVariation}
                selectedVariation={selectedVariation}
                dismiss={(e) => {
                    if (addVariation && e) {
                        let newCarType = JSON.parse(JSON.stringify(carType))
                        newCarType.variations = newCarType?.variations || []
                        newCarType.variations.push(e)
                        setCarType(newCarType)
                    }
                    setAddAvailableVariation(false)
                    setSelectedVariation({})
                    setEditVariation(false)
                    setAddVariation(false)
                }}
                availableVariation={addAvailableVariation}
                addAvailableVariation={() => {
                    if (addAvailableVariation) {
                        let newCarType = JSON.parse(JSON.stringify(carType))
                        newCarType.variations = newCarType?.variations || []
                        newCarType.variations.push(selectedVariation.key)
                        setCarType(newCarType)
                    }
                    setSelectedVariation({})
                    setAddAvailableVariation(false)
                    setEditVariation(false)
                    setAddVariation(false)
                }}
            />}
        </Row >
    );
};

export default WindowTint;