import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import start from "./default";

const Gogo = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Route path={`${match.url}/`} component={start} />
      <Redirect to="/error" />
    </Switch>

  </div>
);
export default Gogo;
