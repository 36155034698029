import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Row,
  NavItem,
} from "reactstrap";
import Select from "react-select";
import { Creatable } from 'react-select';

import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import Script from 'react-load-script';

import axios from 'axios'
import { firestore, functions } from '../../../../helpers/Firebase'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone'
import { NotificationManager } from "../../../../components/common/react-notifications";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import Addons from "./addons";
import { accessTypes, accessTypesObject, colors, emirates } from "../../../../helpers/Utils";


const makeid = (length) => { var result = ''; var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; var charactersLength = characters.length; for (var i = 0; i < length; i++) { result += characters.charAt(Math.floor(Math.random() * charactersLength)); } return result; }
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}
const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);
const sidesArray = [
  { key: 'frontSidePrice', value: 'Front Side' },
  { key: 'sidesBackPrice', value: 'Sides, Back' },
  { key: 'roofTintingPrice', value: 'Roof Tinting' },
  { key: 'allSidePrice', value: 'All Sides' }]

class AddNewTodoModal extends Component {
  constructor(props) {
    super(props);

    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);

    this.state = {
      bookingDate: false,
      created: Date.now(),
      customer: {},
      customer_id: "",
      customer_name: "",
      customer_number: "",
      service_id: "",
      service_name: "",
      sp_id: "",
      sp_name: "",
      status: "new",
      washer_id: "",
      washer_name: "",
      bookingTime: {},
      carDetail: [],
      selectedAddons: [],
      paymentMethod: {},
      paymentType: '',
      place: {},
      price: {},
      totalPrice: 0,

      orderNumber: '',
      newUser: false,

      users: [],
      cars: [],
      vehicleModels: {},
      selectedBrandModels: [],
      modifiers: {},
      overlapAppointment: false,
      reAssignWasher: false,
      reAssignDate: false,
      isLoading: true,
      timeInminutes: 45,
      placeholder: 'Enter copied link',
      advancedDetails: false,
      activeVariation: {
        brand: {
          title: "Package",
          key: 'brand',
          selected: 'true',
        },
        heatRejection: {
          title: "Heat Rejection",
          key: 'heatRejection',
          // selected: advServices[0]?.heatLevels?.[0],
        },
        darkness: {
          title: "Window Tint Level",
          key: 'darkness',
          // selected: advServices[0]?.defaultTintLevel || advServices[0]?.tintLevels?.[0],
        },
        frontSide: {
          title: "Tinting Sides",
          key: 'frontSide',
          // selected: "Sides, Back",
        },
      }
    };
  }

  getAppointment() {
    this.setState({
      orderNumber: '',
      bookingDate: false,
      created: Date.now(),
      customer: {},
      customer_id: "",
      customer_name: "",
      customer_number: "",
      service: {},
      service_id: "",
      service_name: "",
      sp_id: "",
      sp_name: "",
      status: "new",
      washer: {},
      washer_id: "",
      washer_name: "",
      bookingTime: {},
      carDetail: [],
      selectedAddons: [],
      paymentMethod: {},
      paymentType: '',
      place: {},
      price: {},
      selectedType: {},
      selectedCar: {},
      selectedModel: {},
      selectedColor: {},
      address: '',
      plateNumber: '',
      selectedPlateNumber: {},
      carColor: '',
      isLoading: false,
      advancedDetails: false
    });
  }


  componentDidMount() {
    let { authUser } = this.props
    let { users, vehicleModels, modifiers } = this.state
    let _this = this
    firestore().collection('service_provider').doc(authUser.user.uid).collection('customers').get().then(function (customersSnapshot) {
      customersSnapshot.forEach(function (doc) {
        let user = {}
        user = doc.data()
        user.key = doc.id
        user.value = doc.data().number
        user.label = doc.data().number
        users.push(user);
      });
      _this.setState({ users, isLoading: false }, () => {
        _this.getAppointment()
      })
    });
    firestore().collection('cars_models').get().then(function (carsSnapshot) {
      carsSnapshot.forEach(function (doc) {
        let model = {}
        model.data = doc.data()
        model.key = doc.id
        model.value = capitalize(doc.id)
        model.label = capitalize(doc.id)
        vehicleModels[doc.id] = model;

      });
      _this.setState({ vehicleModels, isLoading: false }, () => {
        _this.getAppointment()
      })

    });
    firestore().collection('modifiers').get().then(function (modifiersSnapshot) {
      modifiersSnapshot.forEach(function (doc) {
        let modifier = doc.data()
        modifier.key = doc.id
        modifiers[doc.id] = modifier;
      });
      _this.setState({ modifiers, isLoading: false })

    });

  }

  addNetItem = () => {
    let { authUser, provider } = this.props
    let { customer, newUser, customer_name, customer_number, selectedAddons } = this.state
    let _this = this

    let providerName = provider?.name
    let addons = []
    if (selectedAddons && selectedAddons.length) {
      addons = selectedAddons?.filter(rec => rec.cars && Object.keys(rec.cars).length)?.map(rec => {
        let totalPrice = Object.keys(rec.cars).reduce((total, currentValue) => ((total) + (rec.cars[currentValue]?.price * rec.cars[currentValue]?.count)), 0)
        let totalCount = Object.keys(rec.cars).reduce((total, currentValue) => ((total) + (1 * rec.cars[currentValue]?.count)), 0)
        return { name: rec.name, name_ar: (rec.name_ar || rec.name), price: totalPrice, id: rec.key, cars: rec.cars, totalCount, type: (rec.type || 'Product'), img: rec.img }
      })
    }

    if (authUser) {
      const newItem = {
        bookingDate: this.state.bookingDate,
        created: Date.now(),
        orderNumber: Math.floor(Math.random(new Date().getTime()) * 90000000) + 10000000,
        updated: Date.now(),
        customer_id: this.state.customer_id,
        customer_name: this.state.customer_name,
        country_code: '971',
        customer_number: this.state.customer_number.replace('00971', '').replace('+971', '').replace(/\s/g, ''),
        service_id: this.state.service_id,
        service_name: this.state.service_name,
        sp_id: authUser.user.uid,
        sp_name: providerName || '',
        status: 'accepted',
        washer_id: this.state.washer_id,
        washer_name: this.state.washer_name,
        type: 'offlineBooking',
        carDetail: this.state.carDetail,
        addons: addons,
        place: this.state.place,
        paymentMethod: this.state.paymentMethod,
        paymentType: this.state.paymentType,
        price: this.state.price,
        totalPrice: this.state.price.totalPrice,
        bookingTime: this.state.bookingTime,
        variation: this.state?.variation || ''

      };
      let bookingKey = firestore().collection('bookings').doc().id
      firestore().collection('bookings').doc(bookingKey).set(newItem).then(function () {
        if (newUser) {
          firestore().collection('service_provider').doc(authUser.user.uid).collection('customers').doc(customer.key).set({
            name: customer_name,
            number: customer_number?.replace(/\s/g, ''),
            numberOfBookings: 1,
            type: 'offlineUser',
            carDetail: _this.state.carDetail,
            place: _this.state.place,
          }).then(function () {
          })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        } else {
          firestore().collection('service_provider').doc(authUser.user.uid).collection('customers').doc(customer.key).update({
            name: customer_name,
            number: customer_number?.replace(/\s/g, ''),
            numberOfBookings: firestore.FieldValue.increment(1),
            carDetail: _this.state.carDetail,
            place: _this.state.place,
          }).then(function () {
          })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        }
        firestore().collection('logs').doc(bookingKey).set({
          data: firestore.FieldValue.arrayUnion({
            title: 'newAppointment',
            created: Date.now(),
            bookingTime: _this.state.bookingTime,
            bookingDate: _this.state.bookingDate,
            createdBy: accessTypesObject?.[authUser?.user?.accessType].name,
            createdByName: authUser?.user?.displayName

          }),
          sp_id: authUser?.user?.uid,
          sp_name: providerName || 'superAdmin',
          type: 'offlineBooking',
          created: Date.now(),

        })

      }).catch(function (error) {
        console.error("Error writing document: ", error);
      });


      // this.props.addTodoItem(newItem);
      this.props.toggleModal();
      this.props.dismiss();
      this.setState({
        bookingDate: Date.now(),
        created: Date.now(),
        customer: {},
        customer_id: "",
        customer_name: "",
        customer_number: "",
        service: {},
        service_id: "",
        service_name: "",
        sp_id: "",
        sp_name: "",
        status: "new",
        washer: {},
        washer_id: "",
        washer_name: "",
        bookingTime: {},
        carDetail: [],
        selectedAddons: [],

        paymentMethod: {},
        paymentType: '',
        place: {},
        price: {},
        selectedType: {},
        selectedCar: {},
        selectedModel: {},
        selectedColor: {},
        address: '',
        plateNumber: '',
        selectedPlateNumber: {},
        carColor: '',
        isLoading: false,
        orderNumber: ''
      });
    }

  };
  handleCreate = (inputValue) => {
    let { users } = this.state

    let newCustomer = {}
    let id = firestore().collection('service_provider').doc().id;
    newCustomer.value = inputValue.replace(/\s/g, '')
    newCustomer.label = inputValue.replace(/\s/g, '')
    newCustomer.number = inputValue.replace(/\s/g, '')
    newCustomer.numberOfBookings = 1
    newCustomer.key = id
    newCustomer.type = 'offlineUser'
    users.push(newCustomer)
    this.setState({ users: users, customer: newCustomer, customer_id: id, customer_number: newCustomer.number, newUser: true });


  };
  createNotification = (className, msg) => {
    let cName = className || "";
    return (
      NotificationManager.error(
        msg,
        "Error",
        2000,
        null,
        cName
      )
    )
  };


  render() {
    const { dismiss, selectedBooking, activeServices, washers, authUser, up_selling, variations } = this.props;
    let { isLoading, users, cars, vehicleModels, selectedBrandModels, service_name, bookingTime, carDetail, selectedAddons, place, price, modifiers, paymentMethod, paymentType, totalPrice, customer_id, customer_name, customer_number, service_id, washer, washer_id, bookingDate, orderNumber, advancedDetails, overlapAppointment, variation, activeVariation } = this.state
    let filteredWashers = []
    if (accessTypes.includes(authUser?.user?.accessType)) {
      filteredWashers = Object.values(washers).filter(item => {
        return item.sp_id === selectedBooking.sp_id
      })
    }

    let addonsOptimized = {}
    if (selectedAddons.length) {
      let addons = []
      addons = selectedAddons?.filter(rec => rec.cars && Object.keys(rec.cars).length)?.map(rec => {
        let totalPrice = Object.keys(rec.cars).reduce((total, currentValue) => ((total) + (rec.cars[currentValue]?.price * rec.cars[currentValue]?.count)), 0)
        let totalCount = Object.keys(rec.cars).reduce((total, currentValue) => ((total) + (1 * rec.cars[currentValue]?.count)), 0)
        return { name: rec.name, name_ar: (rec.name_ar || rec.name), price: totalPrice, id: rec.key, cars: rec.cars, totalCount, type: (rec.type || 'Product'), img: rec.img }
      })

      addonsOptimized = addons?.reduce((cars, currentAddon) => {
        if (currentAddon.cars) {
          Object.keys(currentAddon.cars).map(carKey => {
            let car = currentAddon.cars[carKey]
            if (cars[car.car_model]) {
              cars[car.car_model][currentAddon.id] = { name: currentAddon.name, type: currentAddon.type, price: car.price, category: car.category, count: car.count }
              return cars
            } else {
              cars[car.car_model] = { [currentAddon.id]: { name: currentAddon.name, type: currentAddon.type, price: car.price, category: car.category, count: car.count } }
              return cars
            }
          })
        }
        return cars
      }, {})
    }

    let windowTintService = activeServices?.['T6AWbnnnGwU1W81HMBUZ']
    let variationOptimized = {}
    let variationsList = []
    let sortedVariation = []
    if (windowTintService && carDetail?.length) {
      carDetail.map((rec) => {
        let category = rec?.modelNumber?.category
        let id = Object.values(modifiers).find(record => record.name === category)?.key;
        return variationOptimized[id] = windowTintService.modifier[id]?.variations || []
      })
      let result = Object.values(variationOptimized).shift().filter(function (v) {
        return Object.values(variationOptimized).every(function (a) {
          return a.indexOf(v) !== -1;
        });
      });
      variationsList = result

    }
    sortedVariation = variationsList.map(rec => variations[rec])

    if (isLoading) {
      return (
        <div className="loading" />
      )
    }
    return (

      <Fragment>
        <Row>
          <Colxx xxs="12">

            <ModalHeader toggle={dismiss}>

              {orderNumber && <div>Order ID: {orderNumber}</div>}
              {"Add New Appointment"}
            </ModalHeader>
            <ModalBody>
              <h5 className="mt-2">Customer Information</h5>
              <Row>
                <Colxx xxs="6" md="4">
                  <Label className="mt-2">Number *</Label>
                  <Creatable
                    components={{ Input: CustomSelectInput }}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="form-field-name"
                    options={users}
                    value={this.state.customer}
                    onChange={val => {
                      this.setState({
                        customer: val,
                        customer_number: val?.label?.replace(/\s/g, ''),
                        customer_name: val.name,
                        customer_id: val.key,
                        address: val.place ? val.place.address : '',
                        // selectedCar: val.carDetail ? { key: val.carDetail.car_model, label: val.carDetail.car_model, value: val.carDetail.car_model } : {},
                        // selectedModel: val.carDetail ? { key: val.carDetail.modelNumber.model, label: val.carDetail.modelNumber.model, value: val.carDetail.modelNumber.model, category: val.carDetail.modelNumber.category } : {},
                        // selectedType: val.carDetail ? { key: val.carDetail.vehicle, label: val.carDetail.vehicle, value: val.carDetail.vehicle } : {},
                        // selectedColor: val.carDetail ? { key: val.carDetail.car_color, label: val.carDetail.car_color, value: val.carDetail.car_color } : {},
                        // carColor: val.carDetail ? val.carDetail.car_color : '',
                        // plateNumber: val.carDetail && val.carDetail.plateNumber ? val.carDetail.plateNumber.split(' ').slice(1).join(' ') : '',
                        // selectedPlateNumber: val.carDetail && val.carDetail.plateNumber ? { key: val.carDetail.plateNumber.split(" ")[0], label: val.carDetail.plateNumber.split(" ")[0], value: val.carDetail.plateNumber.split(" ")[0] } : {},
                        carDetail: val.carDetail?.length ? val.carDetail : val.carDetail ? [val.carDetail] : {},
                        place: val.place ? val.place : {},
                        newUser: false
                      });
                    }}
                    onCreateOption={this.handleCreate}
                  />
                </Colxx>
                <Colxx xxs="6" md="4">
                  <Label className="mt-2">Name *</Label>
                  <Input
                    type="text"
                    defaultValue={this.state.customer_name}
                    value={this.state.customer_name}
                    onChange={event => {
                      this.setState({ customer_name: event.target.value });
                    }}
                  />
                </Colxx>
                <Colxx xxs="12" md="4">
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Label className="mt-2">Address *</Label>
                    <div>
                      <Input type='checkbox'
                        onChange={() => {
                          this.setState({ whatsappLink: !this.state.whatsappLink })
                        }}
                      />
                      <Label style={{ fontSize: '12px', color: '#3498db' }}>Map link</Label>
                    </div>
                  </div>
                  {
                    this.state.whatsappLink ?
                      <Input
                        type="text"
                        defaultValue={this.state.address}
                        value={this.state.address}
                        placeholder={this.state.placeholder}
                        onChange={event => {
                          let value = event.target.value;
                          let url;
                          if (value) {
                            try {
                              url = new URL(value)
                            }
                            catch (e) {
                              NotificationManager.error(
                                'Cannot get location from URL',
                                "Error",
                                2000,
                                null,
                              )
                              return
                            }
                            let query = url.searchParams.get('q')
                            if (url && query) {
                              this.setState({ placeholder: 'loading...' }, () => {
                                let translate = functions().httpsCallable('translateAddress')
                                translate({ area: query }).then((res) => {
                                  let { results } = res.data
                                  if (results && results.length > 0) {
                                    let { formatted_address, geometry } = results[0]
                                    place.address = formatted_address
                                    place.location = {
                                      latitude: geometry.location.lat,
                                      longitude: geometry.location.lng
                                    }
                                    NotificationManager.success(
                                      'Location Added successfully',
                                      "Success",
                                      2000,
                                      null,
                                    )
                                    this.setState({ address: formatted_address, place, placeholder: 'Enter copied link' });
                                  } else {
                                    NotificationManager.error(
                                      'Cannot get location from URL',
                                      "Error",
                                      2000,
                                      null,
                                    )
                                    this.setState({ placeholder: 'Enter copied link' });
                                  }
                                }).catch(err => {
                                  NotificationManager.error(
                                    'Cannot get location from URL',
                                    "Error",
                                    2000,
                                    null,
                                  )
                                  this.setState({ placeholder: 'Enter copied link' });
                                })
                              })

                            }
                          }
                        }}
                      />
                      :
                      <>
                        <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCExglJjHXgXzSGHJB1NmLll7NgjOG8_bc&libraries=places&language=en"
                          onLoad={this.handleScriptLoad}
                        />
                        <Input
                          id='autocomplete'
                          type="text"
                          defaultValue={this.state.search ? this.state.search : this.state.address}
                          value={this.state.search ? this.state.search : this.state.address}
                          onChange={event => {
                            this.setState({ search: [event.target.value] });
                          }}

                        />
                      </>
                  }



                </Colxx>

              </Row>
              <Row>
                <Colxx xxs="12" md="12">
                  <Label className="mt-2">Additional Instructions</Label>
                  <Input
                    type="textarea"
                    placeholder="Additional Instructions"
                    defaultValue={place.additionalInstruction}
                    value={place.additionalInstruction}
                    onChange={event => {
                      place.additionalInstruction = event.target.value
                      this.setState({ place });
                    }}
                  />
                </Colxx>

              </Row>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <h5 className="mt-4">Car Details</h5>{selectedBooking?.carDetail?.length ? <h6 className="mt-4" style={{ fontWeight: '700', padding: '0 8px' }}>{selectedBooking.carDetail.length + " vehicle(s)"} </h6> : ''}
              </div>

              {
                this.state.carDetail?.length ?
                  <Row>
                    {/* <Colxx xxs="12" md="4">
                      <div>
                        {
                          this.state.carDetail.map((car, index) => {
                            return (
                              <div key={index} style={{ padding: '8px', border: '1px solid #cacaca', borderRadius: '4px', margin: '4px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                  <div>{car?.car_model}{" "}{car.modelNumber?.model}{" "}{car.modelNumber?.category}</div>
                                  <div>{car.plateNumber}</div>
                                </div>
                                <div onClick={() => {
                                  let carDetail = this.state.carDetail.filter(item => item !== car)
                                  this.setState({ carDetail, washer: false, service: false })
                                }}
                                  style={{ fontSize: '16px', cursor: 'pointer' }}
                                >
                                  x
                                </div>
                              </div>
                            )

                          })
                        }
                      </div>
                    </Colxx> */}
                    <Colxx xxs="12" md="6">
                      <div>
                        {
                          this.state.carDetail.map((car, index) => {
                            let car_model = car.car_model + ' ' + car?.modelNumber?.model
                            let filteredPrds = Object.values(up_selling).filter(rec => {
                              if (rec.type && rec.type === "Service") {
                                return Object.values(rec.price).filter(price => price.name === car?.modelNumber?.category).length
                              }
                              return true
                            })
                            return (
                              <div key={index} style={{ marginTop: 8, alignItems: 'flex-start' }}>
                                <div style={{ padding: '8px', border: '1px solid #cacaca', borderRadius: '4px', margin: '4px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <div>
                                    <div style={{ paddingHorizontal: '5%', color: '#4d4d4d', fontSize: 14, fontWeight: '700' }}>
                                      {
                                        car?.modelNumber?.category === 'Bike' ?
                                          car?.modelNumber?.model + ' - ' + car?.modelNumber?.category
                                          :
                                          car.car_model + ' ' + car?.modelNumber?.model + ' - ' + car?.modelNumber?.category
                                      }
                                    </div>
                                    <div>{car.plateNumber}</div>
                                  </div>
                                  <div onClick={() => {
                                    let carDetail = this.state.carDetail.filter(item => item !== car)

                                    Object.values(filteredPrds && filteredPrds.length ? filteredPrds : up_selling).map((item, index) => {
                                      if (item.cars) {
                                        delete item?.cars[car_model]
                                        selectedAddons[index] = item

                                        let addonsPrice = selectedAddons?.reduce((total, currentValue) => {
                                          let cumPrice = 0
                                          Object.values(currentValue.cars).map(item => {
                                            return (cumPrice += Number(item?.price) * Number(item?.count))
                                          })
                                          return ((total) + cumPrice)
                                        }, 0)

                                        price.addonsPrice = addonsPrice
                                        price.totalPrice = Number(price.servicePrice) + Number(price.addonsPrice) + (Number(price?.variationPrice) || 0)
                                        this.setState({ selectedAddons, price })
                                      }
                                      return true
                                    })

                                    this.setState({ carDetail, service: {}, service_name: '', service_id: '', price: {}, totalPrice: 0 })
                                  }}
                                    style={{ fontSize: '16px', cursor: 'pointer' }}
                                  >
                                    x
                                  </div>
                                </div>
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  padding: '8px',
                                  width: '100%',
                                  whiteSpace: 'nowrap',
                                  position: 'relative',
                                  overflowX: 'scroll',
                                  overflowY: 'hidden',
                                }}>
                                  {Object.values(filteredPrds && filteredPrds.length ? filteredPrds : up_selling).map((item, index) => {
                                    return (
                                      item.active && <Addons key={item.key + car} item={item} car={car} index={index} carDetail={this.state.carDetail} price={price} up_selling={up_selling} selectedAddons={selectedAddons} update={(e) => this.setState(e)} />
                                    )
                                  })
                                  }
                                </div>
                              </div>
                            )

                          })
                        }
                      </div>
                    </Colxx>
                  </Row>
                  : this.state.carDetail.modelNumber?.category ?
                    <Row>
                      <Colxx xxs="12" md="4">
                        <div style={{ padding: '8px', border: '1px solid #cacaca', borderRadius: '4px', margin: '4px 0' }}>
                          <div>{this.state.carDetail?.car_model || ''}{" "}{this.state.carDetail.modelNumber?.model || ''}{" "}{this.state.carDetail.modelNumber?.category || ''}</div>
                          <div>{this.state.carDetail?.plateNumber || ''}</div>
                        </div>
                      </Colxx>
                    </Row>
                    : ''

              }
              <Button size='sm' color='primary' onClick={() => this.setState({ addCars: true })}>Add Cars</Button>
              <Modal isOpen={this.state.addCars}>
                <ModalHeader>
                  Add Car
                </ModalHeader>
                <div className='m-3'>

                  <div className="mt-2 ml-3">
                    <Input type='checkbox'
                      onChange={() => {
                        this.setState({
                          advancedDetails: !this.state.advancedDetails,
                          service: {},
                          service_name: '',
                          service_id: '',
                          price: {},
                          bookingTime: {},
                          paymentMethod: {},
                          paymentType: '',
                          totalPrice: 0
                        })
                      }}
                    />
                    <Label style={{ fontSize: '12px', color: '#3498db' }}>Advanced car details</Label>
                  </div>
                  {
                    !advancedDetails && (
                      <Row>
                        <ul className="list-unstyled mt-2 mb-0" style={deviceWidth() === 'desktop' ? { display: "flex", flexWrap: 'wrap' } : { display: "block" }}>
                          {['Sedan', 'SUV', '2 weeler', '3 weeler', 'Off Road', 'ATV'].map((item, index) => {
                            return (
                              <Colxx key={index} xxs="6" md="5">
                                <NavItem style={{ cursor: 'pointer' }}>
                                  <div onClick={() => this.setState({
                                    selectedVehicleType: item,
                                    selectedCar: {},
                                    selectedModel: { category: item },
                                    selectedType: {},
                                    selectedColor: {},
                                    carColor: '',
                                    plateNumber: '',
                                    selectedPlateNumber: {},
                                    service: {},
                                    service_name: '',
                                    service_id: '',
                                    price: {},
                                    bookingTime: {},
                                    paymentMethod: {},
                                    paymentType: '',
                                    totalPrice: 0
                                  })}>
                                    <div className="custom-control custom-radio">
                                      <input
                                        style={{ cursor: 'pointer' }}
                                        type="radio"
                                        className="custom-control-input"
                                        checked={this.state.selectedVehicleType === item}
                                      />
                                      <label style={{ cursor: 'pointer' }} className="custom-control-label">{item}</label>
                                    </div>
                                  </div>
                                </NavItem>
                              </Colxx>

                            );
                          })}
                        </ul>
                      </Row>
                    )
                  }
                  {
                    advancedDetails && (
                      <Row>
                        <Colxx xxs="6" md="4">
                          <Label className="mt-2">Vehicle type *</Label>
                          <Select
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            // classNamePrefix="react-select"
                            key='selectedType'
                            // name="form-field-name"
                            options={Object.values(vehicleModels)}
                            value={this.state.selectedType}
                            onChange={val => {
                              // carDetail.vehicle = val.label

                              cars = []
                              Object.keys(val.data).map((item, index) => {
                                let car = {}
                                car.key = index
                                car.label = item
                                car.value = item
                                return (cars.push(car))
                              })
                              this.setState({ selectedType: val, cars: cars, selectedCar: null, selectedModel: null });
                            }}
                          />
                        </Colxx>
                        <Colxx xxs="6" md="4">
                          <Label className="mt-2">Car Brand *</Label>
                          <Select
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="form-field-name"
                            options={cars}
                            value={this.state.selectedCar}
                            onChange={val => {
                              // carDetail.car_model = val.label
                              selectedBrandModels = []
                              vehicleModels[(this.state.selectedType.label).toLowerCase()].data[val.label].map(item => {
                                let carModel = {}
                                carModel.key = item.model
                                carModel.label = item.model
                                carModel.value = item.model
                                carModel.year = item.year
                                carModel.category = item.category
                                return (selectedBrandModels.push(carModel))
                              })
                              this.setState({ selectedCar: val, selectedBrandModels: selectedBrandModels });
                            }}
                          />
                        </Colxx>
                        <Colxx xxs="6" md="4">
                          <Label className="mt-2">Car Model *</Label>
                          <Select
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="form-field-name"
                            options={selectedBrandModels}
                            value={this.state.selectedModel}
                            onChange={val => {
                              // carDetail.modelNumber = { category: val.category, year: val.year, model: val.label }
                              this.setState({ selectedModel: val, service: {}, service_name: '', service_id: '', price: {}, bookingTime: {}, paymentMethod: {}, paymentType: '', totalPrice: 0 });
                            }}
                          />
                        </Colxx>
                      </Row>
                    )
                  }
                  {
                    advancedDetails && (
                      <Row>
                        <Colxx xxs="6" md="4">
                          <Label className="mt-2">Plate Number</Label>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Select
                              components={{ Input: CustomSelectInput }}
                              className="react-select custoumSelect"
                              classNamePrefix="react-select"
                              name="form-field-name"
                              options={emirates}
                              value={this.state.selectedPlateNumber}
                              onChange={val => {
                                // carDetail.plateNumber = ''
                                this.setState({ selectedPlateNumber: val, plateNumber: '' });
                              }}
                            />
                            <Input
                              type="text"
                              placeholder="A 1234"
                              defaultValue={this.state.plateNumber}
                              value={this.state.plateNumber}
                              onChange={event => {
                                // carDetail.plateNumber = this.state.selectedPlateNumber.value + " " + event.target.value
                                this.setState({ plateNumber: event.target.value });
                              }}
                            />
                          </div>

                        </Colxx>
                        <Colxx xxs="6" md="4">
                          <Label className="mt-2">Car Color</Label>
                          <Select
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="form-field-name"
                            options={colors}
                            value={this.state.selectedColor}
                            onChange={val => {
                              // carDetail.car_color = val.value
                              this.setState({ selectedColor: val, carColor: val.value });
                            }}
                          />

                        </Colxx>

                      </Row>
                    )
                  }
                </div>
                <ModalFooter>
                  <Button color='danger' onClick={() => this.setState({ addCars: false })}>Dismiss</Button>
                  <Button color='primary'
                    onClick={() => {
                      let post = {
                        car_color: this.state?.carColor || "N/A",
                        car_model: this.state.selectedCar?.label || makeid(6) || "N/A",
                        modelNumber: {
                          category: this.state.selectedModel?.category || "N/A",
                          model: this.state.selectedModel?.label || "N/A",
                          year: this.state.selectedModel?.year || "N/A"
                        },
                        plateNumber: this.state.selectedPlateNumber?.label ? this.state.selectedPlateNumber?.label + " " + this.state?.plateNumber : "N/A",
                        vehicle: this.state.selectedModel.category,
                      }
                      let carDetail = this.state.carDetail
                      if (carDetail?.length) {
                        carDetail.push(post)

                      } else {
                        carDetail = [post]
                      }
                      this.setState({ addCars: false, carDetail, washer: {}, washer_name: '', washer_id: '', service: false })
                    }}

                  >Add</Button>

                </ModalFooter>
              </Modal>

              <h5 className="mt-4">Appointment Details</h5>

              <Row>
                <Colxx xxs="8" md="6">
                  <Label className="mt-2">Booking Date *</Label>
                  <DatePicker
                    popperClassName="redatepicker"
                    customInput={<DatepickerInput />}
                    selected={(moment(this.state.bookingDate || new Date()).toDate())}
                    onChange={date => {
                      bookingTime.startTime = moment("1955-01-05T" + moment(date).format('HH:mm')).toDate().getTime()
                      bookingTime.endTime = (moment(bookingTime.startTime)).add(this.state.timeInminutes, 'minutes').toDate().getTime()
                      this.setState({ bookingDate: moment(moment(date).format('lll')).toDate().getTime(), bookingTime, washer: {}, washer_name: '', washer_id: '', travelTime: false })
                    }}
                    showTimeSelect
                    timeFormat="hh:mm a"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="dd/MM/yyyy hh:mm a"
                  />
                </Colxx>
                <Colxx xxs="8" md="6">
                  <Label className="mt-2">Travel Time (mins) *</Label>
                  <Input
                    type="number"
                    placeholder="Add travel time in mins"
                    value={this.state.travelTime}
                    onChange={event => {
                      bookingTime.endTime = (moment(bookingTime.startTime)).add(this.state.timeInminutes, 'minutes').add(Number(event.target.value), 'minutes').toDate().getTime()
                      this.setState({ travelTime: Number(event.target.value), bookingTime });
                    }}
                  />
                </Colxx>
              </Row>
              <Row>
                <Colxx xxs="8" md="6">
                  <Label className="mt-2">Washer *</Label>
                  <Select
                    components={{ Input: CustomSelectInput }}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="form-field-name"
                    options={accessTypes.includes(authUser?.user?.accessType) ? Object.values(filteredWashers) : Object.values(washers)}
                    value={this.state.washer}
                    onChange={val => {
                      if (this.state.washer.key !== val.key) {
                        // this.setState({ washer: val, washer_name: val.label, washer_id: val.key });

                        this.setState({ service: {}, service_name: '', service_id: '', price: {}, totalPrice: 0 }, () => {
                          this.handleWasherChange(val)
                        })
                      }
                    }}
                  />
                </Colxx>
                <Colxx xxs="8" md="6">
                  <Label className="mt-2">Service *</Label>
                  <Select
                    components={{ Input: CustomSelectInput }}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="form-field-name"
                    options={Object.values(activeServices)}
                    value={this.state.service}
                    onChange={val => {
                      let cumPrice = 0
                      let timeInminutes = 0;
                      washer && washer.activeServices && washer.activeServices[val.key] && Object.keys(washer.activeServices[val.key]).map(item => {
                        if (this.state.carDetail.length) {
                          this.state.carDetail.map(car => {
                            if (modifiers[item].name === car?.modelNumber?.category) {
                              cumPrice = cumPrice + (washer.activeServices[val.key][item].price * 1)
                              let modifierDuration = washer.activeServices[val.key][item].duration;
                              let hours = modifierDuration.split(":")[0]
                              let mins = modifierDuration.split(":")[1]
                              timeInminutes = timeInminutes + ((hours * 60) + (mins * 1))
                            }
                            return false
                          })
                        }
                        return false

                      })
                      let addonsPrice = selectedAddons?.reduce((total, currentValue) => {
                        let cumPrice = 0
                        Object.values(currentValue.cars).map(item => {
                          return (cumPrice += Number(item?.price) * Number(item?.count))
                        })
                        return ((total) + cumPrice)
                      }, 0)

                      price.addonsPrice = addonsPrice
                      price.servicePrice = cumPrice
                      price.vat = cumPrice * 0.05
                      price.totalPrice = Number(price.servicePrice) + Number(price.addonsPrice) + (Number(price?.variationPrice) || 0)
                      bookingTime.startTime = moment("1955-01-05T" + moment(this.state.bookingDate).format('HH:mm')).toDate().getTime()
                      bookingTime.endTime = timeInminutes ? moment(bookingTime.startTime).add(timeInminutes, 'minutes').add(this.state.travelTime, 'minutes').toDate().getTime() : moment(bookingTime.startTime).add(45, 'minutes').toDate().getTime()
                      paymentType = 'cash'
                      paymentMethod.amount = price.totalPrice
                      paymentMethod.currency = 'AED'
                      totalPrice = price.totalPrice
                      this.setState({ service: val, service_name: val.label, service_id: val.key, price, bookingTime, paymentMethod, totalPrice, timeInminutes: timeInminutes, paymentType })
                    }}
                  />
                </Colxx>
                {sortedVariation?.length && service_id === 'T6AWbnnnGwU1W81HMBUZ' ?
                  <Colxx xxs="8" md="6">
                    <Label className="mt-2" style={{ fontSize: '16px', fontWeight: '600', marginTop: '8px' }}>Select window tint options *</Label>
                    {
                      Object.values(activeVariation).filter(rec => rec.selected).map(rec => {
                        let activeArray = ((rec.key === 'brand') ? (sortedVariation?.map(rec => rec.name)) : (rec.key === 'heatRejection') ? this.state.heatLevels || [] : (rec.key === 'darkness') ? this.state.tintLevels || [] : sidesArray.map(rec => rec.value))
                        return (
                          <div key={rec.key}>
                            <div style={{ fontSize: '14px', fontWeight: '600', marginTop: '8px' }}>{rec.title}</div>
                            <ul className="list-unstyled d-flex mb-0">

                              {activeArray.map((vari, varIndex) => {
                                return (
                                  <NavItem key={varIndex} style={{ cursor: 'pointer', marginRight: '4px' }}>
                                    <div onClick={() => {
                                      let newSelected = JSON.parse(JSON.stringify(rec))
                                      newSelected.selected = vari

                                      let object = JSON.parse(JSON.stringify(activeVariation))
                                      object[rec.key] = newSelected
                                      if (rec.key === 'brand') {
                                        this.setState({ heatLevels: sortedVariation?.find(brand => brand.name === newSelected.selected)?.data.map(heat => heat.heatRejection), tintLevels: [] })
                                        object.heatRejection.selected = ' '
                                        delete object.darkness.selected
                                        delete object.frontSide.selected
                                        delete price.totalPrice
                                        delete price.variationPrice
                                        delete price.variPrice

                                      }
                                      if (rec.key === 'heatRejection') {
                                        this.setState({ tintLevels: sortedVariation?.find(brand => brand.name === activeVariation.brand.selected)?.data?.find(heat => heat.heatRejection === newSelected.selected)?.darknessLevels })
                                        object.darkness.selected = ' '
                                        delete object.frontSide.selected
                                        delete price.totalPrice
                                        delete price.variationPrice
                                        delete price.variPrice

                                      }
                                      if (rec.key === 'darkness') {
                                        object.frontSide.selected = ' '
                                        delete price.totalPrice
                                        delete price.variationPrice
                                        delete price.variPrice

                                      }
                                      if (rec.key === 'frontSide') {
                                        let selectedSide = sidesArray.find(side => side.value === newSelected?.selected)?.key
                                        let selected = sortedVariation?.find(brand => brand.name === activeVariation?.brand?.selected)?.data?.find(heat => heat.heatRejection === activeVariation?.heatRejection?.selected)?.[selectedSide]
                                        let variationPrice = 0
                                        let variPrice = {}

                                        // eslint-disable-next-line
                                        carDetail.map((rec) => {
                                          let category = rec?.modelNumber?.category
                                          let id = Object.values(modifiers).find(record => record.name === category)?.key;
                                          variPrice[id] = Number(selected?.[id]) || 0
                                          variationPrice = variationPrice + (Number(selected?.[id]) || 0)
                                        })

                                        price.variationPrice = variationPrice
                                        price.variPrice = variPrice
                                        price.totalPrice = price.servicePrice + price.variationPrice + (Number(price?.addonsPrice) || 0)

                                        variation = Object.keys(object).reduce((value, currentValue) => {
                                          if (object[currentValue].selected) {
                                            value[currentValue] = object[currentValue].selected
                                          }
                                          return value
                                        }, {})
                                      }


                                      this.setState({ activeVariation: object, price, variation })
                                    }}>
                                      <div className="custom-control custom-radio mr-2">
                                        <input
                                          style={{ cursor: 'pointer' }}
                                          type="radio"
                                          className="custom-control-input"
                                          checked={rec.selected === vari}
                                        />
                                        <label style={{ cursor: 'pointer' }} className="custom-control-label">{vari}</label>
                                      </div>
                                    </div>
                                  </NavItem>
                                );
                              })}
                            </ul>
                          </div>
                        )
                      })
                    }

                  </Colxx> : ''}
              </Row>
              {price && price.totalPrice ?
                <div>
                  <h5 className="mt-4">Details</h5>
                  <Row>
                    <Colxx xxs="4" className="mt-2">
                      <div style={{ fontWeight: '700' }}>Payment Method:</div>
                      <div >{paymentType === 'card' ? 'Online Payment' : 'Cash'}</div>
                    </Colxx>
                  </Row>

                  {paymentType === 'card' && paymentMethod && paymentMethod.authorize_id ?
                    <Row>
                      <Colxx xxs="4" className="mt-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontWeight: '700' }}>Transaction ID:</div>
                        <div >{paymentMethod.authorize_id}</div>
                      </Colxx>
                    </Row>
                    : ''}
                  <Row>

                    <Colxx xxs="4" className="mt-2" >
                      <div style={{ fontWeight: '700' }}>Service Price:</div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>{carDetail?.length + "x " + service_name} </div>
                        <div>{price.servicePrice + (price?.variationPrice || 0)} AED</div>
                      </div>
                    </Colxx>
                  </Row>

                  {
                    addonsOptimized && Object.keys(addonsOptimized).length ?
                      <Row>
                        <Colxx xxs="4" className="mt-2">
                          <div style={{ fontWeight: '700' }}>Addons:</div>
                          {Object.keys(addonsOptimized).map(rec => {
                            let category = Object.values(addonsOptimized[rec])[0].category
                            return (
                              <div key={rec}>
                                <li className="mt-2 mb-1" style={{ fontWeight: '600' }}>{rec + " - " + category}</li>
                                {Object.values(addonsOptimized[rec]).map((addon, index) => {
                                  return (
                                    <div key={index} style={{ marginLeft: '18px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                      <div ><span style={{ fontWeight: '600' }}>{addon.count + "x "}</span> {addon.name} </div>
                                      <div >{Number(addon.count) * Number(addon.price)} AED</div>
                                    </div>
                                  )
                                })
                                }
                              </div>
                            )
                          })
                          }
                        </Colxx>
                      </Row> : ''

                  }
                  {/* <Colxx xxs="12" className="mt-2" style={{ display: 'flex', alignItems: 'center' }}>
                  <div >Vat:</div>
                  <div style={{ fontWeight: '700', marginLeft: '8px' }}>{price.vat} AED</div>
                </Colxx> */}
                  {price.code && price.discount && <Row>
                    <Colxx xxs="4" className="mt-2" >
                      <div style={{ fontWeight: '700' }}>Discount:</div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {price.code === 'loyalty' ? <div >Loyalty points</div> : <div >Promo Code: {price.code}</div>}
                        <div >{price.discount} AED</div>
                      </div>
                    </Colxx>
                  </Row>

                  }
                  <Row>
                    <Colxx xxs="4" className="mt-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                      <div style={{ fontWeight: '700' }}>Total Price:</div>
                      <div style={{ fontWeight: '700' }}>{price.totalPrice} AED</div>
                    </Colxx>
                  </Row>
                </div> : ''
              }

            </ModalBody>
            {!this.props.fromHistory && <ModalFooter style={{ justifyContent: 'flex-end', color: '#dc3545 !important' }}>
              <Button color="primary"
                disabled={
                  (!customer_id ||
                    !customer_name ||
                    !customer_number ||
                    !service_id ||
                    !washer_id ||
                    !bookingDate ||
                    !bookingTime ||
                    !bookingTime.startTime ||
                    !bookingTime.endTime ||
                    !carDetail.length ||
                    // !carDetail.vehicle ||
                    // !carDetail.car_model ||
                    // !carDetail.modelNumber ||
                    !place.address ||
                    !price.totalPrice)} className="top-right-button" onClick={() => {
                      if (this.state.travelTime) {
                        this.addNetItem()
                      } else {
                        this.createNotification("error", "Kindly add travel time to proceed")
                      }
                    }}>
                {"Create Appointment"}
              </Button>{" "}
            </ModalFooter>}

            <Modal
              isOpen={overlapAppointment}
              backdrop="static">
              <ModalHeader >{<div><span style={{
                fontWeight: '700',
                color: '#dc3545'
              }}>Overlap</span> </div>}</ModalHeader>
              <ModalBody>
                By doing this you will Overlap with worker appointments, are you sure you want to continue?
              </ModalBody>
              <ModalFooter>
                <Button color="primary" outline onClick={() => this.setState({ overlapAppointment: false, overlap: false, washer: {}, washer_name: '', washer_id: '' })}>
                  Cancel
                </Button>
                <Button color="danger" outline onClick={() => this.setState({ overlapAppointment: false })} >
                  Continue
                </Button>{" "}
              </ModalFooter>
            </Modal>
          </Colxx>

        </Row>

      </Fragment >
    );
  }



  handleWasherChange(val) {
    let { bookings } = this.props
    let { bookingDate, bookingTime } = this.state
    let washerBookings = Object.values(bookings).filter(item => {
      return item.washer_id === val.key
    })
    let today = moment(bookingDate).format('dddd')
    if (val.active && (today !== 'Invalid date') && val.timings[today].isOpen && !moment(bookingDate).isSame(moment(val?.busyTimings?.startTime)) && !moment(bookingDate).isBetween(moment(val?.busyTimings?.startTime), moment(val?.busyTimings?.endTime))) {
      if (washerBookings.length) {
        let count2 = 0

        val.timings[today].timings.every(time => {

          let startTime2 = moment(time.startTime)
            .set('year', moment(bookingDate).year())
            .set('month', moment(bookingDate).month())
            .set('date', moment(bookingDate).date()).seconds(0).milliseconds(0);
          let endTime2 = moment(time.endTime)
            .set('year', moment(bookingDate).year())
            .set('month', moment(bookingDate).month())
            .set('date', moment(time.endTime).hours() === 0 ? moment(bookingDate).date() + 1 : moment(bookingDate).date()).seconds(0).milliseconds(0);

          let currentBookingEndTime = moment(bookingTime.endTime).set('year', moment(bookingDate).year()).set('month', moment(bookingDate).month()).set('date', moment(bookingDate).date()).seconds(0).milliseconds(0);
          if ((moment(bookingDate).isSameOrAfter(startTime2) && currentBookingEndTime.isSameOrBefore(endTime2))) {
            // eslint-disable-next-line
            let pickedTiming = washerBookings.find(item => {
              if (item.bookingTime && (item.status === 'new' || item.status === 'pending' || item.status === 'accepted' || item.status === 'active')) {
                let Year = moment(item.bookingDate).year()
                let Month = moment(item.bookingDate).month()
                let Day = moment(item.bookingDate).date()
                let startTime = moment(item.bookingTime.startTime).set('year', Year).set('month', Month).set('date', Day);
                let endTime = moment(item.bookingTime.endTime).set('year', Year).set('month', Month).set('date', Day);
                return (
                  (moment(bookingDate).isSame(startTime) ||
                    moment(bookingDate).isBetween(startTime, endTime)) ||
                  (currentBookingEndTime.isBetween(startTime, endTime)))
              }
            })

            if (pickedTiming) {
              this.setState({ overlapAppointment: true, washer: val, washer_name: val.label, washer_id: val.key, overlap: true });

            } else {
              this.setState({ washer: val, washer_name: val.label, washer_id: val.key });
            }
            return false
          }
          count2 += 1
          return true
        })
        if (count2 === val.timings[today].timings.length) {
          this.createNotification("error", "Outside worker timings")
          this.setState({ washer: {}, washer_name: '', washer_id: '' });

        }
      } else {
        if (val.timings[today].isOpen) {
          this.setState({ washer: val, washer_name: val.label, washer_id: val.key });
        } else {
          this.createNotification("error", "This worker is not available today")
          this.setState({ washer: {}, washer_name: '', washer_id: '' });
        }
      }
    } else {
      this.createNotification("error", "This worker is not available today")
      this.setState({ washer: {}, washer_name: '', washer_id: '' });

    }
  }
  handleScriptLoad() {
    // Declare Options For Autocomplete 
    // var circle = new google.maps.Circle(
    //     { center: { lat: 25.1874756, lng: 55.2529987 }, radius: 20000 });
    var options = {
      types: ['geocode'],
      componentRestrictions: { country: 'ae' },
      // language: 'en'
    };

    // Initialize Google Autocomplete 
    /*global google*/
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options);
    // this.autocomplete.setBounds(circle.getBounds());

    // Avoid paying for data that you don't need by restricting the 
    // set of place fields that are returned to just the address
    // components and formatted address
    this.autocomplete.setFields(['address_components',
      'formatted_address', 'geometry', 'type', 'name', 'vicinity', 'place_id']);
    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed',
      this.handlePlaceSelect);
  }
  handlePlaceSelect() {
    let country = localStorage.getItem("country")
    let _this = this
    let { place, address } = this.state
    // Extract City From Address Object
    let addressObject = this.autocomplete.getPlace();
    let mapArea = addressObject.name
    // Check if address is valid
    let { formatted_address, geometry } = addressObject
    let paramArray = formatted_address.split((country === 'Egypt') ? ', ' : ' - ')

    if (paramArray.length > 1) {
      let areaTitle = paramArray[paramArray.length - 2]
      let country = paramArray[paramArray.length - 1]

      if (mapArea) {
        axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
          params: {
            place_id: addressObject.place_id,
            key: 'AIzaSyCExglJjHXgXzSGHJB1NmLll7NgjOG8_bc',
            language: 'ar',
          }
        }).then(res => {
          let { data } = res
          let { results } = data
          if (results) {
            let { address_components } = results[0];

            let sublocaltyAR = address_components.filter(item => item.types.includes("locality") || item.types.includes('administrative_area_level_1'))[0].short_name
            if (sublocaltyAR) {
              address = mapArea + ' - ' + areaTitle + ' - ' + country
              place.address = mapArea + ' - ' + areaTitle + ' - ' + country
              place.location = {
                latitude: geometry?.location?.lat(),
                longitude: geometry?.location?.lng(),
              }
            }
            _this.setState(
              {
                address,
                place,
                search: '',
              }
            );
          }
        })
      }
    }
  }

}

const mapStateToProps = ({ authUser, providers }) => {
  return {
    authUser,
    up_selling: providers ? providers.up_selling : {},
    variations: providers ? providers.variations : {},
    provider: providers ? Object.values(providers.provider || {})?.[0] : {},

  };
};
export default connect(
  mapStateToProps
)(AddNewTodoModal);
