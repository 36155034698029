const data = [
  {
    id: "dashboard",
    icon: "iconsminds-monitor-analytics",
    label: "menu.dashboard",
    to: "/app/dashboard",
    role: [
      "admin",
      "superAdmin",
      "customerService"
    ]
  },
  {
    id: "providers",
    icon: "iconsminds-shop",
    label: "menu.service_providers",
    to: "/app/providers",
    role: [
      "superAdmin",
      "admin",
      "customerService",
      'finance'

    ]
  },
  {
    id: "workers",
    icon: "iconsminds-engineering",
    label: "menu.workers",
    to: "/app/workers",
    role: [
      "superAdmin",
      "admin",
      "customerService"

    ]
  },

  {
    id: "requests",
    icon: "iconsminds-calendar-4",
    label: "Requests",
    role: [
      "superAdmin",
      "admin",
      "customerService"
    ],

    to: "/app/orders/requests"
  },
  {
    id: "appointments",
    icon: "iconsminds-calendar-4",
    label: "Appointments",
    role: [
      "superAdmin",
      "admin",
      "customerService"
    ],

    to: "/app/orders/appointments"
  },

  // {
  //   id: "requests",
  //   icon: "iconsminds-calendar-4",
  //   label: "menu.requests",
  //   to: "/app/requests",
  //   role: [
  //     "superAdmin",
  //     "admin",
  //     "customerService"
  //   ]
  // },
  // {
  //   id: "appointments",
  //   icon: "iconsminds-calendar-4",
  //   label: "menu.appointments",
  //   to: "/app/appointments",
  //   role: [
  //     "superAdmin",
  //     "admin",
  //     "customerService"
  //   ]
  // },
  {
    id: "subscriptions",
    icon: "simple-icon-clock",
    label: "Subscriptions",
    to: "/app/subscriptions",
    role: [
      "superAdmin",
      "admin",
      "customerService",
      "finance"
    ]
  },
  {
    id: "chat",
    icon: "simple-icon-speech",
    label: "Chat",
    role: [
      "superAdmin",
      "admin",
      "customerService"
    ],
    to: "/app/chat"
  },

  {
    id: "services",
    icon: "iconsminds-wrench",
    label: "menu.services",
    to: "/app/services",
    role: [
      "superAdmin",
    ]
  },
  {
    id: "up selling",
    icon: "iconsminds-wrench",
    label: "menu.upSelling",
    to: "/app/up-selling",
    role: [
      "superAdmin",
      "admin",
      "customerService"
    ]
  },
  {
    id: "CRMmenu",
    icon: "iconsminds-monitor-analytics",
    label: "CRM",
    to: "/app/crm",
    role: [
      "superAdmin",
      "admin",
      "customerService",
      "marketing",
      "finance"
    ],
    subs: [
      {
        icon: "simple-icon-people",
        label: "Customers",
        to: "/app/crm/customers",
        role: [
          "superAdmin",
          // "admin",
          "customerService",
          // "marketing",
          "finance"

        ]

      },
      {
        icon: "simple-icon-clock",
        label: "Orders",
        to: "/app/crm/orders",
        role: [
          "superAdmin",
          "admin",
          "customerService",
          "marketing",
          "finance"

        ]

      },

    ]
  },
  {
    id: "marketing",
    icon: "iconsminds-loudspeaker",
    label: "Marketing",
    to: "/app/marketing",
    role: [
      "superAdmin",
      "customerService",
      "marketing"
    ],
    subs: [
      {
        icon: "iconsminds-qr-code",
        label: "Promocodes",
        to: "/app/marketing/promocodes",
        role: [
          "superAdmin",
          "customerService",
          "marketing"

        ]

      },
      {
        icon: "simple-icon-layers",
        label: "Banners",
        to: "/app/marketing/banners",
        role: [
          "superAdmin",
          "customerService",
          "marketing"

        ]
      },
      {
        icon: "simple-icon-star",
        label: "Featured Providers",
        to: "/app/marketing/featured",
        role: [
          "superAdmin",
          "customerService",
          // "marketing"

        ]

      },

      {
        icon: "iconsminds-gift-box",
        label: "Vouchers",
        to: "/app/marketing/vouchers",
        role: [
          "superAdmin",
          "customerService",
          "marketing"
        ],
        subs: [
          {
            // icon: "iconsminds-gift-box",
            label: "Vouchers / Compensations",
            to: "/app/marketing/vouchers",
            role: [
              "superAdmin",
              "customerService",
              // "marketing"
            ]

          },
          {
            // icon: "iconsminds-gift-box",
            label: "Marketing Vouchers",
            to: "/app/marketing/marketing-vouchers",
            role: [
              "superAdmin",
              "customerService",
              "marketing"
            ]

          }
        ]

      },
      // {
      //   icon: "iconsminds-gift-box",
      //   label: "Vouchers",
      //   to: "/app/marketing/vouchers",
      //   role: [
      //     "superAdmin",
      //     "customerService",
      //     // "marketing"
      //   ]

      // },
      // {
      //   icon: "iconsminds-gift-box",
      //   label: "Marketing Vouchers",
      //   to: "/app/marketing/marketing-vouchers", 
      //   role: [
      //     "superAdmin",
      //     "customerService",
      //     // "marketing"
      //   ]

      // }
    ]
  },
  {
    id: "vehicles",
    icon: "iconsminds-car",
    label: "Vehicles",
    to: "/app/vehicles",
    role: ["superAdmin"],
    subs: [
      {
        icon: "iconsminds-car",
        label: "Cars",
        to: "/app/vehicles/cars",
        role: ["superAdmin"],
      },
      {
        icon: "iconsminds-motorcycle",
        label: "Bikes",
        to: "/app/vehicles/bikes",
        role: ["superAdmin"],
      },
      {
        icon: "iconsminds-bus-2",
        label: "Caravans",
        to: "/app/vehicles/caravans",
        role: ["superAdmin"],
      },
      {
        icon: "iconsminds-yacht",
        label: "Boats",
        to: "/app/vehicles/boats",
        role: ["superAdmin"],
      },
    ]
  },
  // {
  //   id: "users",
  //   icon: "iconsminds-user",
  //   label: "Users",
  //   to: "/app/users",
  //   role: ["superAdmin"],
  //   subs: [
  //     {
  //       icon: "iconsminds-user",
  //       label: "Customers",
  //       to: "/app/users/customers"
  //     },
  //     {
  //       icon: "iconsminds-engineering",
  //       label: "Wasers",
  //       to: "/app/users/washers"
  //     }
  //   ]
  // },

];
export default data;
