import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';


import Requests from "./requests";
import Appointments from "./appointments";

const Applications = ({ match }) => {



  return (<Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/orders`} />
      <Route path={`${match.url}/requests`} component={Requests} />
      <Route path={`${match.url}/appointments`} component={Appointments} />
      <Redirect to="/error" />
    </Switch>
  </Suspense>)

};
export default Applications;
