import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";

import moment from "moment";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import { firestore } from "../../../../helpers/Firebase";
import { CalculatePoints, createNotification, getAmountColor, getAmountSign, getDescription, getTitle } from "../../../../helpers/Utils";

const WasherWallet = ({ washer }) => {
    console.log(washer)
    const [customerWallet, setCustomerWallet] = useState([])
    const [showModal, setShowModal] = useState(false)
    useEffect(() => {
        if (washer) {
            firestore().collection('washers').doc(washer.key).collection('walletLog').onSnapshot((snap) => {
                let data = snap.docs.map(rec => {
                    return { ...rec.data(), id: rec.id, key: rec.id }
                })
                data = data?.sort((a, b) => b.created - a.created)?.filter(t => !t?.voided)
                setCustomerWallet(data)
            }
            )
        }
    }, [washer])
    const settleTips = async () => {
        try {
            let data = customerWallet?.filter(t => !t?.voided && !t?.transferred);
            const totalWasherTip = CalculatePoints(data).totalWasherTip;

            if (totalWasherTip > 0) {
                const batch = firestore().batch();
                data.forEach(doc => {
                    const docRef = firestore().collection('washers').doc(washer.id).collection('walletLog').doc(doc.id);
                    batch.update(docRef, { transfered: moment().valueOf() });
                });
                await batch.commit();
                createNotification('success', 'Worker Tips settled successfully');
                setShowModal(false)
            }
        } catch (error) {
            console.error("Error settling tips: ", error);
        }
    };

    return (

        <Card className="rounded">
            <CardBody>
                <Row>
                    <Colxx xxs="12" >
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                <h4>Worker Tips</h4>
                            </div>
                            <Button
                                color="primary"
                                className="btn-shadow float-right"
                                size="sm"
                                disabled={CalculatePoints(customerWallet).totalWasherTip <= 0}
                                onClick={() => { setShowModal(true) }}>
                                Settle Total Washer Tips: AED {CalculatePoints(customerWallet).totalWasherTip.toFixed(2)}
                            </Button>
                        </div>
                    </Colxx>
                </Row>

                <Separator className="mt-4" />


                <div className="p-4" style={{ height: '500px' }}>
                    {customerWallet?.length ? customerWallet?.sort((a, b) => b.created - a.created)?.filter(t => !t?.voided)?.map((item, index) => {
                        return (
                            <>
                                <Row className={`d-flex align-items-center ${index !== 0 && "mt-4"}`}>

                                    <Colxx xxs="3" style={{ fontWeight: '700' }}>Type</Colxx>
                                    <Colxx xxs="3" style={{ fontWeight: '700' }}>Description</Colxx>
                                    <Colxx xxs="3" style={{ fontWeight: '700' }}>Created</Colxx>
                                    <Colxx xxs="3" style={{ fontWeight: '700' }}>Amount</Colxx>
                                </Row>
                                <Row key={index} className="d-flex align-items-center">

                                    <Colxx xxs="3">
                                        <span>{getTitle(item)}</span>
                                    </Colxx>
                                    <Colxx xxs="3">
                                        <span>{getDescription(item) || '--'}</span>
                                    </Colxx>
                                    <Colxx xxs="3">
                                        <span>{moment(item?.created).format('DD/MM/YYYY hh:mm A')}</span>
                                    </Colxx>
                                    <Colxx xxs="3">
                                        <span style={{ fontWeight: 'bold', color: getAmountColor(item) }}>{`${getAmountSign(item)} AED ${(Number(item.amount) * 0.9705).toFixed(2)}`}</span>

                                    </Colxx>
                                </Row>
                                <Separator className="mt-4" />
                            </>
                        )
                    }) : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '350px', fontSize: '18px' }}>No data found</div>}
                </div>
                <Modal isOpen={showModal} toggle={() => { }} size="md">
                    <ModalHeader>Settle Worker Tips</ModalHeader>
                    <ModalBody>
                        Are you sure you want to settle the worker tips?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => {
                            setShowModal(false);
                        }}>Dismiss</Button>
                        <Button color="primary" onClick={() => {
                            settleTips();
                        }}>Settle Amount</Button>
                    </ModalFooter>
                </Modal>
            </CardBody>
        </Card>
    );
};

export default WasherWallet;