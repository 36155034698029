import { createAction } from 'redux-action';
import {
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS
} from '../actions';
// export const loginUser = createAction(LOGIN_USER, (user) => {
//   return auth().signInWithEmailAndPassword(user.email, user.password).then(loginUser => {
//     localStorage.setItem('user_id', loginUser.user.uid);
//     localStorage.setItem('accessType', loginUser.user.accessType);

//   })
// });

export const loginUserSuccess = createAction("LOGIN_USER_SUCCESS", (user) => {
  return user
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history }
});