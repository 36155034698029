import React, { Component, Fragment } from "react";
import {
  Row, Button, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Label,
} from "reactstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { firestore } from '../../../helpers/Firebase'
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import moment from 'moment';
import { NotificationManager } from "../../../components/common/react-notifications";
import Switch from "rc-switch";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";

const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}
const TheadComponent = props => null; // a component returning null (to hide) or you could write as per your requirement

class PromoCodes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addNewPromocode: false,
      code: '',
      limited: '',
      count: '',
      percent: '',
      global: false,
      sp_id: '',
      services: [],
      cap: '',
      endDate: new Date(),
      isLoading: true,
      selectedPromocode: {},
      promocodes: [],
      updatePromocode: false,
      search: "",
      deletePromocode: false
    };
  }

  dataTableColumns() {
    let _this = this
    let { providers, authUser } = this.props

    return ([
      {
        accessor: "active",
        width: 100,
        Cell: props => <p className="list-item-heading">
          <Switch
            className="custom-switch custom-switch-primary"
            checked={props.value}
            onChange={switchCheckedPrimary => {
              this.setState({ isLoading: true })
              firestore().collection('offers').doc(props.original.key).update({ active: switchCheckedPrimary, updated: Date.now() }).then(function () {
                _this.getPromocodesList()
              });
            }}
          />
        </p>
      },
      {
        Header: "Code",
        accessor: "code",
        width: 100,
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },

      {
        Header: "Number of Promos",
        accessor: "count",
        width: 130,
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Discount Value",
        accessor: "percent",
        width: 130,
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value}%</p>
      },
      {
        Header: "Maximum Value",
        accessor: "cap",
        width: 130,
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value} AED</p>
      },
      {
        Header: "Created",
        accessor: "created",
        width: 130,
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{moment(props.value).format('DD/MM/YYYY')}</p>
      },
      {
        Header: "Expiers",
        accessor: "endDate",
        width: 130,
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{moment(props.value).format('DD/MM/YYYY')}</p>
      },
      {
        Header: "Service Provider",
        accessor: "sp_id",
        Cell: props => <p className="providerfieldText">{providers[props.value] ? providers[props.value].name : ''}</p>
      },
      {
        width: 80,
        accessor: "delete",
        show: (authUser?.user?.accessType === 'superAdmin') && deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">
          <Button
            color="danger"
            size="sm"
            outline
            onClick={() => this.setState({ deletePromocode: true, selectedPromocode: props.original })}
          >
            Delete
          </Button>
        </p>
      },
      {
        width: 70,
        accessor: "edit",
        Cell: props => <p className="providerfieldText">
          <Button
            color="primary"
            size="sm"
            outline
            className="top-right-button"
            onClick={() => this.onClickItem(props.original)}
          >
            Edit
          </Button>
        </p>
      }
    ])


  }
  deletePromocode(PromoCode) {
    let _this = this
    firestore().collection("offers").doc(PromoCode.key).delete().then(function () {
      _this.setState({ deletePromocode: false, selectedPromocode: {} }, () => { _this.getPromocodesList() })
    }).catch(function (error) {
      _this.setState({ deletePromocode: false, selectedPromocode: {} }, () => { _this.getPromocodesList() })
    });
  }

  componentDidMount() {
    this.getPromocodesList()
  }
  getPromocodesList() {
    let { promocodes } = this.state
    let _this = this
    promocodes = [];
    firestore().collection('offers').get().then(function (promoCodesSnapshot) {
      promoCodesSnapshot.forEach(function (doc) {
        let promocode = {}
        promocode = doc.data()
        promocode.key = doc.id
        promocodes.push(promocode);
      });
      _this.setState({ promocodes, isLoading: false })
    });

  }
  createNotification = (className) => {
    let cName = className || "";
    return (
      NotificationManager.error(
        "Please fill all information required!",
        "Error",
        2000,
        null,
        cName
      )
    )
  };

  submit() {
    let { code, limited, percent, global, sp_id, services, cap, updatePromocode, selectedPromocode, endDate, count } = this.state
    let key = selectedPromocode.key ? selectedPromocode.key : firestore().collection('offers').doc().id
    let _this = this
    if (key) {
      if (!updatePromocode) {
        firestore().collection('offers').doc(key).set({
          active: true,
          code: code.toLowerCase(),
          limited: limited ? limited : 'unlimited',
          cap: Number(cap),
          count: Number(count),
          endDate: moment(endDate).toDate().getTime(),
          percent: Number(percent),
          global: global && sp_id ? global : false,
          sp_id: sp_id,
          services: services,
          created: Date.now(),
        }).then(() => {
          _this.updateGlobalPromocode()
        })
      } else {
        firestore().collection('offers').doc(key).update({
          code: code.toLowerCase(),
          limited: limited ? limited : 'unlimited',
          count: Number(count),
          cap: Number(cap),
          endDate: moment(endDate).toDate().getTime(),
          percent: Number(percent),
          global: global && sp_id ? global : false,
          sp_id: sp_id,
          services: services||[],
          updated: Date.now(),
        }).then(() => {
          _this.updateGlobalPromocode()
        })
      }
    }

  }
  updateGlobalPromocode = () => {
    let { selectedPromocode, promocodes, global, sp_id } = this.state
    let _this = this

    if (global && (selectedPromocode.sp_id || sp_id)) {
      if (promocodes.filter(rec => rec.sp_id === (selectedPromocode.sp_id || sp_id)).length > 1) {
        promocodes.map(item => {
          return (
            item.key !== selectedPromocode.key && item.sp_id === (selectedPromocode.sp_id || sp_id) && (
              firestore().collection('offers').doc(item.key).update({
                global: false,
                updated: Date.now(),
              }).then(() => {
                _this.getPromocodesList()
                _this.setState({ updatePromocode: false, addNewPromocode: false, promocodes, selectedPromocode: {}, code: '', limited: '', percent: '', global: false, sp_id: '', services: [], cap: '', count: '', endDate: new Date(), selectedSP: {} })
              })
            ))

        })
      } else {
        _this.getPromocodesList()
        _this.setState({ updatePromocode: false, addNewPromocode: false, promocodes, selectedPromocode: {}, code: '', limited: '', percent: '', global: false, sp_id: '', services: [], cap: '', count: '', endDate: new Date(), selectedSP: {} })
      }
    } else {
      _this.getPromocodesList()
      _this.setState({ updatePromocode: false, addNewPromocode: false, promocodes, selectedPromocode: {}, code: '', limited: '', percent: '', global: false, sp_id: '', services: [], cap: '', count: '', endDate: new Date(), selectedSP: {} })
    }
  };
  toggleModal = () => {
    this.setState({ addNewPromocode: true })
  };
  onSearchKey = e => {
    this.setState(
      {
        search: e.target.value.toLowerCase()
      }
    );
  };
  onClickItem = (promoCode) => {
    let { promocodes } = this.state
    this.setState({ updatePromocode: true, selectedPromocode: promoCode, code: promoCode.code, limited: promoCode.limited, count: promoCode.count, percent: promoCode.percent, global: promoCode.global, sp_id: promoCode.sp_id, services: promoCode?.services, cap: promoCode.cap, endDate: promoCode.endDate ? moment(promoCode.endDate) : '', promocodes: promocodes })
  };




  render() {
    let { providers } = this.props
    let { code, limited, cap, percent, global, sp_id, services, endDate, promocodes, updatePromocode, addNewPromocode, search, selectedPromocode, deletePromocode, count } = this.state
    let allServices = this.props.services && this.props.advServices && Object.values(this.props.services).concat(Object.values(this.props.advServices))
    let filteredPromocodes = promocodes.filter(item => {
      return (item.code).toLowerCase().includes(search.toLowerCase())
    })
    return (

      <Fragment>
        {this.state.isLoading && (
          <div className="loading" />
        )
        }

        <div className="disable-text-selection">

          <ListPageHeading
            heading="Promo Codes"
            onSearchKey={this.onSearchKey}
            toggleModal={this.toggleModal}
          />

          <Row className="survey-app">
            <Colxx xxs="12">
              {
                filteredPromocodes && filteredPromocodes.length ?

                  (deviceWidth() === 'mobile') ?
                    (
                      <ReactTable
                        data={filteredPromocodes}
                        columns={this.dataTableColumns()}
                        defaultPageSize={10}
                        pageSize={filteredPromocodes.length < 10 ? filteredPromocodes.length : 10}
                        showPageJump={false}
                        defaultSorted={[
                          {
                            id: "active",
                            desc: true
                          }
                        ]}
                        showPageSizeOptions={false}
                        PaginationComponent={DataTablePagination}
                        className='custuomReactTable'
                        TheadComponent={TheadComponent}
                      />
                    ) :
                    <ReactTable
                      data={filteredPromocodes}
                      columns={this.dataTableColumns()}
                      defaultPageSize={10}
                      pageSize={filteredPromocodes.length < 10 ? filteredPromocodes.length : 10}
                      showPageJump={false}
                      defaultSorted={[
                        {
                          id: "active",
                          desc: true
                        }
                      ]}
                      showPageSizeOptions={false}
                      PaginationComponent={DataTablePagination}
                      className='custuomReactTable' />
                  :

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Promocodes</div>
              }
            </Colxx>
          </Row>
          <Modal wrapClassName="modal-right" backdrop="static" isOpen={addNewPromocode || updatePromocode}>
            <ModalHeader >
              {addNewPromocode ?
                "Create New Promo Code" :
                "Edit Promo Code"
              }
            </ModalHeader>
            <ModalBody>

              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2">Enter Promo Code:</Label>
                <Input
                  className='mb-4'
                  placeholder={'Promo code'}
                  type='text'
                  id='code'
                  value={code}
                  onChange={(e) => {
                    code = e.target.value
                    this.setState({ code })
                  }}
                />
              </Colxx>

              <Colxx xxs="12" className={sp_id ? "mb-2" : "mb-4"}>
                <Label className="mb-2">Service Provider (optional):</Label>
                <Select
                  components={{ Input: CustomSelectInput }}
                  className="react-select"
                  classNamePrefix="react-select"
                  key='selectedSP'
                  name="form-field-name"
                  placeholder="Select service provider"
                  options={providers && Object.values(providers)}
                  value={sp_id ? providers[sp_id] : ""}
                  onChange={val => {
                    sp_id = val.key
                    this.setState({ selectedSP: val, sp_id });
                  }}
                />

              </Colxx>
              {sp_id && <Colxx xxs="12" className="mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                <div style={{ width: '65%' }}>
                  <Label style={{ marginBottom: '0' }}>Auto Applied:</Label>
                  <p style={{ fontSize: '10px', color: 'gray', lineHeight: '14px' }}>
                    * Enabling this will disable it in all other promo codes for this service provider
                  </p>
                </div>
                <Switch
                  className="custom-switch custom-switch-primary"
                  checked={global}
                  onChange={switchCheckedPrimary => {
                    this.setState({ global: switchCheckedPrimary })
                  }}
                />
              </Colxx>}
              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2">Discount Value (%):</Label>
                <Input
                  className='mb-4'
                  placeholder={'Promo code value'}
                  type='number'
                  id='percent'
                  value={percent}
                  onChange={(e) => {
                    percent = e.target.value
                    this.setState({ percent })
                  }}
                />
              </Colxx>
              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2">Maximum Value (AED):</Label>
                <Input
                  className='mb-4'
                  placeholder={'promo code limit'}
                  type='number'
                  id='cap'
                  value={cap}
                  onChange={(e) => {
                    cap = e.target.value
                    this.setState({ cap })
                  }}
                />
              </Colxx>
              <Colxx xxs="12" className={"mb-4"}>
                <Label className="mb-2">Services (optional):</Label>
                <Select
                  isMulti
                  components={{ Input: CustomSelectInput }}
                  className="react-select"
                  classNamePrefix="react-select"
                  key='selectedServices'
                  name="form-field-name"
                  placeholder="Select services"
                  options={allServices || []}
                  value={allServices?.filter(r => services?.includes(r.key))}
                  onChange={val => {
                    services = val?.map(r => r.key)
                    this.setState({ services });
                  }}
                />

              </Colxx>
              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2">Number Of Promos:</Label>
                <Input
                  className='mb-4'
                  placeholder={'promo code limit'}
                  type='number'
                  id='count'
                  value={count}
                  onChange={(e) => {
                    count = e.target.value
                    this.setState({ count })
                  }}
                />
              </Colxx>
              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2">Expiration Date:</Label>
                <DatePicker
                  selected={moment(endDate).toDate()}
                  placeholderText='Select Date'
                  className="customDatePicker"
                  onChange={date => {
                    this.setState({ endDate: date })
                  }}
                  dateFormat="dd/MM/yyyy"
                />
              </Colxx>
              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2">Limited Per User:</Label>
                <Input
                  className='mb-4'
                  placeholder={'promo code limit'}
                  type='number'
                  id='limited'
                  value={limited}
                  onChange={(e) => {
                    limited = e.target.value
                    this.setState({ limited })
                  }}
                />
              </Colxx>


            </ModalBody>
            <ModalFooter>
              <Button color='danger' onClick={() => this.setState({ addNewPromocode: false, updatePromocode: false, selectedPromocode: {}, code: '', limited: '', percent: '', global: false, sp_id: '', services: [], cap: '', count: '' }, () => { this.getPromocodesList() })}>Cancel</Button>
              <Button onClick={() =>
                code !== "" && count !== "" && percent !== "" && cap !== "" && endDate !== "" ?
                  this.setState({ isLoading: true }, () => this.submit()) : this.createNotification("error")}>{updatePromocode ? "Save" : "Create"}</Button>

            </ModalFooter>

          </Modal>

          <Modal backdrop="static" isOpen={deletePromocode}>
            <ModalHeader >
              Delete Promo code
            </ModalHeader>
            <ModalBody>
              <Row>
                <Colxx xxs="12" className="mb-4">
                  <Label className="mb-2">Are you sure you want to delete this promo code?</Label>
                </Colxx>
              </Row>

            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.setState({ deletePromocode: false, selectedPromocode: {} })}>Dismiss</Button>
              <Button color='danger' onClick={() => this.setState({ isLoading: true }, () => { this.deletePromocode(selectedPromocode) })}>Delete Promo code</Button>
            </ModalFooter>

          </Modal>
        </div>
      </Fragment>
    )

  }
}
const mapStateToProps = ({ providers, authUser }) => {
  return {
    authUser,
    providers: providers ? providers.provider : {},
    services: providers ? providers.services : {},
    advServices: providers ? providers.advServices : {},
  };
};

export default injectIntl(connect(mapStateToProps)(PromoCodes));


