import React, { Component } from "react";
import { injectIntl } from "react-intl";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import OutletImg from '../../assets/css/img/CarWashCenter.png';
import {
  changeLocale,
  clickOnMobileMenu,
  logoutUser,
  setContainerClassnames,
} from "../../redux/actions";


import { MenuIcon, MobileMenuIcon } from "../../components/svg";
import { auth } from '../../helpers/Firebase';
import { accessTypes, accessTypesObject } from "../../helpers/Utils";
import TopnavNotifications from "./Topnav.Notifications";
class TopNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInFullScreen: false,
    };
  }


  isInFullScreen = () => {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    );
  };

  componentWillMount() {

    // auth().onAuthStateChanged(user => {
    //   if (user) {
    //     user.getIdTokenResult(true).then(result => {
    //       let { role } = result.claims
    //       if (["admin", "superAdmin"].includes(role)) {
    //         let post = {
    //           email: user.email,
    //           uid: user.uid,
    //           displayName: user.displayName,
    //           accessType: role,
    //           phoneNumber: user.phoneNumber
    //         }
    //         this.props.loginUserSuccess(post)
    //       } else {
    //         this.props.logoutUser(this.props.history);

    //       }

    //     })
    //   }
    // })
  }


  toggleFullScreen = () => {
    const isInFullScreen = this.isInFullScreen();

    var docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    this.setState({
      isInFullScreen: !isInFullScreen
    });
  };

  handleLogout = () => {
    auth().signOut().then(() => {
      window.location.reload()
    })

  };

  menuButtonClick = (e, menuClickCount, containerClassnames) => {
    e.preventDefault();

    setTimeout(() => {
      var event = document.createEvent("HTMLEvents");
      event.initEvent("resize", false, false);
      window.dispatchEvent(event);
    }, 350);
    this.props.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.props.selectedMenuHasSubItems
    );
  };
  mobileMenuButtonClick = (e, containerClassnames) => {
    e.preventDefault();
    this.props.clickOnMobileMenu(containerClassnames);
  };

  render() {
    const { containerClassnames, menuClickCount, authUser, provider, bookings } = this.props;
    return (
      <nav className="navbar fixed-top">
        <div className="d-flex align-items-center navbar-left">
          <NavLink
            to="#"
            className="menu-button d-none d-md-block"
            onClick={e =>
              this.menuButtonClick(e, menuClickCount, containerClassnames)
            }
          >
            <MenuIcon />
          </NavLink>
          <NavLink
            to="#"
            className="menu-button-mobile d-xs-block d-sm-block d-md-none"
            onClick={e => this.mobileMenuButtonClick(e, containerClassnames)}
          >
            <MobileMenuIcon />
          </NavLink>


        </div>
        <a className="navbar-logo" href="/">
          <span className="logo d-none d-xs-block" />
          <span className="logo-mobile d-block d-xs-none" />
        </a>
        <div className="navbar-right">

          <div className="header-icons d-inline-block align-middle">
            {bookings?.filter(b => b.delayed)?.length ?
              <a href="/app/orders/appointments">
                <Button color='danger' size='sm' style={{ verticalAlign: 'middle', fontWeight: '700' }}>
                  {`${bookings?.filter(b => b.delayed)?.length} Appointments(s) Needs Your Attention`}
                </Button>
              </a>
              : ''}
            <TopnavNotifications />
            <button
              className="header-icon btn btn-empty d-none d-sm-inline-block"
              type="button"
              id="fullScreenButton"
              onClick={this.toggleFullScreen}
            >
              {this.state.isInFullScreen ? (
                <i className="simple-icon-size-actual d-block" />
              ) : (
                <i className="simple-icon-size-fullscreen d-block" />
              )}
            </button>
          </div>
          <div className="user d-inline-block">
            <UncontrolledDropdown className="dropdown-menu-right">
              {
                (accessTypes.includes(authUser?.user?.accessType) ?
                  <DropdownToggle className="p-0" color="empty">

                    <span className="name mr-1">{authUser.user.displayName}</span>
                    <span className="name mr-1">{'( ' + accessTypesObject?.[authUser?.user?.accessType]?.name + ' )'}</span>
                    <span>
                      <img alt="Profile" src={OutletImg} />
                    </span>
                  </DropdownToggle> :
                  <DropdownToggle className="p-0" color="empty">

                    <span className="name mr-1">{authUser.user.accessType === 'admin' && provider && Object.values(provider)[0]?.name}</span>
                    <span className="name mr-1">{'( ' + accessTypesObject?.[authUser?.user?.accessType]?.name + ' )'}</span>

                    <span>
                      <img alt="Profile" src={authUser.user.accessType === 'admin' && provider && Object.values(provider)[0]?.pictures && Object.values(provider)[0]?.pictures[0] ? Object.values(provider)[0]?.pictures[0] : OutletImg} />
                    </span>
                  </DropdownToggle>)
              }

              <DropdownMenu className="mt-3" right>
                {/* <DropdownItem>Account</DropdownItem>
                <DropdownItem>Features</DropdownItem>
                <DropdownItem>History</DropdownItem>
                <DropdownItem>Support</DropdownItem>
                <DropdownItem divider /> */}
                {authUser.user.accessType === "superAdmin" && <>
                  <Link to={"/app/admins"} onClick={() => { }
                  }>
                    <DropdownItem >
                      {/* <i className={sub.icon} />{" "} */}
                      Users and permissions

                    </DropdownItem>
                  </Link>

                  <DropdownItem divider />
                </>}
                <DropdownItem onClick={() => this.handleLogout()}>
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = ({ menu, settings, authUser, providers }) => {
  const { containerClassnames, menuClickCount, selectedMenuHasSubItems } = menu;
  const { locale } = settings;
  return {
    containerClassnames,
    menuClickCount,
    selectedMenuHasSubItems,
    locale,
    authUser,
    provider: providers ? providers.provider : {},
    bookings: providers ? providers.bookings : {},

  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    { setContainerClassnames, clickOnMobileMenu, logoutUser, changeLocale }
  )(TopNav)
);
