import moment from "moment";
import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Badge, Modal, ModalBody, ModalHeader } from "reactstrap";
import { accessTypesArray, deepCompare } from "../../../../helpers/Utils";
import Rating from "../../../../components/common/Rating";
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
const colors = {
  reAssignProvider: '#145388',
  reAssignWorker: '#145388',
  rescheduleDate: '#145388',
  newAppointment: '#3498db',
  rejectedAppointment: '#c43d4b',
  canceledAppointment: '#c43d4b',
  statusChanged: '#3e884f',
  ratedAppointment: '#b69329',
  superAdminComment: '#000',
  cancellationFeesApplied: '#c43d4b'

}
class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  generateMsg(log, selectedBooking) {
    let msg = ''
    let createdBy = `${log?.createdByName || ''} ${capitalize(log.createdBy)}`
    switch (log.title) {
      case 'newAppointment':
        msg = createdBy + (accessTypesArray.includes(log.createdBy) ? ' created' : ' booked ') + ' appointment'
        break;
      case 'statusChanged':
        if (accessTypesArray.includes(log.createdBy) && log.createdBy !== 'Provider') {
          msg = createdBy + ' changed status from ' + log.oldsStatus + ' to ' + (log.status === 'new' ? 'pending' : log.status)

        } else {
          log.oldsStatus === 'pending' || log.oldsStatus === 'new' ?
            msg = createdBy + ' accepted appointment'
            : log.oldsStatus === 'accepted' ?
              msg = createdBy + ' arrived to customer'
              : msg = createdBy + ' completed appointment'

        }

        break;

      case 'ratedAppointment':
        msg = (
          <div>
            {createdBy + ' rated ' + log.service + ' with ' + log.score + ' stars'}<br />
            {'Feedback: ' + (log.comment || 'No Comment')}
            {selectedBooking.score ? <div>Average Rate: {selectedBooking.score}</div> : ''}
            {selectedBooking.serviceRate ? <div className="d-flex align-items-center">Service Rate: <Rating total={5} rating={selectedBooking.serviceRate} style={{ margin: '4px 8px' }} /></div> : ''}
            {selectedBooking.workerRate ? <div className="d-flex align-items-center">Worker Rate: <Rating total={5} rating={selectedBooking.workerRate} style={{ margin: '4px 8px' }} /></div> : ''}
            {selectedBooking.arrivalTimingRate ? <div className="d-flex align-items-center">Arrival Timing Rate: <Rating total={5} rating={selectedBooking.arrivalTimingRate} style={{ margin: '4px 8px' }} /></div> : ''}
          </div>
        );
        break;
      case 'rejectedAppointment':
        msg = <div>{createdBy + ' rejected appointment'}<br />
          {'Reason: ' + (selectedBooking?.cancelReason || 'No Comment')}</div>
        break;
      case 'canceledAppointment':
        msg = <div>{createdBy + ' canceled appointment'}<br />
          {'Reason: ' + (selectedBooking?.cancelReason || 'No Comment')}</div>
        break;
      case 'reAssignProvider':
        msg = createdBy + ' reassigned service provider from ' + log.oldProviderName + ' to ' + log.provider_name
        break;
      case 'reAssignWorker':
        msg = createdBy + ' reassigned worker from ' + log.oldWasherName + ' to ' + log.washer_name
        break;
      case 'rescheduleDate':
        msg = createdBy + ' recheduled appointment from ' + moment(log.oldBookingDate).format("DD/MM/YYYY hh:mm A") + ' to ' + moment(log.bookingDate).format("DD/MM/YYYY hh:mm A")
        break;
      case 'superAdminComment':
        msg = <div>{createdBy + ' left comment'}<br />
          {'Comment: ' + (log.comment || 'No Comment')}</div>
        break;
      case 'cancellationFeesApplied':
        msg = createdBy + ' applied cancellation fees for this order'
        break;
      case 'vehicleModified':
        let addedVehicles = deepCompare(log.oldVehicle, log.newVehicle).filter(r => r.origin === 'newVehicle').length
        let removedVehicle = deepCompare(log.oldVehicle, log.newVehicle).filter(r => r.origin === 'oldVehicle').length
        msg = <div>{createdBy + (addedVehicles ? ` added ${addedVehicles} vehicles` : '') + (removedVehicle ? ` ${addedVehicles ? 'and' : ''} removed ${removedVehicle} vehicles` : '')}<br />
          <div className="mt-2">
            {deepCompare(log.oldVehicle, log.newVehicle)?.map(c => {
              return (
                <p className="d-sm-inline-block mb-1 mr-1" key={c.vehicleId}>
                  <Badge
                    className="mb-1"
                    color={c.origin === 'newVehicle' ? 'primary' : 'danger'}
                    pill>
                    {c?.car_model + " " + c?.modelNumber?.model}
                  </Badge>
                </p>
              )
            })}
          </div>
        </div>
        break
      default:
        break;
    }
    return msg
  }


  render() {
    let { dismiss, logsOpen, logs, selectedBooking } = this.props
    return (
      <Modal
        isOpen={logsOpen}
        style={{ boxShadow: 'none', maxWidth: '600px', top: '30px', overflow: 'hidden', borderRadius: '14px' }}
        toggle={dismiss}
        backdrop="static"
        size="lg">
        <ModalHeader toggle={dismiss}>Logs</ModalHeader>
        <ModalBody >
          <div className="dashboard-logs">
            {logs?.data ? <PerfectScrollbar
              option={{ suppressScrollX: true, wheelPropagation: false }}>
              <table className="table table-sm table-borderless">
                <tbody>
                  {logs?.data?.map((log, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span style={{ borderColor: colors[log.title] }}
                            className={`log-indicator align-middle ${log.color
                              }`}
                          />
                        </td>
                        <td>
                          <span className="font-weight-medium">
                            {this.generateMsg(log, selectedBooking)}
                          </span>
                        </td>
                        <td className="text-right" style={{ width: '29%' }}>
                          <span className="text-muted">{moment(log.created).format("DD/MM/YYYY hh:mm A")}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </PerfectScrollbar> :
              <div style={{
                width: '100%',
                fontSize: '18px',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: '600'
              }}>No data to show</div>
            }
          </div>
        </ModalBody >
      </Modal >);
  }

};
export default Logs;
