import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import {firebaseConfig} from '../constants/defaultValues'

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth;
const firestore = firebase.firestore;
const storage = firebase.storage;
const functions = firebase.functions;

export {
   auth,
   firestore,
   storage,
   functions
};
